import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Program = (props) => (
  <SvgIcon {...props} viewBox="0 0 21 21">
    <svg
      width="5.5562501mm"
      height="5.5562501mm"
      viewBox="0 0 5.5562501 5.5562501"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(-102.22188,-145.72188)">
        <g
          transform="matrix(0.26458333,0,0,0.26458333,104.88807,147.9192)"
        >
          <path
            d="M 5.74587,0 C 5.63097,0 5.48727,0.0276203 5.40107,0.11045 L 1.92372,3.36859 0.745456,2.3746 c -0.2299,-0.16567 -0.48855,-0.13806 -0.63224,0.05522 -0.1724201,0.19329 -0.1436903,0.4694 0.05748,0.60747 l 1.465644,1.2425 c 0.02874,0.0276 0.08621,0.0828 0.17243,0.0828 0.05747,0.0277 0.1437,0.0277 0.1724,0.0277 0.1437,0 0.2587,-0.0553 0.3449,-0.1105 L 6.11947,0.74552 C 6.20567,0.66268 6.26317,0.55224 6.26317,0.44179 6.26317,0.33134 6.20567,0.2209 6.11947,0.13806 5.97577,0.0552301 5.86087,0 5.74587,0 Z"
            fill="currentColor"
          />
        </g>
        <g
          id="g310"
          transform="matrix(0.26458333,0,0,0.26458333,102.22188,145.72188)"
        >
          <path
            d="M 18.025,0 H 4.305 C 2.66,0 1.33,1.25078 1.33,2.79781 V 4.11442 H 0 v 0.95455 h 1.33 v 4.7398 H 0 v 0.95453 h 1.33 v 4.7069 H 0 v 0.9546 h 1.33 v 1.7774 C 1.33,19.7492 2.66,21 4.305,21 h 13.72 C 19.67,21 21,19.7492 21,18.2022 V 2.73197 C 21,1.25078 19.67,0 18.025,0 Z M 19.88,2.79781 V 18.2351 c 0,0.9546 -0.84,1.7445 -1.855,1.7445 H 7.105 V 1.05329 h 10.92 c 1.015,0 1.855,0.78997 1.855,1.74452 z M 2.485,15.5031 v -4.7398 h 1.4 V 9.80877 h -1.4 v -4.7398 h 1.4 V 4.11442 h -1.4 V 2.79781 c 0,-0.95455 0.84,-1.74452 1.855,-1.74452 H 6.125 V 19.9467 H 4.34 c -1.015,0 -1.855,-0.757 -1.855,-1.7445 v -1.7445 h 1.4 V 15.536 h -1.4 z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  </SvgIcon>
);

export default Program;
