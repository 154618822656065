import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';

const ActivityFilledIcon = (props) => {
  const { color } = props;
  const primaryColor = color === 'primary' ? '#BA9B53' : 'white';

  return (
    <SvgIcon {...props}>
      <svg width="63" height="50" viewBox="20 14 63 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_214_309" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="34" y="15" width="3" height="5">
          <path d="M34.8633 15.0001H36.9961V19.0104H34.8633V15.0001Z" fill={primaryColor} />
        </mask>

        <g>
          <path d="M36.95 16.0766V17.8633C36.95 18.4519 36.4956 18.9324 35.9358 18.9324C35.3795 18.9324 34.9258 18.4519 34.9258 17.8633V16.0766C34.9258 15.4849 35.3802 15.0038 35.9358 15.0038C36.4956 15.0041 36.95 15.4849 36.95 16.0766Z" fill={primaryColor} />
        </g>

        <mask id="mask1_214_309" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="26" y="15" width="3" height="5">
          <path d="M26.0039 15.0001H28.1367V19.0104H26.0039V15.0001Z" fill={primaryColor} />
        </mask>

        <g>
          <path d="M28.0736 16.0766V17.8633C28.0736 18.4519 27.6195 18.9324 27.0632 18.9324C26.5034 18.9324 26.0491 18.4519 26.0491 17.8633V16.0766C26.0491 15.4849 26.5034 15.0038 27.0632 15.0038C27.6195 15.0041 28.0736 15.4849 28.0736 16.0766Z" fill={primaryColor} />
        </g>

        <mask id="mask2_214_309" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="22" y="17" width="19" height="6">
          <path d="M22.3336 17.2743H40.6676V22.1354H22.3336V17.2743Z" fill={primaryColor} />
        </mask>

        <g>
          <path d="M40.6657 19.9843V22.0564H22.3336V19.9843C22.3336 18.5143 23.4625 17.3163 24.8513 17.3163H25.3909V17.8633C25.3909 18.8385 26.1413 19.6292 27.0632 19.6292C27.9848 19.6292 28.7321 18.8385 28.7321 17.8633V17.3163H34.2676V17.8633C34.2676 18.8385 35.0148 19.6292 35.9357 19.6292C36.8576 19.6292 37.6081 18.8385 37.6081 17.8633V17.3163H38.1442C39.5336 17.3163 40.6657 18.515 40.6657 19.9843Z" fill={primaryColor} />
        </g>

        <mask id="mask3_214_309" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="22" y="22" width="19" height="13">
          <path d="M22.3336 22.743H40.6676V34.987H22.3336V22.743Z" fill={primaryColor} />
        </mask>

        <g>
          <path d="M22.3336 22.7529V32.3269C22.3336 33.7972 23.4625 34.9948 24.8513 34.9948H38.1442C39.5336 34.9948 40.6657 33.7972 40.6657 32.3269V22.7529H22.3336ZM35.1334 26.3926L30.9632 31.1988C30.9565 31.2056 30.9536 31.2056 30.9466 31.209C30.9434 31.2164 30.9405 31.2195 30.937 31.2266C30.9168 31.2442 30.8976 31.251 30.8774 31.2649C30.8642 31.272 30.8543 31.2788 30.8412 31.2859C30.8017 31.3036 30.7623 31.3141 30.7194 31.3141C30.6761 31.3141 30.6338 31.3036 30.5944 31.2859C30.5813 31.2788 30.5714 31.2686 30.5582 31.2618C30.5387 31.2479 30.5153 31.2374 30.4957 31.2201C30.4925 31.2164 30.489 31.2096 30.4861 31.2059C30.4826 31.1995 30.4765 31.1995 30.473 31.1957L27.9287 28.1277C27.8108 27.9815 27.8239 27.7622 27.9585 27.6367C28.0966 27.5082 28.304 27.5214 28.4225 27.6682L30.7268 30.444L34.6498 25.9233C34.7719 25.7806 34.9792 25.77 35.1138 25.8992C35.2484 26.0308 35.2548 26.2495 35.1334 26.3926Z" fill={primaryColor} />
        </g>
      </svg>
    </SvgIcon>
  );
};

ActivityFilledIcon.propTypes = {
  color: PropTypes.string,
};

ActivityFilledIcon.defaultProps = {
  color: 'primary',
};

export default ActivityFilledIcon;
