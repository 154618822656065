import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PropTypes from 'prop-types';
import InputBase from '../InputBase';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    fontWeight: '400',
  },
  select: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  small: {
    width: '220px',
  },
  medium: {
    width: '320px',
  },
  large: {
    width: '420px',
  },
}));

const renderValue = (selected) => selected.join(', ');
const SelectInput = (props) => {
  const { value: selectValue, defaultValue } = props;
  const [checkedItems, setCheckedItems] = useState(selectValue ? undefined : defaultValue);
  const {
    options, handleChange, size, emptyValueLabel,
    multiple, disabled, helperText, ...other
  } = props;
  const classes = useStyles();
  let completeOptions = options;
  if (emptyValueLabel) completeOptions = [{ label: emptyValueLabel, value: '' }, ...options];
  return (
    <FormControl
      classes={{
        root: clsx(classes.root,
          size === 'small' && classes.small,
          size === 'medium' && classes.medium,
          size === 'large' && classes.large),
      }}
    >
      <Select
        classes={{
          select: classes.select,
        }}
        displayEmpty
        margin="dense"
        multiple={multiple}
        IconComponent={KeyboardArrowDownIcon}
        value={checkedItems ? selectValue : checkedItems}
        renderValue={multiple && renderValue}
        input={(
          <InputBase
            disabled={disabled}
            handleChange={defaultValue ? setCheckedItems : handleChange}
          />
        )}
        {...other}
      >
        {completeOptions.map(({ value, label }) => (
          <MenuItem
            key={value}
            value={value}
          >
            {multiple ? (
              <FormControlLabel
                label={label}
                checked={selectValue ? selectValue.includes(value) : checkedItems.includes(value)}
                control={(
                  <Checkbox
                    color="primary"
                  />
                )}
              />

            ) : (
              label
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SelectInput.propTypes = {
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  emptyValueLabel: PropTypes.string,
  size: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  handleChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  })).isRequired,
};

SelectInput.defaultProps = {
  value: undefined,
  defaultValue: undefined,
  handleChange: undefined,
  helperText: undefined,
  emptyValueLabel: undefined,
  size: undefined,
  multiple: undefined,
  disabled: undefined,
};

export default SelectInput;
