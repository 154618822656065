import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import DenyIcon from '@material-ui/icons/HighlightOff';
import AllowIcon from '@material-ui/icons/CheckCircleOutline';
import Button from 'eventtia-ui-components/lib/Button';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import callApi from '../../actions/callApi';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2),
    minWidth: 0,
    marginLeft: theme.spacing(-1),
  },
}));

const DenyParticipant = ({
  participant, denyList, callApi: dispatchCallApi, isFetching, setMessage, disabled,
}) => {
  const { t } = useTranslation('attendees');
  const classes = useStyles();

  const denied = !!Object.values(denyList).find(
    ({ blacklistedParticipant: { id: participantId } }) => participantId === participant.id
  );

  const { id: participantId, businessConference: { id: businessConferenceId } } = participant;
  const updateDenyList = (action) => dispatchCallApi(action, {
    businessConferenceId, participantId,
  }).then(({ error }) => {
    if (error) setMessage({ type: 'error', text: error });
    else setMessage();
  });
  const allow = () => updateDenyList('allowParticipant');
  const deny = () => updateDenyList('denyParticipant');

  return isFetching ? (
    <CircularProgress
      size={20}
      thickness={4.5}
      color="secondary"
    />
  ) : (
    <Button
      className={classes.root}
      icon={denied ? <AllowIcon /> : <DenyIcon />}
      onClick={denied ? allow : deny}
      disabled={disabled || isFetching}
      variant="tertiary"
    >
      {t(denied ? 'actions.allowParticipant' : 'actions.denyParticipant')}
    </Button>
  );
};

DenyParticipant.propTypes = {
  participant: CustomPropTypes.participant.isRequired,
  denyList: PropTypes.objectOf(CustomPropTypes.deniedParticipant),
  callApi: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  setMessage: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

DenyParticipant.defaultProps = {
  denyList: {},
  disabled: false,
};

const mapStateToProps = ({
  entities: {
    businessConferenceParticipantBlacklists: denyList,
  },
  fetchStatus: {
    denyParticipant: { isFetching: denying },
    allowParticipant: { isFetching: allowing },
    ratings: { isFetching: fetchingDenyList },
  },
}) => ({
  denyList,
  isFetching: denying || allowing || fetchingDenyList,
});

export default connect(mapStateToProps, { callApi })(DenyParticipant);
