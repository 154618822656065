import humps from 'humps';
import { getLocale } from './getters';
import { EVENT_URI } from './constants';

const endpoints = {
  login: () => `events/${EVENT_URI}/app-auth`,
  accessToken: () => `events/${EVENT_URI}/app-access-token`,
  ssoAuth: () => 'app-sso-auth',
  attendeeTypes: () => `events/${EVENT_URI}/attendee_types`,
  attendees: ({
    attendeeTypes, sort, offsetToUtc, showOnlyConfirmed,
  }) => `events/${EVENT_URI}/attendees?order=${sort || 'first_name'}${attendeeTypes ? `&attendee_types=${attendeeTypes}` : ''}&include=business_conference_participants,business_conference_participants.participant_type${showOnlyConfirmed ? '&only_confirmed=true' : ''}${offsetToUtc ? '&offset_to_utc=true' : ''}`,
  singleAttendee: ({ id, offsetToUtc }) => `events/${EVENT_URI}/attendees/${id}?include=attendee_type_custom_fields,attendee_type,business_conference_participants,business_conference_participants.participant_type${offsetToUtc ? '&offset_to_utc=true' : ''}`,
  singleeAttendeeSimple: ({ id }) => `events/${EVENT_URI}/attendees/${id}?include=attendee_type`,
  currentAttendee: ({ id, offsetToUtc }) => `events/${EVENT_URI}/attendees/${id}?include=attendee_type_custom_fields${offsetToUtc ? '&offset_to_utc=true' : ''}`,
  // this prevents LoggedInLayout from reloading the whole app
  refetchCurrentAttendee: ({ id, offsetToUtc }) => `events/${EVENT_URI}/attendees/${id}?include=attendee_type_custom_fields${offsetToUtc ? '&offset_to_utc=true' : ''}`,
  updateCurrentAttendee: ({ id }) => `events/${EVENT_URI}/attendees/${id}?include=attendee_type_custom_fields&offset_to_utc=true`,
  settings: () => `events/${EVENT_URI}/app_settings`,
  manifest: () => `events/${EVENT_URI}/app_settings/manifest`,
  meeting: ({ businessConferenceId, meetingId, offsetToUtc }) => `events/${EVENT_URI}/business_conferences/${businessConferenceId}/business_conference_meetings/${meetingId}?${offsetToUtc ? 'offset_to_utc=true' : ''}`,
  meetings: ({ businessConferenceId, participantId, offsetToUtc }) => `events/${EVENT_URI}/business_conferences/${businessConferenceId}/business_conference_participants/${participantId}/agenda?${offsetToUtc ? 'offset_to_utc=true' : ''}`,
  meetingCheckIn: ({ businessConferenceId, meetingId }) => `events/${EVENT_URI}/business_conferences/${businessConferenceId}/business_conference_meetings/${meetingId}/mark-attended`,
  changeMeetingAttendanceMode: ({ businessConferenceId, meetingId }) => `events/${EVENT_URI}/business_conferences/${businessConferenceId}/business_conference_meetings/${meetingId}/change-attendance-mode`,
  participantTypes: ({ businessConferenceId, offsetToUtc }) => `events/${EVENT_URI}/business_conferences/${businessConferenceId}/business_conference_participant_types?${offsetToUtc ? 'offset_to_utc=true' : ''}`,
  requestMeeting: ({ businessConferenceId }) => `events/${EVENT_URI}/business_conferences/${businessConferenceId}/business_conference_meetings`,
  meetingsRequests: ({ businessConferenceId }) => `/events/${EVENT_URI}/business_conferences/${businessConferenceId}/meeting_requests?include=sent_by.attendee,received_by.attendee`,
  createMeetingRequest: ({ businessConferenceId }) => `events/${EVENT_URI}/business_conferences/${businessConferenceId}/meeting_requests`,
  approveMeetingRequest: ({ businessConferenceId, meetingRequestId }) => `events/${EVENT_URI}/business_conferences/${businessConferenceId}/meeting_requests/${meetingRequestId}/approve`,
  rejectMeetingRequest: ({ businessConferenceId, meetingRequestId }) => `events/${EVENT_URI}/business_conferences/${businessConferenceId}/meeting_requests/${meetingRequestId}`,
  surveys: () => `events/${EVENT_URI}/surveys?include=custom_fields`,
  quizzes: () => `events/${EVENT_URI}/surveys?include=custom_fields,event_files&session_type=quiz`,
  activeQuizQuestion: ({ id }) => `events/${EVENT_URI}/surveys/${id}/active_question`,
  singleSurvey: ({ id }) => `events/${EVENT_URI}/surveys/${id}?include=custom_fields`,
  addSurveyAnswer: ({ id }) => `events/${EVENT_URI}/surveys/${id}/answers`,
  participant: ({
    businessConferenceId, participantId, offsetToUtc,
  }) => `events/${EVENT_URI}/business_conferences/${businessConferenceId}/business_conference_participants/${participantId}?${offsetToUtc ? 'offset_to_utc=true' : ''}`,
  updateParticipant: ({
    businessConferenceId, participantId,
  }) => `events/${EVENT_URI}/business_conferences/${businessConferenceId}/business_conference_participants/${participantId}`,
  countries: 'countries',
  regions: ({ countryId }) => `countries/${countryId}/regions`,
  cities: ({ countryId, regionId }) => `countries/${countryId}/regions/${regionId}/cities`,
  sponsors: () => `events/${EVENT_URI}/sponsors?include_file_category=true&include_representatives=true&attendance_mode=0,1&include=category,attendee,attendee.attendee_type_custom_fields,sponsor_representatives.attendee,sponsor_representatives.attendee.attendee_type,sponsor_representatives.attendee.attendee_type_custom_fields,attendee.attendee_type`,
  sponsorsFiles: ({ fileCategoryId, id }) => `events/${EVENT_URI}/event_file_categories/${fileCategoryId}/event_files?sponsor_id=${id}`,
  hotspots: ({ sponsorId }) => `events/${EVENT_URI}/sponsors/${sponsorId}/hotspots`,
  sponsorProducts: ({ sponsorId }) => `events/${EVENT_URI}/sponsors/${sponsorId}/sponsor_products`,
  markAsDownloaded: ({ fileCategoryId, fileId }) => `events/${EVENT_URI}/event_file_categories/${fileCategoryId}/event_files/${fileId}/mark_as_downloaded`,
  sponsorActivities: ({ sponsorId }) => `events/${EVENT_URI}/workshops?offset_to_utc=true${sponsorId ? `&sponsor_id=${sponsorId}` : ''}`,
  workshops: ({ attendeeTypeId }) => `events/${EVENT_URI}/workshops?include=workshop_categories,category&offset_to_utc=true&include_sponsor_activities=true&include_file_category=true${attendeeTypeId ? `&attendee_type_id=${attendeeTypeId}` : ''}`,
  attendeeWorkshops: () => `events/${EVENT_URI}/attendee_workshops`,
  registerAttendee: () => `events/${EVENT_URI}/attendee_workshops`,
  unregisterAttendee: () => `events/${EVENT_URI}/attendee_workshops`,
  workshopsFiles: ({ fileCategoryId, id }) => `events/${EVENT_URI}/event_file_categories/${fileCategoryId}/event_files?workshop_id=${id}`,
  speakers: () => `events/${EVENT_URI}/speakers?include_file_category=true`,
  speakersFiles: ({ fileCategoryId, id }) => `events/${EVENT_URI}/event_file_categories/${fileCategoryId}/event_files?speaker_id=${id}`,
  updateNote: ({ businessConferenceId, meetingId }) => `events/${EVENT_URI}/business_conferences/${businessConferenceId}/business_conference_meetings/${meetingId}/notes`,
  updateMeetingDuration: ({ businessConferenceId, meetingId }) => `events/${EVENT_URI}/business_conferences/${businessConferenceId}/business_conference_meetings/${meetingId}/durations`,
  updateMeeting: ({ businessConferenceId, meetingId }) => `events/${EVENT_URI}/business_conferences/${businessConferenceId}/business_conference_meetings/${meetingId}`,
  rateParticipant: ({ businessConferenceId, participantId }) => `events/${EVENT_URI}/business_conferences/${businessConferenceId}/business_conference_participants/${participantId}/rating`,
  resetRating: ({ businessConferenceId, participantId }) => `events/${EVENT_URI}/business_conferences/${businessConferenceId}/business_conference_participants/${participantId}/rating`,
  denyParticipant: ({ businessConferenceId, participantId }) => `events/${EVENT_URI}/business_conferences/${businessConferenceId}/business_conference_participants/${participantId}/blacklist`,
  allowParticipant: ({ businessConferenceId, participantId }) => `events/${EVENT_URI}/business_conferences/${businessConferenceId}/business_conference_participants/${participantId}/blacklist`,
  denyList: ({ businessConferenceId }) => `events/${EVENT_URI}/business_conferences/${businessConferenceId}/blacklist`,
  ratings: ({ businessConferenceId }) => `events/${EVENT_URI}/business_conferences/${businessConferenceId}/ratings`,
  recommendedParticipants: ({
    businessConferenceId, currentParticipantId,
  }) => `events/${EVENT_URI}/business_conferences/${businessConferenceId}/business_conference_participants/${currentParticipantId}/recommendations`,
  // social wall
  singlePost: ({ postId }) => `events/${EVENT_URI}/social_posts/${postId}`,
  createSocialPost: () => `events/${EVENT_URI}/social_posts`,
  updateSocialPost: ({ postId }) => `events/${EVENT_URI}/social_posts/${postId}`,
  deleteSocialPost: ({ postId }) => `events/${EVENT_URI}/social_posts/${postId}`,
  socialPosts: () => `events/${EVENT_URI}/social_posts`,
  createPostComment: ({ postId }) => `events/${EVENT_URI}/social_posts/${postId}/post_comments`,
  postComments: ({ postId }) => `events/${EVENT_URI}/social_posts/${postId}/post_comments`,
  // updatePostComment:
  // ({ postId }) => `events/${EVENT_URI}/social_posts/${postId}/post_comments`,
  deletePostComment: ({ postId, commentId }) => `events/${EVENT_URI}/social_posts/${postId}/post_comments/${commentId}`,
  // attendee leads:
  attendeeLeads: () => `events/${EVENT_URI}/attendee_leads`,
  createOrUpdateAttendeeLead: ({ uuid }) => `events/${EVENT_URI}/attendee_leads/${uuid}`,
  deleteAttendeeLead: ({ uuid }) => `events/${EVENT_URI}/attendee_leads/${uuid}`,
  batchUpdateAttendeeLeads: () => `events/${EVENT_URI}/attendee_leads/batch`,
  downloadAttendeeLeads: () => `events/${EVENT_URI}/attendee_leads/report`,
  // Q&A
  qaSessions: () => `events/${EVENT_URI}/event_qa_sessions`,
  qaSessionQuestions: ({ sessionId, attendeeTypeId }) => `events/${EVENT_URI}/event_qa_sessions/${sessionId}/event_qa_session_questions?include=event_qa_session,attendee,attendee.attendee_type_custom_fields&status=approved,answered&attendee_type_id=${attendeeTypeId}`,
  askQaQuestion: ({ sessionId }) => `events/${EVENT_URI}/event_qa_sessions/${sessionId}/event_qa_session_questions`,
  toggleQaQuestionLike: ({ sessionId, id }) => `events/${EVENT_URI}/event_qa_sessions/${sessionId}/event_qa_session_questions/${id}`,
  // Firebase
  firebaseToken: () => `events/${EVENT_URI}/firebase/token`,
  // random stuff
  twilioToken: ({ businessConferenceId, meetingId }) => `events/${EVENT_URI}/business_conferences/${businessConferenceId}/business_conference_meetings/${meetingId}/twilio/token`,
  dailyToken: ({ workshopId }) => `events/${EVENT_URI}/workshops/${workshopId}/daily-token`,
  activityICS: ({ businessConferenceId, activityId }) => `events/${EVENT_URI}/${businessConferenceId ? `business_conferences/${businessConferenceId}/business_conference_meetings/` : 'workshops/'}${activityId}/ics`,
  tags: () => `events/${EVENT_URI}/tags`,
  demodeskToken: ({ businessConferenceId, meetingId }) => `events/${EVENT_URI}/business_conferences/${businessConferenceId}/business_conference_meetings/${meetingId}/demodesk/token`,
  registrationToken: () => `events/${EVENT_URI}/attendees/register-token`,
  categoryFiles: ({ fileCategoryId }) => `events/${EVENT_URI}/event_file_categories/${fileCategoryId}/event_files`,
};

const appendFilters = (endpoint, filters) => {
  if (!filters || Object.keys(filters).length === 0) return endpoint;

  const connector = endpoint.includes('?') ? '&' : '?';
  const query = Object.keys(filters)
    .filter((f) => (typeof filters[f] !== 'undefined' && filters[f] !== null && filters[f] !== ''))
    .map((filter) => `${encodeURIComponent(humps.decamelize(filter))}=${encodeURIComponent(filters[filter])}`)
    .join('&');
  return `${endpoint}${connector}${query}`;
};

// const [defaultHost, defaultSocket, defaultEnv] = ['http://localhost:3002', 'wss://dev-iridium.eventtia.com/socket', 'local'];
const [defaultHost, defaultSocket, defaultEnv] = ['https://connect.eventtia.com', 'wss://iridium.eventtia.com/socket', 'prod'];
// const [defaultHost, defaultSocket, defaultEnv] = ['https://dev-fg.eventtia.com', 'wss://dev-iridium.eventtia.com/socket', 'dev'];
// const [defaultHost, defaultSocket, defaultEnv] = ['https://stage-fg.eventtia.com', 'wss://stage-iridium.eventtia.com/socket', 'stage'];

const host = process.env.REACT_APP_EVENTTIA_HOST || defaultHost;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL || defaultSocket;
export const DEFAULT_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || defaultEnv;

export const getZoomMeetingUrl = (guid, name, email) => `${host}/${getLocale()}/public/events/${EVENT_URI}/workshops/${guid}/zoom?name=${name}&email=${email}&ui_lang=${getLocale({ bypassLocaleMapping: true })}`;

export const getPublicRegistrationUrl = (uuid = '') => `${host}/${getLocale()}/public/events/${EVENT_URI}/registration/attendees${uuid ? `/${uuid}` : ''}`;
export const getUpdateRegistrationUrl = (uuid, token) => `${host}/${getLocale()}/public/events/${EVENT_URI}/registration/attendees/${uuid}?token=${token}`;
const twilioHost = process.env.REACT_APP_TWILIO_HOST || 'http://localhost:3001';
// const twilioHost = process.env.REACT_APP_TWILIO_HOST || 'https://dev-hello.eventtia.com';
export const getTwilioUrl = (conference, guid, token) => `${twilioHost}/room/${EVENT_URI}/${conference}/${guid}?token=${token}&locale=${getLocale()}`;
export const getTwilioPreviewUrl = () => `${twilioHost}/room/eventtia/video/test?previewOnly=true`;

const vonageHost = process.env.REACT_APP_VONAGE_HOST || 'http://localhost:3001';
export const getVonageUrl = (conferenceId, meetingId, token) => `${vonageHost}/en/${EVENT_URI}/conference/${conferenceId}/meeting/${meetingId}?token=${token}`;
export const getVonagePreviewUrl = () => `${vonageHost}/en/eventtia/video/test?previewOnly=true`;

export const getSSOUrl = (loginUri, ssoStandardType) => `${host}/users/auth/${ssoStandardType}?locale=${getLocale()}&login_uri=${loginUri}&provider=saml&event_uri=${EVENT_URI}`;
export const getAzureSSOUrl = (loginUri) => `${host}/${getLocale()}/users/auth/openid/azure/setup?login_uri=${loginUri}&event_uri=${EVENT_URI}`;

export default (name, params, filters) => {
  const locale = getLocale();
  const endpoint = typeof endpoints[name] === 'function' ? endpoints[name](params) : endpoints[name];
  const endpointWithFilters = appendFilters(endpoint, filters);
  return `${host}/${locale}/api/v3/${endpointWithFilters}`;
};
