/* eslint-disable no-trailing-spaces */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 32,
  },
  title: {
    textAlign: 'center',
    fontWeight: 700,
  },
  termsAndConditions: {
    textAlign: 'justify',
    marginBottom: theme.spacing(4),
  },
  li: {
    marginTop: theme.spacing(2),
  },
}));

const TermsAndConditions = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        NON-DISCLOSURE UNDERTAKING
        <br />
        (“NDU”)
      </div>
      <div className={classes.termsAndConditions}>
        <p>
          Dear Madam, Dear Sir,
          <p>
            In the context of the Cartier World Meeting 2024 (hereinafter the 
            “<b>Cartier Meetings”</b>)
            organized by CARTIER and/or any of its affiliated companies (hereinafter 
            <b>”CARTIER”</b>) which will take place in Marbella from June 23 to June 28, you shall 
            be provided with and/or have access to highly confidential and sensitive proprietary 
            information as defined below.
          </p>
          <p>
            In particular, you shall be given access to new CARTIER products and business strategy
            plans and will be entrusted with the access to a dedicated website containing pictures 
            and information relating to various CARTIER products 
            (hereafter the “<b>Cartier Book</b>”)
            for the sole purpose of projecting and planning your participation in Cartier’s 
            worldwide presentation, launch and sales of its new collections in the forthcoming year 
            (hereafter the “<b>Purpose</b>”).
          </p>
          <p>
            In addition to the Cartier Book, CARTIER will be providing you, directly or indirectly,
            whatever the means of communication and medium with information and/or tangible property
            relating to CARTIER itself, its products, creations, services, customers, know-how,
            strategy and/or generally pertaining to the business of CARTIER and/or information
            that you know, or ought reasonably to know, is confidential
            (together the “<b>Confidential Information</b>”).
          </p>
          <p>In view of the foregoing, you accept and acknowledge that:</p>
        </p>
        <ol>
          <li className={classes.li}>
            The Confidential Information shall remain the exclusive property of CARTIER. 
            This NDU does not in any way grant you any right of ownership, intellectual property 
            right, license or right of use on the Confidential Information, except for the Purpose
          </li>
          <li className={classes.li}>
            The Confidential Information shall be kept strictly confidential and shall by no means 
            be fully or partially, directly or indirectly, disclosed to anyone except to Cartier’s 
            employees who have a need to know it for the Purpose. Furthermore, the Confidential 
            Information may not be used for any other purposes than that of the Purpose. 
            You shall be solely liable for the compliance with the confidentiality obligations 
            set forth in this NDU. Moreover you shall take all necessary measures to preserve 
            the confidentiality of the Confidential Information, prevent the unauthorised 
            disclosure of the Confidential Information and protect the Confidential Information 
            against destruction, damage or loss (in whole or in part). The Confidential Information 
            should in particular be kept in a safe place, inaccessible to unauthorized persons.
            <p>
              Unless we have given our written consent in advance, you must not divulge, directly or
              indirectly, to anyone, this NDU or its content, the fact that Confidential Information
              has been sent to you or your potential participation to the Cartier Meetings. You also
              undertake not to divulge its progress either. Finally, you undertake not to make any
              declaration or reference in connection with the Cartier Meetings.
            </p>
          </li>
          <li className={classes.li}>
            You shall refrain from making any copies and/or reproductions of any part 
            of the Confidential Information, whatever the support (including digital and 
            paper copies). 
            <b> It is expressly forbidden to take any photographs, videos and/or
            sound recordings of CARTIER products or presentations given during the Cartier 
            Meetings.
            </b>
          </li>
          <li className={classes.li}>
            If you observe or suspect the disclosure of all or part of the Confidential Information,
            due to negligence, a lack of diligence, a breach of confidentiality by an authorised
            person or unauthorised access to any of the physical or intangible systems for storing
            that Confidential Information, you must inform us of it without delay and communicate
            any information or send any element that would make it possible to identify the cause
            and/or persons behind such disclosure.
          </li>
          <li className={classes.li}>
            If you are compelled pursuant to applicable law, or by a decision from a competent
            court or authority, to disclose Confidential Information, you may disclose it provided
            that, where legally permitted, you (a) promptly notify CARTIER in detail prior to
            disclosure; (b) fully cooperate with CARTIER for any of CARTIER’s action to contest
            or obtain any protective measure against disclosure; and (c) limits disclosure to
            the extent required by law or by the decision.
          </li>
          <li className={classes.li}>
            You shall destroy and/or return to CARTIER all Confidential Information within 5 
            (five) days upon receipt of and in accordance with CARTIER’s request. If, pursuant 
            to applicable law, you must retain copies of certain Confidential Information, you 
            shall inform CARTIER and keep such Confidential Information in accordance with this 
            NDU. In any event, this NDU will continue to apply until its expiration.
          </li>
          <li className={classes.li}>
            You acknowledge that the Confidential Information has independent economic value, 
            actual or potential, for CARTIER. You acknowledge that any direct or indirect breach 
            of your confidentiality obligations under this NDU may cause CARTIER an irreparable 
            loss for which CARTIER may not be able to obtain monetary compensation.
          </li>
          <li className={classes.li}>
            Failure or delay to enforce any provision of this NDU will not constitute a waiver.
            If any provision of this NDU is held to be unenforceable, it shall be replaced by a
            provision having, to the extent possible, the same effect, while the remaining 
            provisions shall remain in full force.
          </li>
          <li className={classes.li}>
            This NDU shall enter into force as of its electronic signing date when you will register
            for the Cartier Meetings or the date Confidential Information has been first disclosed 
            by the Maison, whichever occurs earlier. It shall remain in force until such time 
            CARTIER has chosen to disclose the Confidential Information to the public.
          </li>
          <li className={classes.li}>
            This NDU Agreement is construed in accordance with and governed by Swiss law. 
            Any dispute arising out of or in connection with the interpretation, performance, 
            non-performance, bad performance or validity of this agreement shall be submitted 
            to the exclusive jurisdiction of the Courts of Geneva (Switzerland).
          </li>
        </ol>
      </div>
      <p>Yours sincerely,</p>
    </div>
  );
};

export default TermsAndConditions;
