import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Accordion from 'eventtia-ui-components/lib/Accordion';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import CustomFieldValue from '../CustomFieldValue';
import parseCustomFields from '../../helpers/customFields';
import { EVENT_URI } from '../../helpers/constants';

const useStyles = makeStyles((theme) => ({
  profileData: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'justify',
  },
  dataDafault: {
    flex: '0 50%',
  },
  dataCustom: {
    flex: '100%',
  },
  dataTitle: {
    fontWeight: 'bolder',
    marginBottom: 12,
    fontSize: 12,
    color: theme.palette.darkGrey.light,
  },
  anchor: {
    marginBottom: theme.spacing(1),
    fontSize: 14,
    color: theme.palette.darkGrey.main,
    display: 'block',
    width: 250,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  imgData: {
    width: 150,
  },
  data: {
    marginBottom: theme.spacing(3),
    fontSize: 14,
    color: theme.palette.darkGrey.main,
    display: 'block',
    fontWeight: 'bolder',
  },
}));

const PeopleFullProfile = ({
  person, attendeeTypeCustomFields, otherParticipant, showContactInfo,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('attendees, meeting');
  const { fields } = person;
  const parsedFields = parseCustomFields(fields, attendeeTypeCustomFields);
  let { email, telephone } = parsedFields;
  const { cityId } = parsedFields;
  if (showContactInfo) {
    if (!email) email = otherParticipant.profileContactEmail;
    if (!telephone) telephone = otherParticipant.telephone;
  }

  const fieldsToShow = Object.keys(fields).filter((fieldId) => (
    !!attendeeTypeCustomFields[fieldId] && !attendeeTypeCustomFields[fieldId].default
  )).map((fieldId) => (
    <div className={classes.dataCustom} key={fieldId}>
      <Typography className={classes.dataTitle} variant="body2">
        {attendeeTypeCustomFields[fieldId].name}
      </Typography>
      <CustomFieldValue
        field={attendeeTypeCustomFields[fieldId]}
        value={fields[fieldId]}
        classes={{
          anchorValue: classes.anchor,
          value: classes.data,
          img: classes.imgData,
        }}
      />
    </div>
  ));

  const isEmpty = !(
    cityId?.country
    || email
    || telephone
    || !!fieldsToShow.length
  );

  if (isEmpty) return null;

  const renderDefaultField = (name, value) => (
    <div className={classes.dataDafault}>
      <Typography className={classes.dataTitle} variant="body2">
        {t(`attendees:data.${name}`)}
      </Typography>
      <Typography className={classes.data} variant="subtitle1">
        {value}
      </Typography>
    </div>
  );

  return (
    <>
      <Accordion
        sections={[{
          title: t('meeting:meeting.participantInfo'),
          content: (
            <div className={classes.profileData}>
              {cityId?.country && renderDefaultField('country', cityId.country)}
              {email && renderDefaultField('email', email)}
              {telephone && renderDefaultField('phone', telephone)}
              {fieldsToShow}
            </div>
          ),
        }]}
        defaultExpandedPanel={EVENT_URI === 'recntm2023' ? 0 : undefined}
      />
      <Divider />
    </>
  );
};

PeopleFullProfile.propTypes = {
  person: CustomPropTypes.person.isRequired,
  attendeeTypeCustomFields: PropTypes.objectOf(
    CustomPropTypes.attendeeTypeCustomField
  ),
  otherParticipant: CustomPropTypes.participant.isRequired,
  showContactInfo: PropTypes.bool,
};

PeopleFullProfile.defaultProps = {
  attendeeTypeCustomFields: {},
  showContactInfo: false,
};

export default PeopleFullProfile;
