import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
// import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
// import Tooltip from 'eventtia-ui-components/lib/Tooltip';
import FlightIcon from '@material-ui/icons/Flight';
// import moment from '../../helpers/dates';
import InputBase from '../InputBase';
import Button from '../Button';
import callApi from '../../actions/callApi';
import moment from '../../helpers/dates';
import { getToken } from '../../helpers/auth';
import { EVENT_URI } from '../../helpers/constants';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    // display: 'flex',
    gap: theme.spacing(),
    '& button': {
      height: 39,
    },
  },
}));

const FlightInput = ({
  id,
  flightDate,
  flightValue,
  onFlightSuccess,
  setFlightError,
  flightType,
  callApi: dispatchCallApi,
  ...other
}) => {
  const classes = useStyles();
  const [fetching, setFetching] = useState(false);

  const validateFlight = () => {
    if (flightValue && flightDate) {
      setFetching(true);
      setFlightError();
      const formattedDate = encodeURIComponent(moment(flightDate).format('DD/MM/YYYY'));
      const flightDirection = (flightType === 'arrival') ? 'arriving' : 'departing';
      fetch(
        `https://25ovyan346n4xbo2bkgndkwime0akkrr.lambda-url.us-east-1.on.aws/?flight_date=${formattedDate}&flight_code=${flightValue.replace(' ', '')}&direction=${flightDirection}`, {
          headers: {
            Authorization: getToken(EVENT_URI),
            Accept: 'application/json',
          },
        }
      ).then((res) => res.json()).then((response) => {
        setFetching(false);
        if (response?.destination) {
          const {
            arrival_time: arrivalDateLocal,
            departure_time: departureDateLocal,
            destination: { city: arrivalPlace },
            origin: { city: departurePlace },
          } = response;
          const timeValue = (flightType === 'arrival') ? arrivalDateLocal : departureDateLocal;
          const placeValue = (flightType === 'arrival') ? departurePlace : arrivalPlace;
          onFlightSuccess(moment(timeValue).format('LTS'), placeValue);
        } else setFlightError(flightType === 'arrival'
          ? 'Flight not found, please indicate the Arrival Time and Flight origin in the Comment field below.'
          : 'Flight not found, please indicate the Departure Time and Flight destination in the Comment field below.');
      }).catch((err) => {
        setFetching(false);
        setFlightError('Failed to fetch flight info');
        console.error(err);
      });
    }
  };

  return (
    <div className={classes.wrapper}>
      <InputBase
        id={id}
        name={id}
        endAdornment={(
          <InputAdornment position="end">
            {/* <Tooltip title="Validate flight">
              <IconButton
                disableRipple
                size="small"
                onClick={validateFlight}
                disabled={other.disabled || !flightDate || !flightValue || fetching}
              >
                <FlightIcon />
              </IconButton>
            </Tooltip> */}
            <Button
              disableRipple
              size="small"
              onClick={validateFlight}
              disabled={other.disabled || !flightDate || !flightValue || fetching}
              icon={<FlightIcon />}
            >
              Validate flight
            </Button>
          </InputAdornment>
        )}
        {...other}
      />
    </div>
  );
};

FlightInput.propTypes = {
  id: PropTypes.string.isRequired,
  callApi: PropTypes.func.isRequired,
  flightValue: PropTypes.string,
  flightDate: PropTypes.string.isRequired,
  onFlightSuccess: PropTypes.func.isRequired,
  setFlightError: PropTypes.func.isRequired,
  flightType: PropTypes.oneOf(['arrival', 'departure']).isRequired,
};

FlightInput.defaultProps = {
  flightValue: '',
};

export default connect(null, { callApi })(FlightInput);
