import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Card from 'eventtia-ui-components/lib/Card';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import LanguageIcon from '@material-ui/icons/Language';
import EmailIcon from '@material-ui/icons/Email';
import InstagramIcon from '@material-ui/icons/Instagram';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import TwitterXIcon from '../../assets/TwitterXIcon';
import callApi from '../../actions/callApi';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import TimestampCard from '../../components/ActivityCard/TimestampCard';
import AttachmentFile from '../../components/AttachmentFile';
import BackButton from '../../components/BackButton';
import useStringTransforms from '../../hooks/useStringTransforms';
import { baseMoment } from '../../helpers/dates';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 194,
    margin: '-32px -52px 20px -52px',
    overflow: 'hidden',
  },
  tabletHeader: {
    marginLeft: -theme.spacing(6.5),
    marginRight: -theme.spacing(6.5),
  },
  mobileHeader: {
    marginLeft: -theme.spacing(4),
    marginRight: -theme.spacing(4),
  },
  backButton: {
    position: 'absolute',
    left: 35,
    top: 32,
  },
  bannerImage: {
    width: '100%',
    height: 194,
    objectFit: 'contain',
  },
  speakerDetails: {
    width: '45%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  speakerCard: {
    display: 'flex',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  avatar: {
    height: 70,
    width: 70,
    alignSelf: 'center',
    marginRight: theme.spacing(3),
    backgroundColor: theme.palette.lightGrey.main,
    color: theme.palette.primary.main,
  },
  speakerContact: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  contactInfo: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    textDecoration: 'none',
    color: 'inherit',
    fontSize: '0.8rem',
    overflowWrap: 'anywhere',
    overflow: 'hidden',
  },
  icon: {
    fontSize: '1.3rem',
    marginRight: theme.spacing(1),
  },
  name: {
    color: theme.palette.darkGrey.main,
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  subtitle: {
    color: theme.palette.darkGrey.main,
    fontWeight: 'bold',
    margin: theme.spacing(2, 0, 2, 0),
  },
  biographyText: {
    color: theme.palette.darkGrey.light,
    lineHeight: 2,
    '& iframe': {
      maxWidth: '100%',
    },
  },
  divider: {
    margin: theme.spacing(3, 0),
  },
  speakerPosition: {
    fontSize: '0.8rem',
    opacity: 0.6,
  },
  pipe: {
    borderTop: '1px solid',
    color: theme.palette.lightGrey.dark,
    opacity: 0.5,
    margin: theme.spacing(0, 1, 0, 1),
    display: 'inline-Block',
  },
  filesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  mobileFilesContainer: {
    flexDirection: 'column',
  },
}));

const urlRegexp = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

const nickID = (link) => {
  if (!urlRegexp.test(link)) return link;

  if (link.endsWith('/')) {
    const newID = link.slice(0, -1);
    return newID.substr(newID.lastIndexOf('/') + 1);
  }

  return link.substr(link.lastIndexOf('/') + 1);
};

const ContactInfo = ({ Icon, label, href }) => {
  const classes = useStyles();

  return (
    <Typography component="a" className={classes.contactInfo} href={href} rel="noopener noreferrer" target="_blank">
      <Icon className={classes.icon} />
      {label}
    </Typography>
  );
};

ContactInfo.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

const Biography = ({
  speaker,
  callApi: dispatchCallApi,
  eventFiles,
  workshops,
}) => {
  const classes = useStyles();
  const { toSentenceCase } = useStringTransforms();
  const { t } = useTranslation(['speakers, global, schedule,']);
  const {
    id: speakerId,
    bio,
    fullName,
    position,
    company,
    instagram,
    linkedin,
    website,
    twitter,
    email,
    picture,
    banner,
    workshops: workshopSpeaker,
    fileCategoryId,
  } = speaker;
  const { locale } = useParams();
  const dataWorkshops = (workshopSpeaker || []).filter(
    ({ id: workshopId }) => workshops[workshopId]
  )?.map(({ id: workshopId }) => {
    const {
      name, startDate, endDate,
    } = workshops[workshopId];
    return {
      id: workshopId,
      name,
      startDate,
      endDate,
    };
  }).sort((a, b) => {
    if (baseMoment(a.startDate).isAfter(b.startDate, 'hour')) return 1;
    if (baseMoment(a.startDate).isBefore(b.startDate, 'hour')) return -1;
    return a.name.localeCompare(b.name, locale, { numeric: true });
  });

  const at = position && company ? ` ${t('global:prepositions.at')} ` : '';
  const speakerPosition = `${toSentenceCase(position)}${at}${toSentenceCase(company, true)}`;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tablet = useMediaQuery(theme.breakpoints.down('md'));
  useEffect(() => {
    if (speakerId && fileCategoryId) dispatchCallApi('speakersFiles', { speakerId, fileCategoryId });
  }, [speakerId, fileCategoryId, dispatchCallApi]);
  const speakerFiles = Object.values(eventFiles).filter((file) => file.entityType === 'Speaker' && file.entityId.toString() === speakerId);

  return (
    <>
      <div className={clsx(classes.header,
        tablet && classes.tabletHeader, mobile && classes.mobileHeader)}
      >
        <BackButton
          className={classes.backButton}
        />
        <img
          className={classes.bannerImage}
          src={banner?.filename ? banner.large : '/default-banner-sponsors.png'}
          alt={fullName}
        />
      </div>
      <Card className={classes.speakerCard}>
        <Avatar src={picture?.filename ? picture.small : null} className={classes.avatar} />
        {fullName && (
          <div className={classes.speakerDetails}>
            <Typography className={classes.name}>
              {fullName}
            </Typography>
            <Typography className={classes.speakerPosition}>
              {speakerPosition}
            </Typography>
          </div>
        )}
        <hr className={classes.pipe} />
        <div className={classes.speakerContact}>
          {website && (
            <ContactInfo
              Icon={LanguageIcon}
              label={mobile ? '' : website}
              href={`http://${website}`}
            />
          )}
          {email && (
            <ContactInfo
              Icon={EmailIcon}
              label={mobile ? '' : email}
              href={`mailto:${email}`}
            />
          )}
          {linkedin && (
            <ContactInfo
              Icon={LinkedInIcon}
              label={mobile ? '' : nickID(linkedin)}
              href={urlRegexp.test(linkedin) ? linkedin : `https://www.linkedin.com/in/${linkedin}`}
            />
          )}
          {instagram && (
            <ContactInfo
              Icon={InstagramIcon}
              label={mobile ? '' : nickID(instagram)}
              href={urlRegexp.test(instagram) ? instagram : `https://www.instagram.com/${instagram}`}
            />
          )}
          {twitter && (
            <ContactInfo
              Icon={TwitterXIcon}
              label={mobile ? '' : nickID(twitter)}
              href={urlRegexp.test(twitter) ? twitter : `https://www.twitter.com/${twitter}`}
            />
          )}
        </div>
      </Card>
      {bio && (
      <div>
        <Typography className={classes.subtitle}>
          {t('speakers:biography.title')}
        </Typography>
        <div
          className={classes.biographyText}
          dangerouslySetInnerHTML={{ __html: bio }}
        />
      </div>
      )}
      {workshopSpeaker.length > 0 && (
        <>
          <Divider className={classes.divider} />
          <Typography className={classes.subtitle}>
            {t('schedule:activityType.activities')}
          </Typography>
          <Grid container spacing={2}>
            {dataWorkshops.map(({ id, name, startDate, endDate }) => (
              <Grid item xs={12} md={6} lg={6} key={id}>
                <TimestampCard
                  id={id}
                  name={name}
                  startDate={startDate}
                  endDate={endDate}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
      {!!speakerFiles.length && (
        <>
          <Typography className={classes.subtitle} variant="body1">
            {t('schedule:title.attachments')}
          </Typography>
          <div className={clsx(classes.filesContainer,
            mobile && classes.mobileFilesContainer)}
          >
            {speakerFiles.map((file) => (
              <AttachmentFile key={file.id} file={file} source="sponsor" sourceId={speakerId} />
            ))}
          </div>
        </>
      )}
    </>
  );
};

Biography.propTypes = {
  speaker: CustomPropTypes.speaker.isRequired,
  callApi: PropTypes.func.isRequired,
  eventFiles: PropTypes.objectOf(
    CustomPropTypes.eventFile
  ),
  workshops: PropTypes.objectOf(
    CustomPropTypes.workshop
  ),
};

Biography.defaultProps = {
  eventFiles: {},
  workshops: {},
};

const mapStateToProps = ({
  entities: {
    eventFiles,
    workshops,
  },
}) => ({
  eventFiles,
  workshops,
});

export default connect(mapStateToProps, { callApi })(Biography);
