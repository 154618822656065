import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import callApi from '../../actions/callApi';
import ActivityFilled from '../../assets/ActivityFilledIcon';
import Button from '../Button';

const DownloadActivity = ({
  businessConferenceId, id, name, button, className, callApi: dispatchCallApi, color,
}) => {
  const { eventUri } = useParams();
  const { t } = useTranslation('global');

  const downloadICS = () => {
    const params = { eventUri, activityId: id };
    if (businessConferenceId) params.businessConferenceId = businessConferenceId;
    dispatchCallApi('activityICS', params).then((res) => {
      if (!res.error) {
        const { response: { content: data } } = res;
        const blob = new Blob([data], { type: 'text/calendar' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name.trim()}.ics`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    });
  };

  const onClick = (event) => {
    event.stopPropagation();
    downloadICS();
  };

  return button ? (
    <Button
      variant="secondary"
      className={className}
      icon={<ActivityFilled color={color} />}
      onClick={onClick}
    >
      {t('actions.addToCalendar')}
    </Button>
  ) : (
    <div
      onClick={onClick}
      role="button"
      tabIndex={0}
      className={className}
      onKeyDown={(event) => {
        if (event.key === 'Enter') onClick();
      }}
    >
      <ActivityFilled color={color} />
    </div>
  );
};

DownloadActivity.propTypes = {
  callApi: PropTypes.func.isRequired,
  businessConferenceId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  button: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
};

DownloadActivity.defaultProps = {
  button: false,
  businessConferenceId: undefined,
  className: undefined,
  color: 'primary',
};

export default connect(null, { callApi })(DownloadActivity);
