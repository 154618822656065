import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import { connect } from 'react-redux';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import copyToClipboard from '../../helpers/copyToClipboard';
import { setActiveLead } from '../../actions/app';
import { deleteAttendeeLead } from '../../actions/attendeeLeads';
import AttendeeLeadCardHeader from './AttendeeLeadCardHeader';
import AttendeeLeadCardContactInfo from './AttendeeLeadCardContactInfo';

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    width: '100%',
    boxShadow: theme.customShadows.default,
    border: '1px solid #70707019',
    borderRadius: 10,
    backgroundColor: theme.palette.common.white,
    padding: 0,
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
}));

const AttendeeLeadCard = ({
  attendeeLead,
  setActiveLead: dispatchSetActiveLead,
  deleteAttendeeLead: dispatchDeleteAttendeeLead,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('attendeeLeads');

  const { fullName, company, email, phone } = attendeeLead;

  const menuItems = [{
    name: t('actions.copyEmail'),
    onClick: () => {
      copyToClipboard(email);
    },
    icon: <FileCopyIcon />,
  }, {
    name: t('actions.deleteLead'),
    onClick: () => {
      dispatchDeleteAttendeeLead(attendeeLead);
    },
    color: 'red',
    icon: <DeleteIcon />,
  }];

  return (
    <div
      className={classes.root}
      tabIndex={0}
      role="button"
      onClick={() => {
        dispatchSetActiveLead(attendeeLead.uuid);
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') dispatchSetActiveLead(attendeeLead.uuid);
      }}
    >
      <AttendeeLeadCardHeader
        fullName={fullName}
        company={company}
        menuItems={menuItems}
      />
      <Divider className={classes.divider} />
      <AttendeeLeadCardContactInfo
        email={email}
        phone={phone}
      />
    </div>
  );
};

AttendeeLeadCard.propTypes = {
  attendeeLead: CustomPropTypes.attendeeLead.isRequired,
  setActiveLead: PropTypes.func.isRequired,
  deleteAttendeeLead: PropTypes.func.isRequired,
};

export default connect(null, { setActiveLead, deleteAttendeeLead })(AttendeeLeadCard);
