import React, { useState, useMemo, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// import { useDrag } from 'react-dnd';

import Typography from '@material-ui/core/Typography';

import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';

import LinkIcon from '@material-ui/icons/Link';
import AttachmentIcon from '@material-ui/icons/Attachment';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import SubjectIcon from '@material-ui/icons/Subject';
import CloseIcon from '@material-ui/icons/Close';

import callApi from '../../actions/callApi';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import HotspotTypes from './hotspots';

const useStyles = makeStyles((theme) => ({
  hotspotPaper: {
    position: 'absolute',
    display: 'flex',
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(2.25),
    boxShadow: theme.customShadows.small,
    height: theme.spacing(5),
    maxWidth: 150,
    '&:hover': {
      cursor: 'pointer',
      boxShadow: '0 3px 16px rgba(0, 0, 0, 0.24)',
    },
    zIndex: 2,
    marginLeft: -theme.spacing(2.5),
    marginTop: -theme.spacing(3),
  },
  hotspotContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    maxWidth: 150,
  },
  hotspotTypeIcon: {
    background: theme.palette.primary.main,
    borderRadius: '50%',
    height: theme.spacing(4),
    width: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(0.5),
    flexShrink: 0,
  },
  hotspotIcon: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    color: theme.palette.lightGrey.main,
  },
  hotspotNameText: {
    fontWeight: 'bold',
    fontSize: '1em',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
  previewCloseButton: {
    position: 'absolute',
    top: '-1%',
    right: '-1%',
    backgroundColor: theme.palette.secondary.light,
    boxShadow: '2px 2px 16px #0006',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  previewCloseIcon: {
    color: theme.palette.common.white,
  },
  previewVideo: {
    width: '100vh',
    height: '60vh',
    '& > iframe': {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    },
  },
  previewVideoContainer: {
    margin: -theme.spacing(2),
    padding: theme.spacing(2),
    position: 'relative',
  },
  previewModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(4),
    '& div:focus': {
      outline: 'none',
    },
  },
}));

const urlFixed = (url) => {
  if (!/^https?:\/\//i.test(url)) return `http://${url}`;
  return url;
};

const HotspotVideo = ({ videoPreviewIsOpen, closeVideoPreview, content }) => {
  const classes = useStyles();
  return (
    <Modal
      className={classes.previewModal}
      open={videoPreviewIsOpen}
      onClose={closeVideoPreview}
    >
      <Fade in={videoPreviewIsOpen}>
        <div
          className={classes.previewVideoContainer}
        >
          <IconButton
            className={classes.previewCloseButton}
            onClick={closeVideoPreview}
          >
            <CloseIcon className={classes.previewCloseIcon} />
          </IconButton>
          <div
            className={classes.previewVideo}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </Fade>
    </Modal>
  );
};

HotspotVideo.propTypes = {
  videoPreviewIsOpen: PropTypes.bool.isRequired,
  closeVideoPreview: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
};

const HotspotClient = ({ hotspot, brandColor, eventFiles, callApi: dispatchCallApi }) => {
  const classes = useStyles();

  const { name, coordinates, content, contentType } = hotspot;

  const Icon = useMemo(() => {
    if (contentType === HotspotTypes.types.video) return VideoLibraryIcon;
    if (contentType === HotspotTypes.types.file) return AttachmentIcon;
    if (contentType === HotspotTypes.types.info) return SubjectIcon;
    if (contentType === HotspotTypes.types.url) return LinkIcon;
    return undefined;
  }, [contentType]);

  const isFile = contentType === HotspotTypes.types.file;
  const fileIsValid = isFile ? !!Object.keys(eventFiles).includes(content) : true;
  const hotspotIsReady = content !== '\\N' && fileIsValid;

  const markAsDownloaded = useCallback(() => {
    const { eventFileCategory: { id: fileCategoryId }, id: fileId } = eventFiles?.[content];
    dispatchCallApi('markAsDownloaded', { fileCategoryId, fileId });
  }, [eventFiles, content, dispatchCallApi]);

  const handleOpenLink = useCallback(() => {
    window.open(urlFixed(content), '_blank');
  }, [content]);

  const handleDownloadFile = useCallback(() => {
    markAsDownloaded();
    window.open(eventFiles?.[content].eventFile, '_blank');
  }, [markAsDownloaded, eventFiles, content]);

  // Preview states
  const [videoPreviewIsOpen, setVideoPreviewOpen] = useState(false);
  const openVideoPreview = () => setVideoPreviewOpen(true);
  const closeVideoPreview = () => setVideoPreviewOpen(false);

  const hotspotActions = {
    file: handleDownloadFile,
    url: handleOpenLink,
    video: openVideoPreview,
  };

  if (!hotspotIsReady) return null;

  return (
    <>
      <HotspotVideo
        videoPreviewIsOpen={videoPreviewIsOpen}
        closeVideoPreview={closeVideoPreview}
        content={content}
      />
      <Paper
        className={classes.hotspotPaper}
        style={{ top: `${coordinates.y}%`, left: `${coordinates.x}%` }}
        onClick={() => hotspotActions[contentType]()}
      >
        <div
          className={classes.hotspotContainer}
        >
          <div
            className={classes.hotspotTypeIcon}
            style={{ backgroundColor: brandColor }}
          >
            <Icon className={classes.hotspotIcon} />
          </div>
          <Typography
            className={classes.hotspotNameText}
            style={{ color: brandColor }}
          >
            {name}
          </Typography>
        </div>
      </Paper>
    </>
  );
};

HotspotClient.propTypes = {
  hotspot: CustomPropTypes.hotspot.isRequired,
  brandColor: PropTypes.string.isRequired,
  eventFiles: PropTypes.objectOf(
    CustomPropTypes.eventFile
  ),
  callApi: PropTypes.func.isRequired,
};

HotspotClient.defaultProps = {
  eventFiles: {},
};

export default connect(null, { callApi })(HotspotClient);
