import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Profile = (props) => (
  <SvgIcon {...props} viewBox="0 0 21 21">
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M10.5 1.32751C13.2009 1.32751 15.3699 3.34267 15.3699 5.84901C15.3699 8.35667 13.2009 10.413 10.5 10.413C7.79923 10.413 5.63029 8.35667 5.63029 5.84901C5.63029 3.34267 7.79923 1.32751 10.5 1.32751ZM10.5 0C7.02717 0 4.20053 2.62449 4.20053 5.84901C4.20053 8.15622 5.6789 10.1236 7.77493 11.0767C3.34268 12.2171 -0.000169729 15.8544 -0.000169729 20.3281C-0.00581233 20.6945 0.310164 20.9946 0.704777 21C1.09939 21.0052 1.42395 20.7131 1.42966 20.3454V20.3281C1.42966 15.6712 5.48446 11.9064 10.5 11.9064C15.5157 11.9064 19.5705 15.6712 19.5705 20.3281C19.5648 20.6945 19.8807 20.9946 20.2754 21C20.67 21.0052 20.9945 20.7131 21.0002 20.3454V20.3281C21.0002 15.8544 17.6575 12.2171 13.2252 11.0767C15.3212 10.1236 16.7996 8.15622 16.7996 5.84901C16.7996 2.62449 13.9729 0 10.5 0Z"
      />
    </svg>
  </SvgIcon>
);

export default Profile;
