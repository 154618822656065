/* eslint-disable comma-dangle */
export default {
  en: {
    notes: {
      placeholder: 'Write down notes on your meeting. You will be able to download them later.',
      saveSuccess: 'Your notes were saved',
    },
    actions: {
      evaluateMeeting: 'Evaluate meeting',
      sendMessage: 'Send message',
      approve: 'Approve meeting',
      reject: 'Reject meeting',
      cancelMeeting: 'Cancel meeting',
      cancel: 'Cancel request',
      join: 'Join',
      blockSpace: 'Block space',
      unblockSpace: 'Click to unblock space',
      send: 'Send',
      checkInMeeting: 'Check-in to meeting',
    },
    meeting: {
      title: 'Meeting',
      newMeetingMessage: 'Message',
      selectANewTime: 'Select a new time',
      selectANewDay: 'Select a new day',
      proposeNewTime: 'Propose new time',
      meetingRequestMessage: 'Your meeting request has been sent, you will be notified when {{participant}} accepts .',
      requestSent: 'Meeting request sent',
      error: 'Error',
      message: 'Meeting message',
      mode: 'Meeting mode',
      cancelMeetingLabel: 'No longer interested in this meeting?',
      changeAttendanceMode: 'Change mode to {{newMeetingMode}}',
      changeModeInfo: 'You are about to change how you are going to attend to this meeting. Do you wish to continue?',
      change: 'Change',
      changeModeSuccess: 'Your meeting attendance mode has been changed to {{newMeetingAttendanceMode}} successfully.',
      diferentAttendanceMode: 'The person you are meeting will join virtually. Please go to the meeting location and join the virtual meeting with the button below',
      onlineMeeting: 'Via online meeting',
      pendingMeetingEvaluation: 'You haven\'t evaluated this meeting yet',
      alreadyRequested: 'You already have a pending request with this participant.',
      alreadyScheduled: 'You have reached the maximum amount of meetings that can be scheduled with this participant ({{maxRepeatedMeetings}}). You can still chat with them, but can\'t send more meeting invitations.',
      alreadyScheduledPhysical: 'You have reached the maximum amount of meetings that can be scheduled with this participant ({{maxRepeatedMeetings}}) and can\'t send more meeting invitations.',
      schedulingDisabled: '[Meetings scheduling is disabled. It will be enabled on the] Do MMMM YYYY [at] {{timeFormat}} [GMT] Z',
      schedulingEnded: 'Meetings scheduling is disabled.',
      ratingDisabled: '[Participant rating is disabled. It will be enabled on the] Do MMMM YYYY [at] {{timeFormat}} [GMT] Z',
      ratingEnded: 'Participant rating is disabled.',
      info: 'Meeting information',
      participantInfo: 'Participant information',
      with: 'Meeting with {{participant}}',
      requestMeeting: 'Request meeting',
      sendRequest: 'Send request',
      meetingEvaluation: 'Meeting evaluation',
      pending: 'Pending: ',
      pendingEvaluation: 'Pending Evaluation: ',
      evaluationSuccess: 'Successfully submitted evaluation',
      cancelMeeting: 'Are you sure you want to cancel this meeting?',
      rejectionReasons: 'Reasons for rejecting meeting',
      waitingMyConfirmation: 'Waiting for my approval',
      waitingApproval: 'Waiting for approval from {{ participant }}',
      networkingMeeting: 'Networking meeting',
      slotHasRequests: 'You have meeting requests awaiting approval in this space',
    },
    slots: {
      occupied: 'Occupied',
      available: 'Available',
      blockedSpace: 'Blocked space',
      blockedExplanation: 'Let other attendees know you are unavailable at this time.',
    },
    requests: {
      request: 'Request',
      requests: 'Requests',
      rejectedRequests: 'Rejected requests',
      confirmApprove: 'By approving this meeting, all other requests will be automatically rejected.',
      confirmReject: 'Are you sure you want to reject this request?',
      confirmCancel: 'Are you sure you want to cancel this request?',
      received: 'Received',
      sent: 'Sent',
      waitingApproval: 'Waiting for approval',
      seeRequests: 'See requests',
      noSentRequestsYet: 'You haven\'t sent meeting requests yet.\nExplore attendees to request or review those received.',
      noReceivedRequestYet: 'No meeting requests have been sent to you.\nYou can Explore attendees and take the initiative with those you find interesting.',
      successReject: 'The meeting request was rejected.',
      successApprove: 'The meeting request was accepted.',
      meetingMessage: 'Meeting message',
      status: {
        rejected: 'Rejected',
        cancelled: 'Cancelled',
      }
    },
  },
  es: {
    notes: {
      placeholder: 'Escriba las notas de su reunión. Podrá descargarlos más tarde.',
      saveSuccess: 'Sus notas se guardaron',
    },
    actions: {
      evaluateMeeting: 'Evaluar reunión',
      sendMessage: 'Enviar mensaje',
      approve: 'Aceptar reunión',
      reject: 'Rechazar reunión',
      cancelMeeting: 'Cancelar reunión',
      cancel: 'Cancelar solicitud',
      join: 'Unirse',
      blockSpace: 'Bloquear este espacio',
      unblockSpace: 'Click para desbloquear este espacio',
      send: 'Enviar',
      checkInMeeting: 'Confirmar asistencia',
    },
    meeting: {
      title: 'Reunión',
      newMeetingMessage: 'Mensaje',
      selectANewTime: 'Seleccione una nueva hora',
      selectANewDay: 'Seleccione un nuevo día',
      proposeNewTime: 'Proponer nueva hora',
      meetingRequestMessage: 'Tu solicitud de cita ha sido enviada, serás notificado cuando {{participant}} acepte.',
      requestSent: 'Solicitud de cita enviada.',
      error: 'Error',
      message: 'Mensaje de la reunión',
      mode: 'Tipo de asistencia',
      cancelMeetingLabel: '¿Ya no estás interesado(a) en esta reunión?',
      changeAttendanceMode: 'Cambiar modo a {{newMeetingMode}}',
      changeModeInfo: 'Está a punto de cambiar la forma en que asistirá a esta reunión. ¿Desea continuar?',
      change: 'Cambiar',
      changeModeSuccess: 'Tu modo de asistencia a la reunión se ha cambiado a {{newMeetingAttendanceMode}} con éxito.',
      diferentAttendanceMode: 'La persona con la que se va a reunir se unirá virtualmente. Vaya a la ubicación de la reunión y únase a la reunión virtual',
      onlineMeeting: 'Reunión en línea',
      pendingMeetingEvaluation: 'Aún no has evaluado esta reunión',
      alreadyRequested: 'Ya tienes una solicitud pendiente con este participante.',
      alreadyScheduled: 'Ha alcanzado el número máximo de reuniones que se pueden programar con este participante ({{maxRepeatedMeetings}}). Puede seguir charlando con ellos, pero no puede enviar más invitaciones a reuniones.',
      alreadyScheduledPhysical: 'Ha alcanzado el número máximo de reuniones que se pueden programar con este participante ({{maxRepeatedMeetings}}) y no puede enviar más invitaciones a reuniones.',
      schedulingDisabled: '[El agendamiento de citas está deshabilitado. Este se habilitará el] D [de] MMMM [de] YYYY [a las] {{timeFormat}} [GMT] Z',
      schedulingEnded: 'El agendamiento de citas está deshabilitado.',
      ratingDisabled: '[La calificación de participantes está deshabilitada. Esta se habilitará el] D [de] MMMM [de] YYYY [a las] {{timeFormat}} [GMT] Z',
      ratingEnded: 'La calificación de participantes está deshabilitada.',
      info: 'Información de la reunión',
      participantInfo: 'Información del participante',
      with: 'Reunión con {{participant}}',
      requestMeeting: 'Solicitar reunión',
      sendRequest: 'Enviar solicitud',
      meetingEvaluation: 'Evaluación de la cita',
      pending: 'Pendiente: ',
      pendingEvaluation: 'Evaluación pendiente: ',
      evaluationSuccess: 'Evaluación enviada correctamente',
      cancelMeeting: '¿Estás seguro que quieres cancelar esta reunión?',
      rejectionReasons: 'Razones para rechazar la cita',
      waitingMyConfirmation: 'Esperando mi aprobación',
      waitingApproval: 'Esperando aprobación de {{ participant }}',
      slotHasRequests: 'Tienes solicitudes de reunión en espera de aprobación en este espacio',
    },
    slots: {
      occupied: 'Ocupado',
      available: 'Disponible',
      blockedSpace: 'Espacio bloqueado',
      blockedExplanation: 'Deja que otros participantes sepan que no estás disponible en este momento.',
    },
    requests: {
      request: 'Solicitud',
      requests: 'Solicitudes',
      rejectedRequests: 'Solicitudes rechazadas',
      confirmApprove: 'Al aprobar esta reunión, todas las demás solicitudes se rechazarán automáticamente.',
      confirmReject: '¿Estás seguro de que deseas rechazar esta solicitud?',
      confirmCancel: '¿Estás seguro de que deseas cancelar esta solicitud?',
      received: 'Recibidas',
      sent: 'Enviadas',
      waitingApproval: 'Esperando aprobación',
      seeRequests: 'Ver solicitudes',
      noSentRequestsYet: 'Todavía no has enviado solicitudes de reunión.\nExplora a los asistentes para solicitar o revisar las recibidas.',
      noReceivedRequestYet: 'No se han enviado solicitudes de reunión a tu nombre.\nPuedes explorar a los asistentes y tomar la iniciativa con aquellos que te parezcan interesantes.',
      successReject: 'La solicitud de reunión ha sido rechazada.',
      successApprove: 'La solicitud de reunión ha sido aceptada.',
      meetingMessage: 'Mensaje de la reunión',
      status: {
        rejected: 'Rechazada',
        cancelled: 'Cancelada',
      }
    }
  },
  fr: {
    notes: {
      placeholder: 'Ecrivez ici vos notes durant la réunion. Vous pourrez les télécharger à tout moment en cliquant sur l\'icône "imprimante" depuis l\'onglet Programme',
      saveSuccess: 'Vos notes ont été enregistrées',
    },
    actions: {
      evaluateMeeting: 'Evaluer la réunion',
      sendMessage: 'Envoyer un message',
      approve: 'Approuver la réunion',
      reject: 'Réunion rejetée',
      cancelMeeting: 'Annuler la réunion',
      cancel: 'Annuler la demande',
      join: 'Rejoignez',
      blockSpace: 'Bloquer l\'espace',
      unblockSpace: 'Cliquez pour débloquer l\'espace',
      send: 'Envoyer',
      checkInMeeting: 'Arrivée à la réunion',
    },
    meeting: {
      title: 'Réunion',
      newMeetingMessage: 'Message',
      selectANewTime: 'Sélectionnez un nouveau créneau',
      selectANewDay: 'Sélectionnez une nouvelle date',
      proposeNewTime: 'Proposer un nouvel horaire',
      meetingRequestMessage: 'Votre demande de réunion a été envoyée, vous serez informé lorsque {{participant}} acceptera',
      requestSent: 'Demande de réunion envoyée',
      error: 'Erreur',
      message: 'Message de la réunion',
      mode: 'Mode d\'assistance',
      cancelMeetingLabel: 'Vous n\'êtes plus intéressé par cette rencontre ?',
      changeAttendanceMode: 'Changer de mode pour {{newMeetingMode}}',
      changeModeInfo: 'Vous êtes sur le point de changer la façon dont vous allez assister à cette réunion. Souhaitez-vous continuer?',
      change: 'Changement',
      changeModeSuccess: 'Votre mode de participation à la réunion a été changé en {{newMeetingAttendanceMode}} avec succès.',
      diferentAttendanceMode: 'La personne que vous rencontrez se joindra virtuellement. Veuillez vous rendre sur le lieu de la réunion et rejoindre la réunion virtuelle avec le bouton ci-dessous',
      onlineMeeting: 'Réunion en ligne',
      pendingMeetingEvaluation: 'Vous n\'avez pas encore évalué cette réunion',
      alreadyRequested: 'Vous avez déjà une demande en attente avec ce participant.',
      alreadyScheduled: 'Vous avez atteint le nombre maximum de réunions pouvant être planifiées avec ce participant ({{maxRepeatedMeetings}}). Vous pouvez toujours discuter avec eux, mais vous ne pouvez plus envoyer d\'invitations à des réunions.',
      alreadyScheduledPhysical: 'Vous avez atteint le nombre maximum de réunions pouvant être planifiées avec ce participant ({{maxRepeatedMeetings}}) et vous ne pouvez plus envoyer d\'invitations à des réunions',
      schedulingDisabled: '[La programmation des réunions est désactivée. Elle sera activée le] D MMMM YYYY [à] HH[h]mm',
      schedulingEnded: 'La programmation des réunions est désactivée.',
      ratingDisabled: '[L\'évaluation des participants est désactivée. Il sera activé sur la page] D MMMM YYYY [à] HH[h]mm',
      ratingEnded: 'L\'évaluation des participants est désactivée.',
      info: 'Informations sur la réunion',
      participantInfo: 'Information du participant',
      with: 'Rencontre avec {{participant}}',
      meetingEvaluation: 'Évaluation de la réunion',
      pending: 'En attente: ',
      pendingEvaluation: 'En attente d\'évaluation: ',
      evaluationSuccess: 'Évaluation soumise avec succès',
      requestMeeting: 'Demande de réunion',
      sendRequest: 'Envoyer la requête',
      cancelMeeting: 'Vous êtes sûr de vouloir annuler cette réunion ?',
      rejectionReasons: 'Raisons du rejet de la réunion',
      waitingMyConfirmation: 'En attente de mon approbation',
      waitingApproval: 'En attente de l\'approbation de {{ participant }}',
      slotHasRequests: 'Vous avez des demandes de réunion en attente d\'approbation dans cet espace.'
    },
    slots: {
      occupied: 'Occupé',
      available: 'Disponible',
      blockedSpace: 'Espace bloqué',
      blockedExplanation: 'Faites savoir aux autres participants que vous n\'êtes pas disponible pour le moment.',
    },
    requests: {
      request: 'Demande',
      requests: 'Demandes',
      rejectedRequests: 'Demandes rejetées',
      confirmApprove: 'En approuvant cette réunion, toutes les autres demandes seront automatiquement rejetées.',
      confirmReject: 'Êtes vous sûr(e) de vouloir refuser cette demande ?',
      confirmCancel: 'Êtes vous sûr(e) de vouloir annuler cette demande ?',
      received: 'Reçues',
      sent: 'Envoyées',
      waitingApproval: 'En attente d\'approbation',
      seeRequests: 'Voir les demandes',
      noSentRequestsYet: 'Vous n\'avez pas encore envoyé de demandes de réunion.\nExplorez les participants pour en faire la demande ou examinez celles que vous avez reçues.',
      noReceivedRequestYet: 'Aucune demande de réunion ne vous a été envoyée.\nVous pouvez explorer les participants et prendre l\'initiative avec ceux qui vous intéressent.',
      successReject: 'La demande de réunion a été rejetée.',
      successApprove: 'La demande de réunion a été acceptée.',
      meetingMessage: 'Message de réunion',
      status: {
        rejected: 'Rejetée',
        cancelled: 'Annulée',
      }
    }
  },
  ca: {
    notes: {
      placeholder: 'Anoteu notes a la vostra reunió. Podràs descarregar-los més endavant.',
      saveSuccess: 'Les teves notes es van guardar',
    },
    actions: {
      evaluateMeeting: 'Avalua la reunió',
      sendMessage: 'Envia el missatge',
      approve: 'Aprova la reunió',
      reject: 'Rebutja la reunió',
      cancelMeeting: 'Cancel·la la reunió',
      cancel: 'Cancel·la el requeriment',
      join: 'Uneix-t’hi',
      blockSpace: 'Bloqueja l’espai',
      unblockSpace: 'Fes clic per desbloquejar l’espai',
      send: 'Envia',
      checkInMeeting: 'Entra a la reunió',
    },
    meeting: {
      title: 'Reunió',
      newMeetingMessage: 'Missatge',
      selectANewTime: 'Selecciona una hora nova',
      selectANewDay: 'Selecciona un dia nou',
      proposeNewTime: 'Proposa una nova hora',
      meetingRequestMessage: 'El teu requeriment de reunió s’ha enviat, et notificarem quan en/na {{participant}} accepti.',
      requestSent: 'Requeriment de reunió enviat',
      error: 'Error',
      message: 'Missatge de reunió',
      mode: 'Mode de reunió',
      cancelMeetingLabel: 'Ja no t’interessa aquesta reunió?',
      changeAttendanceMode: 'Canvia mode a {{newMeetingMode}}',
      changeModeInfo: 'Estàs a punt de canviar com atendràs aquesta reunió. Vols continuar?',
      change: 'Canvia',
      changeModeSuccess: 'El teu mode d’assistència a la reunió ha canviat a {{newMeetingAttendanceMode}}',
      diferentAttendanceMode: 'La persona amb qui et reuneixes atendrà virtualment. Per favor, vés al lloc de reunió i uneix-te a la trobada virtual amb el botó de sota',
      onlineMeeting: 'Mitjançant reunió en línia',
      pendingMeetingEvaluation: 'No has avaluat aquesta reunió encara',
      alreadyRequested: 'Ja tens una sol·licitud pendent amb aquest participant.',
      alreadyScheduled: 'Heu assolit el màxim de reunions que es poden programar amb aquest participant ({{maxRepeatedMeetings}}). Encara podeu xatejar amb ells, però no podeu enviar més invitacions de reunió.',
      alreadyScheduledPhysical: 'Heu assolit el màxim de reunions que es poden programar amb aquest participant ({{maxRepeatedMeetings}}) i no podeu enviar més invitacions a reunions.',
      schedulingDisabled: undefined,
      schedulingEnded: 'La planificació de reunions està desactivada ',
      ratingDisabled: undefined,
      ratingEnded: 'La qualificació de participant està desactivada',
      info: 'Informació de reunió',
      participantInfo: 'Informació de participant',
      with: 'Reunió amb {{participant}}',
      requestMeeting: 'Sol·licita reunió',
      sendRequest: 'Envia sol·licitud',
      meetingEvaluation: 'Avaluació de reunió',
      pending: 'Pendent:',
      pendingEvaluation: 'Avaluació pendent:',
      evaluationSuccess: 'Avaluació enviada',
      cancelMeeting: 'Estàs segur/a que vols cancel·lar aquesta reunió?',
      rejectionReasons: 'Raons per rebutjar la reunió',
      waitingMyConfirmation: 'Esperant aprovació',
      waitingApproval: 'Esperant aprovació de {{ participant }}',
      slotHasRequests: 'Teniu sol·licituds de reunió pendents d\'aprovació en aquest espai.'
    },
    slots: {
      occupied: 'Ocupat',
      available: 'Disponible',
      blockedSpace: 'Espai bloquejat',
      blockedExplanation: 'Fes saber a la resta d’assistents que no estàs disponible en aquesta hora.',
    },
    requests: {
      request: 'Sol·licitud',
      requests: 'Sol·licituds',
      rejectedRequests: 'Sol·licituds rebutjades',
      confirmApprove: 'En aprovar aquesta reunió, totes les altres sol·licituds seran rebutjades automàticament.',
      confirmReject: 'Esteu segur que voleu rebutjar aquesta sol·licitud?',
      confirmCancel: 'Esteu segur que voleu cancel·lar aquesta sol·licitud?',
      received: 'Rebudes',
      sent: 'Enviades',
      waitingApproval: 'Esperant aprovació',
      seeRequests: 'Veure sol·licituds',
      noSentRequestsYet: 'Encara no has enviat sol·licituds de reunió.\nExplora els assistents per a sol·licitar-ne o revisa les rebudes.',
      noReceivedRequestYet: 'No s\'ha enviat cap sol·licitud de reunió a tu.\nPots explorar els assistents i prendre la iniciativa amb els que et semblin interessants.',
      successReject: 'La sol·licitud de reunió ha estat rebutjada.',
      successApprove: 'La sol·licitud de reunió ha estat acceptada.',
      meetingMessage: 'Missatge de reunió',
      status: {
        rejected: 'Rebutjada',
        cancelled: 'Cancel·lada',
      }
    },
  },
  pt: {
    notes: {
      placeholder: 'Anote suas anotações sobre sua reunião. Você poderá baixá-los mais tarde.',
      saveSuccess: 'Suas anotações foram salvas',
    },
    actions: {
      evaluateMeeting: 'Avaliar reunião',
      sendMessage: 'Enviar mensagem',
      approve: 'Aprovar reunião',
      reject: 'Rejeitar reunião',
      cancelMeeting: 'Cancelar reunião',
      cancel: 'Cancelar solicitação',
      join: 'Entrar',
      blockSpace: 'Bloquear',
      unblockSpace: 'Clique para desbloquear o espaço',
      send: 'Enviar',
      checkInMeeting: 'Entrada para reunião',
    },
    meeting: {
      title: 'Reunião',
      newMeetingMessage: 'Mensagem',
      selectANewTime: 'Selecione uma nova hora',
      selectANewDay: 'Selecione um novo dia',
      proposeNewTime: 'Propor novo horário',
      meetingRequestMessage: 'Sua solicitação de reunião foi enviada, você será notificado quando {{participant}} aceitar.',
      requestSent: 'Pedido de reunião enviado',
      error: 'Erro',
      message: 'Mensagem pedido reunião',
      mode: 'Modo de assistência',
      cancelMeetingLabel: 'Não está mais interessado nesta reunião?',
      changeAttendanceMode: 'Alterar modo para {{newMeetingMode}}',
      changeModeInfo: 'Você está prestes a mudar a forma como vai participar desta reunião. Você deseja continuar?',
      change: 'Mudar',
      changeModeSuccess: 'Seu modo de participação na reunião foi alterado para {{newMeetingAttendanceMode}} com sucesso.',
      diferentAttendanceMode: 'A pessoa que você está conhecendo se juntará virtualmente. Por favor, vá para o local da reunião e participe da reunião virtual com o botão abaixo',
      onlineMeeting: 'Reunião on-line',
      pendingMeetingEvaluation: 'Você ainda não avaliou esta reunião',
      alreadyRequested: 'Você já tem uma solicitação pendente com este participante.',
      alreadyScheduled: 'Você atingiu a quantidade máxima de reuniões que podem ser agendadas com esse participante ({{maxRepeatedMeetings}}). Você ainda pode conversar com eles, mas não pode enviar mais convites para reuniões.',
      alreadyScheduledPhysical: 'Você atingiu a quantidade máxima de reuniões que podem ser agendadas com esse participante ({{maxRepeatedMeetings}}) e não pode enviar mais convites para reuniões.',
      schedulingDisabled: '[O agendamento de reuniões está desativado. Ele será ativado no] Fazer MMMM AAAA [at] {{timeFormat}} [GMT] Z',
      schedulingEnded: 'O agendamento de reuniões está desativado.',
      ratingDisabled: '[A classificação do participante está desativada. Será habilitado no dia] D MMMM YYYY [às] HH[h]mm',
      ratingEnded: 'A classificação do participante está desativada.',
      info: 'Informação da reunião',
      participantInfo: 'Informações do participante',
      with: 'Reunião com {{participant}}',
      requestMeeting: 'Solicitar reunião',
      sendRequest: 'Enviar pedido',
      meetingEvaluation: 'Avaliação da reunião',
      pending: 'Pendente: ',
      pendingEvaluation: 'Avaliação pendente: ',
      evaluationSuccess: 'Avaliação enviada com sucesso',
      cancelMeeting: 'Tem certeza de que deseja cancelar esta reunião?',
      rejectionReasons: 'Razões para rejeitar a reunião',
      waitingMyConfirmation: 'Esperando minha aprovação',
      waitingApproval: 'Aguardando aprovação de {{ participant }}',
      slotHasRequests: 'Você tem solicitações de reunião aguardando aprovação neste espaço.',
    },
    slots: {
      occupied: 'Ocupado',
      available: 'Disponível',
      blockedSpace: 'Agenda bloqueada',
      blockedExplanation: 'Você aparecerá indisponível aos outros participantes.',
    },
    requests: {
      request: 'Solicitação',
      requests: 'Solicitações',
      rejectedRequests: 'Solicitações rejeitadas',
      confirmApprove: 'Ao aprovar esta reunião, todas as outras solicitações serão automaticamente rejeitadas.',
      confirmReject: 'Tem certeza de que deseja rejeitar este pedido?',
      confirmCancel: 'Tem certeza de que deseja cancelar este pedido?',
      received: 'Recebidas',
      sent: 'Enviadas',
      waitingApproval: 'Aguardando aprovação',
      seeRequests: 'Ver solicitações',
      noSentRequestsYet: 'Você ainda não enviou solicitações de reunião.\nExplore os participantes para solicitar ou revise as recebidas.',
      noReceivedRequestYet: 'Nenhuma solicitação de reunião foi enviada para você.\nVocê pode explorar os participantes e tomar a iniciativa com aqueles que achar interessantes.',
      successReject: 'A solicitação de reunião foi rejeitada.',
      successApprove: 'A solicitação de reunião foi aceita.',
      meetingMessage: 'Mensagem da reunião',
      status: {
        rejected: 'Rejeitada',
        cancelled: 'Cancelada',
      }
    },
  },
  it: {
    notes: {
      placeholder: 'Scriva degli appunti sulla sua riunione. Potrà scaricarli più tardi.',
      saveSuccess: 'I suoi appunti sono stati salvati',
    },
    actions: {
      sendMessage: 'Invia messaggio',
      cancelMeeting: 'Annulla riunione',
      reject: 'Rifiuta la riunione',
      cancel: 'Annulla richiesta',
      approve: 'Approva la riunione',
      join: 'Unisciti',
      blockSpace: 'Spazio a blocchi',
      send: 'Invia',
      evaluateMeeting: 'Valuta la riunione',
      unblockSpace: 'Fai clic per sbloccare lo spazio',
      checkInMeeting: 'Check-in alla riunione',
    },
    meeting: {
      newMeetingMessage: 'Messaggio',
      error: 'Errore',
      selectANewDay: 'Seleziona un nuovo giorno',
      title: 'Incontro',
      selectANewTime: 'Seleziona una nuova ora',
      proposeNewTime: 'Proponi nuovo orario',
      requestSent: 'Richiesta incontro inviata',
      message: 'Messaggio della riunione',
      mode: 'Tipo di assistenza',
      cancelMeetingLabel: 'Non sei più interessato a questo incontro?',
      changeAttendanceMode: 'Cambia modo a {{newMeetingMode}}',
      changeModeInfo: 'Stai per cambiare il modo in cui parteciperai a questa riunione. Vuoi continuare?',
      change: 'Modificare',
      changeModeSuccess: 'La modalità di partecipazione alla riunione è stata modificata correttamente in {{newMeetingAttendanceMode}}.',
      diferentAttendanceMode: 'La persona che stai incontrando si unirà virtualmente. Vai al luogo della riunione e partecipa alla riunione virtuale con il pulsante in basso',
      onlineMeeting: 'Incontro in linea',
      pendingMeetingEvaluation: 'Non hai ancora valutato questo incontro',
      alreadyRequested: 'Hai già una richiesta in sospeso con questo partecipante.',
      info: 'Informazioni sulla riunione',
      schedulingEnded: 'La pianificazione delle riunioni è disabilitata.',
      sendRequest: 'Invia richiesta',
      ratingEnded: 'La valutazione dei partecipanti è disabilitata.',
      meetingEvaluation: 'Valutazione della riunione',
      participantInfo: 'Informazioni sui partecipanti',
      meetingRequestMessage: 'La tua richiesta di riunione è stata inviata, riceverai una notifica quando {{participant}} accetterà.',
      pending: 'In attesa di: ',
      alreadyScheduled: 'Ha raggiunto il numero massimo di riunioni che possono essere programmate con questo partecipante ({{maxRepeatedMeetings}}). Può ancora chiacchierare con loro, ma non può inviare altri inviti alle riunioni.',
      alreadyScheduledPhysical: 'Ha raggiunto il numero massimo di riunioni che possono essere programmate con questo partecipante ({{maxRepeatedMeetings}}) e non può inviare altri inviti alle riunioni.',
      requestMeeting: 'Richiesta di incontro',
      evaluationSuccess: 'Valutazione inviata con successo',
      cancelMeeting: 'Sei sicuro di voler annullare questo incontro?',
      schedulingDisabled: '[La pianificazione delle riunioni è disabilitata. Sarà abilitato su] Do MMMM AAAA [at] {{timeFormat}} [GMT] Z',
      rejectionReasons: 'Motivi per rifiutare la riunione',
      ratingDisabled: '[La valutazione dei partecipanti è disabilitata. Sarà abilitato su] Do MMMM AAAA [at] {{timeFormat}} [GMT] Z',
      with: 'Incontro con {{participant}}',
      pendingEvaluation: 'In attesa di valutazione: ',
      waitingMyConfirmation: 'In attesa della mia approvazione',
      waitingApproval: 'In attesa di approvazione da {{ participant }}',
      slotHasRequests: 'Hai richieste di riunione in attesa di approvazione in questo spazio.',
    },
    slots: {
      available: 'Disponibile',
      blockedExplanation: 'Fai sapere agli altri partecipanti che non sei disponibile in questo momento.',
      occupied: 'Occupati',
      blockedSpace: 'Spazio bloccato',
    },
    requests: {
      request: 'Richiesta',
      requests: 'Richieste',
      rejectedRequests: 'Richieste rifiutate',
      confirmApprove: 'Approvando questo incontro, tutte le altre richieste saranno automaticamente respinte.',
      confirmReject: 'Sei sicuro di voler rifiutare questa richiesta?',
      confirmCancel: 'Sei sicuro di voler cancellare questa richiesta?',
      received: 'Ricevute',
      sent: 'Inviati',
      waitingApproval: 'In attesa di approvazione',
      seeRequests: 'Visualizza richieste',
      noSentRequestsYet: 'Non hai ancora inviato richieste di incontro.\nEsplora i partecipanti per richiederne o rivedi quelle ricevute.',
      noReceivedRequestYet: 'Nessuna richiesta di incontro è stata inviata a te.\nPuoi esplorare i partecipanti e prendere l\'iniziativa con quelli che trovi interessanti.',
      successReject: 'La richiesta di incontro è stata respinta.',
      successApprove: 'La richiesta di incontro è stata accettata.',
      meetingMessage: 'Messaggio di riunione',
      status: {
        rejected: 'Rifiutata',
        cancelled: 'Annullata',
      }
    },
  },
  ja: {
    notes: {
      placeholder: '会議のメモを書き留めてください。後でダウンロードできます。',
      saveSuccess: 'あなたのメモは保存されました',
    },
    actions: {
      approve: 'ミーティングを承認する',
      evaluateMeeting: 'ミーティングの評価',
      cancel: 'リクエストをキャンセルする',
      reject: '会議を拒否',
      sendMessage: 'メッセージを送る',
      blockSpace: 'ブロックスペース',
      cancelMeeting: 'ミーティングをキャンセルする',
      send: '送る',
      unblockSpace: 'クリックするとスペースのブロックが解除されます',
      join: '加入',
      checkInMeeting: '会議へのチェックイン',
    },
    meeting: {
      title: 'ミーティング',
      selectANewTime: '新しい時間を選択してください',
      selectANewDay: '新しい曜日を選ぶ',
      error: 'エラー',
      message: 'ミーティングメッセージ',
      mode: '支援の種類',
      cancelMeetingLabel: 'この会議にもう興味がありませんか',
      changeAttendanceMode: '出席をに変更します {{newMeetingMode}}',
      changeModeInfo: 'この会議への参加方法を変更しようとしています。 続行しますか',
      change: '変化する',
      changeModeSuccess: '会議出席モードが {{newMeetingAttendanceMode}} に正常に変更されました。',
      diferentAttendanceMode: 'あなたが会っている人は事実上参加します。 ミーティングの場所に移動し、下のボタンを使用して仮想ミーティングに参加してください',
      onlineMeeting: 'オンライン会議',
      pendingMeetingEvaluation: 'この会議はまだ評価されていません',
      alreadyRequested: 'この参加者とは既に保留中のリクエストがあります。',
      participantInfo: '参加者情報',
      info: 'ミーティング情報',
      schedulingEnded: 'ミーティングのスケジュールは無効になっています。',
      alreadyScheduled: 'この参加者と予定できる会議の最大数に達しました ({{maxRepeatedMeetings}})。彼らとチャットすることはできますが、会議への招待状をこれ以上送ることはできません。',
      alreadyScheduledPhysical: 'この参加者と予定できる会議の最大数（{{maxRepeatedMeetings}}）に達したため、これ以上会議の招待状を送ることができません。',
      meetingEvaluation: 'ミーティング評価',
      newMeetingMessage: 'メッセージ',
      meetingRequestMessage: '会議出席依頼が送信されました。{{participant}} が承諾すると通知されます。',
      with: '{{participant}} とのミーティング',
      proposeNewTime: '新しい時間を提案する',
      pending: '保留中: ',
      requestSent: '会議出席依頼を送信しました',
      requestMeeting: 'ミーティングをリクエストする',
      ratingEnded: '参加者の評価は無効になっています。',
      cancelMeeting: 'この会議をキャンセルしてよろしいですか？',
      schedulingDisabled: '[ミーティングのスケジュールは無効になっています。それは] Do MMMM YYYY [at] {{timeFormat}} [GMT] Zで有効になります',
      rejectionReasons: '会議を却下する理由',
      ratingDisabled: '[参加者の評価は無効になっています。それは] Do MMMM YYYY [at] {{timeFormat}} [GMT] Zで有効になります',
      evaluationSuccess: '評価が正常に送信されました',
      pendingEvaluation: '評価保留中: ',
      sendRequest: 'リクエストを送信',
      waitingMyConfirmation: '私の承認を待っています',
      waitingApproval: '{{ participant }} からの承認を待っています',
      slotHasRequests: 'このスペースで承認を待つ会議リクエストがあります。',
    },
    slots: {
      occupied: '占領',
      blockedExplanation: '現時点では、自分が参加できないことを他の出席者に知らせます。',
      available: '使用可能',
      blockedSpace: 'ブロックされた領域',
    },
    requests: {
      request: 'リクエスト',
      requests: 'リクエスト一覧',
      rejectedRequests: '却下されたリクエスト',
      confirmApprove: 'このミーティングを承認すると、他のすべてのリクエストが自動的に却下されます。',
      confirmReject: 'このリクエストを拒否してもよろしいですか？',
      confirmCancel: 'このリクエストをキャンセルしてもよろしいですか？',
      received: '受信済み',
      sent: '送信済み',
      waitingApproval: '承認待ち',
      seeRequests: 'リクエストを表示',
      noSentRequestsYet: 'まだミーティングのリクエストを送信していません。\n参加者を探索してリクエストを送信するか、受信したリクエストを確認してください。',
      noReceivedRequestYet: 'あなた宛にミーティングのリクエストは送信されていません。\n参加者を探索し、興味のある相手に主導権を取ることができます。',
      successReject: 'ミーティングのリクエストが却下されました。',
      successApprove: 'ミーティングのリクエストが承認されました。',
      meetingMessage: '会議メッセージ',
      status: {
        rejected: '却下済み',
        cancelled: 'キャンセル済み',
      }
    },
  },
  zh: {
    notes: {
      placeholder: '写下会议笔记。您稍后可以下载它们。',
      saveSuccess: '您的笔记已保存',
    },
    actions: {
      cancelMeeting: '取消会议',
      cancel: '取消请求',
      approve: '批准会议',
      reject: '拒绝会议',
      evaluateMeeting: '评估会议',
      sendMessage: '发送消息',
      blockSpace: '块空间',
      send: '发送',
      unblockSpace: '单击以取消封锁空间',
      join: '加入',
      checkInMeeting: '登记参加会议',
    },
    meeting: {
      newMeetingMessage: '消息',
      title: '会议',
      error: '错误',
      selectANewDay: '选择新的一天',
      selectANewTime: '选择一个新的时间',
      proposeNewTime: '提出新的时间',
      requestSent: '会议请求已发送',
      message: '会议消息',
      mode: '援助類型',
      cancelMeetingLabel: '對此會議不再感興趣',
      changeAttendanceMode: '將出席更改為 {{newMeetingMode}}',
      changeModeInfo: '您即將改變參加本次會議的方式。 你想繼續嗎',
      change: '改變',
      changeModeSuccess: '您的會議出席模式已成功更改為 {{newMeetingAttendanceMode}} 。',
      diferentAttendanceMode: '您正在會見的人將虛擬加入。 請前往會議地點並使用下方按鈕加入虛擬會議',
      onlineMeeting: '在線會議',
      pendingMeetingEvaluation: '你还没有评估这次会议',
      alreadyRequested: '您已经有一个待处理的请求与此参与者相关。',
      info: '会议信息',
      participantInfo: '参与者信息',
      ratingEnded: '参与者评分已禁用。',
      meetingRequestMessage: '您的会议请求已发送，当 {{participant}} 接受时，您将收到通知。',
      schedulingEnded: '会议安排已禁用。',
      requestMeeting: '请求开会',
      alreadyScheduled: '您已达到可以与该参与者安排的最大会议次数 ({{maxRepeatedMeetings}})。您仍然可以与他们聊天，但无法发送更多会议邀请。',
      alreadyScheduledPhysical: '您已达到可以与该参与者 ({{maxRepeatedMeetings}}) 安排的最大会议次数，无法发送更多会议邀请。',
      sendRequest: '发送请求',
      with: '与 {{参与者}} 会面',
      pending: '待处理： ',
      evaluationSuccess: '成功提交评估',
      pendingEvaluation: '待评估： ',
      schedulingDisabled: '[会议安排已禁用。它将在] Do MMMM YYYY [at] {{timeFormat}} [格林尼治标准时间] Z 上启用',
      cancelMeeting: '你确定要取消这次会议吗？',
      ratingDisabled: '[参与者评分已禁用。它将在] Do MMMM YYYY [at] {{timeFormat}} [格林尼治标准时间] Z 上启用',
      meetingEvaluation: '会议评估',
      rejectionReasons: '拒绝会议的原因',
      waitingMyConfirmation: '等待我的批准',
      waitingApproval: '等待 {{ participant }} 的批准',
      slotHasRequests: '您在此空间中有待批准的会议请求。',
    },
    slots: {
      available: '可用',
      occupied: '被占领',
      blockedSpace: '封锁的空间',
      blockedExplanation: '让其他与会者知道你目前不在场。',
    },
    requests: {
      request: '请求',
      requests: '请求',
      rejectedRequests: '已拒绝的请求',
      confirmApprove: '通过批准此会议，所有其他请求将被自动拒绝。',
      confirmReject: '您确定要拒绝此请求吗？',
      confirmCancel: '您确定要取消此请求吗？',
      received: '已收到',
      sent: '已发送',
      waitingApproval: '等待批准',
      seeRequests: '查看请求',
      noSentRequestsYet: '您还没有发送会议请求。\n探索与会者以提出请求或查看已收到的请求。',
      noReceivedRequestYet: '尚未有会议请求发送给您。\n您可以探索与会者并主动联系您感兴趣的人。',
      successReject: '会议请求已被拒绝。',
      successApprove: '会议请求已被接受。',
      meetingMessage: '会议留言',
      status: {
        rejected: '已拒绝',
        cancelled: '已取消',
      }
    },
  },
  ar: {
    notes: {
      placeholder: 'اكتب الملاحظات على اجتماعك. ستتمكن من تنزيلها لاحقًا.',
      saveSuccess: 'تم حفظ ملاحظاتك',
    },
    actions: {
      evaluateMeeting: 'تقييم الاجتماع',
      sendMessage: 'إرسال رسالة',
      approve: 'الموافقة على الاجتماع',
      reject: 'رفض الاجتماع',
      cancelMeeting: 'إلغاء الاجتماع',
      cancel: 'طلب إلغاء',
      join: 'انضم',
      blockSpace: 'مساحة الكتلة',
      unblockSpace: 'انقر لإلغاء حظر المساحة',
      send: 'إرسال',
      checkInMeeting: 'تسجيل الوصول إلى الاجتماع',
    },
    meeting: {
      title: 'اجتماع',
      newMeetingMessage: 'رسالة',
      selectANewTime: 'حدد وقتًا جديدًا',
      selectANewDay: 'حدد يومًا جديدًا',
      proposeNewTime: 'اقتراح وقت جديد',
      meetingRequestMessage: 'تم إرسال طلب الاجتماع الخاص بك، سيتم إعلامك عندما يقبل {{participant}}}.',
      requestSent: 'تم إرسال طلب الاجتماع',
      error: 'خطأ',
      message: 'رسالة اجتماع',
      mode: 'وضع الاجتماع',
      cancelMeetingLabel: 'لم تعد مهتمة بهذا الاجتماع؟',
      changeAttendanceMode: 'تغيير الوضع إلى {{newMeetingMode}}',
      changeModeInfo: 'أنت على وشك تغيير الطريقة التي ستحضرها إلى هذا الاجتماع. هل ترغب في الاستمرار؟',
      change: 'تغيير',
      changeModeSuccess: 'تم تغيير وضع حضور الاجتماع الخاص بك إلى {{newMeetingAttendanceMode}} بنجاح.',
      diferentAttendanceMode: 'سينضم الشخص الذي تقابله افتراضيًا. يرجى الانتقال إلى موقع الاجتماع والانضمام إلى الاجتماع الافتراضي باستخدام الزر أدناه.',
      onlineMeeting: 'عبر الاجتماع عبر الإنترنت',
      pendingMeetingEvaluation: 'لم تقم بتقييم هذا الاجتماع حتى الآن',
      alreadyRequested: 'لديك بالفعل طلب معلق مع هذا المشارك.',
      alreadyScheduled: 'لقد وصلت إلى الحد الأقصى لعدد الاجتماعات التي يمكن جدولتها مع هذا المشارك ({{maxRepeatedMeetings}}). لا يزال بإمكانك الدردشة معهم، ولكن لا يمكنك إرسال المزيد من دعوات الاجتماعات.',
      alreadyScheduledPhysical: 'لقد وصلت إلى الحد الأقصى لعدد الاجتماعات التي يمكن جدولتها مع هذا المشارك ({{maxRepeatedMeetings}}) ولا يمكنك إرسال المزيد من دعوات الاجتماعات.',
      schedulingDisabled: '[تم تعطيل جدولة الاجتماعات. سيتم تمكينه على] هل MMMM YYYY [في] {{timeFormat}} [GMT] Z',
      schedulingEnded: 'تم تعطيل جدولة الاجتماعات.',
      ratingDisabled: '[تم تعطيل تصنيف المشاركين. سيتم تمكينه على] هل MMMM YYYY [في] {{timeFormat}} [GMT] Z',
      ratingEnded: 'تم تعطيل تصنيف المشاركين.',
      info: 'معلومات الاجتماع',
      participantInfo: 'معلومات المشاركين',
      with: 'لقاء مع {{participant}}',
      requestMeeting: 'طلب اجتماع',
      sendRequest: 'إرسال طلب',
      meetingEvaluation: 'تقييم الاجتماع',
      pending: 'في انتظار: ',
      pendingEvaluation: 'التقييم المعلق: ',
      evaluationSuccess: 'التقييم الذي تم تقديمه بنجاح',
      cancelMeeting: 'هل تريد بالتأكيد إلغاء هذا الاجتماع؟',
      rejectionReasons: 'أسباب رفض الاجتماع',
      waitingMyConfirmation: 'في انتظار موافقتي',
      waitingApproval: 'في انتظار الموافقة من {{ participant }}',
      slotHasRequests: 'لديك طلبات اجتماع في هذا المكان في انتظار الموافقة.',
    },
    slots: {
      occupied: 'احتلت',
      available: 'متاح',
      blockedSpace: 'مساحة محظورة',
      blockedExplanation: 'دع الحاضرين الآخرين يعرفون أنك غير متاح في هذا الوقت.',
    },
    requests: {
      request: 'طلب',
      requests: 'طلبات',
      rejectedRequests: 'الطلبات المرفوضة',
      confirmApprove: 'من خلال الموافقة على هذا الاجتماع، سيتم رفض جميع الطلبات الأخرى تلقائيًا.',
      confirmReject: 'هل أنت متأكد من رغبتك في رفض هذا الطلب؟',
      confirmCancel: 'هل أنت متأكد من رغبتك في إلغاء هذا الطلب؟',
      received: 'تم الاستلام',
      sent: 'تم الإرسال',
      waitingApproval: 'في انتظار الموافقة',
      seeRequests: 'عرض الطلبات',
      noSentRequestsYet: 'لم ترسل بعد طلبات للاجتماع.\nاستكشف المشاركين لتقديم الطلب أو مراجعة تلك التي تم استلامها.',
      noReceivedRequestYet: 'لم يتم إرسال أي طلبات اجتماع إليك بعد.\nيمكنك استكشاف المشاركين واتخاذ البادرة مع من تجدهم مثيرين للاهتمام.',
      successReject: 'تم رفض طلب الاجتماع.',
      successApprove: 'تم قبول طلب الاجتماع.',
      meetingMessage: 'رسالة الاجتماع',
      status: {
        rejected: 'مرفوض',
        cancelled: 'تم الإلغاء',
      }
    },
  },
  tr: {
    notes: {
      placeholder: 'Toplantınızla ilgili notları yazın. Bunları daha sonra indirebileceksiniz.',
      saveSuccess: 'Notlarınız kaydedildi',
    },
    actions: {
      evaluateMeeting: 'Toplantıyı değerlendirin',
      sendMessage: 'Mesaj gönder',
      approve: 'Toplantıyı onaylama',
      reject: 'Toplantıyı reddet',
      cancelMeeting: 'Toplantıyı iptal et',
      cancel: 'İsteği iptal et',
      join: 'Katıl',
      blockSpace: 'Bu slotu meşgul olarak işaretle',
      unblockSpace: 'Alanın engelini kaldırmak için tıklayın',
      send: 'Gönder',
      checkInMeeting: 'Toplantıya giriş',
    },
    meeting: {
      title: 'Toplantı',
      newMeetingMessage: 'Mesaj',
      selectANewTime: 'Yeni bir saat seçin',
      selectANewDay: 'Yeni bir gün seçin',
      proposeNewTime: 'Yeni zaman önerin',
      meetingRequestMessage: 'Toplantı isteğiniz gönderildi, {{participant}}} kabul ettiğinde size bildirilecektir.',
      requestSent: 'Toplantı isteği gönderildi',
      error: 'Hata',
      message: 'Toplantı mesajı',
      mode: 'Toplantı modu',
      cancelMeetingLabel: 'Bu toplantıyla artık ilgilenmiyor musun?',
      changeAttendanceMode: 'Modu {{newMeetingMode}} olarak değiştir',
      changeModeInfo: 'Bu toplantıya nasıl katılacağınızı değiştirmek üzeresiniz. Devam etmek istiyor musun?',
      change: 'Değiştir',
      changeModeSuccess: 'Toplantıya katılım kipiniz başarıyla {{newMeetingAttendanceMode}} olarak değiştirildi.',
      diferentAttendanceMode: 'Buluştuğunuz kişi neredeyse katılacak. Lütfen toplantı konumuna gidin ve aşağıdaki düğmeyle sanal toplantıya katılın',
      onlineMeeting: 'Çevrimiçi toplantı yoluyla',
      pendingMeetingEvaluation: 'Bu toplantıyı henüz değerlendirmediniz',
      alreadyRequested: 'Bu katılımcıyla zaten bekleyen bir isteğiniz var.',
      alreadyScheduled: 'Bu katılımcıyla planlanabilecek maksimum toplantı miktarına ulaştınız ({{maxRepeatedMeetings}}). Hala onlarla sohbet edebilirsiniz, ancak daha fazla toplantı davetiyesi gönderemezsiniz.',
      alreadyScheduledPhysical: 'Bu katılımcıyla planlanabilecek maksimum toplantı miktarına ulaştınız ({{maxRepeatedMeetings}}) ve daha fazla toplantı daveti gönderemezsiniz.',
      schedulingDisabled: '[Toplantı zamanlaması devre dışı bırakıldı. Bu,] Do MMMM YYYY [at] {{timeFormat}} [GMT] Z',
      schedulingEnded: 'Toplantı zamanlaması devre dışı bırakıldı.',
      ratingDisabled: '[Katılımcı derecelendirmesi devre dışı bırakıldı. Bu,] Do MMMM YYYY [at] {{timeFormat}} [GMT] Z',
      ratingEnded: 'Katılımcı derecelendirmesi devre dışı bırakılır.',
      info: 'Toplantı bilgileri',
      participantInfo: 'Katılımcı bilgileri',
      with: '{{participant}} ile toplantı',
      requestMeeting: 'Toplantı isteği gönder',
      sendRequest: 'İstek gönder',
      meetingEvaluation: 'Toplantı değerlendirme',
      pending: 'Beklemede: ',
      pendingEvaluation: 'Bekleyen Değerlendirme: ',
      evaluationSuccess: 'Başarıyla sunuldu değerlendirme',
      cancelMeeting: 'Bu toplantıyı iptal etmek istediğinizden emin misiniz?',
      rejectionReasons: 'Toplantıyı reddetme nedenleri',
      waitingMyConfirmation: 'onayımı bekliyorum',
      waitingApproval: '{{ participant }} tarafından onay bekleniyor',
      slotHasRequests: 'Bu alanda onay bekleyen toplantı istekleriniz var.',
    },
    slots: {
      occupied: 'İşgâl',
      available: 'Mevcut',
      blockedSpace: 'Meşgul',
      blockedExplanation: 'Diğer katılımcılara şu anda müsait olmadığınızı bilmesine izin verin.',
    },
    requests: {
      request: 'İstek',
      requests: 'İstekler',
      rejectedRequests: 'Reddedilen İstekler',
      confirmApprove: 'Bu toplantıyı onayladığınızda, diğer tüm istekler otomatik olarak reddedilecektir.',
      confirmReject: 'Bu isteği reddetmek istediğinizden emin misiniz?',
      confirmCancel: 'Bu isteği iptal etmek istediğinizden emin misiniz?',
      received: 'Alınanlar',
      sent: 'Gönderilenler',
      waitingApproval: 'Onay Bekleniyor',
      seeRequests: 'İstekleri Görüntüle',
      noSentRequestsYet: 'Henüz toplantı isteği göndermediniz.\nİstekte bulunmak veya alınanları incelemek için katılımcıları keşfedin.',
      noReceivedRequestYet: 'Size herhangi bir toplantı isteği gönderilmedi.\nKatılımcıları keşfedebilir ve ilginç bulduklarınıza öncülük edebilirsiniz.',
      successReject: 'Toplantı isteği reddedildi.',
      successApprove: 'Toplantı isteği kabul edildi.',
      meetingMessage: 'Toplantı mesajı',
      status: {
        rejected: 'Reddedildi',
        cancelled: 'İptal Edildi',
      }
    },
  },
  de: {
    notes: {
      placeholder: 'Schreiben Sie Notizen zu Ihrem Meeting auf. Sie können sie später herunterladen.',
      saveSuccess: 'Ihre Notizen wurden gespeichert.',
    },
    actions: {
      evaluateMeeting: 'Meeting bewerten',
      sendMessage: 'Nachricht senden',
      approve: 'Meeting genehmigen',
      reject: 'Meeting ablehnen',
      cancelMeeting: 'Meeting absagen',
      cancel: 'Anfrage stornieren',
      join: 'Join',
      blockSpace: 'Raum blockieren',
      unblockSpace: 'Klicken Sie, um den Speicherplatz freizugeben',
      send: 'senden',
      checkInMeeting: 'Zum Treffen einchecken',
    },
    meeting: {
      title: 'Meeting',
      newMeetingMessage: 'Botschaft',
      selectANewTime: 'Wählen Sie eine neue Zeit',
      selectANewDay: 'Wähle einen neuen Tag',
      proposeNewTime: 'Schlage neue Zeit vor',
      meetingRequestMessage: 'Ihre Besprechungsanfrage wurde gesendet, Sie werden benachrichtigt, wenn {{participant}} zustimmt.',
      requestSent: 'Besprechungsanfrage gesendet',
      error: 'Fehler',
      message: 'Botschaft der Besprechung',
      mode: 'Meeting-Modus',
      cancelMeetingLabel: 'Sie sind nicht mehr an diesem Treffen interessiert?',
      changeAttendanceMode: 'Modus wechseln zu {{newMeetingMode}}',
      changeModeInfo: 'Sie sind im Begriff, die Art und Weise zu ändern, wie Sie an diesem Treffen teilnehmen werden. Wollen Sie fortfahren?',
      change: 'Veränderung',
      changeModeSuccess: 'Ihr Anwesenheitsmodus für Meetings wurde erfolgreich in {{newMeetingAttendanceMode}} geändert.',
      diferentAttendanceMode: 'Die Person, die Sie treffen, wird virtuell beitreten. Bitte gehen Sie zum Treffpunkt und nehmen Sie über die Schaltfläche unten an der virtuellen Besprechung teil',
      onlineMeeting: 'Über ein Online-Treffen',
      pendingMeetingEvaluation: 'Sie haben dieses Meeting noch nicht bewertet',
      alreadyRequested: 'Sie haben bereits eine ausstehende Anfrage bei diesem Teilnehmer.',
      alreadyScheduled: 'Sie haben die maximale Anzahl an Besprechungen erreicht, die mit diesem Teilnehmer geplant werden können ({{maxRepeatedMeetings}}). Sie können immer noch mit ihnen chatten, aber Sie können keine weiteren Besprechungseinladungen verschicken.',
      alreadyScheduledPhysical: 'Sie haben die maximale Anzahl an Besprechungen erreicht, die mit diesem Teilnehmer geplant werden können ({{maxRepeatedMeetings}}), und Sie können keine weiteren Besprechungseinladungen versenden.',
      schedulingDisabled: '[Die Planung von Besprechungen ist deaktiviert. Es wird auf dem] Do MMMM JJJJ [at] {{timeFormat}} [GMT] Z aktiviert',
      schedulingEnded: 'Die Planung von Besprechungen ist deaktiviert.',
      ratingDisabled: '[Die Teilnehmerbewertung ist deaktiviert. Es wird auf dem] Do MMMM JJJJ [at] {{timeFormat}} [GMT] Z aktiviert',
      ratingEnded: 'Die Teilnehmerbewertung ist deaktiviert.',
      info: 'Informationen zur Besprechung',
      participantInfo: 'Informationen zum Teilnehmer',
      with: 'Treffen mit {{participant}}',
      requestMeeting: 'Meeting anfragen',
      sendRequest: 'Anfrage senden',
      meetingEvaluation: 'Bewertung der Besprechung',
      pending: 'Ausstehend: ',
      pendingEvaluation: 'Auswertung ausstehend: ',
      evaluationSuccess: 'Bewertung erfolgreich eingereicht',
      cancelMeeting: 'Bist du sicher, dass du dieses Meeting absagen möchtest?',
      rejectionReasons: 'Gründe für die Ablehnung der Sitzung',
      waitingMyConfirmation: 'Warte auf meine Zustimmung',
      waitingApproval: 'Warten auf Genehmigung von {{ participant }}',
      slotHasRequests: 'Du hast Meetinganfragen, die auf Genehmigung in diesem Bereich warten.',
    },
    slots: {
      occupied: 'Belegt',
      available: 'Verfügbar',
      blockedSpace: 'Blockierter Speicherplatz',
      blockedExplanation: 'Teilen Sie anderen Teilnehmern mit, dass Sie zu diesem Zeitpunkt nicht verfügbar sind.',
    },
    requests: {
      request: 'Anfrage',
      requests: 'Anfragen',
      rejectedRequests: 'Abgelehnte Anfragen',
      confirmApprove: 'Durch die Genehmigung dieses Treffens werden alle anderen Anfragen automatisch abgelehnt.',
      confirmReject: 'Sind Sie sicher, dass Sie diese Anfrage ablehnen möchten?',
      confirmCancel: 'Sind Sie sicher, dass Sie diese Anfrage stornieren möchten?',
      received: 'Erhalten',
      sent: 'Gesendet',
      waitingApproval: 'Wartet auf Genehmigung',
      seeRequests: 'Anfragen anzeigen',
      noSentRequestsYet: 'Sie haben noch keine Meeting-Anfragen gesendet.\nErkunden Sie Teilnehmer, um Anfragen zu stellen oder prüfen Sie erhaltene Anfragen.',
      noReceivedRequestYet: 'Es wurden keine Meeting-Anfragen an Sie gesendet.\nSie können Teilnehmer erkunden und die Initiative ergreifen, wenn Sie interessante Personen finden.',
      successReject: 'Die Meeting-Anfrage wurde abgelehnt.',
      successApprove: 'Die Meeting-Anfrage wurde akzeptiert.',
      meetingMessage: 'Meeting-Nachricht',
      status: {
        rejected: 'Abgelehnt',
        cancelled: 'Abgesagt',
      }
    },
  },
};
