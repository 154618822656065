import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Input from 'eventtia-ui-components/lib/Input';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Loader from 'eventtia-ui-components/lib/Loader';
import SelectInput from 'eventtia-ui-components/lib/SelectInput';
import Control from 'eventtia-ui-components/lib/Control';
import FloatingDialog from '../FloatingDialog';
import ConfirmDialog from '../ConfirmDialog';
import callApi from '../../actions/callApi';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import { identifyParticipants } from '../../helpers/auth';
import { baseMoment } from '../../helpers/dates';

const SHOW_NEW_PROPOSAL_FIELDS = 'show_fields';

const useStyles = makeStyles((theme) => ({
  backdropDialog: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  dialogContainer: {
    position: 'relative',
  },
  modalTitle: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 'bold',
  },
  modalSelect: {
    margin: theme.spacing(1.5, 0, 1, 0),
    '& > div > div': {
      marginRight: 96,
    },
    '& li': {
      whiteSpace: 'normal',
    },
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  newTimeHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  messageInput: {
    marginTop: theme.spacing(1),
  },
}));

const RejectionMeetingDialog = ({
  meeting, closeDialog, attendees, newMeetingMessage, setNewMeetingMessage,
  answerToMeetingRequest, participants, updateError, updating, meetingStatuses,
  fetchingParticipant, participantTypes, conferences, meetings, newProposedSlot,
  setNewProposedSlot, meetingProposalToggleValue,
  setMeetingProposalToggleValue, rejectionDialogOpen, setRejectionDialogOpen,
  rejectionReason, setRejectionReason, confirmDialogOpen, setConfirmDialogOpen,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('meeting');

  const [newProposedDay, setNewProposedDay] = useState('');

  useEffect(() => {
    setNewProposedSlot('');
  }, [newProposedDay]);

  const showNewProposalFields = meetingProposalToggleValue.includes(SHOW_NEW_PROPOSAL_FIELDS);

  const { otherParticipant, currentParticipant } = identifyParticipants(
    meeting,
    participants
  );
  const currentParticipantType = participantTypes[currentParticipant?.participantTypeId];
  const canRequestToTypes = currentParticipantType?.canRequestMeetingToAttendeeTypes;

  const {
    businessConferenceId,
    host: {
      id: hostId,
    },
  } = meeting;
  const {
    meetingRejectionEnabled,
    meetingRejectionDescriptionOptions,
    bookingDeadlineDate,
  } = conferences[businessConferenceId];
  const {
    blockedSlots,
    attendee: {
      id: otherAttendeeId,
    },
  } = otherParticipant;
  const {
    id: participantId,
    blockedSlots: selfBlockedSlots,
  } = currentParticipant;

  const rejectionReasons = meetingRejectionDescriptionOptions?.map((reason) => ({
    value: reason,
    label: reason,
  }));

  const person = attendees[otherAttendeeId];
  const otherAttendeeTypeId = person?.attendeeType.id;
  const canRequestToOtherType = (canRequestToTypes || []).includes(Number(otherAttendeeTypeId));
  const {
    agenda: otherAgenda,
  } = participantTypes[otherParticipant?.participantType.id];
  const myAgenda = currentParticipantType?.agenda;
  const meetingSlots = Object.values(meetings).map(({ slotId }) => slotId);

  const days = {};
  const now = baseMoment();
  if (canRequestToOtherType && (baseMoment(bookingDeadlineDate).diff(now, 'seconds') > 0)) Object
    .keys(myAgenda).sort().forEach((slotEpoch) => {
      const [sD, eD, sId] = myAgenda[slotEpoch];
      const sDay = baseMoment(sD);
      const eDay = baseMoment(eD);
      const skip = meetingSlots.includes(sId)
        || sDay.diff(now, 'seconds') <= 0
        || selfBlockedSlots.includes(slotEpoch)
        || !otherAgenda[slotEpoch]
        || blockedSlots.includes(slotEpoch);

      if (!skip) {
        const day = sDay.format('YYYY-MM-DD');
        const slotInfo = {
          value: slotEpoch,
          label: `${sDay.format('h:mm A')} - ${eDay.format('h:mm A [GMT] Z')}`,
        };
        if (days[day]) days[day].push(slotInfo);
        if (!days[day]) days[day] = [slotInfo];
      }
    });

  const newProposedDays = useMemo(() => {
    const newDays = [];
    Object.keys(days).forEach((day) => {
      const formattedDay = baseMoment(day).format('dddd[,] MMMM Do');
      newDays.push({
        label: formattedDay,
        value: day,
      });
    });
    return newDays;
  }, [days]);

  const newProposedHours = useMemo(() => (days[newProposedDay] || []),
    [newProposedDay]);

  const rejectMeeting = () => answerToMeetingRequest(meetingStatuses.rejected);

  const errorMsgs = typeof updateError === 'string' ? updateError : (updateError || []).join('\n');

  return (
    <>
      {confirmDialogOpen && (
        <>
          <div className={classes.backdropDialog} />
          <div className={classes.dialogContainer}>
            <ConfirmDialog
              error={errorMsgs || undefined}
              closeDialog={() => setConfirmDialogOpen(false)}
              action={{
                name: t('global:confirmation.yes'),
                onClick: rejectMeeting,
              }}
              secondaryAction={{
                name: t('global:actions.cancel'),
                onClick: () => setConfirmDialogOpen(false),
              }}
            >
              <div className={classes.confirmDialogContent}>
                <Typography>{t('meeting:meeting.cancelMeeting')}</Typography>
              </div>
            </ConfirmDialog>
          </div>
        </>
      )}
      {rejectionDialogOpen && (
        <>
          <div className={classes.backdropDialog} />
          <div className={classes.dialogContainer}>
            <FloatingDialog
              closeDialog={closeDialog}
              action={{
                name: t('actions.send'),
                onClick: () => {
                  setConfirmDialogOpen(true);
                  setRejectionDialogOpen(false);
                },
              }}
              disabled={(meetingProposalToggleValue.length > 0 && newProposedSlot === '') || (!rejectionReason && meetingRejectionEnabled) || updating}
            >
              {fetchingParticipant ? (
                <Loader loading />
              ) : (
                <>
                  {meetingRejectionEnabled && rejectionReasons && (
                    <>
                      <Typography className={classes.modalTitle}>
                        {t('meeting.rejectionReasons')}
                      </Typography>
                      <SelectInput
                        id="rejection-reason"
                        className={classes.modalSelect}
                        MenuProps={{ disablePortal: true }}
                        value={rejectionReason}
                        handleChange={setRejectionReason}
                        options={[{ value: '', label: '' }, ...rejectionReasons]}
                      />
                      <Divider className={classes.divider} />
                    </>
                  )}
                  {(participantId === hostId) && newProposedDays?.length > 0 && (
                    <>
                      <div className={classes.newTimeHeader}>
                        <Typography className={classes.modalTitle}>
                          {t('meeting.proposeNewTime')}
                        </Typography>
                        <Control
                          options={[
                            { label: '', value: SHOW_NEW_PROPOSAL_FIELDS },
                          ]}
                          controlType="toggle"
                          value={meetingProposalToggleValue}
                          handleChange={setMeetingProposalToggleValue}
                          id="meeting-proposal-toggle"
                        />
                      </div>
                      {showNewProposalFields && (
                        <>
                          <SelectInput
                            id="new-day"
                            className={classes.modalSelect}
                            MenuProps={{ disablePortal: true }}
                            value={newProposedDay}
                            handleChange={setNewProposedDay}
                            options={newProposedDays}
                            emptyValueLabel={t('meeting.selectANewDay')}
                          />
                          <SelectInput
                            id="new-hour"
                            className={classes.modalSelect}
                            disabled={newProposedDay === ''}
                            MenuProps={{ disablePortal: true }}
                            value={newProposedSlot}
                            handleChange={setNewProposedSlot}
                            emptyValueLabel={t('meeting.selectANewTime')}
                            options={newProposedHours}
                          />
                          <Input
                            label={t('meeting.newMeetingMessage')}
                            className={classes.messageInput}
                            value={newMeetingMessage}
                            handleChange={setNewMeetingMessage}
                            rows={3}
                            rowsMax={3}
                            inputProps={{ maxLength: 700 }}
                            multiline
                            fullWidth
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </FloatingDialog>
          </div>
        </>
      )}
    </>
  );
};

RejectionMeetingDialog.propTypes = {
  meeting: CustomPropTypes.meeting.isRequired,
  participants: PropTypes.objectOf(
    CustomPropTypes.participant
  ),
  conferences: PropTypes.objectOf(
    CustomPropTypes.conference
  ).isRequired,
  closeDialog: PropTypes.func.isRequired,
  updateError: PropTypes.arrayOf(PropTypes.string),
  updating: PropTypes.bool.isRequired,
  answerToMeetingRequest: PropTypes.func.isRequired,
  meetingStatuses: PropTypes.objectOf(
    PropTypes.number
  ),
  fetchingParticipant: PropTypes.bool.isRequired,
  participantTypes: CustomPropTypes.participantType,
  attendees: PropTypes.objectOf(
    CustomPropTypes.attendee
  ),
  meetings: PropTypes.objectOf(CustomPropTypes.meeting),
  newMeetingMessage: PropTypes.string,
  setNewMeetingMessage: PropTypes.func.isRequired,
  newProposedSlot: PropTypes.string,
  setNewProposedSlot: PropTypes.func.isRequired,
  meetingProposalToggleValue: PropTypes.arrayOf(PropTypes.string),
  setMeetingProposalToggleValue: PropTypes.func.isRequired,
  rejectionReason: PropTypes.string,
  setRejectionReason: PropTypes.func.isRequired,
  rejectionDialogOpen: PropTypes.bool,
  setRejectionDialogOpen: PropTypes.func.isRequired,
  confirmDialogOpen: PropTypes.bool,
  setConfirmDialogOpen: PropTypes.func.isRequired,
};

RejectionMeetingDialog.defaultProps = {
  participants: {},
  updateError: undefined,
  participantTypes: {},
  attendees: {},
  meetings: {},
  meetingStatuses: {},
  newMeetingMessage: '',
  newProposedSlot: '',
  rejectionReason: '',
  meetingProposalToggleValue: [],
  rejectionDialogOpen: false,
  confirmDialogOpen: false,
};
const mapStateToProps = ({
  entities: {
    businessConferenceParticipantTypes,
    businessConferenceParticipants,
    businessConferences,
    businessConferenceMeetings,
  },
  fetchStatus: {
    updateMeeting: { isFetching: updating, success: updateSuccess, error: updateError },
    participant: { isFetching: fetchingParticipant },
  },
  meta: {
    meetingStatuses,
  },
}) => ({
  participantTypes: businessConferenceParticipantTypes,
  participants: businessConferenceParticipants,
  conferences: businessConferences,
  meetings: businessConferenceMeetings,
  updating,
  updateSuccess,
  updateError,
  meetingStatuses,
  fetchingParticipant,
});

export default connect(mapStateToProps, { callApi })(RejectionMeetingDialog);
