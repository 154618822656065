import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tooltip from 'eventtia-ui-components/lib/Tooltip';
import PropTypes from 'prop-types';
import ConfidentialityIcon from '../../assets/sidebarIcons/Confidentiality';

const StyledInputBase = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  input: {
    fontWeight: 500,
    color: theme.palette.darkGrey.main,
    position: 'relative',
    backgroundColor: theme.palette.lightGrey.main,
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: 'Brilliant Cut Pro',
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  label: {
    '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(0px, 0px) scale(0.75)',
      maxWidth: '133%',
      pointerEvents: 'all',
      display: 'flex',
    },
  },
  labelText: {
    flex: 1,
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    lineHeight: 2,
  },
  helperIcon: {
    flexShrink: 0,
    '& svg': {
      marginLeft: theme.spacing(1),
      fontSize: 18,
    },
  },
}));

const StyledFormHelperText = withStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0, 0),
  },
}))(FormHelperText);

const InputBaseStyled = ({
  variant, margin, id, label, helperText, multiline, rows, rowsMax,
  handleChange, InputProps, error, ...other
}) => {
  const classes = useStyles();

  return (
    <FormControl variant={variant} fullWidth>
      <InputLabel htmlFor={id} shrink className={classes.label}>
        <Tooltip title={label}>
          <div className={classes.labelText}>
            {label}
          </div>
        </Tooltip>
        {!!helperText && (
          <Tooltip title={helperText || label}>
            <div className={classes.helperIcon}>
              <ConfidentialityIcon />
            </div>
          </Tooltip>
        )}
      </InputLabel>
      <StyledInputBase
        id={id}
        margin={margin}
        onChange={handleChange ? ((e) => handleChange(e.target.value)) : undefined}
        multiline={multiline}
        rows={multiline ? rows : undefined}
        rowsMax={multiline ? rowsMax : undefined}
        {...other}
      />
      {!!error && (
        <StyledFormHelperText error={!!error}>{error}</StyledFormHelperText>
      )}
    </FormControl>
  );
};

InputBaseStyled.propTypes = {
  variant: PropTypes.string,
  multiline: PropTypes.bool,
  margin: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.node,
  helperText: PropTypes.node,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  handleChange: PropTypes.func,
  InputProps: PropTypes.shape({
    readOnly: PropTypes.bool,
  }),
  error: PropTypes.string,
};

InputBaseStyled.defaultProps = {
  handleChange: undefined,
  multiline: false,
  variant: 'outlined',
  rowsMax: 6,
  margin: 'none',
  id: undefined,
  label: undefined,
  helperText: undefined,
  rows: 3,
  InputProps: undefined,
  error: undefined,
};

export default InputBaseStyled;
