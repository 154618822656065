import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import callApi from '../../actions/callApi';
import SelectInput from '../SelectInput';

const useStyles = makeStyles((theme) => ({
  menu: {
    '& li': {
      width: '100%',
      display: 'flex',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      minHeight: theme.spacing(4.5),
    },
  },
  select: {
    '& > div': {
      fontWeight: 'normal',
      padding: theme.spacing(1.5),
    },
  },
  titleError: {
    color: theme.palette.red.main,
  },
}));

const CountryField = ({
  mainTitle, error, countries: rawCountries, regions: rawRegions, identifiers,
  cities: rawCities, value, callApi: dispatchCallApi, handleChange, disabled,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('global');
  const { country, region, city } = identifiers;
  const rawCountryId = useMemo(() => (value?.countryId ? value.countryId : ''), [value]);
  const rawRegionId = useMemo(() => (value?.regionId ? value.regionId : ''), [value]);
  const rawCityId = useMemo(() => (value?.cityId ? value.cityId : ''), [value]);
  const [countryId, setCountryId] = useState(rawCountryId);
  const [regionId, setRegionId] = useState(rawRegionId);
  const [cityId, setCityId] = useState(rawCityId);

  const countries = useMemo(() => {
    if (!rawCountries) return [];
    return Object.values(rawCountries)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(({ id, name }) => ({ value: id, label: name }));
  }, [rawCountries]);

  const regions = useMemo(() => {
    if (!rawRegions || !countryId) return [];
    return Object.values(rawRegions)
      .filter(({ country: c }) => c?.id === countryId.toString())
      .map(({ id, name }) => ({ value: id, label: name }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [rawRegions, countryId]);

  const cities = useMemo(() => {
    if (!rawCities) return [];
    return Object.values(rawCities)
      .filter(({ region: r }) => r?.id === regionId.toString())
      .map(({ id, name }) => ({ value: id, label: name }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [rawCities, regionId]);

  useEffect(() => {
    dispatchCallApi('countries');
  }, [dispatchCallApi]);

  useEffect(() => {
    if (countryId) dispatchCallApi('regions', { countryId });
  }, [dispatchCallApi, countryId]);

  useEffect(() => {
    if (countryId && regionId) dispatchCallApi('cities', { countryId, regionId });
  }, [dispatchCallApi, countryId, regionId]);

  const onChangeCountryId = (id) => {
    setCountryId(id);
    setRegionId('');
    setCityId('');
  };

  const onChangeRegionId = (id) => {
    setRegionId(id);
    setCityId('');
  };

  return (
    <>
      {mainTitle && (
        <Grid item xs={12}>
          <Typography
            variant="body2"
            className={error ? classes.titleError : undefined}
          >
            {mainTitle}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <SelectInput
          id={country}
          name={country}
          className={classes.select}
          MenuProps={{ className: classes.menu }}
          handleChange={onChangeCountryId}
          value={countries.length > 0 ? countryId : ''}
          label={t('forms.country')}
          options={[{ value: '', label: '' }, ...countries]}
          error={error}
          disabled={disabled}
        />
      </Grid>
      {countryId && (
        <Grid item xs={12}>
          <SelectInput
            id={region}
            name={region}
            className={classes.select}
            MenuProps={{ className: classes.menu }}
            handleChange={onChangeRegionId}
            value={regions.length > 0 ? regionId : ''}
            label={t('forms.region')}
            options={regions}
            error={error}
            disabled={disabled}
          />
        </Grid>
      )}
      {countryId && regionId && (
        <Grid item xs={12}>
          <SelectInput
            id={city}
            name={city}
            className={classes.select}
            MenuProps={{ className: classes.menu }}
            handleChange={(val) => {
              setCityId(val);
              handleChange(val);
            }}
            value={cities.length > 0 ? cityId : ''}
            label={t('forms.city')}
            options={cities}
            error={error}
            disabled={disabled}
          />
        </Grid>
      )}
    </>
  );
};

CountryField.propTypes = {
  mainTitle: PropTypes.string,
  handleChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      cityId: PropTypes.number,
      regionId: PropTypes.number,
      countryId: PropTypes.number,
    }),
  ]),
  identifiers: PropTypes.shape({
    city: PropTypes.string,
    region: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
  callApi: PropTypes.func.isRequired,
  error: PropTypes.string,
  countries: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  regions: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  cities: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  disabled: PropTypes.bool,
};

CountryField.defaultProps = {
  mainTitle: undefined,
  handleChange: undefined,
  error: undefined,
  value: {},
  countries: {},
  regions: {},
  cities: {},
  disabled: false,
};

const mapStateToProps = ({
  entities: {
    countries,
    regions,
    cities,
  },
}) => ({
  countries,
  regions,
  cities,
});

export default connect(mapStateToProps, { callApi })(CountryField);
