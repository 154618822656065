import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
// import MuiChip from '@material-ui/core/Chip';
// import HowToRegIcon from '@material-ui/icons/HowToReg';
import { baseMoment, getEventTimeFormat } from '../../helpers/dates';
import useStringTransforms from '../../hooks/useStringTransforms';
import { identifyParticipants } from '../../helpers/auth';
import CustomPropTypes from '../../helpers/CustomPropTypes';
// import ActivityLocation from '../ActivityLocation';
import { EVENT_URI } from '../../helpers/constants';

// const Chip = withStyles((theme) => ({
//   root: {
//     marginLeft: theme.spacing(1),
//     fontSize: theme.typography.caption.fontSize,
//     fontWeight: 'bold',
//     height: '1.8em',
//     '& svg': {
//       color: 'inherit',
//       fontSize: '1.5em',
//       marginRight: -theme.spacing(0.5),
//       marginLeft: theme.spacing(1),
//     },
//   },
//   label: {
//     padding: theme.spacing(0, 1.5),
//   },
// }))(MuiChip);

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.darkGrey.light,
    padding: theme.spacing(3, 0),
  },
  info: {
    width: '95%',
  },
  speakers: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  activityName: {
    fontWeight: 600,
    cursor: 'pointer',
  },
  date: {
    display: 'flex',
    fontSize: 12,
    opacity: '70%',
    marginTop: theme.spacing(1),
  },
  // extraInfo: {
  //   display: 'flex',
  //   marginTop: theme.spacing(1.5),
  // },
  location: {
    marginRight: theme.spacing(1),
  },
  categories: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  category: {
    color: 'white',
    fontSize: 10,
    fontWeight: 600,
    borderRadius: 12,
    padding: theme.spacing(0.2, 1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.8),
  },
  divider: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, -theme.subpageDrawer.paddings.mobile.horizontal),
    },
  },
}));

const mandatoryWorkshopCategoryId = '7762';
const optionalWorkshopCategoryId = '7763';

const ScheduleListItem = ({
  speakers,
  events,
  activity,
  businessConferenceParticipants,
  workshopCategories: categories,
}) => {
  const { t } = useTranslation('meeting');
  const timeFormat = getEventTimeFormat(events, { includeSeconds: true });
  const classes = useStyles();
  const { toTitleCase } = useStringTransforms();
  const {
    name,
    startDate,
    endDate,
    businessConferenceId,
    workshopCategories,
    onClick,
    type,
    // registered,
  } = activity;
  const workshopSpeakers = activity.speakers?.map((speaker) => {
    const { fullName, position, company } = speakers[speaker.id];
    const at = position && company ? ' at ' : '';
    const pipe = position || company ? ' | ' : '';
    const job = `${pipe}${toTitleCase(position, true)}${at}${toTitleCase(company, true)}`;
    return activity.speakers.length === 1 ? `${toTitleCase(fullName)}${job}` : toTitleCase(fullName);
  });

  let otherParticipant;
  let profileContactName;
  let profileDisplayName;
  if (businessConferenceId) ({ otherParticipant } = identifyParticipants(
    EVENT_URI,
    activity,
    businessConferenceParticipants
  ));
  if (otherParticipant) ({ profileContactName, profileDisplayName } = otherParticipant);

  return (
    (type !== 'ownSlot' && type !== 'request') && (
      <>
        <div className={classes.root}>
          {businessConferenceId ? (
            <>
              <Typography variant="subtitle2" className={classes.activityName} onClick={onClick}>
                {t('meeting:meeting.with', { participant: toTitleCase(profileContactName) })}
                {profileDisplayName && (` | ${toTitleCase(profileDisplayName, true)}`)}
              </Typography>
            </>
          ) : (
            <div>
              <div className={classes.info}>
                <Typography className={classes.speakers} variant="body2">
                  {workshopSpeakers.join(', ')}
                </Typography>
                <Typography variant="subtitle2" className={classes.activityName} onClick={onClick}>
                  {name}
                </Typography>
              </div>
            </div>
          )}
          <Typography variant="caption" className={classes.date}>
            {baseMoment(startDate).format(`dddd, MMMM Do YYYY, ${timeFormat}`)} - {baseMoment(endDate).format(timeFormat)}
          </Typography>
          {/* <div className={classes.extraInfo}>
            <ActivityLocation className={classes.location} activity={activity} />
            {registered && (
              <Chip icon={<HowToRegIcon />} color="primary" label={t('global:status.registered')} />
            )}
          </div> */}
          {workshopCategories?.length > 0 && (
            <div className={classes.categories}>
              {workshopCategories.map((cat) => {
                const category = categories[cat.id];
                return (
                  (category.id === mandatoryWorkshopCategoryId
                    || category.id === optionalWorkshopCategoryId) && (
                    <Typography key={category.id} variant="caption" style={{ backgroundColor: '#BA9B54' }} className={classes.category}>
                      {category.name}
                    </Typography>
                  )
                );
              })}
            </div>
          )}
        </div>
        <Divider className={classes.divider} />
      </>
    )
  );
};

ScheduleListItem.propTypes = {
  events: PropTypes.objectOf(
    CustomPropTypes.event
  ).isRequired,
  speakers: PropTypes.objectOf(
    CustomPropTypes.speaker
  ),
  businessConferenceParticipants: PropTypes.objectOf(
    CustomPropTypes.participant
  ).isRequired,
  activity: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    businessConferenceId: PropTypes.number,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.string,
    registered: PropTypes.bool,
    location: PropTypes.string,
    attendanceMode: PropTypes.string,
    workshopCategories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
    speakers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
  }).isRequired,
  workshopCategories: PropTypes.objectOf(
    CustomPropTypes.workshopCategory
  ),
};

ScheduleListItem.defaultProps = {
  speakers: {},
  workshopCategories: {},
};

const mapStateToProps = ({
  entities: {
    events,
    speakers,
    businessConferenceParticipants,
    workshopCategories,
  },
}) => ({
  events,
  speakers,
  businessConferenceParticipants,
  workshopCategories,
});

export default connect(mapStateToProps)(ScheduleListItem);
