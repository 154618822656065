/* eslint-disable comma-dangle */
export default {
  en: {
    actions: {
      add: 'Add',
      addToCalendar: 'Add to calendar',
      accept: 'Accept',
      addFile: 'Add file',
      addImage: 'Add image',
      clear: 'Clear',
      clone: 'Clone',
      create: 'Create',
      delete: 'Delete',
      filter: 'Filter',
      hideFilters: 'Hide filters',
      hideReactions: 'Disable Emotions',
      showReactions: 'Enable Emotions',
      remove: 'Remove',
      saveChanges: 'Save changes',
      save: 'Save',
      search: 'Search',
      cancel: 'Cancel',
      moreOptions: 'More options',
      logout: 'Log out',
      editPhoto: 'Edit Photo',
      send: 'Send',
      upload: 'Upload',
      deleteImage: 'Delete image',
      showQr: 'Show My QR',
      close: 'Close',
      shareMyContact: 'Share my contact',
      share: 'Share',
    },
    error404: {
      title: 'We couldnt find the page you were looking for',
      subtitle: 'Try another option using the sidebar.',
    },
    title: {
      announcement: 'Announcement!',
      reminder: 'Reminder',
    },
    formError: {
      enterValidEmail: 'Enter a valid email.',
      requiredField: 'The field is required.',
      invalidToken: 'The security code you entered is invalid.',
      invalidEmail: 'The email you provided is invalid.',
    },
    forms: {
      notRegisteredLink: ' Register here',
      signature: 'Signature',
      firstName: 'First name',
      lastName: 'Last name',
      telephone: 'Phone',
      birthdate: 'Birthdate',
      country: 'Country',
      jobTitle: 'Job title',
      company: 'Company',
      region: 'Region',
      city: 'City',
      photo: 'Photo',
      searchKeyword: 'Keywords',
      email: 'Email',
      fullName: 'Full name',
      notes: 'Notes',
      accessToken: 'Security code',
      validateToken: 'Validate code',
      logIn: 'Log in',
      loginActiveDirectory: 'Login using Active Directory',
      error: 'Error',
    },
    filters: {
      apply: 'Apply filters',
      clear: 'Clear filters',
      title: 'Filters',
      categories: {
        categoriesTypes: 'Categories types',
        all: 'All',
      },
      events: {
        datesRange: 'Date Range',
      },
      attendees: {
        showOnlyRatedBy: 'Participants that I have rated',
      },
      sort: {
        sortBy: 'Sort by',
        'first_name asc': 'Name A-Z↓',
        'first_name desc': 'Name Z-A↓',
        'last_name asc': 'Last name A-Z↓',
        'last_name desc': 'Last name Z-A↓',
        'company asc': 'Company A-Z↓',
        'company desc': 'Company Z-A↓',
        'id asc': 'Newest last',
        'id desc': 'Newest first',
      },
    },
    dates: {
      localTimeIs: 'Local time is',
      timeAtEvent: 'Time at event location',
      startDate: 'Start date',
      endDate: 'End date',
      from: 'From',
      to: 'To',
    },
    status: {
      title: 'Status',
      saving: 'Saving',
      completed: 'Completed',
      registered: 'Registered',
      optional: 'Optional',
      mandatory: 'Mandatory',
    },
    info: {
      logoAspectRatio: 'For a better display, make sure the logo has a 16:9 aspect ratio',
      loginDisabled: 'You\'re viewing this page because {{event}} is still under construction. Come back again once everything is configured and activated.',
    },
    speakers: {
      speakers: 'Speakers',
    },
    sponsors: {
      sponsoredBy: 'Sponsored by',
      poweredBy: 'Powered by',
    },
    timer: {
      before: 'This meeting hasn’t started yet!\nTake a seat and relax, you have {{time}} before it begins.',
      ended: 'Your meeting with {{otherParticipant}} ended {{when}}',
      remaining: 'remaining',
      joinNow: 'Join Now',
      extendedTimeTitle: 'This meeting has ended',
      extendedTimeMessage: 'Your meeting with {{otherParticipant}} has ended, you can join your next meeting with {{nextParticipant}} by clicking on "JOIN NOW"',
      endingSoon: 'In less than a minute this meeting will end and you will be redirected automatically to your next meeting',
    },
    notes: {
      title: 'Notes',
    },
    chat: {
      title: 'Chat',
    },
    prepositions: {
      at: 'at',
    },
    qa: {
      sendAQuestion: 'Send a question...',
    },
    confirmation: {
      yes: 'Yes',
    },
    reactions: {
      reactions: 'Reactions',
    },
    attendanceMode: {
      online: 'Virtual',
      offline: 'In person',
      mixed: 'Hybrid',
    },
    activities: {
      liveNow: 'Live now',
      chat: 'Chat',
    },
  },
  es: {
    actions: {
      add: 'Agregar',
      addToCalendar: 'Agregar al calendario',
      accept: 'Aceptar',
      addFile: 'Agregar un archivo',
      addImage: 'Agregar una imagen',
      clear: 'Limpiar',
      clone: 'Clonar',
      create: 'Crear',
      delete: 'Eliminar',
      filter: 'Filtrar',
      hideFilters: 'Ocultar filtros',
      hideReactions: 'Deshabilitar emociones',
      showReactions: 'Habilitar emociones',
      remove: 'Eliminar',
      saveChanges: 'Guardar cambios',
      save: 'Guardar',
      search: 'Buscar',
      cancel: 'Cancelar',
      moreOptions: 'Más opciones',
      logout: 'Cerrar sesión',
      editPhoto: 'Editar foto',
      send: 'Enviar',
      upload: 'Subir',
      deleteImage: 'Eliminar imagen',
      showQr: 'Mostrar mi QR',
      close: 'Cerrar',
      shareMyContact: 'Compartir mi contacto',
      share: 'Compartir',
    },
    error404: {
      title: 'No encontramos la página que tratas de acceder.',
      subtitle: 'Intenta otra opción en la barra lateral.',
    },
    title: {
      announcement: '¡Anuncio!',
      reminder: 'Recordatorio',
    },
    formError: {
      enterValidEmail: 'Ingresa un email válido',
      requiredField: 'El campo es obligatorio.',
      invalidToken: 'El código de seguridad que has ingresado es inválido.',
      invalidEmail: 'El correo ingresado es inválido.',
    },
    forms: {
      notRegisteredLink: ' Regístrate aquí',
      signature: 'Firma',
      firstName: 'Nombre',
      lastName: 'Apellido',
      telephone: 'Teléfono',
      country: 'Pais',
      jobTitle: 'Titulo laboral',
      company: 'Compañía',
      region: 'Región',
      city: 'Ciudad',
      photo: 'Foto',
      searchKeyword: 'Palabras clave',
      email: 'Correo',
      fullName: 'Nombre completo',
      notes: 'Notas',
      accessToken: 'Código de seguridad',
      validateToken: 'Validar código',
      logIn: 'Ingresar',
      loginActiveDirectory: 'Conectarse con Active Directory',
      error: 'Error',
    },
    filters: {
      apply: 'Aplicar filtros',
      clear: 'Borrar filtros',
      title: 'Filtros',
      categories: {
        categoriesTypes: 'Tipos de categorias',
        all: 'Todas',
      },
      events: {
        datesRange: 'Rango de Fechas',
      },
      attendees: {
        showOnlyRatedBy: 'Participantes que he calificado',
      },
      sort: {
        sortBy: 'Ordenar por',
        'first_name asc': 'Nombre A-Z↓',
        'first_name desc': 'Nombre Z-A↓',
        'last_name asc': 'Apellido A-Z↓',
        'last_name desc': 'Apellido Z-A↓',
        'company asc': 'Compañia A-Z↓',
        'company desc': 'Compañia Z-A↓',
        'id asc': 'Más antiguos primero',
        'id desc': 'Más recientes primero',
      },
    },
    dates: {
      localTimeIs: 'La hora local es',
      timeAtEvent: 'Hora en el lugar del evento',
      startDate: 'Fecha de inicio',
      endDate: 'Fecha de fin',
      from: 'Desde',
      to: 'Hasta',
    },
    status: {
      title: 'Estado',
      saving: 'Guardando',
      completed: 'Completada',
      registered: 'Registrado',
      optional: 'Opcional',
      mandatory: 'Obligatorio',
    },
    info: {
      logoAspectRatio: 'Para una mejor visualización asegúrate que el logo tenga una proporción de 16:9',
      loginDisabled: 'Estás viendo esta página porque {{event}} aún está en construcción. Visítanos nuevamente cuando todo esté configurado.',
    },
    speakers: {
      speakers: 'Ponentes',
    },
    sponsors: {
      sponsoredBy: 'Patrocinado por',
      poweredBy: 'Powered by',
    },
    timer: {
      before: 'Ésta reunión aún no ha comenzado!\nToma asiento y relájate, tienes {{time}} antes de que comience',
      ended: 'Tu reunión con {{otherParticipant}} terminó {{when}}',
      remaining: 'restante',
      joinNow: 'Unirse ahora',
      extendedTimeTitle: 'La reunión ha terminado',
      extendedTimeMessage: 'Tu reunión con {{otherParticipant}} ha terminado, puedes unirte a tu próxima reunión con {{nextParticipant}} presionando "UNIRSE AHORA"',
      endingSoon: 'En menos de un minuto esta reunión terminará y serás redirigido automáticamente a tu siguiente cita',
    },
    notes: {
      title: 'Notas',
    },
    chat: {
      title: 'Chat',
    },
    prepositions: {
      at: 'en',
    },
    qa: {
      sendAQuestion: 'Envia una pregunta...',
    },
    confirmation: {
      yes: 'Si',
    },
    reactions: {
      reactions: 'Reacciones',
    },
    attendanceMode: {
      online: 'Virtual',
      offline: 'Presencial',
      mixed: 'Híbrido',
    },
    activities: {
      liveNow: 'En vivo',
      chat: 'Chat',
    },
  },
  fr: {
    actions: {
      add: 'Ajouter',
      addToCalendar: 'Ajouter au calendrier',
      accept: 'Accepter',
      addFile: 'Ajouter un fichier',
      addImage: 'Ajouter une image',
      clear: 'Effacer',
      clone: 'Cloner',
      create: 'Créer',
      delete: 'Supprimer',
      filter: 'Filtrer',
      hideFilters: 'Cacher les filtres',
      hideReactions: 'Désactiver l\'émotion',
      showReactions: 'Permettre les émotions',
      remove: 'Éliminer',
      saveChanges: 'Sauvegarder les changements',
      save: 'Sauvegarder',
      search: 'Rechercher',
      cancel: 'Annuler',
      moreOptions: 'Plus d\'options',
      logout: 'Se déconnecter',
      editPhoto: 'Éditer la photo',
      send: 'Envoyer',
      upload: 'Télécharger',
      deleteImage: 'Supprimer l\'image',
      showQr: 'Afficher mon QR',
      close: 'Fermer',
      shareMyContact: 'Partager mon contact',
      share: 'Partager',
    },
    error404: {
      title: 'Nous n\'avons pas trouvé la page que vous recherchiez',
      subtitle: 'Essayez une autre option en utilisant la barre latérale.',
    },
    title: {
      announcement: 'Annonce!',
      reminder: 'Rappel',
    },
    formError: {
      enterValidEmail: 'Entrez un courriel valide',
      requiredField: 'Le champ est obligatoire.',
      invalidToken: 'Le code de sécurité que vous avez saisi n\'est pas valable.',
      invalidEmail: 'Le courrier entré n\'est pas valide',
    },
    forms: {
      notRegisteredLink: ' Inscrivez-vous ici',
      signature: 'Signature',
      firstName: 'Prénom',
      lastName: 'Nom de famille',
      telephone: 'Téléphone',
      country: 'Pays',
      jobTitle: 'Fonction',
      company: 'Organisation',
      region: 'Région',
      city: 'Cité',
      photo: 'Photo',
      searchKeyword: 'Mots clés',
      email: 'Email',
      fullName: 'Nom complet',
      notes: 'Remarques',
      accessToken: 'Code de sécurité',
      validateToken: 'Valider le code',
      logIn: 'Se connecter',
      loginActiveDirectory: 'Se connecter avec Active Directory',
      error: 'Erreur',
    },
    filters: {
      apply: 'Appliquer les filtres',
      clear: 'Effacer les filtres',
      title: 'Filtres',
      categories: {
        categoriesTypes: 'Type de catégories',
        all: 'Tous',
      },
      events: {
        datesRange: 'Plage de dates ',
      },
      attendees: {
        showOnlyRatedBy: 'Participants que j\'ai évalués',
      },
      sort: {
        sortBy: 'Trier par',
        'first_name asc': 'Prénom A-Z↓',
        'first_name desc': 'Prénom Z-A↓',
        'last_name asc': 'Nom A-Z↓',
        'last_name desc': 'Nom Z-A↓',
        'company asc': 'Organisation A-Z↓',
        'company desc': 'Organisation Z-A↓',
        'id asc': 'Le plus récent en dernier',
        'id desc': 'Le plus récent en premier',
      },
    },
    dates: {
      localTimeIs: 'L\'heure locale est',
      timeAtEvent: 'Heure du lieu de l\'événement',
      startDate: 'Date de début',
      endDate: 'Date de fin',
      from: 'de',
      to: 'à',
    },
    status: {
      title: 'Statut',
      saving: 'Sauver',
      completed: 'Complété',
      registered: 'Inscrit',
      optional: 'Facultatif',
      mandatory: 'Obligatoire',
    },
    info: {
      logoAspectRatio: 'Pour une meilleure visualisation, assurez-vous que le logo a un rapport de 16:9',
      loginDisabled: 'Vous êtes consultez avec cette page, car {{event}} est toujours en construction. Revenez une fois que tout est configuré et activé.',
    },
    speakers: {
      speakers: 'Intervenants',
    },
    sponsors: {
      sponsoredBy: 'Sponsorisé par',
      poweredBy: 'Powered by',
    },
    timer: {
      before: 'Cette réunion n\'a pas encore commencé!\nDétendez-vous, vous avez {{time}} avant de commencer',
      ended: 'Votre réunion avec {{otherParticipant}} s\'est terminée {{when}}',
      remaining: 'restant',
      joinNow: 'Rejoignez',
      extendedTimeTitle: 'Cette réunion est terminée',
      extendedTimeMessage: 'Votre réunion avec {{otherParticipant}} est terminée, vous pouvez rejoindre votre prochaine réunion avec {{nextParticipant}} en cliquant sur "REJOIGNEZ"',
      endingSoon: 'Dans moins d\'une minute cette réunion se terminera et vous serez automatiquement redirigé vers votre prochaine réunion',
    },
    notes: {
      title: 'Notes',
    },
    chat: {
      title: 'Chat',
    },
    prepositions: {
      at: 'chez',
    },
    qa: {
      sendAQuestion: 'Envoyer une question...',
    },
    confirmation: {
      yes: 'Oui',
    },
    reactions: {
      reactions: 'Réactions',
    },
    attendanceMode: {
      online: 'Virtuel',
      offline: 'En personne',
      mixed: 'Hybride',
    },
    activities: {
      liveNow: 'En direct',
      chat: 'Chat',
    },
  },
  ca: {
    actions: {
      add: 'Afegeix',
      addToCalendar: 'Afegeix al calendari',
      accept: 'Accepta',
      addFile: 'Afegeix arxiu',
      addImage: 'Afegeix imatge',
      clear: 'Neteja',
      clone: 'Clona',
      create: 'Crea',
      delete: 'Esborra',
      filter: 'Filtra',
      hideFilters: 'Amaga filtres',
      hideReactions: 'Desactiva emoticones',
      showReactions: 'Activa emoticones',
      remove: 'Lleva',
      saveChanges: 'Desa els canvis',
      save: 'Desa',
      search: 'Cerca',
      cancel: 'Cancel·la',
      moreOptions: 'Més opcions',
      logout: 'Surt',
      editPhoto: 'Edita fotografia',
      send: 'Envia',
      upload: 'Puja',
      deleteImage: 'Esborra imatge',
      showQr: 'Mostra el meu QR',
      close: 'Tanca',
      shareMyContact: 'Comparteix el meu contacte',
      share: 'Comparteix',
    },
    error404: {
      title: 'No hem pogut trobar la pàgina que cercaves',
      subtitle: 'Prova una altra opció usant la barra lateral',
    },
    title: { announcement: 'Anunci|', reminder: 'Recordatori' },
    formError: {
      enterValidEmail: 'Introdueix un correu-e vàlid',
      requiredField: 'Aquest camp és obligatori',
      invalidToken: 'El codi de seguretat que has introduït és invàlid',
      invalidEmail: 'El correu-e que has introduït és invàlid',
    },
    forms: {
      notRegisteredLink: 'Inscriu-te aquí',
      signature: 'Signatura',
      firstName: 'Nom',
      lastName: 'Cognom',
      telephone: 'Telèfon',
      country: 'País',
      jobTitle: 'Càrrec',
      company: 'Empresa',
      region: 'Regió',
      city: 'Ciutat',
      photo: 'Foto',
      searchKeyword: 'Paraules clau',
      email: 'Correu-e',
      fullName: 'Nom complet',
      notes: 'Notes',
      accessToken: 'Codi de seguretat',
      validateToken: 'Valida el codi',
      logIn: 'Entra',
      loginActiveDirectory: 'Entra usant el Directori Actiu',
      error: 'Error',
    },
    filters: {
      apply: 'Aplica filtres',
      clear: 'Esborra els filtres',
      title: 'Filtres',
      categories: { categoriesTypes: 'Tipus de categories', all: 'Tot' },
      events: { datesRange: 'Esborra el rang' },
      attendees: { showOnlyRatedBy: 'Participants que he qualificat' },
      sort: {
        sortBy: 'Ordena per',
        'first_name asc': 'Nom A-Z↓',
        'first_name desc': 'Nom Z-A↓',
        'last_name asc': 'Cognom A-Z↓',
        'last_name desc': 'Cognom Z-A↓',
        'company asc': 'Empresa A-Z↓',
        'company desc': 'Empresa Z-A↓',
        'id asc': 'Últim més recent',
        'id desc': 'El més nou primer',
      },
    },
    dates: {
      localTimeIs: "L'hora local és",
      timeAtEvent: "Hora en el lloc de l'esdeveniment",
      startDate: "Data d'inici",
      endDate: "Data d'acabament",
      from: 'De',
      to: 'A',
    },
    status: {
      title: 'Estatus',
      saving: 'Desant',
      completed: 'Completat',
      registered: 'Registrat',
      optional: 'Opcional',
      mandatory: 'Obligatòria',
    },
    info: {
      logoAspectRatio:
        "Per a una visualització millor, assegura't que el logo té un ràtio de 16:9",
      loginDisabled:
        "Veus aquesta pàgina perquè {{event}} encara està en construcció. Torna quan tot s'hagi configurat i activat.",
    },
    speakers: { speakers: 'Ponents' },
    sponsors: {
      sponsoredBy: 'Amb el suport de',
      poweredBy: 'Desenvolupat per',
    },
    timer: {
      before: 'Aquesta reunió encara no està en marxa! Posa\'t còmode i relaxa\'t, tens {{time}} fins que comenci.',
      ended: 'La teva reunió amb en/na {{otherParticipant}} ha acabat a les {{when}}',
      remaining: 'Restant',
      joinNow: "Uneix-t'hi ara",
      extendedTimeTitle: 'Aquesta reunió ha acabat',
      extendedTimeMessage: 'La reunió amb en/na {{otherParticipant}} ha acabat, pots unir-te a la teva propera reunió amb en/na {{nextParticipant}} fent clic a "Uneix-t\'hi ara"',
      endingSoon:
        "Aquesta reunió acabarà en menys d'un minut i se't redirigirà automàticament a la teva nova reunió",
    },
    notes: { title: 'Notes' },
    chat: { title: 'Xat' },
    prepositions: { at: 'a' },
    qa: { sendAQuestion: 'Envia una pregunta…' },
    confirmation: { yes: 'Sí' },
    reactions: { reactions: 'Reaccions' },
    attendanceMode: {
      online: 'Virtual',
      offline: 'En persona',
      mixed: 'Híbrid',
    },
    activities: { liveNow: 'En directe ara', chat: 'Xat' },
  },
  pt: {
    actions: {
      add: 'Adicionar',
      addToCalendar: 'Adicionar ao calendário',
      accept: 'Aceitar',
      addFile: 'Adicionar arquivo',
      addImage: 'Adicionar imagem',
      clear: 'Limpar',
      clone: 'Clonar',
      create: 'Criar',
      delete: 'Excluir',
      filter: 'Filtrar',
      hideFilters: 'Ocultar filtros',
      hideReactions: 'Desativar emoções',
      showReactions: 'Ativar emoções',
      remove: 'Remover',
      saveChanges: 'Salvar alterações',
      save: 'Salvar',
      search: 'Pesquisar',
      cancel: 'Cancelar',
      moreOptions: 'Mais opções',
      logout: 'Fazer logout',
      editPhoto: 'Editar foto',
      send: 'Enviar',
      upload: 'Upload',
      deleteImage: 'Apagar imagem',
      showQr: 'Mostrar meu QR',
      close: 'Fechar',
      shareMyContact: 'Compartilhar meu contato',
      share: 'Compartilhar',
    },
    error404: {
      title: 'Não conseguimos encontrar a página que você estava procurando',
      subtitle: 'Tente outra opção usando a barra lateral.',
    },
    title: {
      announcement: 'Anúncio!',
      reminder: 'Lembrete',
    },
    formError: {
      enterValidEmail: 'Insira um e-mail válido.',
      requiredField: 'O campo é obrigatório.',
      invalidToken: 'O código de segurança inserido é inválido.',
      invalidEmail: 'O e-mail que você forneceu é inválido.',
    },
    forms: {
      notRegisteredLink: ' Registe-se aqui',
      signature: 'Assinatura',
      firstName: 'Primeiro nome',
      lastName: 'Apelido',
      telephone: 'Telefone',
      country: 'País',
      jobTitle: 'Título do trabalho',
      company: 'Empresa',
      region: 'Região',
      city: 'Cidade',
      photo: 'foto',
      searchKeyword: 'Palavras-chave',
      email: 'E-mail',
      fullName: 'Nome completo',
      notes: 'Notas',
      accessToken: 'Código de segurança',
      validateToken: 'Validar código',
      logIn: 'Iniciar sessão',
      error: 'Erro',
      loginActiveDirectory: 'Entrar usando o Active Directory',
    },
    filters: {
      apply: 'Aplicar filtros',
      clear: 'Limpar filtros',
      title: 'Filtros',
      categories: {
        categoriesTypes: 'Tipos de categorias',
        all: 'Todos',
      },
      events: {
        datesRange: 'Faixa de datas',
      },
      attendees: {
        showOnlyRatedBy: 'Participantes que classifiquei',
      },
      sort: {
        sortBy: 'Ordenar por',
        'first_name asc': 'Nome A-Z↓',
        'first_name desc': 'Nome Z-A↓',
        'last_name asc': 'Apelido A-Z↓',
        'last_name desc': 'Apelido Z-A↓',
        'company asc': 'Empresa A-Z↓',
        'company desc': 'Empresa Z-A↓',
        'id asc': 'Último mais recente',
        'id desc': 'O mais novo primeiro',
      },
    },
    dates: {
      localTimeIs: 'Horário local:',
      timeAtEvent: 'Hora no local do evento',
      startDate: 'Data de início',
      endDate: 'Data de fim',
      from: 'A partir de',
      to: 'Para',
    },
    status: {
      title: 'Status',
      saving: 'Salvando',
      completed: 'Concluído',
      registered: 'Registrado',
      optional: 'Opcional',
      mandatory: 'Obrigatória',
    },
    info: {
      logoAspectRatio: 'Para uma melhor exibição, verifique se o logotipo tem uma proporção de 16:9',
      loginDisabled: 'Você está vendo esta página porque {{event}} ainda está em construção. Volte novamente assim que tudo estiver configurado e ativado.',
    },
    speakers: {
      speakers: 'Palestrantes',
    },
    sponsors: {
      sponsoredBy: 'Patrocinado por',
      poweredBy: 'Alimentado por',
    },
    timer: {
      before: 'Esta reunião ainda não começou!\nSente-se e relaxe, você tem {{time}} antes de começar.',
      ended: 'Sua reunião com {{otherParticipant}} terminou {{when}}',
      remaining: 'restante',
      joinNow: 'Entrar',
      extendedTimeTitle: 'Essa reunião acabou',
      extendedTimeMessage: 'Sua reunião com {{otherParticipant}} terminou, você pode entrar na sua próxima reunião com {{nextParticipant}} clicando em "ENTRAR"',
      endingSoon: 'Em menos de um minuto esta reunião terminará e você será redirecionado automaticamente para sua próxima reunião',
    },
    notes: {
      title: 'Notas',
    },
    chat: {
      title: 'Bate-papo',
    },
    prepositions: {
      at: 'em',
    },
    qa: {
      sendAQuestion: 'Envie uma pergunta...',
    },
    confirmation: {
      yes: 'sim',
    },
    reactions: {
      reactions: 'Reações',
    },
    attendanceMode: {
      online: 'Virtual',
      offline: 'Em pessoa',
      mixed: 'Híbrido',
    },
    activities: {
      liveNow: 'Ao vivo',
      chat: 'Chat',
    },
  },
  it: {
    actions: {
      add: 'Inserisci',
      accept: 'Accetta',
      addImage: 'Aggiungi immagine',
      clear: 'Trasparente',
      addFile: 'Aggiungi file',
      delete: 'Cancella',
      addToCalendar: 'Aggiungi al calendario',
      create: 'Crea',
      logout: 'Esci',
      hideFilters: 'Nascondi',
      remove: 'Rimuovi',
      save: 'Salva',
      filter: 'Filtra',
      search: 'Cerca',
      saveChanges: 'Salva le modifiche',
      cancel: 'Annulla',
      hideReactions: 'Disattiva le emozioni',
      upload: 'Carica',
      editPhoto: 'Modifica foto',
      moreOptions: 'Altre opzioni',
      clone: 'Clona',
      send: 'Invia',
      showReactions: 'Abilita le emozioni',
      deleteImage: 'Elimina immagine',
      showQr: 'Mostra il mio QR',
      close: 'Chiudi',
      shareMyContact: 'Condividi il mio contatto',
      share: 'Condividi',
    },
    attendanceMode: {
      online: 'Virtuale',
      offline: 'Di persona',
      mixed: 'Ibrido',
    },
    forms: {
      lastName: 'Cognome',
      telephone: 'Telefono',
      photo: 'Foto',
      searchKeyword: 'Parole chiave',
      notRegisteredLink: ' Registrati qui',
      firstName: 'Nome',
      error: 'Errore',
      region: 'Regione',
      jobTitle: 'Titolo di lavoro',
      signature: 'Firma',
      country: 'Paese',
      company: "L'azienda",
      logIn: 'Accedi',
      email: 'Email',
      fullName: 'Nome completo',
      notes: 'Note',
      validateToken: 'Convalida il codice',
      city: 'Città',
      accessToken: 'Codice di sicurezza',
      loginActiveDirectory: 'Accedi utilizzando Active Directory'
    },
    title: {
      announcement: 'Annuncio!',
      reminder: 'Promemoria'
    },
    error404: {
      subtitle: "Prova un'altra opzione utilizzando la barra laterale.",
      title: 'Non siamo riusciti a trovare la pagina che cercavi'
    },
    formError: {
      enterValidEmail: 'Inserisci un indirizzo email valido.',
      requiredField: 'Il campo è obbligatorio.',
      invalidEmail: "L'email che hai fornito non è valida.",
      invalidToken: 'Il codice di sicurezza inserito non è valido.'
    },
    filters: {
      title: 'Filtri',
      clear: 'Filtri trasparenti',
      categories: {
        categoriesTypes: 'Tipi di categorie',
        all: 'Tutti'
      },
      events: {
        datesRange: 'Intervallo date'
      },
      attendees: {
        showOnlyRatedBy: 'Partecipanti che ho valutato'
      },
      sort: {
        'first_name asc': 'Nome A-Z↓',
        sortBy: 'Ordina per',
        'first_name desc': 'Nome Z-A↓',
        'last_name asc': 'Cognome A-Z↓',
        'company desc': 'Società Z-A↓',
        'id asc': 'Ultimo più recente',
        'id desc': 'Prima i più recenti',
        'company asc': 'Azienda A-Z↓',
        'last_name desc': 'Cognome Z-A↓'
      },
      apply: 'Applica filtri'
    },
    dates: {
      to: 'A',
      localTimeIs: "L'ora locale è",
      startDate: 'Data di inizio',
      endDate: 'Data di fine',
      from: 'Da',
      timeAtEvent: "Ora nel luogo dell'evento"
    },
    status: {
      saving: 'Salvare',
      title: 'Stato',
      completed: 'Completato',
      registered: 'Registrato',
      optional: 'Opzionale',
      mandatory: 'Obbligatoria',
    },
    timer: {
      remaining: 'rimanenti',
      joinNow: 'Iscriviti ora',
      extendedTimeTitle: 'Questo incontro è terminato',
      before: 'Questo incontro non è ancora iniziato!\n'
        + 'Accomodati e rilassati, hai {{time}} prima che inizi.',
      endingSoon: 'In meno di un minuto questo incontro terminerà e verrai reindirizzato automaticamente al tuo prossimo incontro',
      ended: 'Il tuo incontro con {{otherParticipant}} è terminato {{when}}',
      extendedTimeMessage: 'Il tuo incontro con {{otherParticipant}} è terminato, puoi partecipare al tuo prossimo incontro con {{nextParticipant}} facendo clic su «ISCRIVITI ORA»'
    },
    chat: {
      title: 'Chatta'
    },
    confirmation: {
      yes: 'sì'
    },
    prepositions: {
      at: 'al'
    },
    sponsors: {
      sponsoredBy: 'Sponsorizzato da',
      poweredBy: 'Alimentato da'
    },
    info: {
      logoAspectRatio: 'Per una migliore visualizzazione, assicurati che il logo abbia un formato 16:9',
      loginDisabled: 'Stai visualizzando questa pagina perché {{event}} è ancora in costruzione. Tornate ancora una volta che tutto è stato configurato e attivato.',
    },
    notes: {
      title: 'Note'
    },
    reactions: {
      reactions: 'Reazioni'
    },
    qa: {
      sendAQuestion: 'Invia una domanda...'
    },
    speakers: {
      speakers: 'altoparlanti'
    },
    activities: {
      liveNow: 'In diretta adesso',
      chat: 'Chat',
    },
  },
  ja: {
    actions: {
      add: '追加',
      clone: 'クローン',
      filter: 'フィルター',
      delete: '削除',
      hideReactions: '感情を無効にする',
      accept: '承諾',
      clear: 'クリア',
      save: 'セーブ',
      showReactions: '感情を有効にする',
      hideFilters: 'フィルターを非表示にする',
      addFile: 'ファイルを追加',
      moreOptions: 'その他のオプション',
      cancel: 'キャンセル',
      remove: '除去',
      saveChanges: '変更を保存',
      logout: 'ログアウト',
      editPhoto: '写真を編集',
      send: '送る',
      upload: 'アップロードする',
      addImage: 'イメージの追加',
      search: 'サーチ',
      addToCalendar: '予定表に追加する',
      create: '作成',
      deleteImage: '画像を削除する',
      showQr: '自分のQRを表示',
      close: '閉じる',
      shareMyContact: '連絡先をシェア',
      share: 'シェア',
    },
    title: {
      reminder: 'リマインダー',
      announcement: 'お知らせ！'
    },
    error404: {
      subtitle: 'サイドバーを使って別のオプションを試してください。',
      title: 'お探しのページが見つかりませんでした'
    },
    formError: {
      enterValidEmail: '有効なメールアドレスを入力してください。',
      invalidToken: '入力したセキュリティコードは無効です。',
      invalidEmail: '入力されたEメールは無効です。',
      requiredField: 'このフィールドは必須です。'
    },
    forms: {
      notRegisteredLink: ' 登録はこちら',
      photo: '写真',
      firstName: 'ファーストネーム',
      searchKeyword: 'キーワード',
      error: 'エラー',
      signature: '署名',
      lastName: '苗字',
      telephone: '電話',
      company: '会社',
      region: 'リージョン',
      city: 'シティ',
      country: '国',
      jobTitle: '役職名',
      accessToken: 'セキュリティコード',
      loginActiveDirectory: 'Active Directory を使用したログイン',
      validateToken: 'コードの検証',
      email: '電子メール',
      fullName: 'フルネーム',
      notes: 'ノート',
      logIn: 'ログイン'
    },
    filters: {
      title: 'フィルター',
      apply: 'フィルターを適用',
      clear: 'フィルターをクリア',
      categories: {
        categoriesTypes: 'カテゴリタイプ',
        all: '全て'
      },
      attendees: {
        showOnlyRatedBy: '私が評価した参加者'
      },
      events: {
        datesRange: '日付範囲'
      },
      sort: {
        'first_name asc': 'お名前 A-Z↓',
        sortBy: '並び替え',
        'last_name desc': '苗字 Z-A↓',
        'last_name asc': '苗字 A-Z↓',
        'first_name desc': '名前Z-A↓',
        'company asc': 'A-Z社↓',
        'company desc': 'Z-A社↓',
        'id asc': '最新最後',
        'id desc': '最新のものから',
      },
    },
    dates: {
      localTimeIs: '現在時刻',
      timeAtEvent: 'イベント開催場所での時間',
      startDate: '開始日',
      from: 'から',
      endDate: '終了日',
      to: 'に'
    },
    status: {
      title: 'ステータス',
      saving: '保存中',
      registered: '登録済み',
      completed: '完了',
      optional: 'オプション',
      mandatory: '必須',
    },
    timer: {
      remaining: '残り',
      joinNow: '今すぐ入会',
      extendedTimeTitle: 'この会議は終了しました',
      before: 'この会議はまだ始まっていない！\n座ってリラックスしてください。始まる前に {{time}} があります。',
      ended: '{{otherParticipant}} とのミーティングは終了しました {{いつ}}',
      endingSoon: 'このミーティングは 1 分以内に終了し、次のミーティングに自動的にリダイレクトされます。',
      extendedTimeMessage: '{{otherParticipant}} とのミーティングは終了しました。「今すぐ参加」をクリックすると、{{nextParticipant}} とのミーティングに参加できます。'
    },
    sponsors: {
      sponsoredBy: '主催者',
      poweredBy: '搭載'
    },
    prepositions: {
      at: 'で'
    },
    confirmation: {
      yes: 'はい'
    },
    info: {
      loginDisabled: '{{event}} はまだ作成中なので、このページを表示しています。すべての設定とアクティブ化が完了したら、もう一度戻ってください。',
      logoAspectRatio: '見栄えを良くするために、ロゴの縦横比が 16:9 であることを確認してください。',
    },
    chat: {
      title: 'チャット'
    },
    speakers: {
      speakers: 'スピーカー'
    },
    notes: {
      title: '注意事項'
    },
    reactions: {
      reactions: '反応'
    },
    qa: {
      sendAQuestion: '質問を送る...'
    },
    attendanceMode: {
      online: 'バーチャル',
      offline: '直接会って',
      mixed: 'ハイブリッド',
    },
    activities: {
      liveNow: 'ライブ配信',
      chat: 'チャット',
    },
  },
  zh: {
    actions: {
      add: '添加',
      create: '创建',
      delete: '删除',
      addFile: '添加文件',
      clone: '克隆',
      addToCalendar: '添加到日历',
      addImage: '添加图片',
      filter: '筛选',
      remove: '删除',
      hideFilters: '隐藏筛选器',
      search: '搜索',
      moreOptions: '更多选项',
      showReactions: '启用情绪',
      logout: '注销',
      cancel: '取消',
      editPhoto: '编辑照片',
      accept: '接受',
      upload: '上传',
      saveChanges: '保存更改',
      hideReactions: '禁用情绪',
      save: '保存',
      clear: '清除',
      send: '发送',
      deleteImage: '删除图片',
      showQr: '显示我的二维码',
      close: '关闭',
      shareMyContact: '分享我的联系人',
      share: '分享',
    },
    title: {
      announcement: '公告！',
      reminder: '提醒'
    },
    error404: {
      title: '我们找不到你要找的页面',
      subtitle: '使用侧边栏尝试其他选项。'
    },
    formError: {
      requiredField: '该字段为必填字段。',
      enterValidEmail: '输入有效的电子邮件。',
      invalidEmail: '您提供的电子邮件无效。',
      invalidToken: '您输入的安全码无效。'
    },
    forms: {
      photo: '照片',
      signature: '签名',
      notRegisteredLink: ' 在这里注册',
      searchKeyword: '关键词',
      telephone: '電話',
      firstName: '名',
      company: '公司',
      city: '城市',
      jobTitle: '职称',
      region: '地区',
      email: '電郵',
      fullName: '全名',
      notes: '注意',
      accessToken: '安全码',
      validateToken: '验证码',
      logIn: '登录',
      country: '国家/地区',
      error: '错误',
      loginActiveDirectory: '使用活动目录登录',
      lastName: '姓'
    },
    filters: {
      categories: {
        categoriesTypes: '类别类型',
        all: '全部'
      },
      events: {
        datesRange: '日期范围'
      },
      clear: '清除筛选器',
      sort: {
        'first_name asc': '名字 A-Z↓',
        sortBy: '排序方式',
        'first_name desc': '名字 Z-A↓',
        'last_name asc': '姓 A-Z↓',
        'last_name desc': '姓 Z-A↓',
        'company asc': '公司 A-Z↓',
        'company desc': '公司 Z-A↓',
        'id asc': '最新最后一页',
        'id desc': '最新优先',
      },
      title: '过滤器',
      apply: '应用筛选器',
      attendees: {
        showOnlyRatedBy: '我对参与者进行了评分'
      },
    },
    dates: {
      localTimeIs: '当地时间是',
      to: '为了',
      from: '来自',
      timeAtEvent: '活动地点的时间',
      startDate: '开始日期',
      endDate: '结束日期'
    },
    timer: {
      remaining: '剩余',
      joinNow: '现在加入',
      extendedTimeTitle: '这次会议已经结束',
      ended: '您与 {{other参与者}} 的会议已结束 {{when}}',
      endingSoon: '此会议将在不到一分钟的时间内结束，您将被自动重定向到下一次会议',
      extendedTimeMessage: '您与 {{otherParticipant}} 的会议已结束，您可以通过单击 “立即加入” 加入 {{nextParticipant}} 参加下一次会议',
      before: '这次会议还没开始！\n坐下来放松一下，在它开始之前你已经有 {{time}} 了。'
    },
    status: {
      saving: '保存',
      registered: '已注册',
      title: '状态',
      completed: '已完成',
      optional: '選修的',
      mandatory: '強制的',
    },
    prepositions: {
      at: '在'
    },
    speakers: {
      speakers: '演讲者'
    },
    sponsors: {
      poweredBy: '由',
      sponsoredBy: '由... 赞助'
    },
    chat: {
      title: '聊天'
    },
    notes: {
      title: '注意'
    },
    confirmation: {
      yes: '是的'
    },
    qa: {
      sendAQuestion: '发送问题...'
    },
    info: {
      logoAspectRatio: '为了获得更好的显示效果，请确保徽标的长宽比为 16:9',
      loginDisabled: '您正在查看此页面是因为 {{event}} 仍在建设中。一旦配置好并激活所有内容，请再回来。',
    },
    reactions: {
      reactions: '反应'
    },
    attendanceMode: {
      online: '虚拟的',
      offline: '亲自',
      mixed: '杂交种',
    },
    activities: {
      liveNow: '现在住',
      chat: '聊天',
    },
  },
  ar: {
    actions: {
      add: 'إضافة',
      addToCalendar: 'إضافة إلى التقويم',
      accept: 'قبول',
      addFile: 'إضافة ملف',
      addImage: 'إضافة صورة',
      clear: 'مسح',
      clone: 'استنساخ',
      create: 'إنشاء',
      delete: 'حذف',
      filter: 'عامل تصفية',
      hideFilters: 'إخفاء الفلاتر',
      hideReactions: 'تعطيل العواطف',
      showReactions: 'تمكين العواطف',
      remove: 'إزالة',
      saveChanges: 'حفظ التغييرات',
      save: 'حفظ',
      search: 'بحث',
      cancel: 'إلغاء',
      moreOptions: 'المزيد من الخيارات',
      logout: 'تسجيل الخروج',
      editPhoto: 'تحرير الصورة',
      send: 'إرسال',
      upload: 'تحميل',
      deleteImage: 'حذف الصورة',
      showQr: 'عرض QR الخاص بي',
      close: 'إغلاق',
      shareMyContact: 'مشاركة جهة الاتصال الخاصة بي',
      share: 'مشاركة',
    },
    error404: {
      title: 'لم نتمكن من العثور على الصفحة التي كنت تبحث عنها',
      subtitle: 'جرب خيارًا آخر باستخدام الشريط الجانبي.',
    },
    title: {
      announcement: 'إعلان!',
      reminder: 'تذكير',
    },
    formError: {
      enterValidEmail: 'أدخل بريدًا إلكترونيًا صالحًا.',
      requiredField: 'الحقل مطلوب.',
      invalidToken: 'رمز الأمان الذي أدخلته غير صالح.',
      invalidEmail: 'البريد الإلكتروني الذي قدمته غير صالح.',
    },
    forms: {
      notRegisteredLink: ' سجل هنا',
      signature: 'التوقيع',
      firstName: 'الاسم الأول',
      lastName: 'اسم العائلة',
      telephone: 'هاتف',
      country: 'البلد',
      jobTitle: 'عنوان وظيفي',
      company: 'شركة',
      region: 'المنطقة',
      city: 'مدينة',
      photo: 'صورة فوتوغرافية',
      searchKeyword: 'الكلمات الدالة',
      email: 'البريد الإلكتروني',
      fullName: 'الاسم الكامل',
      notes: 'ملاحظات',
      accessToken: 'رمز الأمان',
      validateToken: 'التحقق من صحة الكود',
      logIn: 'تسجيل الدخول',
      loginActiveDirectory: 'تسجيل الدخول باستخدام أكتيف Directory',
      error: 'خطأ',
    },
    filters: {
      apply: 'تطبيق الفلاتر',
      clear: 'مسح الفلاتر',
      title: 'الفلاتر',
      categories: {
        categoriesTypes: 'أنواع الفئات',
        all: 'جميع',
      },
      events: {
        datesRange: 'نطاق التاريخ',
      },
      attendees: {
        showOnlyRatedBy: 'المشاركون الذين قمت بتصنيفهم',
      },
      sort: {
        sortBy: 'فرز حسب',
        'first_name asc': 'الاسم A-Z ↓',
        'first_name desc': 'اسم Z-A↓',
        'last_name asc': 'الاسم الأخير A-Z↓',
        'last_name desc': 'الاسم الأخير Z-A↓',
        'company asc': 'شركة A-Z↓',
        'company desc': 'شركة Z-A↓',
        'id asc': 'الأحدث الأخير',
        'id desc': 'الأحدث أولاً',
      },
    },
    dates: {
      localTimeIs: 'التوقيت المحلي',
      timeAtEvent: 'الوقت في موقع الحدث',
      startDate: 'تاريخ البدء',
      endDate: 'تاريخ الانتهاء',
      from: 'من',
      to: 'إلى',
    },
    status: {
      title: 'الحالة',
      saving: 'إنقاذ',
      completed: 'اكتمل',
      registered: 'مسجل',
      optional: 'خياري',
      mandatory: 'إلزامي',
    },
    info: {
      logoAspectRatio: 'للحصول على عرض أفضل، تأكد من أن الشعار يحتوي على نسبة عرض إلى ارتفاع 16:9',
      loginDisabled: 'أنت تشاهد هذه الصفحة لأن {{event}} لا تزال قيد الإنشاء. عد مرة أخرى بمجرد تكوين كل شيء وتنشيطه.',
    },
    speakers: {
      speakers: 'مكبرات الصوت',
    },
    sponsors: {
      sponsoredBy: 'برعاية',
      poweredBy: 'مدعوم من',
    },
    timer: {
      before: 'لم يبدأ هذا الاجتماع بعد!\nاجلس واسترخي، لديك {{time}} قبل أن تبدأ.',
      ended: 'انتهى اجتماعك مع {{otherParticipant}} {{when}}',
      remaining: 'متبق',
      joinNow: 'انضم الآن',
      extendedTimeTitle: 'انتهى هذا الاجتماع',
      extendedTimeMessage: 'انتهى اجتماعك مع {{otherParticipant}}، يمكنك الانضمام إلى اجتماعك التالي مع {{nextParticipant}} بالضغط على «انضم الآن»',
      endingSoon: 'في أقل من دقيقة سينتهي هذا الاجتماع وسيتم إعادة توجيهك تلقائيًا إلى اجتماعك التالي.'
    },
    notes: {
      title: 'ملاحظات',
    },
    chat: {
      title: 'دردشة',
    },
    prepositions: {
      at: 'في',
    },
    qa: {
      sendAQuestion: 'أرسل سؤالاً...',
    },
    confirmation: {
      yes: 'نعم',
    },
    reactions: {
      reactions: 'ردود الفعل',
    },
    attendanceMode: {
      online: 'افتراضية',
      offline: 'شخصيًا',
      mixed: 'هايبرد',
    },
    activities: {
      liveNow: 'الحدث الحالي',
      chat: 'دردشة',
    },
  },
  tr: {
    actions: {
      add: 'Ekle',
      addToCalendar: 'Takvime ekle',
      accept: 'Kabul et',
      addFile: 'Dosya ekle',
      addImage: 'Resim ekle',
      clear: 'Sıfırla',
      clone: 'Klon',
      create: 'Oluştur',
      delete: 'Sil',
      filter: 'Filtre',
      hideFilters: 'Filtreleri gizle',
      hideReactions: 'Duyguları Devre Dışı Bırak',
      showReactions: 'Duyguları Etkinleştir',
      remove: 'Kaldır',
      saveChanges: 'Değişiklikleri kaydet',
      save: 'Kaydet',
      search: 'Arama',
      cancel: 'İptal',
      moreOptions: 'Daha fazla seçenek',
      logout: 'Oturumu Kapat',
      editPhoto: 'Fotoğrafı Düzenle',
      send: 'Gönder',
      upload: 'Yükle',
      deleteImage: 'Resmi sil',
      showQr: "QR'ımı Göster",
      close: 'Kapat',
      shareMyContact: 'Kişimi paylaş',
      share: 'Paylaşmak',
    },
    error404: {
      title: 'Aradığınız sayfayı bulamadık',
      subtitle: 'Kenar çubuğunu kullanarak başka bir seçenek deneyin.'
    },
    title: {
      announcement: 'Duyuru!',
      reminder: 'Hatırlatma'
    },
    formError: {
      enterValidEmail: 'Geçerli bir e-posta girin.',
      requiredField: 'Alan zorunludur.',
      invalidToken: 'Girdiğiniz güvenlik kodu geçersiz.',
      invalidEmail: 'Verdiğiniz e-posta geçersiz.'
    },
    forms: {
      notRegisteredLink: ' Buradan kaydolun',
      signature: 'İmza',
      firstName: 'Ad',
      lastName: 'Soyadı',
      telephone: 'Telefon',
      country: 'Ülke',
      jobTitle: 'İş unvanı',
      company: 'Şirket',
      region: 'Şehir',
      city: 'Semt',
      photo: 'Fotoğraf',
      searchKeyword: 'Anahtar kelimeler',
      email: 'E-posta',
      fullName: 'Tam adı',
      notes: 'Notlar',
      accessToken: 'Güvenlik kodu',
      validateToken: 'Kodu doğrulayın',
      logIn: 'Oturum aç',
      loginActiveDirectory: 'Active Directory kullanarak oturum açma',
      error: 'Hata'
    },
    filters: {
      apply: 'Filtreleri uygulama',
      clear: 'Temizle filtreleri',
      title: 'Filtreler',
      categories: {
        categoriesTypes: 'Kategori türleri',
        all: 'Tümü'
      },
      events: {
        datesRange: 'Tarih Aralığı'
      },
      attendees: {
        showOnlyRatedBy: 'Oy verdiğim katılımcılar'
      },
      sort: {
        sortBy: 'Sırala',
        'first_name asc': 'Ad A-Z↓',
        'first_name desc': 'Adı Z-A↓',
        'last_name asc': 'Soyadı A-Z↓',
        'last_name desc': 'Soyadı Z-A↓',
        'company asc': 'Şirket A-Z↓',
        'company desc': 'Şirket Z-A↓',
        'id asc': 'En yeni son',
        'id desc': 'En yeni ilk',
      },
    },
    dates: {
      localTimeIs: 'Yerel saat',
      timeAtEvent: 'Etkinlik konumundaki zaman',
      startDate: 'Başlangıç tarihi',
      endDate: 'Bitiş tarihi',
      from: 'itibaren',
      to: 'Kime'
    },
    status: {
      title: 'Durum',
      saving: 'Kaydetme',
      completed: 'Tamamlandı',
      registered: 'Kayıtlı',
      optional: 'isteğe bağlı',
      mandatory: 'Zorunlu',
    },
    info: {
      logoAspectRatio: 'Daha iyi bir ekran için logonun 16:9 en/boy oranına sahip olduğundan emin olun',
      loginDisabled: 'Bu sayfayı görüntülüyorsunuz çünkü {{event}} hala yapım aşamasındadır. Her şey yapılandırıldıktan ve etkinleştirildikten sonra tekrar gelin.',
    },
    speakers: {
      speakers: 'Hoparlörler'
    },
    sponsors: {
      sponsoredBy: 'Sponsorluğunda',
      poweredBy: 'Tarafından desteklenmektedir'
    },
    timer: {
      before: 'Bu toplantı daha başlamadı!\nOturun ve rahatlayın, başlamadan önce {{time}} var.',
      ended: '{{otherParticipant}} ile toplantınız {{when}} sona erdi',
      remaining: 'kalan',
      joinNow: 'Şimdi Katılın',
      extendedTimeTitle: 'Bu toplantı sona erdi',
      extendedTimeMessage: '{{otherParticipant}} ile toplantınız sona erdi, “ŞİMDİ KATIL"a tıklayarak {{nextParticipant}}} ile bir sonraki toplantıya katılabilirsiniz',
      endingSoon: 'Bir dakikadan kısa bir sürede bu toplantı sona erecek ve otomatik olarak bir sonraki toplantıya yönlendirileceksiniz.'
    },
    notes: {
      title: 'Notlar'
    },
    chat: {
      title: 'Sohbet'
    },
    prepositions: {
      at: 'de'
    },
    qa: {
      sendAQuestion: 'Soru gönder...'
    },
    confirmation: {
      yes: 'Evet'
    },
    reactions: {
      reactions: 'Reaksiyonlar'
    },
    attendanceMode: {
      online: 'Sanal',
      offline: 'Şahsen',
      mixed: 'Hibrid'
    },
    activities: {
      liveNow: 'Yayın akışı',
      chat: 'Sohbet'
    }
  },
  de: {
    actions: {
      add: 'Hinzufügen',
      addToCalendar: 'Zum Kalender hinzufügen',
      accept: 'Akzeptieren',
      addFile: 'Datei hinzufügen',
      addImage: 'Bild hinzufügen',
      clear: 'Klar',
      clone: 'Klon',
      create: 'Erstellen',
      delete: 'Löschen',
      filter: 'Filtern',
      hideFilters: 'Filter ausblenden',
      hideReactions: 'Deaktiviere Emotionen',
      showReactions: 'Ermöglichen Sie Emotionen',
      remove: 'entfernen',
      saveChanges: 'Änderungen speichern',
      save: 'sparen',
      search: 'Suche',
      cancel: 'Stornieren',
      moreOptions: 'Mehr Optionen',
      logout: 'Abmelden',
      editPhoto: 'Foto bearbeiten',
      send: 'senden',
      upload: 'Hochladen',
      deleteImage: 'Bild löschen',
      showQr: 'Zeige meinen QR',
      close: 'Schliessen',
      shareMyContact: 'Teile meinen Kontakt',
      share: 'Teilen',
    },
    error404: {
      title: 'Wir konnten die Seite, die Sie gesucht haben, nicht finden.',
      subtitle: 'Probieren Sie eine andere Option mit der Seitenleiste aus.'
    },
    title: {
      announcement: 'Ankündigung!',
      reminder: 'Erinnerung'
    },
    formError: {
      enterValidEmail: 'Geben Sie eine gültige E-Mail ein.',
      requiredField: 'Das Feld ist erforderlich.',
      invalidToken: 'Der eingegebene Sicherheitscode ist ungültig.',
      invalidEmail: 'Die von Ihnen angegebene E-Mail ist ungültig.'
    },
    forms: {
      notRegisteredLink: ' Registriere dich hier',
      signature: 'Signatur',
      firstName: 'Vorname',
      lastName: 'Nachname',
      telephone: 'Telefon',
      country: 'Land',
      jobTitle: 'Titel des Berufs',
      company: 'Firma',
      region: 'Region',
      city: 'City',
      photo: 'Foto',
      searchKeyword: 'Schlüsselwörter',
      email: 'E-mail',
      fullName: 'Voller Name',
      notes: 'Hinweise',
      accessToken: 'Sicherheitscode',
      validateToken: 'Code validieren',
      logIn: 'Anmelden',
      loginActiveDirectory: 'Mit Active Directory anmelden',
      error: 'Fehler'
    },
    filters: {
      apply: 'Filter anwenden',
      clear: 'Filter löschen',
      title: 'Filtert',
      categories: {
        categoriesTypes: 'Kategorien Typen',
        all: 'Alle'
      },
      events: {
        datesRange: 'Datumsbereich'
      },
      attendees: {
        showOnlyRatedBy: 'Teilnehmer, die ich bewertet habe'
      },
      sort: {
        sortBy: 'Sortiere nach',
        'first_name asc': 'Vorname A-Z↓',
        'first_name desc': 'Vorname Z-A↓',
        'last_name asc': 'Nachname A-Z↓',
        'last_name desc': 'Nachname Z-A↓',
        'company asc': 'Firma A-Z↓',
        'company desc': 'Firma Z-A↓',
        'id asc': 'Neuester zuletzt',
        'id desc': 'Die neueste zuerst',
      },
    },
    dates: {
      localTimeIs: 'Ortszeit ist',
      timeAtEvent: 'Zeit am Veranstaltungsort',
      startDate: 'Datum des Beginns',
      endDate: 'Datum des Endes',
      from: 'Ab',
      to: 'Um'
    },
    status: {
      title: 'Status',
      saving: 'Sparen',
      completed: 'Abgeschlossen',
      registered: 'Registriert',
      optional: 'Optional',
      mandatory: 'Obligatorisch',
    },
    info: {
      logoAspectRatio: 'Stellen Sie für eine bessere Darstellung sicher, dass das Logo ein Seitenverhältnis von 16:9 hat',
      loginDisabled: 'Sie sehen diese Seite, weil sich {{event}} noch im Aufbau befindet. Komm wieder, sobald alles konfiguriert und aktiviert ist.',
    },
    speakers: {
      speakers: 'Lautsprecher'
    },
    sponsors: {
      sponsoredBy: 'Gesponsert von',
      poweredBy: 'Unterstützt von'
    },
    timer: {
      before: 'Dieses Treffen hat noch nicht begonnen!\nNehmen Sie Platz und entspannen Sie sich, Sie haben {{time}} bevor es beginnt.',
      ended: 'Ihr Meeting mit {{otherParticipant}} wurde beendet {{when}}',
      remaining: 'verbliebene',
      joinNow: 'Jetzt mitmachen',
      extendedTimeTitle: 'Dieses Treffen ist beendet',
      extendedTimeMessage: 'Ihr Meeting mit {{otherParticipant}} ist beendet, Sie können an Ihrem nächsten Meeting mit {{nextParticipant}} teilnehmen, indem Sie auf „JETZT TEILNEHMEN“ klicken',
      endingSoon: 'In weniger als einer Minute endet dieses Meeting und Sie werden automatisch zu Ihrem nächsten Meeting weitergeleitet.'
    },
    notes: {
      title: 'Hinweise'
    },
    chat: {
      title: 'Chat'
    },
    prepositions: {
      at: 'beim'
    },
    qa: {
      sendAQuestion: 'Sende eine Frage...'
    },
    confirmation: {
      yes: 'Ja'
    },
    reactions: {
      reactions: 'Reaktionen'
    },
    attendanceMode: {
      online: 'Virtuell',
      offline: 'Persönlich',
      mixed: 'Hybrid'
    },
    activities: {
      liveNow: 'Lebe jetzt',
      chat: 'Chat'
    }
  },
};
