import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Loader from 'eventtia-ui-components/lib/Loader';
import clsx from 'clsx';
import callApi from '../../actions/callApi';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import SubpageTitle from '../../components/SubpageTitle';
import ArrowLeft from '../../assets/arrowLeft.svg';
import DownloadIcon from '../../assets/DownloadIcon';
import config from './config';
// import PhotoGroupCover from './PhotoGroupCover';
// import ImageModal from '../../components/ImageModal';

const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  sectionTitle: {
    fontWeight: 'bold',
    fontSize: 15,
    marginLeft: theme.spacing(7.5),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
    },
  },
  imgButton: {
    all: 'unset',
    cursor: 'pointer',
    width: '100%',
    height: '100%',
  },
  photo: {
    width: '100%',
    // height: '100%',
    objectFit: 'contain',
    boxShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
  },
  backButton: {
    width: 45,
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(-2),
  },
  contentWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      gap: 0,
    },
  },
  prevButtonWrapper: {
    [theme.breakpoints.down('md')]: {
      width: '50%',
      marginBottom: theme.spacing(2),
    },
  },
  prevButton: {
    width: 45,
  },
  nextButtonWrapper: {
    [theme.breakpoints.down('md')]: {
      order: 2,
      width: '50%',
      textAlign: 'right',
      marginBottom: theme.spacing(2),
    },
  },
  nextButton: {
    width: 45,
    '& img': {
      transform: 'rotate(180deg)',
    },
  },
  disabled: {
    opacity: 0.3,
  },
  imgWrapper: {
    position: 'relative',
    width: '100%',
    // height: '100%',
    '&:hover $downloadButton': {
      opacity: 1,
    },
    [theme.breakpoints.down('md')]: {
      order: 3,
    },
  },
  downloadButton: {
    position: 'absolute',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    boxShadow: '0 4px 4px rgba(0, 0, 0, 0.4)',
    top: theme.spacing(-4),
    right: theme.spacing(-4),
    zIndex: 1,
    opacity: 0,
    transition: 'opacity 0.5s, background-color 0.5s',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  loader: {
    width: '100%',
  },
}));

const PhotoGroupContentSingleImage = ({
  group,
  closeGroup,
  eventFiles,
  callApi: dispatchCallApi,
  fetchStatus,
}) => {
  // const [openModal, setOpenModal] = useState(false);
  // const [selectedImageUrl, setSelectedImageUrl] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const classes = useStyles();
  const {
    isFetching,
    // links = {},
    order = [],
  } = fetchStatus;
  // const content = order ? order.map((id) => eventFiles[id].urls) : [];
  const pic = order ? order.map((id) => eventFiles[id])[currentIndex] : undefined;

  const fileCategoryId = config[group].categoryId;

  useEffect(() => {
    dispatchCallApi('categoryFiles', { fileCategoryId });
  }, [fileCategoryId]);

  // const { prev, next, first, last } = links;
  // const fetchNextPageIfExists = () => {
  //   if (next) dispatchCallApi(
  //     'categoryFiles',
  //     { fileCategoryId },
  //     next
  //   );
  // };
  // const fetchPrevPageIfExists = () => {
  //   if (prev) dispatchCallApi(
  //     'categoryFiles',
  //     { fileCategoryId },
  //     prev
  //   );
  // };

  const hasMultiplePages = !!order?.length && (order.length > 1);
  const prevEnabled = hasMultiplePages && (currentIndex > 0);
  const nextEnabled = hasMultiplePages && (currentIndex < (order.length - 1));
  // const hasMultiplePages = first !== last;
  // const prevEnabled = hasMultiplePages && !!prev && !isFetching;
  // const nextEnabled = hasMultiplePages && !!next && !isFetching;
  return (
    <>
      <div className={classes.titleWrapper}>
        <IconButton
          className={classes.backButton}
          onClick={closeGroup}
          color="primary"
        >
          <img src={ArrowLeft} alt="go back" />
        </IconButton>
        <SubpageTitle text="Photos" />
      </div>
      <Typography className={classes.sectionTitle} variant="subtitle2">
        {config[group].title}
      </Typography>
      {isFetching ? (
        <Loader loading className={classes.loader} />
      ) : (
        <div className={classes.contentWrapper}>
          {hasMultiplePages && (
            <div className={classes.prevButtonWrapper}>
              <IconButton
                className={clsx(
                  classes.prevButton,
                  !prevEnabled && classes.disabled
                )}
                onClick={() => {
                  // fetchPrevPageIfExists();
                  setCurrentIndex(currentIndex - 1);
                }}
                color="primary"
                disabled={!prevEnabled}
              >
                <img src={ArrowLeft} alt="previous" />
              </IconButton>
            </div>
          )}
          {!!pic && (
            <div className={classes.imgWrapper}>
              <IconButton
                className={classes.downloadButton}
                onClick={() => {
                  fetch(
                    pic.urls.original
                  ).then(
                    (response) => response.blob()
                  ).then((blob) => {
                    const objectUrl = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = objectUrl;
                    link.setAttribute('download', pic.filename);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                  });
                }}
                color="primary"
              >
                <DownloadIcon />
              </IconButton>
              <img
                src={pic.urls.medium}
                alt=""
                className={classes.photo}
              />
            </div>
          )}
          {hasMultiplePages && (
            <div className={classes.nextButtonWrapper}>
              <IconButton
                className={clsx(
                  classes.nextButton,
                  !nextEnabled && classes.disabled
                )}
                onClick={() => {
                  // fetchNextPageIfExists();
                  setCurrentIndex(currentIndex + 1);
                }}
                color="primary"
                disabled={!nextEnabled}
              >
                <img src={ArrowLeft} alt="previous" />
              </IconButton>
            </div>
          )}
        </div>
      )}
    </>
  );
};

PhotoGroupContentSingleImage.propTypes = {
  group: PropTypes.string.isRequired,
  closeGroup: PropTypes.func.isRequired,
  callApi: PropTypes.func.isRequired,
  eventFiles: PropTypes.objectOf(CustomPropTypes.eventFile),
  fetchStatus: CustomPropTypes.fetchStatus.isRequired,
};

PhotoGroupContentSingleImage.defaultProps = {
  eventFiles: undefined,
};

const mapStateToProps = ({
  entities: {
    eventFiles,
  },
  fetchStatus: {
    categoryFiles: fetchStatus,
  },
}) => ({
  eventFiles,
  fetchStatus,
});

export default connect(mapStateToProps, { callApi })(PhotoGroupContentSingleImage);
