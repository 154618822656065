import { createMuiTheme } from '@material-ui/core/styles';
import { rawTheme } from 'eventtia-ui-components/lib/styles/theme';
import cloneDeep from 'lodash/cloneDeep';

const brillianCutPro = {
  fontFamily: 'Brilliant Cut Pro',
  src: "url('https://cdn.eventtia.com/account_fonts/889/BrilliantCutProRegular17128499581712849958.woff?1712849958') format('woff')",
  fontWeight: 400,
  fontStyle: 'normal',
  fontDisplay: 'swap',
};

const brillianCutProMedium = {
  fontFamily: 'Brilliant Cut Pro',
  src: "url('https://cdn.eventtia.com/account_fonts/888/BrilliantCutProMedium17128462651712846265.woff?1712846265') format('woff')",
  fontWeight: 500,
  fontStyle: 'normal',
  fontDisplay: 'swap',
};

const brillianCutProBold = {
  fontFamily: 'Brilliant Cut Pro',
  src: "url('https://cdn.eventtia.com/account_fonts/887/BrilliantCutProBold17127698151712769815.woff?1712769815') format('woff')",
  fontWeight: 700,
  fontStyle: 'normal',
  fontDisplay: 'swap',
};

const fancyCutProItalic = {
  fontFamily: 'Fancy Cut Pro',
  src: "url('https://cdn.eventtia.com/account_fonts/885/FancyCutProRegularItalic17127696711712769671.woff?1712769671') format('woff')",
  fontWeight: 300,
  fontStyle: 'italic',
  fontDisplay: 'swap',
};

const fancyCutProRegular = {
  fontFamily: 'Fancy Cut Pro',
  src: "url('https://cdn.eventtia.com/account_fonts/886/FancyCutProRegular17127697141712769714.woff?1712769714') format('woff')",
  fontWeight: 300,
  fontStyle: 'normal',
  fontDisplay: 'swap',
};

const createTheme = ({ primaryColor, secondaryColor, navbarColor }) => {
  const newRawTheme = cloneDeep(rawTheme);
  newRawTheme.palette.primary = {
    main: primaryColor,
  };
  newRawTheme.palette.secondary = {
    main: secondaryColor,
  };
  newRawTheme.palette.lightGrey = {
    ...newRawTheme.palette.lightGrey,
    main: '#E8E9E8',
    light: '#EFEFEF',
  };
  newRawTheme.palette.darkGrey = {
    ...newRawTheme.palette.darkGrey,
    main: '#7B7676',
  };
  newRawTheme.palette.background = {
    ...newRawTheme.palette.background,
    backdrop: primaryColor,
  };
  newRawTheme.palette.navbar = {
    main: navbarColor || rawTheme.palette.lightGrey.main,
  };
  newRawTheme.customShadows = {
    small: '0 4px 4px rgba(0, 0, 0, 0.16)',
  };
  newRawTheme.subpageDrawer = {
    paddings: {
      desktop: { vertical: 4, horizontal: 6.5 },
      mobile: { vertical: 3, horizontal: 3.5 },
    },
  };
  newRawTheme.typography.fontFamily = 'Brilliant Cut Pro';
  newRawTheme.typography.subtitle1 = {
    fontFamily: 'Fancy Cut Pro',
    letterSpacing: 0.5,
    color: 'black',
  };
  newRawTheme.typography.body1 = {
    fontFamily: 'Brilliant Cut Pro',
    color: newRawTheme.palette.darkGrey.main,
  };
  newRawTheme.typography.h1 = {
    fontFamily: 'Brilliant Cut Pro',
    fontWeight: 'bold',
    fontSize: 45,
    textAlign: 'center',
    color: 'black',
  };
  newRawTheme.typography.h5 = {
    fontFamily: 'Brilliant Cut Pro',
    fontWeight: 'bold',
    fontSize: 20,
    color: 'black',
  };
  newRawTheme.typography.button = {
    fontFamily: 'Brilliant Cut Pro',
    fontWeight: 'bold',
    color: 'white',
  };
  newRawTheme.overrides = {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [brillianCutPro, brillianCutProMedium, brillianCutProBold, fancyCutProItalic, fancyCutProRegular],
      },
    },
  };

  return createMuiTheme(newRawTheme);
};

export default createTheme;
