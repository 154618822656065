import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Tabs from 'eventtia-ui-components/lib/Tabs';
import Typography from '@material-ui/core/Typography';
import difference from 'lodash/difference';
import InfiniteScroll from '../../components/InfiniteScroll';
import callApi from '../../actions/callApi';
import SubpageTitle from '../../components/SubpageTitle';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import TabletExitButton from '../../components/TabletExitButton';
import { getModuleNames, getModuleByType, getCurrentAttendee } from '../../helpers/getters';
import { setActivePost } from '../../actions/app';
import { setFilters } from '../../actions/filters';
import SocialPost from '../../components/SocialPost';
import SocialPostShow from '../SocialPostShow';
import SocialPostCreator from '../../components/SocialPostCreator';
import { EVENT_URI } from '../../helpers/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  singlePostRoot: {
    padding: theme.spacing(9, 0),
  },
  scroll: {
    minWidth: 320,
    padding: theme.spacing(6),
    maxHeight: '100%',
  },
  mobileScroll: {
    padding: theme.spacing(3.5, 2.5, 2.5, 2.5),
    maxHeight: '100%',
  },
  tabs: {
    marginBottom: theme.spacing(4),
  },
  post: {
    display: 'block',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    backgroundColor: theme.palette.common.white,
    cursor: 'pointer',
    '&:last-of-type': {
      marginBottom: theme.spacing(0),
    },
  },
  postInfo: {
    display: 'flex',
  },
  postContent: {
    margin: theme.spacing(2, 0),
  },
  likesAndComments: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(2, 0),
  },
  commentSection: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.lightGrey.light,
    borderRadius: theme.spacing(1),
    minHeight: 150,
  },
}));

const orderWithNewPosts = (order, socialPosts, filterById) => {
  const postsNotInOrder = difference(Object.keys(socialPosts), order);

  postsNotInOrder.reverse();

  const allPosts = postsNotInOrder.concat(order);

  return !filterById ? allPosts : allPosts.filter((postId) => (
    socialPosts[postId].attendee.id === filterById
  ));
};

const SocialWall = ({
  postId,
  entities,
  socialPosts,
  appSettings,
  order,
  isFetchingPosts,
  isFetchingAttendeeTypes,
  isFetchingFirstPage,
  postsError,
  links,
  isFetchingSinglePost,
  singlePostError,
  callApi: dispatchCallApi,
  setFilters: dispatchSetFilters,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('socialWall');
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [settings] = Object.values(appSettings);
  const moduleTitle = getModuleNames(appSettings, 'SocialWall');
  const socialWallModule = getModuleByType(settings.modules, 'SocialWall');
  const description = socialWallModule.customParams?.description;

  const currentAttendee = getCurrentAttendee(entities, { parseCustomFields: true });

  const fetchNextPageIfExists = () => {
    const { next } = links;
    if (!postId && next) dispatchCallApi('socialPosts', next);
  };

  useEffect(() => {
    dispatchCallApi('socialPosts');
    dispatchCallApi('attendeeTypes');
  }, [dispatchCallApi]);

  const activePost = socialPosts?.[postId];
  const shouldFetchSinglePost = !isFetchingPosts && !isFetchingSinglePost
    && !singlePostError && !postsError && postId && Number(postId)
    && !activePost;

  useEffect(() => {
    if (shouldFetchSinglePost) dispatchCallApi('singlePost', { postId });
  }, [dispatchCallApi, postId, shouldFetchSinglePost]);

  const ALL_POSTS_TAB = 0;
  const MY_POSTS_TAB = 1;
  const [activeTab, setActiveTab] = useState(ALL_POSTS_TAB);
  const tabs = [{
    name: t('eventNewsfeed'),
    onClick: () => {
      setActiveTab(ALL_POSTS_TAB);
      dispatchSetFilters(
        'socialPosts',
        { attendeeId: '' },
        { EVENT_URI }
      );
    },
  }, {
    name: t('myPosts'),
    onClick: () => {
      setActiveTab(MY_POSTS_TAB);
      dispatchSetFilters(
        'socialPosts',
        { attendeeId: currentAttendee.id },
        { EVENT_URI }
      );
    },
  }];

  const visiblePosts = orderWithNewPosts(
    order,
    socialPosts,
    activeTab === MY_POSTS_TAB && currentAttendee.id
  );

  const isLoading = isFetchingPosts || isFetchingAttendeeTypes;

  return (
    <div className={classes.root}>
      {!activePost && (
        <TabletExitButton />
      )}
      {activePost ? (
        <div className={classes.singlePostRoot}>
          <SocialPostShow
            post={activePost}
            showAllComments
          />
        </div>
      ) : (
        <InfiniteScroll
          loading={isLoading}
          error={postsError}
          fetchNextPageIfExists={fetchNextPageIfExists}
          className={mobile ? classes.mobileScroll : classes.scroll}
        >
          <SubpageTitle text={moduleTitle} />
          {description && (
            <Typography className={classes.moduleDescription} variant="body1">
              {description}
            </Typography>
          )}
          <SocialPostCreator
            EVENT_URI={EVENT_URI}
            currentAttendee={currentAttendee}
          />
          <Tabs
            className={classes.tabs}
            variant="filter"
            items={tabs}
            activeItem={activeTab}
          />
          <div>
            {!isFetchingFirstPage && visiblePosts.map((item) => {
              const post = socialPosts[item];
              return post ? (
                <SocialPost
                  key={post.id}
                  currentAttendee={currentAttendee}
                  post={post}
                />
              ) : null;
            })}
          </div>
        </InfiniteScroll>
      )}
    </div>
  );
};

SocialWall.propTypes = {
  postId: PropTypes.string,
  entities: CustomPropTypes.entities,
  socialPosts: PropTypes.objectOf(CustomPropTypes.socialPost),
  appSettings: PropTypes.objectOf(CustomPropTypes.appSettings).isRequired,
  order: PropTypes.arrayOf(PropTypes.string),
  isFetchingPosts: PropTypes.bool.isRequired,
  isFetchingAttendeeTypes: PropTypes.bool.isRequired,
  isFetchingFirstPage: PropTypes.bool.isRequired,
  postsError: PropTypes.string,
  links: PropTypes.shape({
    next: PropTypes.string,
  }).isRequired,
  isFetchingSinglePost: PropTypes.bool.isRequired,
  singlePostError: PropTypes.string,
  callApi: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
};

SocialWall.defaultProps = {
  postId: undefined,
  entities: {},
  socialPosts: {},
  order: [],
  postsError: undefined,
  singlePostError: undefined,
};

const mapStateToProps = ({
  entities,
  entities: {
    socialPosts,
    attendees,
    appSettings,
    attendeeTypeCustomFields,
  },
  fetchStatus: {
    socialPosts: {
      order,
      isFetching: isFetchingPosts,
      isFetchingFirstPage,
      error: postsError,
      links,
    },
    singlePost: {
      isFetching: isFetchingSinglePost,
      error: singlePostError,
    },
    attendeeTypes: {
      isFetching: isFetchingAttendeeTypes,
    },
  },
  app: {
    activeSubpage: {
      postId,
    },
  },
}) => ({
  entities,
  attendeeTypeCustomFields,
  postId,
  socialPosts,
  attendees,
  appSettings,
  order,
  isFetchingPosts,
  isFetchingAttendeeTypes,
  isFetchingFirstPage,
  postsError,
  links,
  isFetchingSinglePost,
  singlePostError,
});

export default connect(
  mapStateToProps,
  { callApi, setActivePost, setFilters }
)(SocialWall);
