import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Gallery = (props) => (
  <SvgIcon {...props} viewBox="0 0 22.8 18.64">
    <svg
      width="6.0324736mm"
      height="4.9312248mm"
      viewBox="0 0 6.0324736 4.9312248"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(-52.356645,-127.15727)">
        <g
          mask="url(#mask0_256_447)"
          id="g137"
          transform="matrix(0.26458333,0,0,0.26458333,47.565358,123.4123)"
        >
          <path
            d="m 38.9338,17.318 h -4.3252 l -0.5102,-2.1993 c -0.1317,-0.5676 -0.6088,-0.9645 -1.1601,-0.9645 h -6.8592 c -0.5509,0 -1.0281,0.3962 -1.1601,0.9645 l -0.5102,2.1993 h -4.3251 c -1.0891,0 -1.9749,0.9397 -1.9749,2.0948 v 11.2843 c 0,1.1551 0.8858,2.0948 1.9749,2.0948 h 18.8501 c 1.089,0 1.9749,-0.9397 1.9749,-2.0948 V 19.4128 c 0,-1.1551 -0.8859,-2.0948 -1.9749,-2.0948 z m 1.188,13.3788 c 0,0.6949 -0.5326,1.2603 -1.188,1.2603 H 20.0837 c -0.6551,0 -1.1881,-0.5651 -1.1881,-1.2603 v -11.284 c 0,-0.6948 0.5327,-1.2603 1.1881,-1.2603 h 4.6336 c 0.181,0 0.3388,-0.131 0.3821,-0.3175 l 0.5833,-2.5164 c 0.0453,-0.1938 0.208,-0.3296 0.3964,-0.3296 h 6.8592 c 0.1884,0 0.3514,0.1358 0.3964,0.3296 l 0.5837,2.5164 c 0.0426,0.1862 0.2007,0.3175 0.3817,0.3175 h 4.6337 c 0.6551,0 1.188,0.5651 1.188,1.2603 z"
            fill="currentColor"
            id="path135"
          />
        </g>
        <path
          d="m 55.372947,128.4701 c -0.816848,0 -1.481269,0.70477 -1.481269,1.57104 0,0.86636 0.664421,1.57121 1.481269,1.57121 0.816849,0 1.481191,-0.70477 1.481191,-1.57105 0,-0.86635 -0.664342,-1.5712 -1.481191,-1.5712 z m 0,2.92145 c -0.701966,0 -1.273069,-0.60576 -1.273069,-1.35025 0,-0.74459 0.571103,-1.35043 1.273069,-1.35043 0.701887,0 1.27299,0.60576 1.27299,1.35027 0,0.74457 -0.571103,1.35041 -1.27299,1.35041 z"
          fill="currentColor"
          id="path139"
          style={{ strokeWidth: 0.264583 }}
        />
        <path
          d="m 55.372947,129.21636 c -0.428757,0 -0.777531,0.37004 -0.777531,0.82478 0,0.45472 0.348774,0.82468 0.777531,0.82468 0.428678,0 0.777479,-0.36996 0.777479,-0.82468 0,-0.45474 -0.348801,-0.82478 -0.777479,-0.82478 z m 0,1.42867 c -0.313928,0 -0.569356,-0.27085 -0.569356,-0.60389 0,-0.33303 0.255428,-0.60401 0.569356,-0.60401 0.313876,0 0.569304,0.27098 0.569304,0.60401 0,0.33304 -0.255428,0.60389 -0.569304,0.60389 z"
          fill="currentColor"
          id="path141"
          style={{ strokeWidth: 0.264583 }}
        />
        <path
          d="m 53.443712,128.50862 h -0.497496 c -0.05757,0 -0.104087,0.0494 -0.104087,0.11042 0,0.061 0.04651,0.11038 0.104087,0.11038 h 0.497496 c 0.05757,0 0.104113,-0.0494 0.104113,-0.11038 0,-0.0611 -0.04654,-0.11042 -0.104113,-0.11042 z"
          fill="currentColor"
          id="path143"
          style={{ strokeWidth: 0.264583 }}
        />
      </g>
    </svg>

  </SvgIcon>
);

export default Gallery;
