import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import ReactPhoneNumberInput from 'react-phone-number-input';
import { makeStyles } from '@material-ui/core/styles';
import 'react-phone-number-input/style.css';
import TextField from '../InputBase';

const Input = forwardRef(({ onChange, ...otherProps }, ref) => (
  <TextField
    handleChange={onChange}
    inputRef={ref}
    {...otherProps}
  />
));

Input.propTypes = {
  onChange: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    '& .PhoneInputCountry': {
      padding: theme.spacing(0, 2),
      backgroundColor: theme.palette.lightGrey.main,
      marginTop: theme.spacing(3),
      marginRight: theme.spacing(1),
    },
    '& .MuiFormLabel-root.MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(-70px, 0px) scale(0.75)',
    },
  },
}));

/** This component must be used as a controlled input. */
const PhoneInput = ({
  value, handleChange, ...other
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ReactPhoneNumberInput
        value={value}
        onChange={handleChange}
        inputComponent={Input}
        {...other}
      />
    </div>
  );
};

PhoneInput.propTypes = {
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default PhoneInput;
