import React, { useRef, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import Input from '@material-ui/core/Input';
import { setFilters } from '../../actions/filters';
import CustomPropTypes from '../../helpers/CustomPropTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 540,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  searchInput: {
    width: '100%',
    margin: theme.spacing(0, 2, 0, 0.7),
    paddingLeft: theme.spacing(1.5),
    backgroundColor: '#f5f5f5',
    borderBottom: 'none',

  },
  searchLabel: {
    fontFamily: 'Brilliant Cut Pro',
    fontWeight: '500',
    fontSize: 14,
    letterSpacing: '0.04em',
    color: theme.palette.darkGrey.light,
    paddingLeft: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
  },
}));

export const SearchPeople = ({
  entity, activeFilters, fetchParams,
  setFilters: dispatchSetFilters,
}) => {
  const classes = useStyles();
  const searchRef = useRef(null);

  const search = useCallback(() => {
    dispatchSetFilters(entity, { search: searchRef.current?.value }, fetchParams);
  }, [dispatchSetFilters, entity, fetchParams]);

  const clearSearch = useCallback(() => {
    if (activeFilters.search) dispatchSetFilters(entity, { search: undefined }, fetchParams);
    else if (searchRef.current) {
      searchRef.current.value = '';
      searchRef.current.blur();
    }
  }, [dispatchSetFilters, entity, fetchParams, activeFilters]);

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.value = activeFilters.search || '';
      if (!activeFilters.search) searchRef.current.blur();
    }
  }, [activeFilters.search]);

  return (
    <div className={classes.root}>
      <span className={classes.searchLabel}>
        Search by Name, Title or Market/Department
      </span>
      <Input
        placeholder="John Doe"
        defaultValue={activeFilters.search || ''}
        inputRef={searchRef}
        className={classes.searchInput}
        fullWidth={false}
        margin="dense"
        disableUnderline
        endAdornment={(

          <InputAdornment position="end">
            <IconButton onClick={search}>
              <SearchIcon />
            </IconButton>
            {activeFilters.search && (
              <IconButton onClick={clearSearch}>
                <ClearIcon />
              </IconButton>
            )}
          </InputAdornment>
        )}
        onKeyDown={(e) => {
          if (e.key === 'Enter') search();
        }}
      />
    </div>
  );
};

SearchPeople.propTypes = {
  entity: PropTypes.string.isRequired,
  activeFilters: PropTypes.objectOf(CustomPropTypes.filter).isRequired,
  fetchParams: PropTypes.objectOf(CustomPropTypes.filter),
  setFilters: PropTypes.func.isRequired,
  meta: PropTypes.shape({
    meetingStatuses: PropTypes.objectOf(PropTypes.number),
  }).isRequired,
};

SearchPeople.defaultProps = {
  fetchParams: undefined,
};

const mapStateToProps = ({
  filters,
  entities,
  meta,
}, { entity }) => ({
  activeFilters: filters[entity],
  entities,
  meta,
});

export default connect(mapStateToProps, { setFilters })(SearchPeople);
