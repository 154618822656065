import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import { DatePicker, DateTimePicker } from '@material-ui/pickers';
import moment from '../../helpers/dates';
import InputBase from '../InputBase';

const DateInput = ({
  id, format, value, handleChange, disabled, minDate, maxDate,
  timePicker, timeFormat, error, helperText, ...other
}) => {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const getFormattedDate = (rawDate) => (timePicker
    ? moment(rawDate).format(`${format} ${timeFormat === '12h' ? 'hh:mmA' : 'HH:mm'}`)
    : moment(rawDate).format(format));

  const CustomInput = ({
    error: errorProp, helperText: helperTextProp, ...otherProps
  }) => (
    <InputBase
      id={id}
      error={error}
      helperText={helperText}
      autoComplete="off"
      {...otherProps}
      {...other}
      value={otherProps.value ? otherProps.value : ''}
      endAdornment={(
        <InputAdornment position="end">
          <IconButton
            disableRipple
            size="small"
          >
            <InsertInvitationIcon />
          </IconButton>
        </InputAdornment>
       )}
    />
  );

  CustomInput.propTypes = {
    helperText: PropTypes.string,
    error: PropTypes.string,
  };

  CustomInput.defaultProps = {
    helperText: undefined,
    error: undefined,
  };

  const dateProps = {
    clearable: true,
    onChange: setCurrentValue,
    value: value ? moment(value) : value,
    minDate: minDate ? moment(minDate) : undefined,
    maxDate: maxDate ? moment(maxDate) : undefined,
    onAccept: (val) => handleChange(val ? val.format() : ''),
    TextFieldComponent: CustomInput,
    labelFunc: () => (currentValue ? getFormattedDate(currentValue) : ''),
  };

  return (
    <>
      {timePicker ? (
        <DateTimePicker ampm={timeFormat === '12h'} disabled={disabled} {...dateProps} />
      ) : (
        <DatePicker disabled={disabled} {...dateProps} />
      )}
    </>
  );
};

DateInput.propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  format: PropTypes.string,
  timeFormat: PropTypes.string,
  value: PropTypes.string.isRequired,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  timePicker: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  error: PropTypes.string,
};

DateInput.defaultProps = {
  disabled: false,
  timePicker: false,
  minDate: undefined,
  maxDate: undefined,
  format: 'LL',
  timeFormat: '24h',
  helperText: undefined,
  error: undefined,
};

export default DateInput;
