import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import Card from 'eventtia-ui-components/lib/Card';
import Input from 'eventtia-ui-components/lib/Input';
import PhoneInput from 'eventtia-ui-components/lib/PhoneInput';
import ContextMenu from 'eventtia-ui-components/lib/ContextMenu';
import Button from 'eventtia-ui-components/lib/Button';
import Loader from 'eventtia-ui-components/lib/Loader';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Message from '../../components/Message';
import useDidUpdate from '../../hooks/useDidUpdate';
import withForm from '../../hocs/withForm';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import BackButton from '../../components/BackButton';
import { deleteAttendeeLead } from '../../actions/attendeeLeads';
import { setActiveLead } from '../../actions/app';
import { EVENT_URI } from '../../helpers/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 4),
  },
  card: {
    marginTop: theme.spacing(1),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0, 0, 2.5, 0),
    width: '100%',
    position: 'relative',
  },
  avatar: {
    height: 70,
    width: 70,
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.lightGrey.main,
    color: theme.palette.primary.main,
  },
  name: {
    fontSize: 16,
    color: theme.palette.darkGrey.dark,
    fontWeight: 'bold',
  },
  job: {
    fontSize: 14,
    color: theme.palette.darkGrey.main,
  },
  menuIcon: {
    color: theme.palette.primary.light,
  },
  saveButton: {
    display: 'block',
    marginTop: theme.spacing(3),
    marginLeft: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  loader: {
    zIndex: 3,
  },
  message: {
    marginBottom: theme.spacing(2),
  },
}));

const AttendeeLeadShow = ({
  attendeeLead,
  registerInput,
  watchFormValues,
  setFormValue,
  errors,
  updating,
  success,
  deleteAttendeeLead: dispatchDeleteAttendeeLead,
  setActiveLead: dispatchSetActiveLead,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('global');

  const [message, setMessage] = useState();
  useDidUpdate(() => {
    if (success) setMessage({ type: 'success', text: t('attendeeLeads:info.savedSuccessfully') });
  }, [success]);

  const phone = watchFormValues('attendee[telephone]') || attendeeLead.phone;
  useEffect(() => {
    registerInput({ name: 'phone' });
  }, [registerInput]);

  const menuItems = [{
    name: t('attendeeLeads:actions.deleteLead'),
    onClick: () => {
      dispatchDeleteAttendeeLead(attendeeLead, EVENT_URI);
      dispatchSetActiveLead();
    },
    color: 'red',
    icon: <DeleteIcon />,
  }];

  return (
    <div className={classes.root}>
      <BackButton />
      <Card className={classes.card}>
        <Loader loading={updating} variant="absolute" className={classes.loader} />

        <Message
          className={classes.message}
          type={message?.type}
          onClose={() => setMessage()}
          message={message?.text}
        />

        <div className={classes.header}>
          <Avatar className={classes.avatar} />
          <div>
            <Typography className={classes.name} variant="subtitle1">
              {attendeeLead.fullName}
            </Typography>
            {attendeeLead.company && (
              <Typography className={classes.job} variant="body2">
                {attendeeLead.company}
              </Typography>
            )}
          </div>
          <ContextMenu
            items={menuItems}
            iconButtonClassName={classes.menuIcon}
          />
        </div>

        {/* to preserve the uuid */}
        <input
          id="uuid"
          name="uuid"
          ref={registerInput()}
          type="hidden"
        />

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Input
              id="fullName"
              name="fullName"
              placeholder={t('forms.fullName')}
              label={t('forms.fullName')}
              error={errors?.fullName?.message}
              inputRef={registerInput()}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              id="email"
              name="email"
              placeholder={t('forms.email')}
              label={t('forms.email')}
              error={errors?.email?.message}
              inputRef={registerInput()}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              id="company"
              name="company"
              placeholder={t('forms.company')}
              label={t('forms.company')}
              error={errors?.company?.message}
              inputRef={registerInput()}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PhoneInput
              id="phone"
              name="phone"
              placeholder={t('forms.telephone')}
              label={t('forms.telephone')}
              value={phone || ''}
              handleChange={(val) => setFormValue('phone', val)}
              error={errors?.phone?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              id="notes"
              name="notes"
              placeholder={t('forms.notes')}
              label={t('forms.notes')}
              error={errors?.notes?.message}
              inputRef={registerInput()}
              multiline
              rows={3}
              rowsMax={10}
            />
          </Grid>
        </Grid>

        <Button
          type="submit"
          disabled={updating}
          className={classes.saveButton}
        >
          {t('actions.saveChanges')}
        </Button>
      </Card>
    </div>
  );
};

AttendeeLeadShow.propTypes = {
  attendeeLead: CustomPropTypes.attendeeLead.isRequired,
  registerInput: PropTypes.func.isRequired,
  watchFormValues: PropTypes.func.isRequired,
  setFormValue: PropTypes.func.isRequired,
  errors: CustomPropTypes.formErrors.isRequired,
  updating: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  deleteAttendeeLead: PropTypes.func.isRequired,
  setActiveLead: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  fetchStatus: {
    createOrUpdateAttendeeLead: {
      isFetching,
      success,
    },
  },
}) => ({
  updating: isFetching,
  success,
});

export default compose(
  withForm({ required: false, autoComplete: 'off' }),
  connect(mapStateToProps, { deleteAttendeeLead, setActiveLead })
)(AttendeeLeadShow);
