import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Chip from 'eventtia-ui-components/lib/Chip';
import StarIcon from '@material-ui/icons/Star';
import DenyIcon from '@material-ui/icons/HighlightOff';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import RecommendationIcon from '@material-ui/icons/NewReleases';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { connect } from 'react-redux';
import clsx from 'clsx';
import useStringTransforms from '../../hooks/useStringTransforms';
import CustomPropTypes from '../../helpers/CustomPropTypes';

const useStyles = makeStyles((theme) => ({
  personCard: {
    width: 350,
    height: 140,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    border: 'none',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      padding: theme.spacing(2, 0, 1, 2),
      alignItems: 'stretch',
    },
  },
  avatarWrapper: {
    position: 'relative',
    width: '100%',
    paddingTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      flexDirection: 'row',
      paddingBottom: theme.spacing(1.25),
    },
  },
  avatar: {
    height: 140,
    width: 140,
    backgroundColor: theme.palette.lightGrey.main,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      height: 140,
      width: 140,
    },
  },
  filterAvatar: {
    filter: 'grayscale(100%)',

  },

  name: {
    fontSize: 15,
    fontFamily: 'Brilliant Cut Pro',
    lineHeight: '18px',
    letterSpacing: '0.04em',
    marginBottom: theme.spacing(0.2),
    textAlign: 'start',
    fontWeight: 'bold',
    color: theme.palette.darkGrey.dark,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  description: {
    padding: theme.spacing(0, 0, 0, 4),
    width: 'inherit',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    gap: '15px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1, 0, 2),
    },
  },
  phoneNumber: {
    fontFamily: 'Brilliant Cut',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '14px',
    textAlign: 'start',
  },
  position: {
    fontFamily: 'Brilliant Cut Pro',
    fontWeight: '500',
    fontSize: 12,
    textAlign: 'start',
    lineHeight: '14px',
    letterSpacing: '0.04em',
    color: '#BA9B53',
  },
  marketDepartment: {
    fontFamily: 'Fancy Cut Pro',
    fontWeight: '400',
    fontSize: 12,
    textAlign: 'start',
    lineHeight: '14px',
    letterSpacing: '0.04em',
    color: 'black',
  },
  detail: {
    textAlign: 'start',
    fontSize: 12,
    color: theme.palette.darkGrey.light,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  infoChip: {
    flexDirection: 'row',
    backgroundColor: theme.palette.lightGrey.main,
    boxShadow: '0px 1px 2px #0000001F',
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: 'bold',
    position: 'absolute',
    display: 'flex',
    top: 4,
    left: 20,
    width: 'auto',
    height: 20,
    '& > svg': {
      color: theme.palette.primary.main,
      width: 14,
      height: 14,
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      top: 0,
      left: 0,
      marginLeft: 'auto',
      marginRight: theme.spacing(2),
    },
  },
  deniedChip: {
    left: 0,
  },
  recommendation: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  attendanceModeChip: {
    fontSize: 12,
    width: 84,
    height: 20,
    backgroundColor: fade(theme.palette.primary.main, 0.05),
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(8.75),
    },
  },
  fieldAttendanceMode: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: theme.spacing(1),
  },
}));

const PersonCard = ({
  person, type,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('attendees', 'global');
  const { toTitleCase } = useStringTransforms();
  const {
    firstName,
    lastName,
    fullName,
    position,
    headshot,
    picture,
    rating,
    denied,
    recommended,
    telephone,
    company,
  } = person;

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const attendeeImage = (
    type === 'attendee' ? (headshot?.filename && headshot.medium) : (picture?.filename && picture.large)
  );

  return (
    <button
      type="button"
      className={classes.personCard}
    >
      <div className={classes.avatarWrapper}>
        <Avatar
          src={attendeeImage}
          variant="square"
          className={clsx(classes.avatar, attendeeImage && classes.filterAvatar)}
        />
        {!!rating && !mobile && (
          <Chip
            className={classes.infoChip}
            label={rating.toString()}
            icon={<StarIcon />}
            size="small"
          />
        )}
        {!!denied && !mobile && (
          <Chip
            className={clsx(classes.infoChip, classes.deniedChip)}
            label={t('actions.denyParticipant')}
            icon={<DenyIcon />}
            size="small"
          />
        )}
        {recommended && (
          <RecommendationIcon
            color="primary"
            className={classes.recommendation}
          />
        )}

        <div className={classes.description}>
          <div>

            {type === 'attendee' ? (
              <div>
                <Typography className={classes.name} variant="subtitle2">
                  {firstName
                    ? `${toTitleCase(firstName)}`.toUpperCase() : <br />}
                </Typography>
                <Typography className={classes.name} variant="subtitle2">
                  {lastName
                    ? `${toTitleCase(lastName)}`.toUpperCase() : <br />}
                </Typography>
              </div>
            ) : (
              <Typography className={classes.name} variant="subtitle2">
                {toTitleCase(fullName)}
              </Typography>
            )}
            {telephone && (
              <Typography className={classes.phoneNumber} variant="body2">
                {toTitleCase(telephone, true)}
              </Typography>
            )}
            {position && (
              <Typography className={classes.position} variant="body2">
                {toTitleCase(position, true).toUpperCase()}
              </Typography>
            )}
          </div>
          <div>
            {company && (
              <Typography className={classes.marketDepartment} variant="body2">
                {toTitleCase(company)}
              </Typography>
            )}
          </div>
        </div>

      </div>

    </button>
  );
};

PersonCard.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    fullName: PropTypes.string,
    company: PropTypes.string,
    telephone: PropTypes.string,
    attendanceMode: PropTypes.string,
    headshot: PropTypes.shape({
      filename: PropTypes.string,
      medium: PropTypes.string,
    }),
    picture: PropTypes.shape({
      filename: PropTypes.string,
      large: PropTypes.string,
    }),
    position: PropTypes.string,
    attendeeTypeName: PropTypes.string,
    rating: PropTypes.number,
    denied: PropTypes.bool,
    recommended: CustomPropTypes.recommendation,
  }).isRequired,
  type: PropTypes.string,
};

PersonCard.defaultProps = {
  type: 'attendee',
};

const mapStateToProps = ({
  entities: {
    events,
  },
}) => ({
  events,
});

export default connect(mapStateToProps)(PersonCard);
