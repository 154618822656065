import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import DeleteIcon from '@material-ui/icons/Delete';

import ContextMenu from 'eventtia-ui-components/lib/ContextMenu';

import CustomPropTypes from '../../helpers/CustomPropTypes';
import parseCustomFields from '../../helpers/customFields';
import { baseMoment } from '../../helpers/dates';
import useStringTransforms from '../../hooks/useStringTransforms';

import callApi from '../../actions/callApi';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    margin: '0px auto 16px auto',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(1),
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
  },
  avatar: {
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.lightGrey.main,
    color: theme.palette.primary.main,
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    width: theme.spacing(4),
    height: theme.spacing(4),
    alignSelf: 'flex-start',
  },
  messageInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  attendeeName: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    marginRight: theme.spacing(2),
  },
  contextMenuContainer: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    '& div > button': {
      marginRight: 8,
      marginTop: 8,
    },
    alignSelf: 'flex-start',
  },
  message: {
    color: theme.palette.darkGrey.light,
  },
  commentBody: {
    overflow: 'hidden',
    overflowWrap: 'break-word',
  },
}));

const SocialComment = ({
  comment, postId, currentAttendee, attendees, attendeeTypeCustomFields,
  callApi: dispatchCallApi,
}) => {
  const classes = useStyles();
  const { toTitleCase } = useStringTransforms();

  const [isAttemptingToDelete, setIsAttemptingToDelete] = useState(false);

  const { id: commentId, message, createdAt } = comment;

  const attendee = attendees[comment?.attendee?.id];
  const attendeeInformation = parseCustomFields(
    attendee?.fields || {}, attendeeTypeCustomFields
  );

  const attendeeFullName = toTitleCase(`${attendeeInformation.firstName} ${attendeeInformation.lastName}`);
  const attendeeInitials = `${attendeeInformation.firstName?.[0]}${attendeeInformation.lastName?.[0]}`.toLocaleUpperCase();

  const isCommentOwner = attendee?.id === currentAttendee?.id;

  const commentContextMenuItems = [{
    name: 'Delete',
    onClick: () => setIsAttemptingToDelete(true),
    color: 'red',
    icon: <DeleteIcon />,
  }];

  return message ? (
    <>
      <DeleteConfirmationDialog
        open={isAttemptingToDelete}
        handleClose={() => setIsAttemptingToDelete(false)}
        onConfirm={() => dispatchCallApi('deletePostComment', { postId, commentId })}
      />
      <div className={classes.root}>
        <Avatar
          className={classes.avatar}
          alt={attendeeFullName}
          src={attendeeInformation?.headshot?.small}
        >
          {attendeeInitials}
        </Avatar>
        <div className={classes.commentBody}>
          <div className={classes.messageInfo}>
            <Typography className={classes.attendeeName} variant="body2" color="textPrimary">
              {attendeeFullName}
            </Typography>
            <Typography className={classes.postDate} variant="caption" color="textSecondary">
              {baseMoment(createdAt).fromNow()}
            </Typography>
          </div>
          <Typography
            className={classes.message}
            variant="body1"
            color="textPrimary"
          >
            {message}
          </Typography>
        </div>
        <div className={classes.contextMenuContainer}>
          {isCommentOwner && <ContextMenu items={commentContextMenuItems} />}
        </div>
      </div>
    </>
  ) : null;
};

SocialComment.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.string,
    message: PropTypes.string,
    createdAt: PropTypes.string,
    attendee: CustomPropTypes.attendee,
  }),
  postId: PropTypes.string,
  currentAttendee: CustomPropTypes.attendee,
  attendees: PropTypes.objectOf(CustomPropTypes.attendee),
  attendeeTypeCustomFields: PropTypes.objectOf(CustomPropTypes.attendeeTypeCustomField),
  callApi: PropTypes.func.isRequired,
};

SocialComment.defaultProps = {
  comment: '',
  postId: '',
  currentAttendee: {},
  attendees: {},
  attendeeTypeCustomFields: {},
};

export default connect(null, { callApi })(SocialComment);
