import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Card from 'eventtia-ui-components/lib/Card';
import Message from '../../components/Message';
import useDidUpdate from '../../hooks/useDidUpdate';
import QuizForm from '../../components/QuizForm';
import BackButton from '../../components/BackButton';
import callApi from '../../actions/callApi';
import CustomPropTypes from '../../helpers/CustomPropTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 4),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  moduleName: {
    color: theme.palette.darkGrey.main,
    fontWeight: 'bold',
    padding: theme.spacing(1.5, 0),
    marginLeft: theme.spacing(2),
  },
  mobileModuleName: {
    width: '100%',
    textAlign: 'center',
  },
  surveyDescription: {
    marginBottom: theme.spacing(1.5),
    color: theme.palette.darkGrey.light,
  },
  tabletSurveyDescription: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
  },
  card: {
    margin: theme.spacing(1, 0, 0, 0),
    overflow: 'auto',
    width: '100%',
  },
}));

const QuizShow = ({
  quiz, isFetching, callApi: dispatchCallApi, backError, scrollToTop,
}) => {
  const classes = useStyles();
  const [message, setMessage] = useState({});
  const { t } = useTranslation(['global', 'quizzes']);
  const [activeQuestionId, setActiveQuestionId] = useState(null);
  const [questionSent, setQuestionSent] = useState(false);

  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.down('md'));
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchActiveQuestionId = async () => {
    const response = await dispatchCallApi('activeQuizQuestion', { id: quiz.id });
    setActiveQuestionId(response.response);
  };

  useEffect(() => {
    fetchActiveQuestionId();
    const interval = setInterval(fetchActiveQuestionId, 1000);
    return () => clearInterval(interval);
  }, [quiz.id]);

  useDidUpdate(() => {
    if (Array.isArray(backError) && backError.length >= 1) setMessage({ type: 'error', text: backError.join(', ') });
    if ((typeof backError) === 'string') setMessage({ type: 'error', text: backError });
  }, [backError]);

  useEffect(() => {
    if (!activeQuestionId) setMessage({ type: 'warning', text: t('quizzes:message.quizNotStarted') });
    else {
      setMessage({});
      setQuestionSent(false);
    }
  }, [activeQuestionId]);

  const onAnswerSurvey = ({ ...values }) => {
    scrollToTop();
    if (values[activeQuestionId]) {
      const data = new FormData();
      const answersData = {};
      answersData[activeQuestionId] = values[activeQuestionId];
      data.append('answers', JSON.stringify(answersData));
      data.append('survey_type', 1);
      dispatchCallApi('addSurveyAnswer', { id: quiz.id, data });
      setQuestionSent(true);
      setMessage({ type: 'success', text: t('quizzes:message.answerdSent') });
    } else setMessage({ type: 'error', text: t('quizzes:message.selectAnswer') });
  };
  const onClose = () => setMessage({});

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <BackButton />
        <Typography
          className={clsx(classes.moduleName, mobile && classes.mobileModuleName)}
          variant="subtitle1"
        >
          {quiz.name}
        </Typography>
      </div>
      {quiz.description && (
        <Typography
          className={clsx(classes.surveyDescription,
            tablet && classes.tabletSurveyDescription)}
          variant="body2"
        >
          {quiz.description}
        </Typography>
      )}
      <Message
        type={message.type}
        message={message.text}
        onClose={onClose}
      />
      {activeQuestionId && (
        <Card className={classes.card}>
          <QuizForm
            quiz={quiz}
            disabled={isFetching}
            onSubmit={onAnswerSurvey}
            activeQuestionId={activeQuestionId}
            questionSent={questionSent}
          />
        </Card>
      )}
    </div>
  );
};

QuizShow.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  quiz: CustomPropTypes.quiz,
  callApi: PropTypes.func.isRequired,
  backError: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  scrollToTop: PropTypes.func.isRequired,
};

QuizShow.defaultProps = {
  quiz: {},
  backError: undefined,
};

const mapStateToProps = ({
  entities: {
    customFields,
  },
  fetchStatus: {
    addSurveyAnswer: { isFetching, error: backError },
  },
}) => ({
  isFetching, customFields, backError,
});

export default connect(mapStateToProps, { callApi })(QuizShow);
