import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';
// import CalendarIcon from '../../assets/Agenda';
import DropdownArrow from '../../assets/DropdownArrow';
import { baseMoment } from '../../helpers/dates';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    minWidth: 240,
  },
  mobileRoot: {
    flex: 1,
    width: '100%',
    margin: theme.spacing(0, 0, 2, 0),
  },
  select: {
    textTransform: 'capitalize',
    color: theme.palette.common.black,
    fontFamily: 'Fancy Cut Pro',
    fontStyle: 'italic',
    fontSize: 20,
    '&:hover:not(.Mui-disabled):before': {
      borderBottomColor: theme.palette.darkGrey.main,
      borderBottomWidth: 1,
    },
    '&:after': {
      display: 'none !important',
    },
    '& .MuiSelect-icon': {
      marginRight: theme.spacing(0.5),
    },
  },
  icon: {
    color: theme.palette.common.black,
    transform: 'none',
  },
  menuItem: {
    textTransform: 'capitalize',
  },
}));

export const formatDay = (date, locale) => {
  let dateFormat;
  switch (locale) {
    case 'pt':
      dateFormat = 'dddd, Do [de] MMMM';
      break;
    case 'it':
      dateFormat = 'dddd, D MMMM';
      break;
    case 'ja':
      dateFormat = 'AAAA MMMM DDDD';
      break;
    case 'zh':
      dateFormat = 'AAAA MMMM DDDD';
      break;
    case 'en':
      dateFormat = 'dddd, MMMM Do';
      break;
    default:
      dateFormat = 'dddd, MMMM D';
  }
  return baseMoment(date).format(dateFormat);
};

const DayPicker = ({ onChange, options, day }) => {
  const classes = useStyles();
  const now = baseMoment();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { locale } = useParams();

  const getDefaultValue = useCallback(() => {
    let currentDay;
    let previousDay;
    let firstNextDay;
    options.forEach((date) => {
      if (baseMoment(date).isSame(now, 'day')) currentDay = date;
      else if (!previousDay) previousDay = date;
      else if (baseMoment(date).isAfter(previousDay)
        && baseMoment(date).isBefore(now)) previousDay = date;
      else if (!firstNextDay) firstNextDay = date;
      else if (baseMoment(date).isBefore(firstNextDay)
        && baseMoment(date).isAfter(now)) firstNextDay = date;
    });
    if (currentDay) return currentDay;
    if (previousDay) return previousDay;
    return firstNextDay;
  }, [options, now]);

  useEffect(() => {
    if (!day && options.length > 0) onChange(getDefaultValue());
  }, [day, getDefaultValue, onChange, options]);

  if (options.length === 0) return null;

  return (
    <div className={clsx(classes.root, mobile && classes.mobileRoot)}>
      <FormControl fullWidth>
        <Select
          value={day}
          onChange={({ target: { value } }) => {
            onChange(value);
          }}
          className={classes.select}
          classes={{
            icon: classes.icon,
            iconOpen: classes.icon,
          }}
          IconComponent={DropdownArrow}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              horizontal: 'left',
              vertical: 'bottom',
            },
            transformOrigin: {
              horizontal: 'left',
              vertical: 'top',
            },
          }}
        >
          {options.sort().map((date) => (
            <MenuItem
              value={date}
              key={date}
              className={classes.menuItem}
            >
              {formatDay(date, locale)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

DayPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  day: PropTypes.string,
};

DayPicker.defaultProps = {
  day: '',
};

export default DayPicker;
