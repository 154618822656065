import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SubpageTitle from '../../components/SubpageTitle';

const useStyles = makeStyles(() => ({
  container: {
    width: 785,
    maxWidth: 785,
  },
  mobileContainer: {
    width: '100%',
    '& iframe': {
      maxWidth: '100%',
    },
  },
}));

const hiddenTitles = ['The Venue', 'Confidentiality'];

const Custom = ({ name, content }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div className={clsx(classes.container, mobile && classes.mobileContainer)}>
      {!hiddenTitles.includes(name) && (
        <SubpageTitle text={name} />
      )}
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

Custom.propTypes = {
  content: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default Custom;
