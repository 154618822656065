import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from 'eventtia-ui-components/lib/Loader';
import callApi from '../actions/callApi';
import CustomPropTypes from '../helpers/CustomPropTypes';

const withInitialData = () => (WrappedComponent) => {
  const ComponentWithEventSettings = ({
    callApi: dispatchCallApi,
    events,
    appSettings,
    settingsSuccess,
    sponsorsSuccess,
    ...props
  }) => {
    const [currentEvent] = Object.values(events);
    const [settings] = Object.values(appSettings);
    const { enabled } = settings || {};
    const success = settingsSuccess && (sponsorsSuccess || !enabled);

    useEffect(() => {
      if (!settings) dispatchCallApi('settings');
    }, [dispatchCallApi, settings]);

    useEffect(() => {
      if (enabled && !sponsorsSuccess) dispatchCallApi('sponsors');
    }, [dispatchCallApi, enabled, sponsorsSuccess]);

    return (
      <>
        <Loader loading={!success} variant="absolute" />
        {success && (
          <WrappedComponent
            {...props}
            currentEvent={currentEvent}
            settings={settings}
          />
        )}
      </>
    );
  };

  ComponentWithEventSettings.propTypes = {
    events: PropTypes.objectOf(CustomPropTypes.event),
    appSettings: PropTypes.objectOf(CustomPropTypes.appSettings),
    sponsors: PropTypes.objectOf(CustomPropTypes.sponsor),
    settingsSuccess: PropTypes.bool.isRequired,
    sponsorsSuccess: PropTypes.bool.isRequired,
    callApi: PropTypes.func.isRequired,
  };

  ComponentWithEventSettings.defaultProps = {
    events: {},
    appSettings: {},
    sponsors: {},
  };

  const mapStateToProps = ({
    entities: { appSettings, events },
    fetchStatus: {
      settings: { success: settingsSuccess },
      sponsors: { success: sponsorsSuccess },
    },
  }) => ({
    appSettings,
    events,
    settingsSuccess,
    sponsorsSuccess,
  });

  return connect(mapStateToProps, { callApi })(ComponentWithEventSettings);
};

export default withInitialData;
