import React from 'react';
import clsx from 'clsx';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const StyledToggle = withStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 1),
    fontSize: 'inherit',
  },
  switchBase: {
    color: theme.palette.lightGrey.dark,
    '&$track': {
      color: theme.palette.secondary.main,
    },
    '&$checked': {
      color: theme.palette.secondary.main,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  error: {
    color: theme.palette.lightGrey.dark,
    '&$track': {
      color: theme.palette.red.main,
    },
    '&$checked': {
      color: theme.palette.red.main,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.red.main,
    },
  },
  success: {
    color: theme.palette.lightGrey.dark,
    '&$track': {
      color: theme.palette.green.main,
    },
    '&$checked': {
      color: theme.palette.green.main,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.green.main,
    },
  },
  checked: {},
  track: {},
}))(({
  error, success, classes, disabled, ...other
}) => (
  <Switch
    classes={{
      root: classes.root,
      switchBase: clsx(classes.switchBase,
        !disabled && success && classes.success,
        !disabled && error && classes.error),
      checked: classes.checked,
      track: classes.track,
    }}
    disabled={disabled}
    {...other}
  />
));

const StyledCheckbox = withStyles((theme) => ({
  root: {
    padding: 0,
    margin: theme.spacing(0, 1),
    fontSize: 'inherit',
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  error: {
    color: theme.palette.red.main,
    '&$checked': {
      color: theme.palette.red.main,
    },
  },
  success: {
    color: theme.palette.green.main,
    '&$checked': {
      color: theme.palette.green.main,
    },
  },
  checked: {},
}))(({
  error, success, classes, disabled, ...other
}) => (
  <Checkbox
    classes={{
      root: clsx(classes.root,
        !disabled && success && classes.success,
        !disabled && error && classes.error),
      checked: classes.checked,
    }}
    disabled={disabled}
    {...other}
  />
));

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  formControlLabel: {
    margin: 0,
    paddingLeft: theme.spacing(4),
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.lightGrey.main,
    marginTop: theme.spacing(1),
  },
  label: {
    fontSize: 18,
    fontFamily: 'Brilliant Cut Pro',
  },
}));

const StyledLabel = (label) => {
  const classes = useStyles();
  return (
    <Typography className={classes.label}>
      {label}
    </Typography>
  );
};

const Control = (props) => {
  const {
    handleChange, error, success, options, value, defaultValue,
    row, controlType, name, disabled: disabledForm, inputRef,
  } = props;
  const classes = useStyles();
  const onChange = (event) => {
    const selectedValue = event.target.value;
    const { checked } = event.target;
    let newValues;
    if (checked) newValues = [...value, selectedValue];
    else {
      newValues = [...value];
      const index = newValues.indexOf(selectedValue);
      if (index !== -1) newValues.splice(index, 1);
    }
    if (handleChange) handleChange(newValues);
  };
  const ControlComponent = controlType === 'toggle' ? StyledToggle : StyledCheckbox;
  return (
    <FormControl className={classes.formControl}>
      <FormGroup ref={inputRef} name={name} row={row}>
        {options && options.map(({
          value: optionValue, disabled, label,
        }, k) => (
          <div key={optionValue} className={classes.controlContainer}>
            <FormControlLabel
              id={k}
              value={optionValue}
              label={StyledLabel(label)}
              labelPlacement="start"
              disabled={disabledForm || disabled}
              checked={value ? value.includes(optionValue) : undefined}
              className={clsx(classes.formControlLabel)}
              control={(
                <ControlComponent
                  error={error}
                  success={success}
                  onChange={handleChange ? onChange : undefined}
                  defaultChecked={defaultValue ? defaultValue.includes(optionValue) : undefined}
                />
              )}
            />
          </div>
        ))}
      </FormGroup>
    </FormControl>
  );
};

Control.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  name: PropTypes.string,
  success: PropTypes.bool,
  error: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  defaultValue: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  row: PropTypes.bool,
  controlType: PropTypes.string,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    details: PropTypes.string,
    disabled: PropTypes.bool,
  })).isRequired,
};

Control.defaultProps = {
  inputRef: undefined,
  defaultValue: undefined,
  value: undefined,
  handleChange: undefined,
  disabled: undefined,
  controlType: undefined,
  name: undefined,
  success: false,
  error: false,
  row: undefined,
};

export default Control;
