import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from 'eventtia-ui-components/lib/Card';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Message from '../../components/Message';
import callApi from '../../actions/callApi';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import SurveyForm from '../../components/SurveyForm';
import HackedMeetingEvaluationForm from '../../components/HackedMeetingEvaluationForm';

import dataToBlob from '../../helpers/files';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.darkGrey.main,
  },
  card: {
    margin: theme.spacing(1, 0, 0, 0),
    overflow: 'auto',
    width: '100%',
  },
  message: {
    marginTop: theme.spacing(4),
  },
}));

const MeetingEvaluation = ({
  survey, callApi: dispatchCallApi, customFields, closeEvaluation,
  meetingId, isFetching, backError, scrollToTop,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['global', 'meeting']);
  const [message, setMessage] = useState();
  const [sendingAnswer, setSendingAnswer] = useState(false);
  const [customErrors, setCustomErrors] = useState([]);
  const [success, setSuccess] = useState(false);

  const getRequiredFieldErrorTemplate = (prefix, stringKey = '') => ({
    type: 'manual',
    name: `${prefix}${stringKey ? '.' : ''}${stringKey}`,
    message: t('formError.requiredField'),
  });

  useEffect(() => {
    if (Array.isArray(backError) && backError.length >= 1) setMessage(backError.join(', '));
    if ((typeof backError) === 'string') setMessage(backError);
  }, [backError]);

  const onAnswerSurvey = ({ signature, visibleFields, ...fields }) => {
    const errors = [];
    const data = new FormData();
    const answersData = {};

    if (survey?.enabledSignature && !signature) errors
      .push(getRequiredFieldErrorTemplate('signature'));

    else if (survey.enabledSignature) data.append('signature',
      dataToBlob(signature, 'image/png'), 'signature.png');

    visibleFields.split('-').forEach((key) => {
      const stringKey = `_${key}`;
      const field = customFields[key];
      const fieldValue = fields[stringKey];
      const isEmpty = (!fieldValue && fieldValue !== 0)
        || (Array.isArray(fieldValue) && !fieldValue.length);
      if (field?.optionsStatus?.required && isEmpty) errors
        .push(getRequiredFieldErrorTemplate('answers', stringKey));
      else if (!isEmpty) answersData[key] = fieldValue;
    });

    setCustomErrors(errors);
    if (errors.length) scrollToTop();
    if (!errors.length && !sendingAnswer) {
      data.append('answers', JSON.stringify(answersData));
      data.append('meeting_id', meetingId);
      data.append('survey_type', 2);
      setSendingAnswer(true);
      dispatchCallApi('addSurveyAnswer', { id: survey.id, data }).then((res) => {
        if (!res.error) setSuccess(true);
        else scrollToTop();
        setSendingAnswer(false);
      });
    }
  };
  const hackedSurveyIds = [
    '6708', '6714', '6752', '6751', '6754', '6855', '6893', '6884', '6912',
    '6907', '6885', '7124', '7206', '7288', '7289', '7292', '7341', '7342', '7612', // '7665'
  ];
  const isHackedSurvey = hackedSurveyIds.includes(survey.id);

  const MeetingEvaluationComponent = isHackedSurvey
    ? HackedMeetingEvaluationForm : SurveyForm;

  const closeMessage = () => setMessage('');
  return success ? (
    <>
      {closeEvaluation && (
        <Message
          type="success"
          message={t('meeting:meeting.evaluationSuccess')}
          onClose={closeEvaluation}
          className={classes.message}
        />
      )}
    </>
  ) : (
    <div>
      <Message
        type="error"
        title={t('forms.error')}
        message={message}
        onClose={closeMessage}
      />
      <Card className={classes.card}>
        <MeetingEvaluationComponent
          disabled={isFetching}
          customErrors={customErrors}
          survey={survey}
          onSubmit={onAnswerSurvey}
        />
      </Card>
    </div>
  );
};

MeetingEvaluation.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  // events: PropTypes.objectOf(CustomPropTypes.event).isRequired,
  meetingId: PropTypes.string.isRequired,
  closeEvaluation: PropTypes.func,
  survey: CustomPropTypes.survey,
  customFields: PropTypes.objectOf(
    CustomPropTypes.customField
  ),
  callApi: PropTypes.func.isRequired,
  backError: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  scrollToTop: PropTypes.func.isRequired,
};

MeetingEvaluation.defaultProps = {
  survey: undefined,
  customFields: {},
  closeEvaluation: undefined,
  backError: undefined,
};

const mapStateToProps = ({
  entities: {
    customFields,
    events,
  },
  fetchStatus: {
    addSurveyAnswer: { isFetching, error: backError },
  },
}) => ({
  isFetching,
  customFields,
  backError,
  events,
});

export default connect(mapStateToProps, { callApi })(MeetingEvaluation);
