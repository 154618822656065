import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Venue = (props) => (
  <SvgIcon {...props} viewBox="0 0 21 21">
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9074 5.27397C21 5.17808 21 5.08219 21 4.89041L20.4448 0.479452C20.2598 0.0958904 20.1674 0 19.9823 0H0.925107C0.832596 0 0.647576 0.0958904 0.462554 0.383562L0 4.89041C0 4.9863 -6.89259e-09 5.08219 0.0925108 5.17808V5.27397C0.185022 5.36986 0.370043 5.36986 0.462554 5.36986H0.925107V20.5205C0.925107 20.8082 1.20264 21 1.38766 21H19.5197C19.7973 21 19.9823 20.8082 19.9823 20.5205V5.46575H20.4448C20.5374 5.46575 20.8149 5.46575 20.9074 5.27397ZM10.4538 6.0411C7.6784 6.0411 5.36563 8.43836 5.36563 11.3151V20.137H1.85022V5.46575H19.0572V20.137H15.5419V11.3151C15.5419 8.34247 13.2291 6.0411 10.4538 6.0411ZM9.99121 11.411V20.137H6.29073V11.411H9.99121ZM6.47576 10.4521C6.66078 9.58904 6.93831 8.91781 7.40086 8.43836L9.34364 10.4521H6.47576ZM9.99121 7V9.78082L8.04844 7.76712C8.69602 7.38356 9.34364 7.09589 9.99121 7ZM14.6168 11.3151V20.137H10.9163V11.411H14.6168V11.3151ZM11.6564 10.4521L13.5991 8.43836C14.0617 9.0137 14.3392 9.68493 14.5242 10.4521H11.6564ZM10.9163 9.87671V7C11.6564 7.09589 12.304 7.38356 12.859 7.86301L10.9163 9.87671ZM19.8899 4.50685H1.01762L1.38766 0.958904H19.5197L19.8899 4.50685Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);

export default Venue;
