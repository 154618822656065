import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { goToPreviousSubpage } from '../../actions/app';
import ArrowLeft from '../../assets/arrowLeft.svg';

const BackButton = ({
  className, onClick, goToPreviousSubpage: dispatchGoToPreviousSubpage,
}) => {
  const goBack = () => {
    if (typeof onClick === 'function') onClick();
    dispatchGoToPreviousSubpage();
  };

  return (
    <IconButton
      className={className}
      onClick={goBack}
      color="primary"
    >
      <img src={ArrowLeft} alt="go back" />
    </IconButton>
  );
};

BackButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  goToPreviousSubpage: PropTypes.func.isRequired,
};

BackButton.defaultProps = {
  className: undefined,
  onClick: undefined,
};

export default connect(null, { goToPreviousSubpage })(BackButton);
