import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import Grow from '@material-ui/core/Grow';
import MuiSnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import CloseIcon from '@material-ui/icons/Close';
import usePrevious from '../../hooks/usePrevious';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    borderRadius: 8,
    padding: theme.spacing(1),
  },
  success: {
    backgroundColor: '#ba9b53;',
    color: theme.palette.lightGrey.light,
    '& .MuiSvgIcon-root': {
      color: theme.palette.lightGrey.main,
    },
  },
  error: {
    color: theme.palette.red.dark,
    backgroundColor: theme.palette.red.light,
    '& .MuiSvgIcon-root': {
      color: theme.palette.red.main,
    },
  },
  warning: {
    color: theme.palette.yellow.dark,
    backgroundColor: theme.palette.yellow.light,
    '& .MuiSvgIcon-root': {
      color: theme.palette.yellow.main,
    },
  },
  action: {
    margin: 0,
    padding: 0,
  },
  closeButton: {
    padding: theme.spacing(0.8),
    color: 'white',
    zIndex: 9,
  },
  icon: {
    margin: theme.spacing(0, 2, 0, 1),
    color: 'white',
  },
  messageContainer: {
    padding: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    wordBreak: 'break-word',
  },
  title: {
    fontWeight: 'bold',
    margin: 0,
  },
  message: {
    margin: 0,
  },
  bold: {
    fontWeight: 'bold',
  },
}));

const Message = ({
  className,
  onClose,
  title,
  message,
  action,
  type,
}) => {
  const classes = useStyles();
  const { transitions: { duration } } = useTheme();
  const prevMessage = usePrevious(message);
  const getIcon = (iconType) => {
    switch (iconType) {
      case 'success':
        return <CheckCircleIcon className={classes.icon} />;
      case 'error':
        return <ErrorIcon className={classes.icon} />;
      case 'warning':
        return <WarningIcon className={classes.icon} />;
      default:
        return null;
    }
  };
  return (
    <>
      <Grow
        in={!!message}
        timeout={{ enter: duration.enteringScreen, exit: duration.leavingScreen }}
        mountOnEnter
        unmountOnExit
      >
        <MuiSnackbarContent
          className={clsx(classes.root, classes[type], className)}
          classes={{ message: classes.messageContainer, action: classes.action }}
          message={(
            <div className={classes.messageContainer}>
              {getIcon(type)}
              <div>
                {title && (
                <h4 className={classes.title}>{title}</h4>
                )}
                {message || prevMessage || ''}
              </div>
            </div>
          )}
          action={action ? (
            <Button key="undo" color="inherit" size="small" onClick={action.onClick}>
              {action.text}
            </Button>
          ) : (
            <IconButton key="close" aria-label="close" color="white" onClick={onClose} className={classes.closeButton}>
              <CloseIcon />
            </IconButton>
          )}
        />
      </Grow>
    </>
  );
};

Message.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  action: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func,
  }),
  type: PropTypes.oneOf(['error', 'success', 'warning']),
};

Message.defaultProps = {
  className: undefined,
  onClose: undefined,
  title: undefined,
  message: undefined,
  action: null,
  type: undefined,
};

export default Message;
