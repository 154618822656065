import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import useTimer from '../../hooks/useTimer';
import { baseMoment } from '../../helpers/dates';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    minWidth: '100%',
    [theme.breakpoints.up('md')]: {
      minWidth: 624,
    },
  },
  countdownTitle: {
    fontWeight: 'bold',
    fontStyle: 'italic',
    fontSize: 16,
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(-1),
      fontSize: 20,
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(-3),
      fontSize: 30,
    },
  },
  timeUntilEvent: {
    color: theme.palette.primary.main,
    fontSize: 46,
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: 54,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 110,
    },
  },
  number: {
    flex: 1,
  },
  separator: {
    flex: 0.2,
  },
  units: {
    color: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: theme.spacing(-2),
    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 26,
    },
  },
  unit: {
    flex: 1,
  },
}));

const prependZero = (number) => (
  (number < 10) ? `0${number}` : number
);

const Countdown = ({ events, hideSubtitle }) => {
  const classes = useStyles();

  const [timeUntilEvent, setTimeUntilEvent] = useState('');
  const [{ startDate: eventStartDate }] = Object.values(events);

  const onTick = useCallback((now, start, end) => {
    setTimeUntilEvent(end.diff(now));
  }, [setTimeUntilEvent, eventStartDate]);
  useTimer({ onTick, endDate: eventStartDate, step: 10000 });

  if (timeUntilEvent <= 0) return null;

  const days = baseMoment(eventStartDate).diff(baseMoment(), 'days');
  const duration = baseMoment.duration(timeUntilEvent);
  const hours = duration.hours();
  const minutes = duration.minutes();

  return (
    <div className={classes.root}>
      {!hideSubtitle && (
        <Typography
          variant="subtitle1"
          className={classes.countdownTitle}
        >
          See you there in
        </Typography>
      )}
      <Typography
        className={classes.timeUntilEvent}
      >
        <span className={classes.number}>{prependZero(days)}</span>
        <span className={classes.separator}>:</span>
        <span className={classes.number}>{prependZero(hours)}</span>
        <span className={classes.separator}>:</span>
        <span className={classes.number}>{prependZero(minutes)}</span>
      </Typography>
      <Typography
        className={classes.units}
      >
        <span className={classes.unit}>DAYS</span>
        <span className={classes.separator}>&nbsp;</span>
        <span className={classes.unit}>HOURS</span>
        <span className={classes.separator}>&nbsp;</span>
        <span className={classes.unit}>MIN</span>
      </Typography>
    </div>
  );
};

Countdown.propTypes = {
  events: PropTypes.objectOf(
    CustomPropTypes.event
  ).isRequired,
  hideSubtitle: PropTypes.bool,
};

Countdown.defaultProps = {
  hideSubtitle: false,
};

const mapStateToProps = ({
  entities: {
    events,
  },
}) => ({
  events,
});

export default connect(mapStateToProps)(Countdown);
