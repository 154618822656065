export default {
  wm: {
    enableFrom: '2024-01-01',
    cover: 'https://cdn.eventtia.com/event_files/189965/original/001grouppicturewm2023marrakech17168382301716838230.jpg?1716838229',
    categoryId: '92665',
    title: 'WM',
  },
  jun24: {
    enableFrom: '2024-06-25',
    cover: 'https://cdn.eventtia.com/event_files/189967/original/181wmmonday2023marrakech17168384211716838421.jpg?1716838420',
    categoryId: '92666',
    title: 'Mon 24 June',
  },
  jun25: {
    enableFrom: '2024-06-26',
    cover: 'https://cdn.eventtia.com/event_files/187209/original/jun2117135677671713567767.jpg?1713567767',
    categoryId: '92667',
    title: 'Tue 25 June',
  },
  jun26: {
    enableFrom: '2024-06-27',
    cover: 'https://cdn.eventtia.com/event_files/187210/original/jun2217135677841713567784.jpg?1713567784',
    categoryId: '92668',
    title: 'Wed 26 June',
  },
  jun27: {
    enableFrom: '2024-06-28',
    cover: 'https://cdn.eventtia.com/event_files/189966/original/028bestofwednesdaywm2023marrakech17168384091716838409.jpg?1716838409',
    categoryId: '92669',
    title: 'Thu 27 June',
  },
  jun28: {
    enableFrom: '2024-06-29',
    cover: 'https://cdn.eventtia.com/event_files/187208/original/jun2017135677481713567748.jpg?1713567748',
    categoryId: '92670',
    title: 'Fri 28 June',
  },
};
