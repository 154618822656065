import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Button from 'eventtia-ui-components/lib/Button';
import Helper from 'eventtia-ui-components/lib/Helper';
import Chip from 'eventtia-ui-components/lib/Chip';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Message from '../../components/Message';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import Agenda from '../../components/Agenda';
import CustomFieldValue from '../../components/CustomFieldValue';
import BackButton from '../../components/BackButton';
import ParticipantRating from '../../components/ParticipantRating';
import DenyParticipant from '../../components/DenyParticipant';
import SponsorCardAlt from '../../components/SponsorCard/SponsorsCardAlt';
import RequestContactInfo from '../../components/RequestContactInfo';
import callApi from '../../actions/callApi';
import { addAttendeeLead } from '../../actions/attendeeLeads';
import { addChannel, NEW_CHAT_REQUEST, NEW_MESSAGE } from '../../actions/messages';
import { setActiveSponsor, setActiveChannel, setActiveLead } from '../../actions/app';
import { showInEventTimezone } from '../../helpers/dates';
import { buildPrivateTopic, canChat } from '../../helpers/chat';
import emailRegexp from '../../helpers/strings';
import getMeetingVisibility from '../../helpers/meetings';
import useStringTransforms from '../../hooks/useStringTransforms';
import { getEntity, getSponsorByAttendee, getModuleByType } from '../../helpers/getters';
import moduleEnabledForCurrentAttendeeType from '../../helpers/moduleEnabledForCurrentAttendeeType';
import AttendeeBroadcastContext from '../../contexts/AttendeeBroadcastContext';
import { ATTENDANCE_MODE } from '../../helpers/constants';
import { setViewedAttendee } from '../../actions/viewedAttendees';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(4),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  moduleName: {
    color: theme.palette.darkGrey.main,
    fontWeight: 'bold',
  },
  mobileModuleName: {
    width: '100%',
    textAlign: 'center',
  },
  profile: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  desktopProfile: {
    flexWrap: 'nowrap',
  },
  tabletProfile: {
    flexWrap: 'nowrap',
    flexDirection: 'column',
  },
  profileHeader: {
    alignItems: 'flex-start',
    margin: theme.spacing(6, 0),
    display: 'flex',
    width: '100%',
  },
  profileData: {
    flex: 1,
    textAlign: 'justify',
  },
  schedule: {
    marginLeft: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: theme.spacing(3),
    },
  },
  avatar: {
    height: 70,
    width: 70,
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.lightGrey.main,
    color: theme.palette.primary.main,
  },
  name: {
    fontSize: 16,
    color: theme.palette.darkGrey.dark,
    fontWeight: 'bold',
    textAlign: 'start',
  },
  headerInfo: {
    color: theme.palette.darkGrey.main,
    fontSize: 14,
  },
  dataTitle: {
    opacity: '0.6',
    marginBottom: 12,
    fontSize: 12,
    color: theme.palette.darkGrey.light,
  },
  ratingTitle: {
    '& button': {
      color: theme.palette.darkGrey.dark,
      marginLeft: theme.spacing(1),
    },
  },
  anchor: {
    marginBottom: theme.spacing(3),
    fontSize: 14,
    color: theme.palette.darkGrey.main,
    display: 'block',
    width: 250,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  imgData: {
    width: 150,
  },
  data: {
    marginBottom: theme.spacing(3),
    fontSize: 14,
    color: theme.palette.darkGrey.main,
    display: 'block',
  },
  rating: {
    display: 'flex',
    alignItems: 'center',
  },
  ratingDivider: {
    color: theme.palette.lightGrey.dark,
    margin: theme.spacing(0, 2),
  },
  actionsContainer: {
    margin: theme.spacing(0, -2),
  },
  actionButton: {
    width: 'auto',
    margin: theme.spacing(1, 2),
  },
  message: {
    position: 'absolute',
    left: 52,
    right: 52,
    zIndex: 2000,
    whiteSpace: 'pre-wrap',
  },
  title: {
    height: 'auto',
    width: 263,
    display: 'flex',
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    boxShadow: theme.customShadows.small,
    alignItems: 'center',
  },
  pipe: {
    color: theme.palette.lightGrey.dark,
    margin: theme.spacing(0, 4, 0, 0),
    fontSize: theme.spacing(4),
    lineHeight: '1em',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 2),
    },
  },
  logo: {
    maxHeight: '60%',
    width: '35%',
    objectFit: 'contain',
  },
  defaultLogo: {
    marginRight: theme.spacing(2.5),
    width: 35,
    height: 35,
    color: theme.palette.primary.main,
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  peopleInfoGrid: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
  },
  sponsorBannerWrapper: {
    maxWidth: 280,
  },
  attendanceModeChip: {
    fontSize: 12,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0),
    backgroundColor: fade(theme.palette.primary.main, 0.05),
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}));

const PeopleShow = ({
  currentAttendee, currentParticipant, person, attendeeTypeCustomFields,
  callApi: dispatchCallApi, participants, addChannel: dispatchAddChannel,
  setActiveChannel: dispatchSetActiveChannel, appSettings, openChannels,
  businessConferenceParticipantTypes: participantTypes,
  entities, sponsors, setActiveSponsor: dispatchSetActiveSponsor,
  fetchedCurrentAttendee, scrollToTop, events, broadcasts,
  setViewedAttendee: dispatchSetViewedAttendee,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['attendees', 'meeting']);
  const { toTitleCase, toSentenceCase } = useStringTransforms();
  const attendeeBroadcast = useContext(AttendeeBroadcastContext);
  const [message, setMessage] = useState();
  const [availabilityMessage, setAvailabilityMessage] = useState();
  const [contactOrganizerMessage, setContactOrganizerMessage] = useState();
  const [requestContactDialog, setRequestContactDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tablet = useMediaQuery(theme.breakpoints.down('md'));

  const [settings] = Object.values(appSettings);

  const { canScheduleMeetings: selfCanScheduleMeetings } = currentAttendee;
  const {
    id,
    attendeeTypeId,
    participantId,
    attendeeTypeName,
    attendanceMode,
    firstName,
    lastName,
    headshot,
    company,
    position,
    email,
    cityId,
    telephone,
    fields,
    aliases,
    canScheduleMeetings: otherCanScheduleMeetings,
  } = person;
  const canScheduleMeetings = selfCanScheduleMeetings && otherCanScheduleMeetings;
  const fullName = `${firstName} ${lastName}`;

  const pipe = position && company ? ' | ' : '';
  const job = (position || company) ? `${toTitleCase(company, true)}${pipe}${toTitleCase(position, true)}` : '';

  const sponsor = getSponsorByAttendee(sponsors, id) || {};

  const participant = participants?.[participantId];
  const conference = getEntity(entities, 'businessConferences');
  const businessConferenceId = participant?.businessConference?.id
    || conference?.id;

  const currentParticipantTypeId = currentParticipant?.participantTypeId;
  const currentParticipantType = participantTypes[currentParticipantTypeId];
  const canRequestMeetingTo = currentParticipantType?.canRequestMeetingToAttendeeTypes;
  const offsetToUtc = !showInEventTimezone(settings);

  useEffect(() => {
    if (canScheduleMeetings && businessConferenceId) dispatchCallApi('participant', {
      businessConferenceId, participantId, offsetToUtc,
    });
  }, [
    canScheduleMeetings, dispatchCallApi, businessConferenceId,
    participantId, offsetToUtc,
  ]);

  const leadsModule = Object.values(settings.modules).find((mod) => mod.type === 'AttendeeLeads');
  const canIChat = canChat(settings, currentAttendee.attendeeType.id);
  const canTheOtherOneChat = canChat(settings, attendeeTypeId);

  const otherHasLeads = leadsModule.customParams.availableForAttendeeTypes
  ?.includes(attendeeTypeId);

  const showLeadsOptions = moduleEnabledForCurrentAttendeeType(entities, 'AttendeeLeads');

  useEffect(() => {
    if (!canTheOtherOneChat && canIChat) setAvailabilityMessage({
      title: t('info.privateChatDisabled'),
      text: t('info.attendeeChatDisabled'),
    });
  }, [setAvailabilityMessage, canTheOtherOneChat, canIChat]);

  useEffect(() => {
    if (!!businessConferenceId && !selfCanScheduleMeetings) setContactOrganizerMessage(t('info.contactOrganizer'));
  }, [setContactOrganizerMessage, selfCanScheduleMeetings, businessConferenceId]);

  useEffect(() => {
    if (!participantId && conference) setAvailabilityMessage({
      title: t('attendees:info.networkingDisabled'),
      text: t('attendees:info.attendeeNetworkingDisabled'),
    });
    else if (
      fetchedCurrentAttendee && currentParticipant
      && !canRequestMeetingTo.includes(Number(attendeeTypeId))
    ) setAvailabilityMessage({
      title: t('attendees:info.networkingDisabled'),
      text: t('attendees:info.currentAttendeeCantRequestMeetingTo'),
    });
  }, [participantId, currentParticipant, setAvailabilityMessage, attendeeTypeId]);

  const handleSponsorCard = () => {
    dispatchSetActiveSponsor(sponsor.id);
  };

  const topic = buildPrivateTopic(id, currentAttendee.id);
  const broadcast = broadcasts[topic];
  const isChatOpen = openChannels.some(({ topic: openTopic }) => openTopic === topic);
  const startChat = (redirect) => {
    if (!isChatOpen) {
      attendeeBroadcast(NEW_CHAT_REQUEST, { attendee_id: id });
      dispatchAddChannel({
        type: 'private',
        topic,
        name: fullName,
        avatar: headshot?.filename && headshot.small,
      });
    }
    if (redirect) dispatchSetActiveChannel(topic);
  };

  const requestContactInfo = (event) => {
    startChat();
    setAnchorEl(event.currentTarget);
    setRequestContactDialog(!requestContactDialog);
  };

  const requestDetails = (msg) => {
    if (broadcast) {
      broadcast(NEW_MESSAGE, { body: msg });
      dispatchSetActiveChannel(topic);
    }
  };

  const { inSchedulingTime } = getMeetingVisibility(entities);

  const stringAttendanceMode = `attendanceMode.${attendanceMode}`;
  const [{ attendanceMode: eventAttendanceMode }] = Object.values(events);

  const canAddLeads = getModuleByType(settings.modules, 'AttendeeLeads')?.enabled;

  const { HYBRID } = ATTENDANCE_MODE;

  useEffect(() => {
    dispatchSetViewedAttendee(id);
  }, [currentAttendee.id, dispatchSetViewedAttendee, id]);

  return (
    <div className={classes.root}>
      <Message
        className={classes.message}
        type="warning"
        title={availabilityMessage?.title}
        message={availabilityMessage?.text}
        onClose={() => setAvailabilityMessage()}
      />
      <Message
        className={classes.message}
        type={message?.type}
        title={
          message?.type === 'error' ? t('meeting:meeting.error') : t('meeting:meeting.requestSent')
        }
        message={message?.text}
        onClose={() => setMessage()}
      />
      <Message
        className={classes.message}
        type="warning"
        message={contactOrganizerMessage}
        onClose={() => setContactOrganizerMessage()}
      />
      <div className={classes.header}>
        <BackButton
          className={classes.backButton}
        />
        <Typography
          className={clsx(classes.moduleName, mobile && classes.mobileModuleName)}
          variant="subtitle1"
        >
          {t('title.profile', { name: toTitleCase(firstName) })}
        </Typography>
      </div>
      <div className={clsx(classes.profile,
        !tablet && classes.desktopProfile,
        tablet && classes.tabletProfile)}
      >
        <div className={classes.profileData}>
          <div className={classes.profileHeader}>
            <Avatar
              src={headshot?.filename && headshot.small}
              className={classes.avatar}
            />
            <div>
              <Typography className={classes.name} variant="subtitle1">
                {toTitleCase(fullName)}
              </Typography>
              {job && (
                <Typography className={classes.headerInfo} variant="body2">
                  {job}
                </Typography>
              )}
              <Typography className={classes.headerInfo} variant="subtitle1">
                {toTitleCase(attendeeTypeName, true)}
              </Typography>
              {eventAttendanceMode === HYBRID && attendanceMode && (
                <Chip
                  label={t(`global:${stringAttendanceMode}`)}
                  color="secondary"
                  size="small"
                  className={classes.attendanceModeChip}
                />
              )}
            </div>
          </div>
          {canScheduleMeetings && conference?.automaticScheduling && inSchedulingTime && (
            <>
              <Typography className={clsx(classes.dataTitle, classes.ratingTitle)} variant="body2">
                {t('data.rating')}
                <Helper message={t('info.ratingHelp')} small inline />
              </Typography>
              <Typography className={clsx(classes.data, classes.rating)} component="div">
                <ParticipantRating
                  participant={participant}
                  setMessage={setMessage}
                />
                {conference.blacklistEnabled && (
                  <>
                    <span className={classes.ratingDivider}>|</span>
                    <DenyParticipant
                      participant={participant}
                      setMessage={setMessage}
                    />
                  </>
                )}
              </Typography>
            </>
          )}
          {Object.keys(sponsor).length > 0 && (
            <>
              <Typography className={classes.dataTitle} variant="body2">
                {t('attendees:title.associatedWith')}
              </Typography>
              <Grid className={classes.sponsorBannerWrapper}>
                <SponsorCardAlt
                  sponsor={sponsor}
                  onBlockClick={handleSponsorCard}
                />
              </Grid>
            </>
          )}
          <div className={clsx((tablet && !mobile) && classes.peopleInfoGrid)}>
            {cityId?.country && (
              <div>
                <Typography className={classes.dataTitle} variant="body2">
                  {t('data.country')}
                </Typography>
                <Typography className={classes.data} variant="subtitle1">
                  {cityId.country}
                </Typography>
              </div>
            )}
            {cityId?.region && (
              <div>
                <Typography className={classes.dataTitle} variant="body2">
                  {t('data.region')}
                </Typography>
                <Typography className={classes.data} variant="subtitle1">
                  {cityId.region}
                </Typography>
              </div>
            )}
            {cityId?.city && (
              <div>
                <Typography className={classes.dataTitle} variant="body2">
                  {aliases.cityId || t('data.city')}
                </Typography>
                <Typography className={classes.data} variant="subtitle1">
                  {cityId.city}
                </Typography>
              </div>
            )}
            {email && (
              <div>
                <Typography className={classes.dataTitle} variant="body2">
                  {aliases.email || t('data.email')}
                </Typography>
                <Typography className={classes.data} variant="subtitle1">
                  {emailRegexp.test(email) ? (
                    <a href={`mailto:${email}`}>{email}</a>
                  ) : email}
                </Typography>
              </div>
            )}
            {telephone && (
              <div>
                <Typography className={classes.dataTitle} variant="body2">
                  {aliases.telephone || t('data.phone')}
                </Typography>
                <Typography className={classes.data} variant="subtitle1">
                  {telephone}
                </Typography>
              </div>
            )}
            {Object.values(attendeeTypeCustomFields)
              .sort((a, b) => (a.order - b.order))
              .map(({ id: fieldKey }) => (
                fields[fieldKey]
                && attendeeTypeCustomFields[fieldKey]
                && !(attendeeTypeCustomFields[fieldKey].default) ? (
                  <div key={fieldKey}>
                    <Typography className={classes.dataTitle} variant="body2">
                      {toSentenceCase(attendeeTypeCustomFields[fieldKey].name)}
                    </Typography>
                    <CustomFieldValue
                      field={attendeeTypeCustomFields[fieldKey]}
                      value={fields[fieldKey]}
                      classes={{
                        anchorValue: classes.anchor,
                        value: classes.data,
                        img: classes.imgData,
                      }}
                    />
                  </div>
                  ) : null
              ))}
          </div>
          <div className={classes.actionsContainer}>
            {canIChat && canTheOtherOneChat && (
              <Button
                fullWidth
                onClick={() => startChat(true)}
                className={classes.actionButton}
                disabled={!canTheOtherOneChat}
              >
                {t(isChatOpen ? 'actions.goToChat' : 'actions.startChat')}
              </Button>
            )}
            {canIChat && canAddLeads && showLeadsOptions && otherHasLeads && (
              <Button
                variant="secondary"
                onClick={requestContactInfo}
                className={classes.actionButton}
              >
                {t('actions.requestContactInfo')}
              </Button>
            )}
            <RequestContactInfo
              isChatOpen={isChatOpen}
              closeDialog={requestContactInfo}
              action={requestDetails}
              open={requestContactDialog}
              anchorEl={anchorEl}
            />
          </div>
        </div>
        {canScheduleMeetings && (
          <div className={classes.schedule}>
            <Agenda
              setMessage={setMessage}
              openParticipantId={participantId}
              currentAttendee={currentAttendee}
              currentParticipant={currentParticipant}
              openAttendeeTypeId={attendeeTypeId}
              scrollToTop={scrollToTop}
            />
          </div>
        )}
      </div>
    </div>
  );
};

PeopleShow.propTypes = {
  entities: CustomPropTypes.entities.isRequired,
  sponsors: PropTypes.objectOf(
    CustomPropTypes.sponsor
  ),
  currentAttendee: CustomPropTypes.attendee.isRequired,
  currentParticipant: CustomPropTypes.participant,
  businessConferenceParticipantTypes: PropTypes.objectOf(
    CustomPropTypes.participantType
  ),
  person: CustomPropTypes.person.isRequired,
  attendeeTypeCustomFields: PropTypes.objectOf(
    CustomPropTypes.attendeeTypeCustomField
  ),
  appSettings: PropTypes.objectOf(
    CustomPropTypes.appSettings
  ).isRequired,
  callApi: PropTypes.func.isRequired,
  participants: PropTypes.objectOf(CustomPropTypes.participant),
  addChannel: PropTypes.func.isRequired,
  setActiveChannel: PropTypes.func.isRequired,
  setActiveSponsor: PropTypes.func.isRequired,
  openChannels: PropTypes.arrayOf(CustomPropTypes.channel).isRequired,
  fetchedCurrentAttendee: PropTypes.bool.isRequired,
  scrollToTop: PropTypes.func.isRequired,
  events: PropTypes.objectOf(
    CustomPropTypes.event
  ).isRequired,
  broadcasts: PropTypes.objectOf(PropTypes.func).isRequired,
  setViewedAttendee: PropTypes.func.isRequired,
};

PeopleShow.defaultProps = {
  sponsors: {},
  participants: {},
  currentParticipant: undefined,
  attendeeTypeCustomFields: {},
  businessConferenceParticipantTypes: {},
};

const mapStateToProps = ({
  entities,
  entities: {
    businessConferenceParticipants,
    businessConferenceParticipantTypes,
    appSettings,
    sponsors,
    events,
  },
  fetchStatus: {
    currentAttendee: { success: fetchedCurrentAttendee },
  },
  chat: {
    openChannels,
  },
}) => ({
  entities,
  events,
  sponsors,
  participants: businessConferenceParticipants,
  businessConferenceParticipantTypes,
  appSettings,
  openChannels,
  fetchedCurrentAttendee,
});

export default connect(
  mapStateToProps,
  {
    callApi,
    addAttendeeLead,
    addChannel,
    setActiveChannel,
    setActiveSponsor,
    setActiveLead,
    setViewedAttendee,
  }
)(PeopleShow);
