import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Help = (props) => (
  <SvgIcon {...props} viewBox="0 0 21 21">
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7674 8.67097H18.282V8.1629C18.282 3.65807 14.7874 0 10.4838 0C6.18028 0 2.68567 3.65807 2.68567 8.1629V8.67097H2.20031C1.00308 8.67097 0 9.6871 0 10.9742V14.3952C0 15.6484 0.970724 16.6984 2.20031 16.6984H4.27119C4.49769 16.6984 4.69183 16.4952 4.69183 16.2581V9.14516C4.69183 8.90807 4.49769 8.70484 4.27119 8.70484H3.49461V8.19678C3.49461 4.16612 6.63328 0.880645 10.4838 0.880645C14.3344 0.880645 17.473 4.16612 17.473 8.19678V8.70484H16.6965C16.47 8.70484 16.2758 8.90807 16.2758 9.14516V16.2581C16.2758 16.4952 16.47 16.6984 16.6965 16.6984H18.0231C17.829 17.9855 16.7611 18.9338 15.5316 18.9338H12.6518C12.4253 18.0871 11.6164 17.5452 10.775 17.7145C10.3544 17.8162 9.99846 18.0533 9.77196 18.4258C9.54545 18.7984 9.44838 19.2388 9.54545 19.679C9.70724 20.4581 10.3867 21 11.1309 21C11.2281 21 11.3574 21 11.4546 20.9662C12.037 20.8307 12.5223 20.3903 12.6841 19.7807H15.5639C17.2789 19.7807 18.7026 18.4597 18.8967 16.6645C20.0616 16.5967 21 15.6145 21 14.3612V10.9742C20.9677 9.6871 19.9646 8.67097 18.7674 8.67097ZM20.1264 14.3612C20.1264 15.1403 19.5115 15.7838 18.7674 15.7838H17.1171V9.51774H18.7674C19.5115 9.51774 20.1264 10.1613 20.1264 10.9403V14.3612ZM3.85054 9.55162V15.8178H2.20031C1.45609 15.8178 0.841295 15.1742 0.841295 14.3952V10.9742C0.841295 10.1952 1.45609 9.55162 2.20031 9.55162H3.85054ZM11.8428 19.3403C11.8428 19.7807 11.4869 20.1533 11.0663 20.1533C10.6456 20.1533 10.2897 19.7807 10.2897 19.3403C10.2897 18.9 10.6456 18.5274 11.0663 18.5274C11.4869 18.5274 11.8428 18.9 11.8428 19.3403Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);
export default Help;
