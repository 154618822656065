import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const DropdownArrow = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" baseProfile="tiny" version="1.2" viewBox="0 0 24 24" id="arrow">
      <path d="M5.8 9.7L12 16l6.2-6.3c.2-.2.3-.5.3-.7s-.1-.5-.3-.7c-.2-.2-.4-.3-.7-.3h-11c-.3 0-.5.1-.7.3-.2.2-.3.4-.3.7s.1.5.3.7z" />
    </svg>
  </SvgIcon>
);

export default DropdownArrow;
