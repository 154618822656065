import React from 'react';
import ReactDOM from 'react-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Provider as ReduxProvider } from 'react-redux';
import {
  BrowserRouter, Route, Redirect,
} from 'react-router-dom';
import 'promise-polyfill/src/polyfill';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import locales from './locales';
import IEAlert from './components/IEAlert';
import configureStore from './store/configureStore';
import App from './App';
import { BASE_PATH } from './routes';
import FirebaseProvider from './components/FirebaseProvider';
import 'simplebar-react/dist/simplebar.min.css';

i18n.use(initReactI18next).init({
  resources: {
    ...locales,
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

const store = configureStore();

const msieversion = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) return true;
  return false;
};

if (process.env.REACT_APP_ENV === 'prod') Sentry.init({
  environment: 'prod',
  dsn: 'https://d9f0ba9648e24bf6ab134f6c38050e14@o193591.ingest.sentry.io/5743526',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const inEventtia = /eventtia.com/.test(window.location.host);
if ('serviceWorker' in navigator) window.addEventListener('load', () => {
  if (inEventtia) navigator.serviceWorker.register('/sw.js');
  else navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.forEach((registration) => {
      registration.unregister();
    });
  });
});

if (!msieversion()) ReactDOM.render(
  <ReduxProvider store={store}>
    <FirebaseProvider>
      <BrowserRouter>

        <Route exact path="/">
          <Redirect to="/en" />
        </Route>

        <Route path={BASE_PATH}>
          <App />
        </Route>

      </BrowserRouter>
    </FirebaseProvider>
  </ReduxProvider>,
  document.getElementById('root')
);
else ReactDOM.render(
  <IEAlert />,
  document.getElementById('root')
);
