import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';

import MessageInput from '../MessageInput';

import CustomPropTypes from '../../helpers/CustomPropTypes';

import callApi from '../../actions/callApi';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  avatar: {
    marginRight: theme.spacing(2),
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    backgroundColor: theme.palette.lightGrey.main,
    color: theme.palette.primary.main,
  },
  commentInput: {
    marginTop: 0,
    flexGrow: 1,
  },
}));

const SocialCommentCreator = ({ postId, currentAttendee, callApi: dispatchCallApi }) => {
  const classes = useStyles();
  const { t } = useTranslation('socialWall');

  const attendeeFullName = `${currentAttendee?.firstName}${currentAttendee?.lastName}`;

  return (
    <div className={classes.root}>
      <Avatar
        className={classes.avatar}
        alt={attendeeFullName}
        src={currentAttendee?.headshot?.filename ? currentAttendee?.headshot?.thumb : undefined}
      >
        {`${currentAttendee?.firstName?.[0]}${currentAttendee?.lastName?.[0]}`}
      </Avatar>
      <MessageInput
        id={`comment-for-${postId}`}
        className={classes.commentInput}
        onSend={(message) => {
          if (message?.trim()) dispatchCallApi('createPostComment', { postId, message });
        }}
        placeholder={t('commentPlaceholder')}
        maxLength="250"
      />
    </div>
  );
};

SocialCommentCreator.propTypes = {
  postId: PropTypes.string.isRequired,
  currentAttendee: CustomPropTypes.attendee,
  callApi: PropTypes.func.isRequired,
};

SocialCommentCreator.defaultProps = {
  currentAttendee: {},
};

export default connect(null, { callApi })(SocialCommentCreator);
