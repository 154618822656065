import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LockIcon from '@material-ui/icons/Lock';
import Block from './Block';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import useStringTransforms from '../../hooks/useStringTransforms';
import { identifyParticipants } from '../../helpers/auth';
import { MEETING_REQUEST_STATUSES } from '../../helpers/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.lightGrey.dark,
    cursor: 'grab',
    '&:hover': {
      backgroundColor: theme.palette.lightGrey.dark,
    },
  },
  text: {
    fontWeight: 900,
    fontSize: 'inherit',
    lineHeight: 'inherit',
    whiteSpace: 'nowrap',
    marginRight: theme.spacing(0.5),
  },
  smallText: {
    width: '100%',
    fontWeight: 900,
    fontSize: 12,
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  ocuppiedTitle: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  lockIcon: {
    marginRight: theme.spacing(0.5),
  },
}));

const { REQUESTED } = MEETING_REQUEST_STATUSES;

const BlockedSlot = (props) => {
  const classes = useStyles();
  const { t } = useTranslation('meeting');
  const { toTitleCase } = useStringTransforms();

  const {
    meetings, startDate, businessConferenceParticipants, meetingStatuses, meetingRequests, slotId,
    openParticipantId,
  } = props;
  const { requested, accepted } = meetingStatuses || {};

  const meeting = Object.values(meetings)
    .find(({ startDate: meetingStartDate }) => startDate === meetingStartDate);
  const meetingRequest = Object.values(meetingRequests)
    .filter((
      { status: requestStatus, receivedBy: { id } }
    ) => requestStatus === REQUESTED && id === openParticipantId)
    .find(({ slotId: requestSlotId }) => requestSlotId === slotId);

  const { status, host } = meeting || {};
  const bothParticipants = meeting && identifyParticipants(
    meeting,
    businessConferenceParticipants
  );
  const { currentParticipant, otherParticipant } = bothParticipants || {};

  const otherParticipantRequest = meetingRequest
    && businessConferenceParticipants[meetingRequest.receivedBy.id];

  const isHost = (currentParticipant?.id === host?.id);
  const profileContactName = otherParticipant?.profileContactName
    || otherParticipantRequest?.profileContactName;
  const waitingText = isHost
    ? t('meeting.waitingMyConfirmation')
    : t('meeting.waitingApproval', { participant: toTitleCase(profileContactName) });
  const meetingText = t('meeting.with', { participant: toTitleCase(profileContactName) });
  const requestText = t('meeting.waitingApproval', { participant: toTitleCase(profileContactName) });

  let secondaryLine;
  if (status === accepted) secondaryLine = meetingText;
  if (status === requested) secondaryLine = waitingText;
  else if (!meeting && profileContactName) secondaryLine = requestText;

  return (
    <Block
      {...props}
      className={classes.root}
      mainLine={(
        <div className={classes.ocuppiedTitle}>
          <LockIcon className={classes.lockIcon} />
          <Typography className={classes.text}>{t('slots.occupied')}</Typography>
        </div>
      )}
      secondaryLine={secondaryLine}
      summaryLine={(
        <div className={classes.ocuppiedTitle}>
          <LockIcon className={classes.lockIcon} />
          <Typography className={classes.text}>{t('slots.occupied')}</Typography>
          {secondaryLine && (
            <Typography className={classes.smallText}>
              - {secondaryLine}
            </Typography>
          )}
        </div>
      )}
    />
  );
};

BlockedSlot.propTypes = {
  meetings: PropTypes.objectOf(CustomPropTypes.meeting),
  businessConferenceParticipants: PropTypes.objectOf(
    CustomPropTypes.participant
  ).isRequired,
  startDate: PropTypes.string.isRequired,
  stepSize: PropTypes.number.isRequired,
  meetingStatuses: PropTypes.objectOf(
    PropTypes.number
  ),
  meetingRequests: PropTypes.objectOf(CustomPropTypes.meetingRequest),
  slotId: PropTypes.number.isRequired,
  openParticipantId: PropTypes.number.isRequired,
};

BlockedSlot.defaultProps = {
  meetings: {},
  meetingStatuses: {},
  meetingRequests: {},
};

const mapStateToProps = ({
  entities: {
    businessConferenceMeetings,
    businessConferenceParticipants,
    meetingRequests,
  },
  meta: {
    meetingStatuses,
  },
}) => ({
  meetings: businessConferenceMeetings,
  businessConferenceParticipants,
  meetingRequests,
  meetingStatuses,
});

export default connect(mapStateToProps)(BlockedSlot);
