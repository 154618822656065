/*  eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Chip from '@material-ui/core/Chip';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Message from '../../components/Message';
import InfiniteScroll from '../../components/InfiniteScroll';
import callApi from '../../actions/callApi';
import SurveyShow from '../SurveyShow';
import SubpageTitle from '../../components/SubpageTitle';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import TabletExitButton from '../../components/TabletExitButton';
import { getModuleNames, getModuleByType } from '../../helpers/getters';
import { setActiveSurvey } from '../../actions/app';

const useStyles = makeStyles((theme) => ({
  scroll: {
    minWidth: 320,
    padding: theme.spacing(6),
    maxHeight: '100%',
  },
  mobileScroll: {
    padding: '28px 21px 20px 21px',
    maxHeight: '100%',
  },
  moduleName: {
    padding: theme.spacing(1.5, 0),
    color: theme.palette.darkGrey.main,
    fontWeight: 'bold',
  },
  moduleDescription: {
    color: theme.palette.darkGrey.light,
  },
  survey: {
    width: '100%',
    display: 'flex',
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    boxShadow: theme.customShadows.small,
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  answeredSurvey: {
    justifyContent: 'flex-start',
    cursor: 'default',
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  surveyName: {
    marginLeft: 14,
    fontWeight: 900,
    color: theme.palette.darkGrey.light,
  },
  forwardButton: {
    height: 12,
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  answered: {
    color: theme.palette.green.main,
    height: 24,
  },
  completedChip: {
    backgroundColor: theme.palette.green.main,
    color: theme.palette.common.white,
    fontWeight: 'bold',
    marginLeft: theme.spacing(2),
  },
  root: {
    width: '100%',
  },
}));

const Surveys = ({
  callApi: dispatchCallApi, surveys, order, isFetchingSurveys, surveysError, links, appSettings,
  setActiveSurvey: dispatchSetActiveSurvey, surveyId, isFetchingSingleSurvey, singleSurveyError,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['surveys', 'global']);
  const [settings] = Object.values(appSettings);
  const [surveyAnswered, setSurveyAnswered] = useState(false);
  const moduleTitle = getModuleNames(appSettings, 'Surveys');
  const surveysModule = getModuleByType(settings.modules, 'Surveys');
  const description = surveysModule.customParams?.description;

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchNextPageIfExists = () => {
    const { next } = links;
    if (!surveyId && next) dispatchCallApi('surveys', next);
  };

  useEffect(() => {
    dispatchCallApi('surveys');
  }, [dispatchCallApi]);

  const activeSurvey = surveys?.[surveyId];
  const shouldFetchSingleSurvey = !isFetchingSurveys && !isFetchingSingleSurvey
    && !singleSurveyError && !surveysError && surveyId && Number(surveyId)
    && !activeSurvey;
  useEffect(() => {
    if (shouldFetchSingleSurvey) dispatchCallApi('singleSurvey', { id: surveyId });
  }, [
    dispatchCallApi, surveyId, shouldFetchSingleSurvey,
  ]);

  return (
    <div className={classes.root}>
      {!activeSurvey && (
        <TabletExitButton />
      )}
      {activeSurvey ? (
        <SurveyShow
          survey={activeSurvey}
          onSuccess={() => setSurveyAnswered(true)}
        />
      ) : (
        <InfiniteScroll
          loading={isFetchingSurveys}
          error={surveysError}
          fetchNextPageIfExists={fetchNextPageIfExists}
          className={mobile ? classes.mobileScroll : classes.scroll}
        >
          <SubpageTitle text={moduleTitle} />
          {description && (
            <Typography className={classes.moduleDescription} variant="body1">
              {description}
            </Typography>
          )}
          {surveyAnswered && (
            <Message
              type="success"
              message={t('messages.success')}
              className={classes.message}
              onClose={() => setSurveyAnswered(false)}
            />
          )}
          {order?.map((item) => {
            const currentSurvey = surveys[item];
            return currentSurvey ? (
              <div
                key={currentSurvey.id}
                className={clsx(classes.survey,
                  currentSurvey.answered && classes.answeredSurvey)}
                role="button"
                onClick={() => {
                  if (!currentSurvey.answered) {
                    setSurveyAnswered(false);
                    dispatchSetActiveSurvey(item);
                  }
                }}
              >
                <div className={classes.nameContainer}>
                  <ListAltIcon color="primary" />
                  <Typography className={classes.surveyName} variant="body2">
                    {currentSurvey.name}
                  </Typography>
                </div>
                {currentSurvey.answered ? (
                  <Chip
                    className={classes.completedChip}
                    label={t('global:status.completed')}
                    size="small"
                  />
                ) : (
                  <ArrowForwardIosIcon
                    className={classes.forwardButton}
                  />
                )}
              </div>
            ) : null;
          })}
        </InfiniteScroll>
      )}
    </div>
  );
};

Surveys.propTypes = {
  callApi: PropTypes.func.isRequired,
  setActiveSurvey: PropTypes.func.isRequired,
  surveys: PropTypes.objectOf(CustomPropTypes.survey),
  surveyId: PropTypes.string,
  order: PropTypes.arrayOf(PropTypes.string),
  isFetchingSurveys: PropTypes.bool,
  surveysError: PropTypes.string,
  isFetchingSingleSurvey: PropTypes.bool,
  singleSurveyError: PropTypes.string,
  links: PropTypes.shape({
    next: PropTypes.string,
  }),
  appSettings: CustomPropTypes.appSettings.isRequired,
};

Surveys.defaultProps = {
  surveys: {},
  surveyId: undefined,
  order: undefined,
  isFetchingSurveys: false,
  surveysError: undefined,
  isFetchingSingleSurvey: false,
  singleSurveyError: undefined,
  links: {},
};

const mapStateToProps = ({
  entities: {
    surveys,
    appSettings,
  },
  fetchStatus: {
    surveys: {
      order,
      isFetching: isFetchingSurveys,
      error: surveysError,
      links,
    },
    singleSurvey: {
      isFetching: isFetchingSingleSurvey,
      error: singleSurveyError,
    },
  },
  app: {
    activeSubpage: {
      surveyId,
    },
  },
}) => ({
  surveys,
  order,
  isFetchingSurveys,
  surveysError,
  isFetchingSingleSurvey,
  singleSurveyError,
  links,
  appSettings,
  surveyId,
});

export default connect(mapStateToProps, { callApi, setActiveSurvey })(Surveys);
