import React, {
  useState, useEffect, useCallback, useMemo, useContext,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import momentUtils from '@date-io/moment';
import clsx from 'clsx';
import Loader from 'eventtia-ui-components/lib/Loader';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import Message from '../Message';
import useTimer from '../../hooks/useTimer';
import Sidebar from '../Sidebar';
import { ATTENDANCE_MODE } from '../../helpers/constants';
import VenueSelector from '../VenueSelector';
import SubpageDrawer from '../SubpageDrawer';
import SocketProvider from '../SocketProvider';
import AttendeeBroadcastProvider from '../AttendeeBroadcastProvider';
import ChannelConnection from '../ChannelConnection';
import { parseFieldsByInternalId } from '../../helpers/customFields';
import { getLoginType } from '../../helpers/auth';
import { getCurrentAttendee } from '../../helpers/getters';
import { getAttendanceMode } from '../../helpers/attendanceModeHelper';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import { isMobileButtonVisible } from '../../helpers/subpages';
import { buildCustomTopic } from '../../helpers/chat';
import { addChannel, deleteAnnouncement } from '../../actions/messages';
import useTraces, { ENTITY_TYPES } from '../../hooks/useTraces';
import useFirebaseDB from '../../hooks/useFirebaseDB';
import { setAttendanceType } from '../../actions/app';
import FirebaseContext from '../../contexts/FirebaseContext';
import { updateViewedAttendees } from '../../actions/viewedAttendees';
import callApi, { fetchConfig } from '../../actions/callApi';
import TermsAndConditions from '../TermsAndConditions';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'absolute',
    height: '100%',
    right: 0,
    left: 0,
  },
  main: {
    flex: 1,
    position: 'relative',
    overflow: 'auto',
  },
  tabletMain: {
    width: 'calc(100% - 64px)',
  },
  mobileMain: {
    width: '100%',
  },
  openMenuButton: {
    zIndex: 1500,
    height: 40,
    width: 40,
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(2.5),
    backgroundColor: theme.palette.lightGrey.main,
    '&:hover': {
      backgroundColor: theme.palette.lightGrey.dark,
    },
  },
  closeMenuButton: {
    boxShadow: theme.customShadows.small,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
  announcementMsg: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    zIndex: 3000,
    padding: theme.spacing(3.5, 3.5, 6, 3.5),
    minWidth: 372,
    maxWidth: 372,
    alignItems: 'flex-start',
  },
  mobileAnnouncementMsg: {
    left: 0,
    minWidth: 'unset',
    maxWidth: 'unset',
  },
  dialogRoot: {
    zIndex: '99999 !important',
  },
  dialog: {
    borderRadius: 0,
  },
}));

const EventTimeTracker = ({ eventId, active }) => {
  useTraces(eventId, ENTITY_TYPES.EVENT, active);

  return null;
};

EventTimeTracker.propTypes = {
  eventId: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
};

const { HYBRID, PHYSICAL, VIRTUAL } = ATTENDANCE_MODE;

export const Component = ({
  children, entities, openChannels, addChannel: dispatchAddChannel, attendanceType,
  deleteAnnouncement: dispatchDeleteAnnouncement, announcements, activeSubpage,
  attendeeTypes, currentAttendeeFetched, setAttendanceType: dispatchSetAttendanceType,
  updateViewedAttendees: dispatchUpdateViewedAttendees,
  attendeeTypeCustomFields,
  updatingTerms,
  callApi: dispatchCallApi,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const currentAttendee = getCurrentAttendee(entities, { parseCustomFields: true });
  const { t } = useTranslation(['chat', 'global']);
  const { putAttendeePresence, watchViewedAttendees } = useFirebaseDB();
  const [ongoingEvent, setOngoingEvent] = useState(false);

  const { appSettings, events } = entities;
  const [{
    eventUri, id: eventId, startDate, endDate, attendanceMode: eventAttendanceMode,
  }] = Object.values(events);
  const [settings] = Object.values(appSettings);
  const currentAttendeeTypeId = currentAttendee?.attendeeType?.id;
  const fieldsId = parseFieldsByInternalId(currentAttendee?.fields, attendeeTypeCustomFields);
  const termsFieldId = fieldsId[120];
  const acceptedTerms = !!currentAttendee && currentAttendee.fields[termsFieldId];

  const loginType = getLoginType(eventUri);
  const termsOpen = !!currentAttendee && (loginType !== 'assistant') && !acceptedTerms;

  const { modules } = settings;
  const chatModule = modules?.find(({ type }) => type === 'Chat');
  const channels = chatModule?.customParams?.channels || {};

  const onTick = useCallback((now, start, end) => {
    if (now.isBetween(start, end, null, '[)')) setOngoingEvent(true);
    else setOngoingEvent(false);
  }, [setOngoingEvent]);

  useTimer({
    startDate,
    endDate,
    onTick,
    step: 60000,
  });

  const availableCustomChannels = useMemo(() => {
    const customChannels = [];
    Object.keys(channels).forEach((uuid) => {
      const { name, availableFor } = channels[uuid];
      if (availableFor.includes(currentAttendeeTypeId)) customChannels.push({
        uuid,
        name,
      });
    });
    return customChannels;
  }, [currentAttendeeTypeId]);

  useEffect(() => {
    if (availableCustomChannels) availableCustomChannels
      .forEach(({ uuid, name }) => {
        dispatchAddChannel({
          type: 'group',
          topic: buildCustomTopic(eventUri, uuid),
          name,
        });
      });
  }, [eventUri, dispatchAddChannel, availableCustomChannels]);
  const { singleSession } = settings;

  useEffect(() => {
    dispatchAddChannel({
      type: 'announcement',
      topic: `event:${eventUri}`,
      name: t('channels.public'),
    });
  }, [eventUri, dispatchAddChannel]);

  useEffect(() => {
    const unsubscribe = watchViewedAttendees((value) => {
      dispatchUpdateViewedAttendees(value);
    });

    return () => {
      unsubscribe();
    };
  }, [watchViewedAttendees, dispatchUpdateViewedAttendees]);

  const [openMenu, setOpenMenu] = useState(false);
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tablet = useMediaQuery(theme.breakpoints.down('md'));
  const [announcementOpened, setAnnouncementOpened] = useState(true);
  const [broadcasts, setBroadcasts] = useState({});
  const addBroadcast = useCallback((topic, broadcast) => {
    setBroadcasts((prevState) => ({
      ...prevState,
      [topic]: broadcast,
    }));
  }, [setBroadcasts]);

  useEffect(() => {
    setAnnouncementOpened(true);
  }, [announcements, setAnnouncementOpened]);

  const onCloseAnnouncement = () => {
    setAnnouncementOpened(false);
    setTimeout(() => {
      dispatchDeleteAnnouncement();
    }, 800);
  };

  const removeBroadcast = useCallback((topic) => {
    setBroadcasts((prevState) => {
      const { [topic]: toDelete, ...otherBroadcasts } = prevState;
      return otherBroadcasts;
    });
  }, [setBroadcasts]);

  const userAttendanceMode = attendeeTypes[currentAttendeeTypeId]?.attendanceMode;
  const isSelectingAttendanceType = (
    eventAttendanceMode === HYBRID && userAttendanceMode === HYBRID && !attendanceType
  );
  useEffect(() => {
    const storedAttendanceMode = getAttendanceMode();
    if (storedAttendanceMode) dispatchSetAttendanceType(storedAttendanceMode);
  }, [dispatchSetAttendanceType]);

  const { firebaseAuthComplete } = useContext(FirebaseContext);

  useEffect(() => {
    if (currentAttendeeFetched && firebaseAuthComplete) putAttendeePresence();
  }, [currentAttendeeFetched, firebaseAuthComplete]);

  if (!currentAttendeeFetched || !firebaseAuthComplete) return (
    <Loader loading variant="absolute" />
  );

  const onAccept = () => {
    const data = new FormData();
    data.append(`attendee[custom_fields][${termsFieldId}]`, true);
    dispatchCallApi('updateCurrentAttendee', { id: currentAttendee.id, data })
      .then(({ type, error: errors }) => {
        const [, , updateFailure] = fetchConfig.updateCurrentAttendee.types;
        if (type === updateFailure) console.error(
          Array.isArray(errors) ? errors.join(', ') : errors
        );
      });
  };

  return (
    <MuiPickersUtilsProvider utils={momentUtils}>
      <SocketProvider currentAttendee={currentAttendee} singleSession={singleSession}>
        <AttendeeBroadcastProvider currentAttendeeId={currentAttendee.id}>
          <EventTimeTracker eventId={eventId} active={ongoingEvent} />
          {isSelectingAttendanceType && (
            <VenueSelector />
          )}
          <div className={classes.root}>
            {openChannels.map(({ topic }) => (
              <ChannelConnection
                key={topic}
                topic={topic}
                addBroadcast={addBroadcast}
                removeBroadcast={removeBroadcast}
              />
            ))}
            {mobile && isMobileButtonVisible(activeSubpage) && (
              <IconButton
                className={clsx(
                  classes.openMenuButton, openMenu && classes.closeMenuButton
                )}
                aria-label="open-menu"
                onClick={() => setOpenMenu(!openMenu)}
                edge="end"
                color="primary"
                small="true"
              >
                {openMenu ? (<CloseIcon />) : (<MenuIcon />)}
              </IconButton>
            )}
            <Sidebar
              open={openMenu}
              onClose={() => setOpenMenu(false)}
            />
            <SubpageDrawer
              broadcasts={broadcasts}
              openChannels={openChannels}
            />
            <main className={clsx(classes.main,
              tablet && classes.tabletMain,
              mobile && classes.mobileMain)}
            >
              <Dialog
                open={termsOpen}
                maxWidth="md"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ root: classes.dialogRoot, paper: classes.dialog }}
              >
                <DialogTitle id="alert-dialog-title">Accept terms and conditions</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Download the NON-DISCLOSURE UNDERTAKING document{' '}
                    <a
                      href="https://cdn.eventtia.com/event_files/187194/original/NDA_World_Meeting_2024.DOCX.pdf?1713547982"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      here
                    </a>
                    <TermsAndConditions />
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        onChange={onAccept}
                        disabled={updatingTerms}
                        checked={acceptedTerms}
                      />
                    )}
                    label="I accept terms and conditions"
                  />
                </DialogActions>
              </Dialog>
              {children({ broadcasts })}
              {announcements.length > 0 && announcementOpened && (
                <Message
                  type={announcements[0].type === 'alert' ? 'warning' : 'informative'}
                  className={clsx(classes.announcementMsg,
                    mobile && classes.mobileAnnouncementMsg)}
                  title={t('global:title.announcement')}
                  onClose={onCloseAnnouncement}
                  message={announcements[0].body}
                />
              )}
            </main>
          </div>
        </AttendeeBroadcastProvider>
      </SocketProvider>
    </MuiPickersUtilsProvider>
  );
};

Component.propTypes = {
  children: PropTypes.func.isRequired,
  activeSubpage: PropTypes.shape({
    module: PropTypes.string,
  }).isRequired,
  entities: CustomPropTypes.entities.isRequired,
  currentAttendeeFetched: PropTypes.bool.isRequired,
  attendeeTypes: PropTypes.objectOf(
    CustomPropTypes.attendeeType
  ),
  attendeeTypeCustomFields: PropTypes.objectOf(
    CustomPropTypes.attendeeTypeCustomField
  ),
  openChannels: PropTypes.arrayOf(CustomPropTypes.channel).isRequired,
  addChannel: PropTypes.func.isRequired,
  deleteAnnouncement: PropTypes.func.isRequired,
  announcements: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  setAttendanceType: PropTypes.func.isRequired,
  attendanceType: PropTypes.oneOf([PHYSICAL, VIRTUAL, '']),
  updateViewedAttendees: PropTypes.func.isRequired,
  updatingTerms: PropTypes.bool.isRequired,
  callApi: PropTypes.func.isRequired,
};

Component.defaultProps = {
  announcements: [],
  attendeeTypes: {},
  attendeeTypeCustomFields: {},
  attendanceType: '',
};

const mapStateToProps = ({
  entities,
  entities: {
    attendeeTypes,
    attendeeTypeCustomFields,
  },
  fetchStatus: {
    currentAttendee: { success: currentAttendeeFetched },
    updateCurrentAttendee: { isFetching: updatingTerms },
  },
  chat: {
    openChannels,
  },
  announcements,
  app: {
    activeSubpage,
    attendanceType,
  },
}) => ({
  entities,
  openChannels,
  activeSubpage,
  announcements,
  attendanceType,
  attendeeTypes,
  currentAttendeeFetched,
  attendeeTypeCustomFields,
  updatingTerms,
});

const mapDispatchToProps = {
  addChannel,
  deleteAnnouncement,
  setAttendanceType,
  updateViewedAttendees,
  callApi,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
