import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ForumIcon from '@material-ui/icons/Forum';
import { makeStyles } from '@material-ui/core/styles';
import Loader from 'eventtia-ui-components/lib/Loader';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Message from '../../components/Message';
import QASessionShow from '../QASessionShow';
import SubpageTitle from '../../components/SubpageTitle';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import TabletExitButton from '../../components/TabletExitButton';
import { getModuleNames, getModuleByType } from '../../helpers/getters';
import { setActiveQaSession } from '../../actions/app';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  listContainer: {
    maxHeight: '100%',
    padding: theme.spacing(3.5, 2.5, 2.5, 2.5),
    [theme.breakpoints.up('md')]: {
      minWidth: 320,
      padding: theme.spacing(6),
    },
  },
  moduleDescription: {
    color: theme.palette.darkGrey.light,
  },
  qaSession: {
    width: '100%',
    display: 'flex',
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    boxShadow: theme.customShadows.small,
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  sessionName: {
    marginLeft: 14,
    fontWeight: 900,
    color: theme.palette.darkGrey.light,
  },
  forwardIcon: {
    height: 12,
    color: theme.palette.primary.main,
  },
}));

const QASessions = ({
  qaSessions,
  isFetchingQaSessions,
  qaSessionsError,
  order,
  sessionId,
  appSettings,
  setActiveQaSession: dispatchSetActiveQaSession,
}) => {
  const classes = useStyles();
  const [settings] = Object.values(appSettings);
  const moduleTitle = getModuleNames(appSettings, 'QASessions');
  const qaModule = getModuleByType(settings.modules, 'QASessions');
  const description = qaModule.customParams?.description;

  const [message, setMessage] = useState();
  useEffect(() => {
    if (qaSessionsError) setMessage(qaSessionsError);
  }, [qaSessionsError]);

  const activeSession = qaSessions?.[sessionId];
  const sessionIds = Object.keys(qaSessions);
  useEffect(() => {
    if (
      !isFetchingQaSessions && sessionId && !sessionIds.includes(sessionId)
    ) dispatchSetActiveQaSession(undefined);
  }, [sessionIds, sessionId, isFetchingQaSessions, dispatchSetActiveQaSession]);

  return (
    <div className={classes.root}>
      {!activeSession && (
        <TabletExitButton />
      )}
      {activeSession ? (
        <QASessionShow
          showBackButton={sessionIds.length >= 1}
          qaSession={activeSession}
        />
      ) : (
        <div className={classes.listContainer}>
          <Loader loading={isFetchingQaSessions} variant="absolute" />
          <Message
            type="error"
            message={message}
            className={classes.message}
            onClose={() => {
              setMessage();
            }}
          />
          <SubpageTitle text={moduleTitle} />
          {description && (
            <Typography className={classes.moduleDescription} variant="body1">
              {description}
            </Typography>
          )}
          {order?.map((item) => {
            const currentSession = qaSessions[item];
            return (
              <div
                key={currentSession.id}
                className={classes.qaSession}
                role="button"
                onClick={() => {
                  dispatchSetActiveQaSession(item);
                }}
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') dispatchSetActiveQaSession(item);
                }}
              >
                <div className={classes.nameContainer}>
                  <ForumIcon color="primary" />
                  <Typography className={classes.sessionName} variant="body2">
                    {currentSession.name}
                  </Typography>
                </div>
                <ArrowForwardIosIcon
                  color="primary"
                  className={classes.forwardIcon}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

QASessions.propTypes = {
  setActiveQaSession: PropTypes.func.isRequired,
  qaSessions: PropTypes.objectOf(CustomPropTypes.qaSession),
  sessionId: PropTypes.string,
  order: PropTypes.arrayOf(PropTypes.string),
  isFetchingQaSessions: PropTypes.bool.isRequired,
  qaSessionsError: PropTypes.string,
  appSettings: CustomPropTypes.appSettings.isRequired,
};

QASessions.defaultProps = {
  qaSessions: {},
  sessionId: undefined,
  order: undefined,
  qaSessionsError: undefined,
};

const mapStateToProps = ({
  entities: {
    eventQaSessions: qaSessions,
    appSettings,
  },
  fetchStatus: {
    qaSessions: {
      order,
      isFetching: isFetchingQaSessions,
      error: qaSessionsError,
    },
  },
  app: {
    activeSubpage: {
      sessionId,
    },
  },
}) => ({
  qaSessions,
  order,
  isFetchingQaSessions,
  qaSessionsError,
  appSettings,
  sessionId,
});

export default connect(mapStateToProps, { setActiveQaSession })(QASessions);
