import React from 'react';
import { connect, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Block from './Block';
import DownloadActivity from '../DownloadActivity';
import { selectEventTimeFormat } from '../../helpers/selectors';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import ActivityLocation from '../ActivityLocation';
// import RegisteredIcon from '../../assets/Registered';
import { baseMoment } from '../../helpers/dates';

const customWorkshopsColor = ['302853', '302862'];

const useStyles = makeStyles((theme) => ({
  secondaryLineContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(0.5),
  },
  secondaryLine: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
  },
  registered: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '& *': {
      color: theme.palette.common.white,
    },
  },
  customWorkshop: {
    backgroundColor: '#f0ead7',
  },
  tooltipSummary: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 600,
    fontSize: 'inherit',
    lineHeight: 'inherit',
  },
  location: {
    fontWeight: 'normal',
    overflow: 'hidden',
  },
  locationText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    fontSize: 18,
    marginRight: theme.spacing(1),
    verticalAlign: 'bottom',
  },
  divider: {
    fontWeight: 'normal',
    margin: theme.spacing(0, 2),
  },
  registeredText: {
    textTransform: 'uppercase',
    fontSize: 12,
    // fontWeight: 'bold',
    fontFamily: 'Brilliant Cut Pro',
    fontStyle: 'italic',
  },
  registeredTextInline: {
    textTransform: 'uppercase',
    fontSize: 12,
    fontFamily: 'Fancy Cut Pro',
    fontStyle: 'italic',
    verticalAlign: 'middle',
  },
  summaryLine: {
    display: 'flex',
    alignItems: 'center',
  },
  summaryLineText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 12,
    textTransform: 'uppercase',
  },
  textColorPrimary: {
    color: theme.palette.primary.main,
  },
  timeStyles: {
    color: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
    },
  },
}));

const Workshop = ({
  name, registered, id, allSpeakers, ...workshop
}) => {
  const classes = useStyles();
  const timeFormat = useSelector(selectEventTimeFormat());
  const { t } = useTranslation(['stage', 'global', 'events', 'schedule']);

  const locationInfo = (
    <ActivityLocation activity={workshop} />
  );

  const secondaryLine = (
    <div className={classes.secondaryLineContainer}>
      <div className={clsx(classes.secondaryLine, !registered && classes.textColorPrimary)}>
        {/* {workshop.optional && <RegisteredIcon />} */}
        <Typography
          className={clsx(classes.registeredText, !registered && classes.textColorPrimary)}
        >
          {workshop.optional ? t('global:status.optional') : t('global:status.mandatory')}
        </Typography>
      </div>
    </div>
  );

  // const summaryLine = (
  //   <div className={classes.summaryLine}>
  //     {/* {!registered && (
  //       <RegisteredIcon />
  //     )} */}
  //     <span className={classes.summaryLineText}>
  //       {name}
  //       <span className={classes.divider}>|</span>
  //       {locationInfo}
  //     </span>
  //   </div>
  // );

  const tooltipContent = (
    <>
      {locationInfo}
    </>
  );

  const mainLine = (
    <>
      {name}
      {/* <span
        className={clsx(classes.registeredTextInline, !registered && classes.textColorPrimary)}
      >
        {workshop.optional ? t('global:status.optional') : t('global:status.mandatory')}
      </span> */}
    </>
  );

  const startHourLine = (
    <span className={clsx(classes.textColorPrimary, registered && classes.timeStyles)}>
      {` ${baseMoment(workshop.startDate).format(timeFormat)} - ${baseMoment(workshop.endDate).format(timeFormat)}`}
    </span>
  );

  return (
    <Block
      {...workshop}
      className={clsx(registered ? classes.registered : undefined,
        customWorkshopsColor.includes(id) && classes.customWorkshop)}
      downloadActivity={(
        <DownloadActivity
          color={registered ? 'secondary' : 'primary'}
        />
      )}
      tooltipTitle={tooltipContent}
      mainLine={mainLine}
      secondaryLine={secondaryLine}
      tertiaryLine={undefined}
      summaryLine={undefined}
      hourLine={startHourLine}
      optional={workshop.optional}
      registered={registered}
      location={workshop.location}
    />
  );
};

Workshop.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  registered: PropTypes.bool,
  allSpeakers: PropTypes.objectOf(
    CustomPropTypes.speaker
  ),
};

Workshop.defaultProps = {
  registered: false,
  allSpeakers: {},
};

const mapStateToProps = ({
  entities: {
    speakers: allSpeakers,
  },
}) => ({
  allSpeakers,
});

export default connect(mapStateToProps, null)(Workshop);
