import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import ContextMenu from 'eventtia-ui-components/lib/ContextMenu';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import callApi from '../../actions/callApi';
import Rater from '../Rater';

const useStyles = makeStyles((theme) => ({
  menuIcon: {
    position: 'relative',
    top: 0,
    right: 0,
    marginLeft: theme.spacing(1),
  },
}));

const ParticipantRating = ({
  participant, ratings, denyList, callApi: dispatchCallApi, isFetching, setMessage, disabled,
}) => {
  const { t } = useTranslation('attendees');
  const classes = useStyles();

  const rating = Object.values(ratings).find(
    ({ participant: { id: participantId } }) => participantId === participant.id
  );
  const denied = !!Object.values(denyList).find(
    ({ blacklistedParticipant: { id: participantId } }) => participantId === participant.id
  );

  const { id: participantId, businessConference: { id: businessConferenceId } } = participant;
  const updateRating = (action, newRating) => dispatchCallApi(action, {
    businessConferenceId, participantId, rating: newRating,
  }).then(({ error }) => {
    if (error) setMessage({ type: 'error', text: error });
    else setMessage();
  });
  const rate = (newRating) => updateRating('rateParticipant', newRating);
  const resetRating = () => updateRating('resetRating');

  const menuItems = [{
    name: t('actions.resetRating'),
    onClick: resetRating,
  }];

  return isFetching ? (
    <CircularProgress
      size={20}
      thickness={4.5}
      color="secondary"
    />
  ) : (
    <>
      <Rater
        value={rating?.rate ?? 0}
        onChange={rate}
        disabled={disabled || isFetching || denied}
      />
      {!disabled && !denied && (
        <ContextMenu items={menuItems} iconButtonClassName={classes.menuIcon} />
      )}
    </>
  );
};

ParticipantRating.propTypes = {
  participant: CustomPropTypes.participant.isRequired,
  ratings: PropTypes.objectOf(CustomPropTypes.rating),
  denyList: PropTypes.objectOf(CustomPropTypes.deniedParticipant),
  callApi: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  setMessage: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ParticipantRating.defaultProps = {
  ratings: {},
  denyList: {},
  disabled: false,
};

const mapStateToProps = ({
  entities: {
    businessConferenceRatedPossibleMeetings: ratings,
    businessConferenceParticipantBlacklists: denyList,
  },
  fetchStatus: {
    rateParticipant: { isFetching: ratingParticipant },
    resetRating: { isFetching: resettingRating },
    ratings: { isFetching: fetchingRatings },
  },
}) => ({
  ratings,
  denyList,
  isFetching: ratingParticipant || resettingRating || fetchingRatings,
});

export default connect(mapStateToProps, { callApi })(ParticipantRating);
