import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 'calc(100dvh - 64px)',
    display: 'flex',
    flexDirection: 'column',
  },
  textWrapper: {
    marginTop: 'auto',
    color: theme.palette.darkGrey.dark,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 940,
    textAlign: 'center',
  },
  title: {
    fontWeight: 700,
    color: theme.palette.darkGrey.dark,
    fontSize: 54,
    [theme.breakpoints.down('sm')]: {
      fontSize: 32,
    },
  },
  message: {
    fontWeight: 700,
    color: theme.palette.darkGrey.dark,
    fontSize: 44,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  secondMessage: {
    color: theme.palette.darkGrey.dark,
    marginTop: theme.spacing(4),
    maxWidth: 600,
    fontWeight: 700,
    fontSize: 24,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  logoWrapper: {
    marginTop: 'auto',
    color: 'inherit',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  logo: {
    marginBottom: -15,
    height: 52,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(-4),
    },
  },
}));

const LoginError = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.textWrapper}>
        <Typography className={classes.title}>
          ACCESS DENIED
        </Typography>
        <Typography className={classes.message}>
          WEBSITE RESERVED FOR THE WORLD MEETING PARTICIPANTS
        </Typography>
        <Typography className={classes.secondMessage}>
          IF YOU ARE ASSISTING SOMEONE, PLEASE USE THE DELEGATE BUTTON
        </Typography>
      </div>
      <div
        className={classes.logoWrapper}
      >
        <img
          src="https://cdn.eventtia.com/event_files/186354/original/cartierlogo17127703301712770330.png?1712770330"
          alt="logo"
          className={classes.logo}
        />
        <div>
          <Typography
            variant="h1"
            className={classes.secondMessage}
          >
            WORLD MEETING 20.24
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default LoginError;
