import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Socket } from 'phoenix';
import { getToken } from '../../helpers/auth';
import { SOCKET_URL } from '../../helpers/endpoints';
import SocketContext from '../../contexts/SocketContext';
import { EVENT_URI } from '../../helpers/constants';

const SocketProvider = ({ children, currentAttendee, singleSession }) => {
  const token = getToken(EVENT_URI);
  const { firstName, lastName, headshot, attendeeType: { id: attendeeTypeId } } = currentAttendee;
  const username = `${firstName} ${lastName}`;
  const avatar = headshot?.filename && headshot.small;

  const socket = useMemo(() => new Socket(SOCKET_URL, {
    params: {
      token,
      username,
      avatar,
      single_session: singleSession,
      attendee_type_id: attendeeTypeId,
    },
  }), [token, username, avatar, attendeeTypeId]);

  useEffect(() => {
    if (socket) socket.connect();
    return () => {
      if (socket) socket.disconnect();
    };
  }, [socket]);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};

SocketProvider.propTypes = {
  children: PropTypes.node.isRequired,
  currentAttendee: PropTypes.shape({
    firstName: PropTypes.string,
    attendeeType: PropTypes.shape({
      id: PropTypes.string,
    }),
    lastName: PropTypes.string,
    headshot: PropTypes.shape({
      filename: PropTypes.string,
      small: PropTypes.string,
    }),
  }).isRequired,
  singleSession: PropTypes.bool.isRequired,
};

export default SocketProvider;
