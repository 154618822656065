import { buildPrivateTopic, buildCustomTopic, saveLastReadMessages } from '../../helpers/chat';
import { getCurrentAttendee, getEventUri, getLocale } from '../../helpers/getters';
import { logout } from '../app';
import { EVENT_URI } from '../../helpers/constants';

export const NEW_MESSAGE = 'new_msg';
export const addMessages = (payload, channel) => ({
  type: NEW_MESSAGE,
  channel,
  payload,
});

export const addPreviousMessages = (payload, channel) => (dispatch, getState) => {
  const { messages } = getState();
  if (!messages[channel]) dispatch(addMessages(payload, channel));
};

export const NEW_QUESTION = 'new_question';
export const addQuestion = (payload, channel) => ({
  type: NEW_QUESTION,
  channel,
  payload,
});

export const ADD_CHANNEL = 'ADD_CHANNEL';
export const addChannel = ({
  type, topic, name, description, avatar, createdBy,
}) => ({
  type: ADD_CHANNEL,
  channel: {
    type,
    topic,
    name,
    description,
    avatar: avatar !== 'undefined' ? avatar : undefined,
    createdBy,
  },
});

export const addPrivateChannels = (channels) => (dispatch, getState) => {
  const { entities } = getState();
  const { id: currentAttendeeId } = getCurrentAttendee(entities);
  channels.forEach(({ attendee_id: otherAttendeeId, username, avatar }) => {
    dispatch(addChannel({
      type: 'private',
      topic: buildPrivateTopic(otherAttendeeId, currentAttendeeId),
      name: username,
      avatar,
    }));
  });
};

export const addCustomChannels = (channels) => (dispatch) => {
  channels.forEach(({
    name, description, uuid, created_at: createdAt, created_by_id: createdBy,
  }) => {
    dispatch(addChannel({
      type: 'group',
      topic: buildCustomTopic(EVENT_URI, uuid),
      name,
      description,
      createdBy,
      createdAt,
    }));
  });
};

export const MODERATED_MESSAGE_RECEIVED = 'msg_moderated';
export const addModeratedMessageAlert = (payload, channel) => ({
  type: MODERATED_MESSAGE_RECEIVED,
  channel,
  payload,
});

export const NEW_CHAT_REQUEST = 'open_private';
export const CREATE_CUSTOM_CHANNEL = 'create_custom_channel';
export const NEW_CUSTOM_CHANNEL = 'new_custom_channel';

export const addChannelFromMsg = (payload, type = 'private') => (dispatch, getState) => {
  const { attendee_id: id, username, avatar } = payload;
  const { entities } = getState();
  const { id: currentAttendeeId } = getCurrentAttendee(entities);
  const topic = buildPrivateTopic(id, currentAttendeeId);

  return dispatch(addChannel({
    type,
    topic,
    name: username,
    avatar,
  }));
};

export const READ_MESSAGE = 'READ_MESSAGE';
export const readMessage = (uuid, channel) => (dispatch) => {
  dispatch({
    type: READ_MESSAGE,
    channel,
    uuid,
  });
  const eventUri = getEventUri();
  saveLastReadMessages(eventUri, channel, uuid);
};

export const READ_QUESTION = 'READ_QUESTION';
export const readQuestion = (uuid, channel) => ({
  type: READ_QUESTION,
  channel,
  uuid,
});

export const NEW_ANNOUNCEMENT = 'live_announcements';
export const addAnnouncement = (payload) => (dispatch) => {
  const eventUri = getEventUri();
  const locale = getLocale();
  const { body } = payload;
  if (body === 'command:logout') return dispatch(logout());
  if (body === 'command:reload') window.location.reload();
  if (body === 'command:reload-home') window.location
    .replace(`/${locale}/${eventUri}/stage`);
  return dispatch({
    type: NEW_ANNOUNCEMENT,
    payload,
  });
};

export const DELETE_ANNOUNCEMENT = 'delete_announcement';
export const deleteAnnouncement = () => ({
  type: DELETE_ANNOUNCEMENT,
});

export const SEND_REACTION = 'send_reaction';
export const addReaction = (payload) => ({
  type: SEND_REACTION,
  payload,
});

export const DELETE_REACTION = 'delete_reaction';
export const deleteReaction = (payload) => ({
  type: DELETE_REACTION,
  payload,
});
