import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Loader from 'eventtia-ui-components/lib/Loader';
import Tabs from 'eventtia-ui-components/lib/Tabs';
import SelectButton from 'eventtia-ui-components/lib/SelectButton';
import BackButton from '../../components/BackButton';
import QAQuestionCreator from '../../components/QAQuestionCreator';
import QAQuestion from '../../components/QAQuestion';
import callApi from '../../actions/callApi';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import { getCurrentAttendee } from '../../helpers/getters';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 4),
  },
  backButton: {
    marginLeft: theme.spacing(-5),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: theme.spacing(-3),
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  sessionName: {
    color: theme.palette.darkGrey.main,
    fontWeight: 'bold',
    padding: theme.spacing(1.5, 0),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
      margin: theme.spacing(0, 5),
    },
  },
  description: {
    color: theme.palette.darkGrey.light,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  creatorContainer: {
    margin: theme.spacing(2, 0),
  },
  filtersContainer: {
    marginBottom: theme.spacing(4),
  },
  sortByContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  sortByLabel: {
    marginRight: theme.spacing(2),
  },
}));

const sortByDate = (q1, q2) => (
  (new Date(q2.createdAt)) - (new Date(q1.createdAt))
);

const sortByLikes = (q1, q2) => (
  q2.likedBy.length - q1.likedBy.length
);

const QASessionShow = ({
  entities,
  qaSession,
  qaQuestions,
  isFetching,
  showBackButton,
  callApi: dispatchCallApi,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('qaSessions');

  const currentAttendee = getCurrentAttendee(entities, { parseCustomFields: true });

  const { id: sessionId, description, name } = qaSession;

  const attendeeTypeId = currentAttendee.attendeeType.id;
  useEffect(() => {
    dispatchCallApi('qaSessionQuestions', { sessionId, attendeeTypeId });

    const pollingInterval = setInterval(() => {
      dispatchCallApi('qaSessionQuestions', { sessionId, attendeeTypeId });
    }, 30000);

    return () => {
      clearInterval(pollingInterval);
    };
  }, [sessionId, attendeeTypeId, dispatchCallApi]);

  const [activeTab, setActiveTab] = useState(0);
  const tabs = ['all', 'myQuestions'];
  const tabItems = tabs.map((type, index) => ({
    name: t(`filters.${type}`),
    onClick: () => { setActiveTab(index); },
  }));
  let filteredQuestions = Object.values(qaQuestions).filter(
    ({ eventQaSession: { id: sId } }) => (sId === qaSession.id)
  );
  if (tabs[activeTab] === 'myQuestions') filteredQuestions = filteredQuestions.filter(
    ({ attendee: { id: attendeeId } }) => attendeeId === currentAttendee.id
  );

  const [sortBy, setSortBy] = useState('recent');
  const sortOptions = ['recent', 'mostLiked'];
  const sortSelectItems = sortOptions.map((type) => ({
    label: t(`filters.${type}`),
    onClick: () => { setSortBy(type); },
  }));
  if (sortBy === 'recent') filteredQuestions.sort(sortByDate);
  else if (sortBy === 'mostLiked') filteredQuestions.sort(sortByLikes);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {showBackButton && <BackButton className={classes.backButton} />}
        <Typography
          className={classes.sessionName}
          variant="subtitle1"
        >
          {name}
        </Typography>
      </div>
      {description && (
        <Typography
          className={classes.description}
          variant="body1"
        >
          {description}
        </Typography>
      )}
      <div className={classes.creatorContainer}>
        <QAQuestionCreator
          qaSession={qaSession}
        />
      </div>
      <div className={classes.questionsContainer}>
        <div className={classes.filtersContainer}>
          <Tabs
            variant="filter"
            activeItem={activeTab}
            items={tabItems}
          />

          <div className={classes.sortByContainer}>
            <Typography
              className={classes.sortByLabel}
              color="textSecondary"
              variant="body1"
            >
              {t('global:filters.sort.sortBy')}
            </Typography>
            <SelectButton
              label={t(`filters.${sortBy}`)}
              items={sortSelectItems}
              small
            />
          </div>
        </div>

        <Loader loading={isFetching && filteredQuestions.length === 0} />

        {filteredQuestions.map((qaQuestion) => (
          <QAQuestion
            key={qaQuestion.id}
            qaQuestion={qaQuestion}
          />
        ))}
      </div>
    </div>
  );
};

QASessionShow.propTypes = {
  entities: CustomPropTypes.entities.isRequired,
  qaSession: CustomPropTypes.qaSession.isRequired,
  qaQuestions: PropTypes.objectOf(CustomPropTypes.qaQuestion),
  isFetching: PropTypes.bool.isRequired,
  showBackButton: PropTypes.bool.isRequired,
  callApi: PropTypes.func.isRequired,
};

QASessionShow.defaultProps = {
  qaQuestions: {},
};

const mapStateToProps = ({
  entities,
  entities: {
    eventQaSessionQuestions: qaQuestions,
  },
  fetchStatus: {
    qaSessionQuestions: {
      isFetching,
    },
  },
}) => ({
  entities,
  qaQuestions,
  isFetching,
});

export default connect(mapStateToProps, { callApi })(QASessionShow);
