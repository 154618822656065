import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import DownloadIcon from '../../assets/DownloadIcon';
// import Grid from '@material-ui/core/Grid';
import SubpageTitle from '../../components/SubpageTitle';
import TabletExitButton from '../../components/TabletExitButton';
// import PhotoGroupCover from './PhotoGroupCover';
import PhotoGroupContentSingleImage from './PhotoGroupContentSingleImage';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 785,
    maxWidth: '100%',
  },
  sectionTitle: {
    fontWeight: 'bold',
    fontSize: 15,
  },
  spanText: {
    fontFamily: 'Brilliant Cut Pro',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: 15,
    border: '1px solid black',
    padding: '2px 5px',
    marginRight: '5px',
  },
  place: {
    fontSize: 18,
    fontFamily: 'Fancy Cut Pro',
    fontStyle: 'italic',
  },
  singleImage: {
    position: 'relative',
    width: '100%',
    marginTop: '2rem',
    paddingBottom: '2rem',
    '&:hover $downloadButton': {
      opacity: 1,
    },
  },
  previewImage: {
    width: '100%',
  },
  downloadButton: {
    position: 'absolute',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    boxShadow: '0 4px 4px rgba(0, 0, 0, 0.4)',
    top: theme.spacing(-4),
    right: theme.spacing(-4),
    zIndex: 1,
    opacity: 0,
    transition: 'opacity 0.5s, background-color 0.5s',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  explainer: {
    fontSize: 18,
    fontFamily: 'Fancy Cut Pro',
    fontStyle: 'italic',
    margin: theme.spacing(3, 0, 1),
  },
  grid: {
    paddingBottom: theme.spacing(6),
  },
}));

const myPicUrl = 'https://www.getpica.com/checkins/3OW408F2N';
const imageUrl = 'https://cdn.eventtia.com/event_files/192416/large/wm24grouppicture17199332511719933251.jpg?1719933251';
const toDownloadImageUrl = 'https://cdn.eventtia.com/event_files/192416/original/wm24grouppicture17199332511719933251.jpg?1719933251';
const Gallery = () => {
  const classes = useStyles();

  const [currentPhotoGroup, setCurrentPhotoGroup] = useState();
  const closeGroup = () => {
    setCurrentPhotoGroup();
  };

  const downloadImage = (url, filename) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const objectUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = objectUrl;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  return (
    <div className={classes.root}>
      <TabletExitButton />
      {!currentPhotoGroup && (
        <>
          <SubpageTitle text="Photo Gallery" />
          <Typography className={classes.sectionTitle} variant="subtitle2">
            WORLD MEETING 20.24
          </Typography>
          <Typography className={classes.place} variant="subtitle2">
            Marbella, Spain
          </Typography>
          <Typography className={classes.explainer} variant="subtitle2">
            <span className={classes.spanText}>NEW</span>You will find all the pictures of the
            World Meeting in which you are appearing here:&nbsp;
            <Link href={myPicUrl} target="_blank" rel="noopener noreferrer">{myPicUrl}</Link>
            <br />
            To consult your photos:
            <br />
            1/ sign up
            <br />
            2/ enter the code WM24PHOTO
            <br />
            3/ access to your gallery
          </Typography>
          <div className={classes.singleImage}>
            <IconButton
              className={classes.downloadButton}
              onClick={() => downloadImage(toDownloadImageUrl, 'group_picture.jpg')}
              color="primary"
            >
              <DownloadIcon />
            </IconButton>
            <img className={classes.previewImage} src={imageUrl} alt="Group" />
          </div>
          {/* <Grid container spacing={1} className={classes.grid}>
            <Grid item xs={8}>
              <PhotoGroupCover
                group="wm"
                mainText="WM"
                height={241}
                setCurrentPhotoGroup={setCurrentPhotoGroup}
              />
            </Grid>
            <Grid item xs={4}>
              <PhotoGroupCover
                group="jun24"
                topText="Mon"
                mainText="24"
                bottomText="June"
                height={241}
                setCurrentPhotoGroup={setCurrentPhotoGroup}
              />
            </Grid>
            <Grid item xs={4}>
              <PhotoGroupCover
                group="jun25"
                topText="Tue"
                mainText="25"
                bottomText="June"
                height={189}
                setCurrentPhotoGroup={setCurrentPhotoGroup}
              />
            </Grid>
            <Grid item xs={8}>
              <PhotoGroupCover
                group="jun26"
                topText="Wed"
                mainText="26"
                bottomText="June"
                height={189}
                setCurrentPhotoGroup={setCurrentPhotoGroup}
              />
            </Grid>
            <Grid item xs={8}>
              <PhotoGroupCover
                group="jun27"
                topText="Thu"
                mainText="27"
                bottomText="June"
                height={290}
                setCurrentPhotoGroup={setCurrentPhotoGroup}
              />
            </Grid>
            <Grid item xs={4}>
              <PhotoGroupCover
                group="jun28"
                topText="Fri"
                mainText="28"
                bottomText="June"
                height={290}
                setCurrentPhotoGroup={setCurrentPhotoGroup}
              />
            </Grid>
          </Grid> */}
        </>
      )}
      {!!currentPhotoGroup && (
        <PhotoGroupContentSingleImage
          group={currentPhotoGroup}
          closeGroup={closeGroup}
        />
      )}
    </div>
  );
};

Gallery.propTypes = {
};

Gallery.defaultProps = {
};

export default Gallery;
