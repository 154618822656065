import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import fileIcon from './fileIcon';
import callApi from '../../actions/callApi';
import CustomPropTypes from '../../helpers/CustomPropTypes';

const useStyles = makeStyles((theme) => ({
  attachment: {
    width: '100%',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    boxShadow: theme.customShadows.small,
    minHeight: theme.spacing(10),
  },
  mobileAttachment: {
    width: '100%',
    margin: theme.spacing(1, 0),
  },
  pipe: {
    color: theme.palette.lightGrey.dark,
    margin: theme.spacing(0, 4, 0, 0),
    fontSize: theme.spacing(4),
    lineHeight: '1em',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 2),
    },
  },
  fileIcon: {
    color: theme.palette.darkGrey.main,
    marginRight: theme.spacing(1.5),
  },
  fileSection: {
    display: 'flex',
    width: '30%',
    minWidth: 100,
    marginRight: theme.spacing(1),
    alignItems: 'center',
  },
  fileName: {
    fontSize: 14,
    fontWeight: 'bold',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  fileDescription: {
    width: '60%',
  },
  link: {
    color: theme.palette.primary.light,
    textDecoration: 'none',
  },
  tooltipProperties: {
    fontSize: 12,
  },
  downloadIcon: {
    color: theme.palette.primary.main,
  },
}));

const AttachmentFile = ({ file, callApi: dispatchCallApi }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { description } = file;

  const shortDescription = description.length > 50
    ? `${description.substring(0, 50)} ...`
    : description;

  const markAsDownloaded = () => {
    const { eventFileCategory: { id: fileCategoryId }, id: fileId } = file;
    dispatchCallApi('markAsDownloaded', { fileCategoryId, fileId });
  };

  const handleDownloadFile = () => {
    markAsDownloaded();
    window.open(file.eventFile, '_blank');
  };

  const extension = file.eventFile?.substring(file.eventFile.lastIndexOf('.') + 1, file.eventFile.length).split('?')[0] || file.eventFile;
  return (
    <div className={clsx(classes.attachment, mobile && classes.mobileAttachment)}>
      <div className={classes.fileSection}>
        <img
          src={fileIcon[extension] ? fileIcon[extension].icon : fileIcon.other.icon}
          alt={fileIcon[extension] ? fileIcon[extension].name : fileIcon.other.name}
          className={classes.fileIcon}
        />
        <Typography className={classes.fileName} variant="h5">
          <a className={classes.link} onClick={markAsDownloaded} href={file.eventFile} rel="noopener noreferrer" target="_blank">
            {file.name}
          </a>
        </Typography>
      </div>
      <span className={classes.pipe}>|</span>
      <div className={classes.fileDescription}>
        <Tooltip
          placement="top-start"
          arrow
          title={<p style={{ fontSize: '14px' }}>{description}</p>}
          className={classes.tooltipProperties}
        >
          <Typography variant="body2">
            {shortDescription}
          </Typography>
        </Tooltip>
      </div>
      <div>
        <IconButton onClick={handleDownloadFile}>
          <GetAppIcon className={classes.downloadIcon} />
        </IconButton>
      </div>
    </div>
  );
};

AttachmentFile.propTypes = {
  file: CustomPropTypes.eventFile.isRequired,
  callApi: PropTypes.func.isRequired,
};

export default connect(null, { callApi })(AttachmentFile);
