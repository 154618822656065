import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import Chip from '@material-ui/core/Chip';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Message from '../../components/Message';
import InfiniteScroll from '../../components/InfiniteScroll';
import callApi from '../../actions/callApi';
import QuizShow from '../QuizShow';
import SubpageTitle from '../../components/SubpageTitle';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import TabletExitButton from '../../components/TabletExitButton';
import { getModuleNames, getModuleByType } from '../../helpers/getters';
import { setActiveQuiz } from '../../actions/app';

const useStyles = makeStyles((theme) => ({
  scroll: {
    minWidth: 320,
    padding: theme.spacing(6),
    maxHeight: '100%',
  },
  mobileScroll: {
    padding: '28px 21px 20px 21px',
    maxHeight: '100%',
  },
  moduleDescription: {
    color: theme.palette.darkGrey.light,
  },
  quiz: {
    border: 0,
    width: '100%',
    display: 'flex',
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    boxShadow: theme.customShadows.small,
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  answeredSurvey: {
    justifyContent: 'flex-start',
    cursor: 'default',
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  surveyIcon: {
    color: theme.palette.primary.main,
  },
  surveyName: {
    marginLeft: 14,
    fontWeight: 900,
    color: theme.palette.darkGrey.light,
  },
  forwardButton: {
    height: 12,
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  answered: {
    color: theme.palette.green.main,
    height: 24,
  },
  completedChip: {
    backgroundColor: theme.palette.green.main,
    color: theme.palette.common.white,
    fontWeight: 'bold',
    marginLeft: theme.spacing(2),
  },
  root: {
    width: '100%',
  },
}));

const Quizzes = ({
  callApi: dispatchCallApi, surveys, isFetchingSurveys, surveysError, links, appSettings,
  setActiveQuiz: dispatchsetActiveQuiz, quizId, scrollToTop,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['surveys', 'global']);
  const [settings] = Object.values(appSettings);
  const [surveyAnswered, setSurveyAnswered] = useState(false);
  const moduleTitle = getModuleNames(appSettings, 'Quizzes');
  const quizzesModule = getModuleByType(settings.modules, 'Quizzes');
  const description = quizzesModule.customParams?.description;
  const quizzes = Object.values(surveys).filter(({ sessionType }) => sessionType === 'quiz');

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchNextPageIfExists = () => {
    const { next } = links;
    if (!quizId && next) dispatchCallApi('surveys', next);
  };

  useEffect(() => {
    dispatchCallApi('quizzes');
  }, [dispatchCallApi]);

  const activeQuiz = surveys?.[quizId];

  return (
    <div className={classes.root}>
      {!activeQuiz && (
        <TabletExitButton />
      )}
      {activeQuiz ? (
        <QuizShow
          quiz={activeQuiz}
          onSuccess={() => setSurveyAnswered(true)}
          scrollToTop={scrollToTop}
        />
      ) : (
        <InfiniteScroll
          loading={isFetchingSurveys}
          error={surveysError}
          fetchNextPageIfExists={fetchNextPageIfExists}
          className={mobile ? classes.mobileScroll : classes.scroll}
        >
          <SubpageTitle text={moduleTitle} />
          {description && (
            <Typography className={classes.moduleDescription} variant="body1">
              {description}
            </Typography>
          )}
          {surveyAnswered && (
            <Message
              type="success"
              message={t('messages.success')}
              className={classes.message}
              onClose={() => setSurveyAnswered(false)}
            />
          )}
          {quizzes?.map((quiz) => (
            <button
              type="button"
              key={quiz.id}
              className={clsx(classes.quiz, quiz.answered && classes.answeredSurvey)}
              onClick={() => {
                if (!quiz.answered) {
                  setSurveyAnswered(false);
                  dispatchsetActiveQuiz(quiz.id);
                }
              }}
            >
              <div className={classes.nameContainer}>
                <EmojiObjectsIcon className={classes.surveyIcon} />
                <Typography className={classes.surveyName} variant="body2">
                  {quiz.name}
                </Typography>
              </div>
              {quiz.answered ? (
                <Chip
                  className={classes.completedChip}
                  label={t('global:status.completed')}
                  size="small"
                />
              ) : (
                <ArrowForwardIosIcon
                  className={classes.forwardButton}
                />
              )}
            </button>
          )
          )}
        </InfiniteScroll>
      )}
    </div>
  );
};

Quizzes.propTypes = {
  callApi: PropTypes.func.isRequired,
  setActiveQuiz: PropTypes.func.isRequired,
  surveys: PropTypes.objectOf(CustomPropTypes.quiz),
  quizId: PropTypes.string,
  isFetchingSurveys: PropTypes.bool,
  surveysError: PropTypes.string,
  links: PropTypes.shape({
    next: PropTypes.string,
  }),
  appSettings: CustomPropTypes.appSettings.isRequired,
  scrollToTop: PropTypes.func.isRequired,
};

Quizzes.defaultProps = {
  surveys: {},
  quizId: undefined,
  isFetchingSurveys: false,
  surveysError: undefined,
  links: {},
};

const mapStateToProps = ({
  entities: {
    surveys,
    appSettings,
  },
  fetchStatus: {
    surveys: {
      isFetching: isFetchingSurveys,
      error: surveysError,
      links,
    },
  },
  app: {
    activeSubpage: {
      quizId,
    },
  },
}) => ({
  surveys,
  isFetchingSurveys,
  surveysError,
  links,
  appSettings,
  quizId,
});

export default connect(mapStateToProps, { callApi, setActiveQuiz })(Quizzes);
