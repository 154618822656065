import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { camelize } from 'humps';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PeopleIcon from '@material-ui/icons/People';
import Avatar from '@material-ui/core/Avatar';
import Modal from 'eventtia-ui-components/lib/Modal';
import Button from 'eventtia-ui-components/lib/Button';
import { baseMoment } from '../../helpers/dates';
import useTimer from '../../hooks/useTimer';
import emailRegexp from '../../helpers/strings';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import CustomFieldValue from '../CustomFieldValue';
import parseCustomFields from '../../helpers/customFields';
import useStringTransforms from '../../hooks/useStringTransforms';
import { MEETING_REQUEST_STATUSES } from '../../helpers/constants';

const useStyles = makeStyles((theme) => ({
  modalPaper: {
    borderRadius: 12,
    maxWidth: '100%',
    width: 500,
    color: theme.palette.darkGrey.light,
  },
  modalContent: {
    padding: theme.spacing(7, 5, 3),
  },
  participantInfo: {
    display: 'flex',
    flexDirection: 'row',
  },
  avatar: {
    height: theme.spacing(8),
    width: theme.spacing(8),
    marginRight: theme.spacing(1.5),
  },
  info: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  name: {
    fontWeight: 600,
  },
  fields: {
    marginTop: theme.spacing(5),
  },
  fieldValue: {
    color: theme.palette.darkGrey.main,
    margin: theme.spacing(1, 0, 3),
  },
  modalButtons: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(5),
  },
  modalButton: {
    marginRight: theme.spacing(2),
  },
}));

const { REJECTED, CANCELLED } = MEETING_REQUEST_STATUSES;

const MeetingRequestModal = ({
  openedRequest, attendees, closeModal, attendeeTypeCustomFields, businessConferenceParticipants,
  conferences, requestsSent, setApproveDialog, setRejectDialog, setHideOpenedRequest,
  disabledButtons,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['meeting', 'attendees', 'stage']);
  const { toSentenceCase } = useStringTransforms();

  const {
    sentBy: { id: senderId }, receivedBy: { id: receiverId }, status, message,
  } = openedRequest;
  const otherParticipant = businessConferenceParticipants[requestsSent ? receiverId : senderId];
  const otherAttendee = attendees[otherParticipant.attendee.id];
  const {
    profileContactName,
    profileDisplayName,
    jobTitle,
    businessConferenceId,
  } = otherParticipant;

  const { schedulingStartsOn, bookingDeadlineDate } = conferences[businessConferenceId];

  const [now, setNow] = useState(baseMoment().format());
  useTimer({ onTick: setNow, step: 5000 });

  const schedulingStarted = useMemo(() => (
    baseMoment(now).isSameOrAfter(schedulingStartsOn)
  ), [now, schedulingStartsOn]);
  const schedulingEnded = useMemo(() => (
    baseMoment(now).isSameOrAfter(bookingDeadlineDate)
  ), [now, bookingDeadlineDate]);

  const cantUpdateRequest = !schedulingStarted || schedulingEnded;
  const isArchivedRequest = (status === REJECTED) || (status === CANCELLED);

  const fields = parseCustomFields(otherAttendee?.fields || {}, attendeeTypeCustomFields);
  const {
    headshot,
    email,
    telephone,
    cityId,
  } = fields || {};

  const aliases = {};
  Object.keys(otherAttendee?.fields || {}).forEach((id) => {
    aliases[camelize(attendeeTypeCustomFields[id]?.name)] = attendeeTypeCustomFields[id]?.alias;
  });

  return (
    <Modal
      open
      fullWidth
      handleClose={closeModal}
      classes={{ paper: classes.modalPaper }}
    >
      <div className={classes.modalContent}>
        <div className={classes.participantInfo}>
          <Avatar
            src={headshot?.thumb}
            className={classes.avatar}
          >
            <PeopleIcon />
          </Avatar>
          <div className={classes.info}>
            <div>
              <Typography variant="body1" className={classes.name}>
                {profileContactName}
              </Typography>
              <Typography variant="body2">
                {jobTitle && t('stage:timeline.positionAt', { position: jobTitle })}
                {profileDisplayName}
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.fields}>
          {cityId?.country && (
            <>
              <Typography variant="caption">
                {t('attendees:data.country')}
              </Typography>
              <Typography variant="body2" className={classes.fieldValue}>
                {cityId.country}
              </Typography>
            </>
          )}
          {cityId?.region && (
            <>
              <Typography variant="caption">
                {t('attendees:data.region')}
              </Typography>
              <Typography variant="body2" className={classes.fieldValue}>
                {cityId.region}
              </Typography>
            </>
          )}
          {cityId?.city && (
            <>
              <Typography variant="caption">
                {aliases.cityId || t('attendees:data.city')}
              </Typography>
              <Typography variant="body2" className={classes.fieldValue}>
                {cityId.city}
              </Typography>
            </>
          )}
          {email && (
            <>
              <Typography variant="caption">
                {aliases.email || t('attendees:data.email')}
              </Typography>
              <Typography variant="body2" className={classes.fieldValue}>
                {emailRegexp.test(email) ? (
                  <a href={`mailto:${email}`}>{email}</a>
                ) : email}
              </Typography>
            </>
          )}
          {telephone && (
            <>
              <Typography variant="caption">
                {aliases.telephone || t('attendees:data.phone')}
              </Typography>
              <Typography variant="body2" className={classes.fieldValue}>
                {telephone}
              </Typography>
            </>
          )}
          {Object.values(attendeeTypeCustomFields)
            .sort((a, b) => (a.order - b.order))
            .map(({ id: fieldKey }) => (
              fields[fieldKey]
              && attendeeTypeCustomFields[fieldKey]
              && !(attendeeTypeCustomFields[fieldKey].default) ? (
                <div key={fieldKey}>
                  <Typography variant="caption">
                    {toSentenceCase(attendeeTypeCustomFields[fieldKey].name)}
                  </Typography>
                  <CustomFieldValue
                    field={attendeeTypeCustomFields[fieldKey]}
                    value={fields[fieldKey]}
                    classes={{
                      anchorValue: classes.anchor,
                      value: classes.data,
                      img: classes.imgData,
                    }}
                  />
                </div>
                ) : null
            ))}
          {message && (
            <>
              <Typography variant="caption">
                {t('requests.meetingMessage')}
              </Typography>
              <Typography variant="body2" className={classes.fieldValue}>
                {message}
              </Typography>
            </>
          )}
        </div>
        <div className={classes.modalButtons}>
          <Button
            small
            variant="primary"
            onClick={() => {
              setApproveDialog(true);
              setHideOpenedRequest(true);
            }}
            className={classes.modalButton}
            disabled={cantUpdateRequest || requestsSent || disabledButtons || isArchivedRequest}
            fullWidth
          >
            {t('actions.approve')}
          </Button>
          <Button
            small
            variant="secondary"
            onClick={() => {
              setRejectDialog(true);
              setHideOpenedRequest(true);
            }}
            fullWidth
            disabled={cantUpdateRequest || disabledButtons || isArchivedRequest}
          >
            {requestsSent ? t('actions.cancel') : t('actions.reject')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

MeetingRequestModal.propTypes = {
  openedRequest: CustomPropTypes.meetingRequest.isRequired,
  closeModal: PropTypes.func.isRequired,
  attendees: PropTypes.objectOf(CustomPropTypes.attendee).isRequired,
  businessConferenceParticipants: PropTypes.objectOf(
    CustomPropTypes.participant
  ).isRequired,
  attendeeTypeCustomFields: PropTypes.objectOf(
    CustomPropTypes.attendeeTypeCustomField
  ),
  conferences: PropTypes.objectOf(
    CustomPropTypes.conference
  ).isRequired,
  requestsSent: PropTypes.bool,
  setApproveDialog: PropTypes.func,
  setRejectDialog: PropTypes.func,
  setHideOpenedRequest: PropTypes.func,
  disabledButtons: PropTypes.bool,

};

MeetingRequestModal.defaultProps = {
  attendeeTypeCustomFields: {},
  requestsSent: false,
  disabledButtons: false,
  setApproveDialog: undefined,
  setRejectDialog: undefined,
  setHideOpenedRequest: undefined,
};

const mapStateToProps = ({
  entities: {
    businessConferences: conferences,
  },
  meta: {
    meetingStatuses,
  },

}) => ({
  meetingStatuses,
  conferences,
});

export default connect(mapStateToProps)(MeetingRequestModal);
