import React from 'react';
import MuiButton from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    lineHeight: 1,
    borderRadius: 0,
    color: theme.palette.background.default,
    boxShadow: theme.customShadows.small,
    padding: theme.spacing(0, 2),
    fontFamily: 'Brilliant Cut Pro',
    fontWeight: 700,
    textTransform: 'none',
    fontSize: theme.typography.button.fontSize,
    minWidth: 170,
    minHeight: 35,
    '&:hover, &:active, &:focus': {
      boxShadow: 'none',
    },
  },
  disabled: {
    color: theme.palette.lightGrey.dark,
  },
  sizeSmall: {
    fontSize: theme.typography.buttonSmall.fontSize,
    minWidth: 140,
    minHeight: 30,
  },
  containedSecondary: {
    backgroundColor: theme.palette.lightGrey.main,
    color: theme.palette.background.backdrop,
    '&:hover': {
      backgroundColor: theme.palette.lightGrey.dark,
    },
  },
}));

const variantMap = {
  primary: {
    variant: 'contained',
    color: 'primary',
  },
  secondary: {
    variant: 'contained',
    color: 'secondary',
  },
};

const Button = (props) => {
  const {
    variant, small, icon, ...otherProps
  } = props;
  const classes = useStyles();
  return (
    <MuiButton
      {...otherProps}
      classes={classes}
      variant={variantMap[variant].variant}
      color={variantMap[variant].color}
      size={small ? 'small' : 'medium'}
      startIcon={icon}
    />
  );
};

Button.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  small: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
};

Button.defaultProps = {
  variant: 'primary',
  small: false,
  disabled: false,
  icon: undefined,
};

export default Button;
