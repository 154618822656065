import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import Button from 'eventtia-ui-components/lib/Button';
import SearchInput from 'eventtia-ui-components/lib/SearchInput';
import SelectInput from 'eventtia-ui-components/lib/SelectInput';
import callApi from '../../actions/callApi';
import SponsorCardAlt from '../../components/SponsorCard/SponsorsCardAlt';
import SponsorCard from '../../components/SponsorCard';
import SponsorCardSmall from '../../components/SponsorCardSmall';
import SubpageTitle from '../../components/SubpageTitle';
import SponsorShow from '../SponsorShow';
import { getModuleNames } from '../../helpers/getters';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import TabletExitButton from '../../components/TabletExitButton';
import { setActiveSponsor } from '../../actions/app';
import useParams from '../../hooks/useParams';

const useStyles = makeStyles((theme) => ({
  container: {
    width: 785,
    padding: 0,
  },
  tabletContainer: {
    width: '100%',
  },
  scroll: {
    minWidth: 320,
    padding: theme.spacing(6),
    maxHeight: '100%',
  },
  search: {
    margin: theme.spacing(3, 0, 1.5, 0),
    display: 'flex',
    minHeight: theme.spacing(6),
    justifyContent: 'flex-end',
  },
  mobileSearchInput: {
    fontSize: 12,
  },
  moduleName: {
    padding: theme.spacing(1.5, 0),
    color: theme.palette.darkGrey.main,
    fontWeight: 'bold',
  },
  moduleDescription: {
    color: theme.palette.darkGrey.light,
  },
  filtersButtons: {
    alignItems: 'flex-end',
  },
  buttonMobile: {
    marginTop: theme.spacing(2),
  },
  sponsorsDefault: {
    marginTop: theme.spacing(3),
  },
  sponsorCategory: {
    margin: theme.spacing(3, 0),
    padding: theme.spacing(0.5, 1),
    color: theme.palette.common.white,
    fontWeight: 'bold',
    width: 'fit-content',
    borderRadius: 5,
  },
  sponsorCard: {
    minWidth: 155,
  },
}));

const Sponsors = ({
  sponsors, sponsorCategories, appSettings, activeSponsor,
  setActiveSponsor: dispatchSetActiveSponsor, tags, callApi: dispatchCallApi,
  events, scrollToTop,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tablet = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation('sponsors');
  const { locale, eventUri } = useParams();

  const moduleTitle = getModuleNames(appSettings, 'Sponsors');

  const [filtersOpen, setFiltersOpen] = useState(false);

  const [filters, setFilters] = useState({});
  const [selectedTags, setSelectedTags] = useState([]);
  const [keyword, setKeyword] = useState();
  const searchRef = useRef(null);
  const search = () => {
    setKeyword(searchRef.current?.value);
  };

  const sortedCategories = Object.values(sponsorCategories).sort((a, b) => a.order - b.order);

  // HACK PARA SPONSORSCARD
  // when hack being removed please remove component and import.
  const [currentEvent] = Object.values(events);
  const [sponsorCardSizing, setSponsorCardSizing] = useState({});
  const { event } = Object.values(appSettings)[0];
  const eventId = parseInt(event?.id, 10);
  const accId = parseInt(currentEvent?.account?.id, 10);
  const hackedAccounts = [19444].includes(accId);
  const hackedEvents = [18329].includes(eventId);
  useEffect(() => {
    setSponsorCardSizing((hackedAccounts || hackedEvents)
      ? { xs: 12, sm: 12, md: 6 } : { xs: 12, sm: 6, md: 3 });
  }, [eventId]);
  // FIN HACK PARA SPONSORSCARD

  const toggleFilters = useCallback(() => setFiltersOpen(!filtersOpen),
    [filtersOpen, setFiltersOpen]);

  useEffect(() => {
    dispatchCallApi('tags', { eventUri });
  }, [dispatchCallApi, eventUri]);

  const tagsOptions = Object.values(tags)
    .map(({ name, id }) => ({ label: name, value: id }));

  const renderTags = (values) => values.map((value) => tags[value].name).join(', ');

  const haveAnyFilter = filters?.tags?.length > 0;

  const sortedSponsors = Object.values(sponsors)
    .sort((a, b) => a.name.trim().localeCompare(b.name.trim(), locale, { numeric: true }));

  let filteredSponsors = sortedSponsors;
  if (keyword) filteredSponsors = filteredSponsors.filter(
    ({ name }) => name.toLowerCase().includes(keyword.toLowerCase())
  );
  if (filters?.tags?.length > 0) filteredSponsors = filteredSponsors.filter(
    (sponsor) => sponsor.tags?.some(({ id }) => filters.tags.includes(id))
  );

  const onSubmit = () => setFilters({ tags: selectedTags });

  const clearSearch = () => {
    searchRef.current.value = '';
    searchRef.current.blur();
    setKeyword();
  };

  const clearFilters = () => {
    setFilters({});
    setSelectedTags([]);
  };

  return (
    <Container
      className={clsx(classes.container, tablet && classes.tabletContainer)}
    >
      {!activeSponsor && (
        <TabletExitButton />
      )}
      {activeSponsor ? (
        <SponsorShow
          sponsor={activeSponsor}
          categories={sponsorCategories}
          scrollToTop={scrollToTop}
        />
      ) : (
        <>
          <SubpageTitle text={moduleTitle || t('title.sponsors')} />
          <div className={classes.search}>
            {(Object.values(sponsors).length > 0) && (
              <SearchInput
                placeholder={t('forms.searchKeyword')}
                inputRef={searchRef}
                onSubmit={search}
                onClear={clearSearch}
                className={mobile ? classes.mobileSearchInput : undefined}
                fullWidth={false}
                margin="dense"
              />
            )}
            {Object.values(tags).length > 0 && (
              mobile ? (
                <IconButton
                  aria-label="filter"
                  className={classes.filterSmallButton}
                  onClick={toggleFilters}
                  color="primary"
                  size="small"
                >
                  <FilterListIcon />
                </IconButton>
              ) : (
                <Button
                  className={classes.filterButton}
                  icon={<FilterListIcon />}
                  variant="tertiary"
                  onClick={toggleFilters}
                  small
                >
                  {filtersOpen ? t('global:actions.hideFilters') : t('global:filters.title')}
                </Button>
              ))}
          </div>
          {filtersOpen && (
            <Grid container spacing={2} className={classes.filtersButtons}>
              <Grid item xs={12} md={3}>
                <SelectInput
                  id="tags-filter"
                  name="tags-filter"
                  value={selectedTags}
                  options={tagsOptions}
                  handleChange={setSelectedTags}
                  renderValue={() => renderTags(selectedTags)}
                  label={t('forms.tags')}
                  multiple
                />
              </Grid>
              <Grid container item xs={12} md={9} spacing={!mobile && 2}>
                <Grid item xs={12} md={4}>
                  <Button
                    variant="secondary"
                    onClick={onSubmit}
                    small
                    fullWidth
                  >
                    {t('global:actions.search')}
                  </Button>
                </Grid>
                {haveAnyFilter && (
                  <Grid item xs={12} md={4}>
                    <Button
                      variant="tertiary"
                      onClick={clearFilters}
                      className={clsx(mobile && classes.buttonMobile)}
                      small
                      fullWidth
                    >
                      {t('global:actions.clear')}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
          {sortedCategories.map((category) => {
            const { name, color, id, sponsorLevel } = category;
            return filteredSponsors.some((sponsor) => sponsor.category?.id === id) && (
              <React.Fragment key={id}>
                <Typography
                  className={classes.sponsorCategory}
                  variant="subtitle2"
                  key={name}
                  style={{ backgroundColor: color }}
                >
                  {name}
                </Typography>
                <Grid key={category.id} container spacing={2}>
                  {(hackedAccounts || hackedEvents) ? (
                    filteredSponsors.map((sponsor) => (sponsor.category?.id === id && (
                      <Grid
                        item
                        xs={sponsorCardSizing.xs}
                        sm={sponsorCardSizing.sm}
                        md={sponsorCardSizing.md}
                        key={sponsor.id}
                        className={classes.sponsorCard}
                      >
                        {(sponsorCardSizing?.md === 6)
                          ? (
                            <SponsorCardAlt
                              sponsor={sponsor}
                              onBlockClick={() => dispatchSetActiveSponsor(sponsor.id)}
                            />
                          )
                          : (
                            <SponsorCard
                              sponsor={sponsor}
                              category={name}
                              onBlockClick={() => dispatchSetActiveSponsor(sponsor.id)}
                            />
                          )}
                      </Grid>
                    )))
                  ) : (
                    filteredSponsors.map((sponsor) => (sponsor.category?.id === id && (
                      (sponsorLevel === 'level_1' || sponsorLevel === 'level_2') ? (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={sponsorLevel === 'level_1' ? 12 : 6}
                          key={sponsor.id}
                          className={classes.sponsorCard}
                        >
                          <SponsorCardAlt
                            sponsorLevelTwo={sponsorLevel === 'level_2'}
                            sponsor={sponsor}
                            onBlockClick={() => dispatchSetActiveSponsor(sponsor.id)}
                          />
                        </Grid>
                      ) : (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          key={sponsor.id}
                          className={classes.sponsorCard}
                        >
                          <SponsorCardSmall
                            sponsor={sponsor}
                            onBlockClick={() => dispatchSetActiveSponsor(sponsor.id)}
                          />
                        </Grid>
                      )
                    )))
                  )}
                </Grid>
                <Divider flex="true" />
              </React.Fragment>
            );
          })}
          <Grid container className={classes.sponsorsDefault} spacing={2}>
            {filteredSponsors.map((sponsor) => (!sponsor.category?.id ? (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                key={sponsor.id}
                className={classes.sponsorCard}
              >
                <SponsorCardSmall
                  sponsor={sponsor}
                  onBlockClick={() => dispatchSetActiveSponsor(sponsor.id)}
                />
              </Grid>
            ) : null))}
          </Grid>
        </>
      )}
    </Container>
  );
};

Sponsors.propTypes = {
  events: PropTypes.objectOf(CustomPropTypes.event),
  sponsors: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
      category: PropTypes.shape({
        id: PropTypes.string,
      }),
    })
  ),
  sponsorCategories: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      color: PropTypes.string,
    })
  ),
  appSettings: CustomPropTypes.appSettings.isRequired,
  activeSponsor: CustomPropTypes.sponsor,
  setActiveSponsor: PropTypes.func.isRequired,
  callApi: PropTypes.func.isRequired,
  tags: PropTypes.objectOf(
    CustomPropTypes.tags.isRequired
  ),
  scrollToTop: PropTypes.func.isRequired,
};

Sponsors.defaultProps = {
  activeSponsor: null,
  sponsors: {},
  sponsorCategories: {},
  tags: {},
  events: {},
};

const mapStateToProps = ({
  entities: {
    sponsors,
    sponsorCategories,
    appSettings,
    tags,
    events,
  },
  app: {
    activeSubpage: {
      sponsorId,
    },
  },
}) => ({
  sponsors,
  sponsorCategories,
  appSettings,
  activeSponsor: sponsors?.[sponsorId],
  tags,
  events,
});

export default connect(mapStateToProps,
  { callApi, setActiveSponsor })(Sponsors);
