import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';
import CircularProgress from '@material-ui/core/CircularProgress';
import { camelize } from 'humps';
import withForm from '../../hocs/withForm';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import DownloadIcon from '../../assets/download.png';
import CountryField from '../CountryField';
import CustomField from '../CustomField';
import FileModal from '../FileModal';
import MyQrButton from '../MyQrButton';
import { getCurrentAttendee } from '../../helpers/getters';
import parseCustomFields, { parseFieldsByInternalId } from '../../helpers/customFields';
import emailRegexp from '../../helpers/strings';
import moduleEnabledForCurrentAttendeeType from '../../helpers/moduleEnabledForCurrentAttendeeType';
import INPUT_TYPES, { ATTENDANCE_MODE } from '../../helpers/constants';
import InputBase from '../InputBase';
import PhoneInput from '../PhoneInput';
import DateInput from '../DateInput';
import Button from '../Button';
import FlightInput from '../FlightInput';
import { baseMoment } from '../../helpers/dates';

const { VIRTUAL } = ATTENDANCE_MODE;
const fieldSizes = {
  // 100: 4,
  101: 6,
  102: 6,
  // 103: 4,
  104: 6,
  105: 6,
  107: 4,
  108: 4,
  111: 8,
  113: 6,
  114: 6,
  116: 8,
  115: 8,
  117: 6,
  118: 6,
  needtransfer: 8,
  birthdate: 6,
  Departure: 8,
  downloadVisaInvitationButton: 6,
};

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    marginTop: theme.spacing(1),
    textAlign: 'right',
  },
  customFieldsWrapper: {
    marginTop: 6,
  },
  simpleInput: {
    background: 'none',
    fontWeight: 'normal',
  },
  mainInfoWrapper: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
  },
  profileHeader: {
    alignItems: 'center',
    margin: theme.spacing(0, 0, 2.5, 0),
    display: 'flex',
    width: '100%',
  },
  avatarGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  avatarWrapper: {
    position: 'relative',
    padding: theme.spacing(1, 4),
  },
  editPhotoButton: {
    position: 'absolute',
    width: 32,
    height: 32,
    zIndex: 100,
    right: 10,
    top: -4,
    backgroundColor: theme.palette.lightGrey.main,
    '&:hover': {
      backgroundColor: theme.palette.lightGrey.main,
    },
    '& svg': {
      height: 20,
      width: 20,
    },
  },
  avatar: {
    top: 18,
    height: 170,
    width: 170,
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.lightGrey.main,
    color: theme.palette.primary.main,
  },
  nameAndJob: {
    flex: 1,
  },
  name: {
    fontSize: 16,
    color: theme.palette.darkGrey.dark,
    fontWeight: 'bold',
  },
  job: {
    color: theme.palette.darkGrey.main,
    fontSize: 14,
  },
  profileHeaderHybrid: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  profileHeaderHybridMobile: {
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  divider: {
    margin: theme.spacing(2, -3),
  },
  progressWrapper: {
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    width: 107,
    height: 107,
    right: -24,
    top: 0,
    zIndex: 1,
  },
  progress: {
    position: 'absolute',
    left: 0,
    top: 0,
  },
  progressBakcground: {
    color: theme.palette.lightGrey.main,
    position: 'absolute',
    left: 0,
    top: 0,
  },
  progressValue: {
    fontSize: 36,
    color: theme.palette.common.black,
  },
  progressComplete: {
    position: 'absolute',
    height: 129,
    top: -90,
    right: 0,
    color: theme.palette.primary.main,
    fontSize: 15,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'right',
    fontWeight: 700,
    // [theme.breakpoints.down('sm')]: {
    //   top: -170,
    // },
  },
  downloadVisaInvitationButton: {
    fontWeight: 500,
    width: '100%',
    padding: '0px 16px',
    minHeight: 39,
    color: '#ffffff',
    backgroundColor: theme.palette.primary.main,
    fontSize: 12,
    fontStyle: 'normal',
    fontFamily: 'Brilliant Cut Pro',
    borderRadius: 0,
    border: 0,
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    '& img': {
      width: 32,
      height: 32,
      objectFit: 'cover',
      marginRight: theme.spacing(),
    },
  },
  downloadVisaInvitationButtonDisabled: {
    backgroundColor: theme.palette.lightGrey.dark,
    pointerEvents: 'none',
  },
}));

const cantArrivalTransferFieldName = '<div style="font-size: 18px">Transfers cannot be arranged if the check-in date does not match the arrival date. </div>';
const cantDepartureTransferFieldName = '<div style="font-size: 18px">Transfers cannot be arranged if the check-out date does not match the departure date. </div>';

const validateField = (obj, id, name) => {
  if (obj[id] !== undefined) return obj[id];
  return obj[name];
};

const injectedLabelsFields = {
  cantArrivalTransfer: {
    id: 'cantArrivalTransfer',
    order: 24.1,
    name: cantArrivalTransferFieldName,
    inputType: INPUT_TYPES.LABEL,
    attendeeType: {
      id: undefined,
    },
    display: true,
  },
  cantDepartureTransfer: {
    id: 'cantDepartureTransfer',
    order: 30.1,
    name: cantDepartureTransferFieldName,
    inputType: INPUT_TYPES.LABEL,
    attendeeType: {
      id: undefined,
    },
    display: true,
  },
};

const getCustomFieldValue = (allValues, id, name) => (
  allValues?.[`attendee[customFields]._${id}`]
  || allValues?.attendee?.customFields?.[`_${id}`]
  || allValues?.[`attendee[${name}]`]
  || allValues?.attendee?.[name]
);

const progressFields = {
  130: true, // first_name
  131: true, // last_name
  132: true, // email
  134: true, // telephone
  135: true, // job_title
  136: true, // birthdate
  // 133: true, // company = Market/Department
  113: true, // Passport N°
  114: true, // Passport Expiration Date
  115: { // Arrival Airport Transfer
    YES: {
      // 108: true, // Arrival Date
      100: true, // Arrival Flight N°
    },
  },
  116: { // Departure Airport Transfer
    YES: {
      // 107: true, // Departure Date
      103: true, // Departure Flight N°
    },
  },
};
export const getProgress = (formValues, currentAttendee, attendeeTypeCustomFields) => {
  const fieldsId = parseFieldsByInternalId(currentAttendee.fields, attendeeTypeCustomFields);

  let numRequiredFields = 0;
  let numFilledFields = 0;
  Object.keys(progressFields).forEach((fieldInternalId) => {
    numRequiredFields += 1;

    const { id, name } = attendeeTypeCustomFields[fieldsId[fieldInternalId]];
    const value = getCustomFieldValue(formValues, id, name);

    if (value) numFilledFields += 1;

    const additionalRequirements = progressFields[fieldInternalId][value];
    if (additionalRequirements) Object.keys(additionalRequirements).forEach((addReqInternalId) => {
      numRequiredFields += 1;
      const arId = fieldsId[addReqInternalId];
      const arValue = getCustomFieldValue(formValues, arId);
      if (arValue) numFilledFields += 1;
    });
  });

  return Math.round((numFilledFields * 100.0) / numRequiredFields);
};
// export const getProgress = (formValues, currentAttendee, attendeeTypeCustomFields) => {
//   const { attendeeType: { id: currentAttendeeTypeId } } = currentAttendee;
//   const requiredFields = Object.values(attendeeTypeCustomFields).filter(
//     ({ required, attendeeType: { id: attendeeTypeId } }) => (
//       (currentAttendeeTypeId === attendeeTypeId) && !!required
//     )
//   );
//   const numRequiredFields = requiredFields.length;
//   const numFilledFields = requiredFields.filter(
//     ({ id, name }) => (
//       !!formValues[`attendee[${name}]`]
//       || !!formValues[`attendee[customFields]._${id}`]
//       || !!formValues?.attendee?.[name]
//       || !!formValues?.attendee?.customFields?.[`_${id}`]
//     )
//   ).length;
//   return Math.round((numFilledFields * 100.0) / numRequiredFields);
// };

const visaInvitationLinkEnabled = (formValues, fieldsId) => {
  const firstName = formValues['attendee[first_name]'];
  const lastName = formValues['attendee[last_name]'];

  const birthdate = formValues['attendee[birthdate]'];

  const jobTitle = formValues['attendee[job_title]'];

  // const company = formValues['attendee[company]'];

  const passportNumber = formValues[
    `attendee[customFields]._${fieldsId[113]}`
  ];

  const passportExpiryDate = formValues[
    `attendee[customFields]._${fieldsId[114]}`
  ];

  const checkinDate = formValues[
    `attendee[customFields]._${fieldsId[117]}`
  ];

  const checkoutDate = formValues[
    `attendee[customFields]._${fieldsId[118]}`
  ];

  const hotel = formValues[
    `attendee[customFields]._${fieldsId[121]}`
  ];

  return (
    !!firstName
    && !!lastName
    && !!birthdate
    && !!jobTitle
    // && !!company
    && !!passportNumber
    && !!passportExpiryDate
    && !!checkinDate
    && !!checkoutDate
    && !!hotel
  );
};

const downloadVisaInvitationLink = (formValues, fieldsId) => {
  const baseUrl = 'https://cartier-rooming.eventtia.com/api/utils/download-visa';
  const params = [];

  const firstName = formValues['attendee[first_name]'];
  const lastName = formValues['attendee[last_name]'];
  if (firstName || lastName) params.push(
    `full_name=${encodeURIComponent((`${firstName} ${lastName}`).trim())}`
  );

  const birthdate = formValues['attendee[birthdate]'];
  if (birthdate) params.push(
    `birthdate=${encodeURIComponent(birthdate)}`
  );

  const jobTitle = formValues['attendee[job_title]'];
  if (jobTitle) params.push(
    `position=${encodeURIComponent(jobTitle)}`
  );

  // const company = formValues['attendee[company]'];
  // if (company) params.push(
  //   `company=${encodeURIComponent(company)}`
  // );

  const passportNumber = formValues[`attendee[customFields]._${fieldsId[113]}`];

  if (passportNumber) params.push(
    `passport_number=${encodeURIComponent(passportNumber)}`
  );

  const passportExpiryDate = formValues[
    `attendee[customFields]._${fieldsId[114]}`
  ];
  if (passportExpiryDate) params.push(
    `passport_expiry_date=${encodeURIComponent(passportExpiryDate)}`
  );

  const checkinDate = formValues[
    `attendee[customFields]._${fieldsId[117]}`
  ];
  if (checkinDate) params.push(
    `checkin_date=${encodeURIComponent(baseMoment(checkinDate).format('LL'))}`
  );

  const checkoutDate = formValues[
    `attendee[customFields]._${fieldsId[118]}`
  ];
  if (checkoutDate) params.push(
    `checkout_date=${encodeURIComponent(baseMoment(checkoutDate).format('LL'))}`
  );

  const hotel = formValues[
    `attendee[customFields]._${fieldsId[121]}`
  ];
  if (hotel) params.push(
    `hotel_name=${encodeURIComponent(hotel)}`
  );

  return `${baseUrl}?${params.join('&')}`;
};

const frontPreValidations = (formValues, fieldsId) => {
  const arrivalDateId = fieldsId[108];
  const hotelCheckInId = fieldsId[117];
  const hotelCheckOutId = fieldsId[118];
  const departureDateId = fieldsId[107];

  const arrivalDate = formValues[`attendee[customFields]._${arrivalDateId}`];
  const hotelCheckInDate = formValues[`attendee[customFields]._${hotelCheckInId}`];
  const hotelCheckOutDate = formValues[`attendee[customFields]._${hotelCheckOutId}`];
  const departureDate = formValues[`attendee[customFields]._${departureDateId}`];

  const errors = {};

  if (
    !!arrivalDate
    && !!hotelCheckInDate
    && baseMoment(hotelCheckInDate).isBefore(arrivalDate)
  ) errors[hotelCheckInId] = 'Check in date can\'t be earlier than arrival date';
  if (
    !!departureDate
    && !!hotelCheckOutDate
    && baseMoment(hotelCheckOutDate).isAfter(departureDate)
  ) errors[hotelCheckOutId] = 'Check out date can\'t be after departure date';
  if (
    !!hotelCheckInDate
    && !!hotelCheckOutDate
    && baseMoment(hotelCheckInDate).isAfter(hotelCheckOutDate)
  ) errors[hotelCheckOutId] = 'Check out date can\'t be earlier than check in date';
  if (
    !!arrivalDate
    && !!departureDate
    && baseMoment(arrivalDate).isAfter(departureDate)
  ) errors[arrivalDateId] = 'Arrival date can\'t be after departure date';

  return errors;
};

const MyProfileForm = ({
  registerInput, errors, entities, attendeeTypeCustomFields: attendeeTypeCustomFieldsRaw,
  setFormValue, watchFormValues, setError, customErrors, clearError, disabled: submitDisabled,
  attendeeTypes,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('global');
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openEditPhoto, setOpenEditPhoto] = useState(false);

  const currentAttendee = getCurrentAttendee(entities);

  const attendeeTypeCustomFields = useMemo(() => {
    const newObj = { ...attendeeTypeCustomFieldsRaw };
    newObj.cantArrivalTransfer = injectedLabelsFields.cantArrivalTransfer;
    newObj.cantArrivalTransfer.attendeeType.id = currentAttendee.attendeeType.id;
    newObj.cantDepartureTransfer = injectedLabelsFields.cantDepartureTransfer;
    newObj.cantDepartureTransfer.attendeeType.id = currentAttendee.attendeeType.id;
    newObj.downloadVisaInvitationButton = {
      id: 'downloadVisaInvitationButton',
      order: 13.1,
      name: 'downloadVisaInvitationButton',
      inputType: INPUT_TYPES.DOWNLOAD_BUTTON,
      attendeeType: {
        id: undefined,
      },
      display: true,
    };
    return newObj;
  }, [attendeeTypeCustomFieldsRaw]);

  const customFields = parseCustomFields(currentAttendee.fields, attendeeTypeCustomFields);
  const {
    firstName,
    lastName,
    email,
    birthdate,
    alternativeEmail,
    company,
    jobTitle,
    cityId,
    headshot,
    telephone,
  } = customFields;
  const fieldsId = parseFieldsByInternalId(currentAttendee.fields, attendeeTypeCustomFields);
  const { attendanceMode } = attendeeTypes[currentAttendee.attendeeType.id];
  const formValues = watchFormValues();

  const phone = formValues['attendee[telephone]'] || telephone;
  const locationInfo = formValues['attendee[city_id]'] || cityId;
  const [selectedHeadshot, setSelectedHeadshot] = useState([]);

  const showLeadsOptions = moduleEnabledForCurrentAttendeeType(entities, 'AttendeeLeads');
  const filename = useMemo(() => headshot?.filename, [headshot]);

  useEffect(() => {
    setSelectedHeadshot([]);
  }, [filename]);

  useEffect(() => {
    customErrors.forEach(({ name, type, message }) => setError(name, { type, message }));
  }, [customErrors]);

  useEffect(() => {
    registerInput({ name: 'attendee[telephone]' }, { required: true });
    registerInput({ name: 'attendee[city_id]' });
    registerInput({ name: 'attendee[headshot]' });
    registerInput({ name: 'attendee[birthdate]' });
  }, [registerInput]);

  const attendeeFields = useMemo(() => {
    const result = Object.keys(currentAttendee.fields);
    result.push(...Object.keys(injectedLabelsFields));
    result.push('downloadVisaInvitationButton');
    return result;
  }, [currentAttendee.fields]);

  const defaultFieldsProps = useMemo(() => {
    if (!attendeeFields) return {};
    const names = {};
    attendeeFields.forEach((id) => {
      const { name, alias, display } = attendeeTypeCustomFields[id];
      names[name] = {
        name: alias || (name === 'city_id' ? '' : t(`forms.${camelize(name)}`)),
        display,
      };
    });
    return names;
  }, [attendeeFields]);

  const modalClose = () => {
    setOpenEditPhoto(false);
    setSelectedHeadshot([]);
    if (!headshot) setFormValue('attendee[headshot]', []);
  };

  const frontErrors = frontPreValidations(formValues, fieldsId);
  const disabled = submitDisabled || !!Object.values(frontErrors).length;

  const arrivalAirportTransferId = fieldsId[115];
  const departureAirportTransferId = fieldsId[116];
  const hotelCheckInId = fieldsId[117];
  const hotelCheckOutId = fieldsId[118];
  const dietaryRequirementId = fieldsId[112];
  const arrivalDateId = fieldsId[108];
  const departureDateId = fieldsId[107];

  const hotelCheckInField = getCustomFieldValue(formValues, hotelCheckInId);
  const hotelCheckOutField = getCustomFieldValue(formValues, hotelCheckOutId);
  const arrivalTransferField = getCustomFieldValue(formValues, arrivalAirportTransferId);
  const departureTransferField = getCustomFieldValue(formValues, departureAirportTransferId);
  const dietaryRequirementField = getCustomFieldValue(formValues, dietaryRequirementId);
  const arrivalDateField = getCustomFieldValue(formValues, arrivalDateId);
  const departureDateField = getCustomFieldValue(formValues, departureDateId);

  const hotelCheckInDiffersArrivalDate = !baseMoment(hotelCheckInField).isSame(arrivalDateField, 'day');
  const hotelCheckOutDiffersDepartureDate = !baseMoment(hotelCheckOutField).isSame(departureDateField, 'day');

  useEffect(() => {
    setFormValue(`attendee[customFields]._${dietaryRequirementId}`,
      dietaryRequirementField || 'No');
  }, [setFormValue, dietaryRequirementId, dietaryRequirementField]);

  useEffect(() => {
    if (!arrivalTransferField) setFormValue(`attendee[customFields]._${arrivalAirportTransferId}`, 'YES');
  }, [setFormValue, arrivalAirportTransferId, arrivalTransferField]);

  useEffect(() => {
    if (!departureTransferField) setFormValue(`attendee[customFields]._${departureAirportTransferId}`, 'YES');
  }, [setFormValue, departureAirportTransferId, departureTransferField]);

  useEffect(() => {
    if (hotelCheckInDiffersArrivalDate) setFormValue(`attendee[customFields]._${arrivalAirportTransferId}`, 'NO');
    if (hotelCheckOutDiffersDepartureDate) setFormValue(`attendee[customFields]._${departureAirportTransferId}`, 'NO');
  }, [setFormValue, hotelCheckInDiffersArrivalDate, hotelCheckOutDiffersDepartureDate,
    arrivalAirportTransferId, departureAirportTransferId]);

  const visaButtonEnabled = visaInvitationLinkEnabled(formValues, fieldsId);
  const downloadVisaInvitationButton = (
    <a
      href={
        visaButtonEnabled ? downloadVisaInvitationLink(formValues, fieldsId) : undefined
      }
      download
      className={clsx(
        classes.downloadVisaInvitationButton,
        !visaButtonEnabled && classes.downloadVisaInvitationButtonDisabled
      )}
    >
      <img
        src={DownloadIcon}
        alt=""
      />
      Download Invitation Letter for Visa Purpose
    </a>
  );

  const arrivalRelatedFields = {
    100: true,
    101: true,
    102: true,
    'Arrival Comment': true,
  };
  const departureRelatedFields = {
    103: true,
    104: true,
    105: true,
    111: true,
    'Departure Comment': true,
  };
  const hiddenFields = useMemo(() => {
    let result = {
      [cantArrivalTransferFieldName]: true,
      [cantDepartureTransferFieldName]: true,
      'Specify your special diet': true,
    };
    if (arrivalTransferField === 'NO') result = { ...result, ...arrivalRelatedFields };
    if (departureTransferField === 'NO') result = { ...result, ...departureRelatedFields };
    if (dietaryRequirementField === 'Yes') result = { ...result, 'Specify your special diet': false };
    if (hotelCheckInDiffersArrivalDate) result = {
      ...result,
      // 115: true,
      [cantArrivalTransferFieldName]: false,
    };
    if (hotelCheckOutDiffersDepartureDate) result = {
      ...result,
      // 116: true,
      [cantDepartureTransferFieldName]: false,
    };
    return result;
  }, [arrivalTransferField, departureTransferField, hotelCheckInDiffersArrivalDate,
    hotelCheckOutDiffersDepartureDate, dietaryRequirementField, arrivalRelatedFields,
    departureRelatedFields]);
  const shouldForceDisable = useMemo(() => {
    let result = {};
    if (hotelCheckInDiffersArrivalDate) result = {
      ...result,
      115: true,
    };
    if (hotelCheckOutDiffersDepartureDate) result = {
      ...result,
      116: true,
    };
    return result;
  }, [hotelCheckInDiffersArrivalDate, hotelCheckOutDiffersDepartureDate]);

  // const progress = getProgress(formValues, currentAttendee, attendeeTypeCustomFields);
  const [progress, setProgress] = useState(
    getProgress(formValues, currentAttendee, attendeeTypeCustomFields)
  );
  useEffect(() => {
    setProgress(
      getProgress(formValues, currentAttendee, attendeeTypeCustomFields)
    );
  });

  return (
    <>
      <FileModal
        title={t('actions.editPhoto')}
        open={openEditPhoto}
        handleClose={modalClose}
        action={{
          name: t('actions.accept'),
          onClick: () => setOpenEditPhoto(false),
          disabled: !selectedHeadshot.length,
        }}
        secondaryAction={{
          name: t('actions.cancel'),
          onClick: modalClose,
        }}
        fileInputProps={{
          id: 'attendee[headshot]',
          name: 'attendee[headshot]',
          label: errors?.attendee?.['headshot']?.message || t('forms.photo'),
          type: 'image',
          accept: 'image/*',
          error: errors?.attendee?.['headshot']?.message,
          value: selectedHeadshot,
          handleChange: (val) => {
            setSelectedHeadshot(val);
            setFormValue('attendee[headshot]', val);
          },
        }}
      />
      <div className={clsx(classes.profileHeaderHybrid,
        mobile && classes.profileHeaderHybridMobile)}
      >
        <div className={classes.profileHeader}>
          {(attendanceMode !== VIRTUAL && showLeadsOptions) && (
            <MyQrButton
              attendeeInfo={{ ...customFields, uuid: currentAttendee.uuid }}
            />
          )}
        </div>
      </div>
      <Grid container spacing={1} className={classes.mainInfoWrapper}>
        <Grid item container xs={12} md={8}>
          {Object.values(attendeeTypeCustomFields).filter(({ id }) => (
            attendeeFields.includes(id))).sort((a, b) => (a.order - b.order))
            .map((field) => {
              if (field && field.display) if (field.default) {
                const { name } = field;
                switch (name) {
                  case 'first_name':
                    return defaultFieldsProps.first_name.display ? (
                      <Grid item xs={12} key={field.id}>
                        <InputBase
                          className={classes.simpleInput}
                          id="attendee[first_name]"
                          name="attendee[first_name]"
                          label={`${defaultFieldsProps.first_name.name}*`}
                          placeholder={defaultFieldsProps.first_name.name}
                          defaultValue={firstName}
                          inputRef={registerInput({
                            required: { value: true, message: t('formError.requiredField') },
                          })}
                          error={errors?.attendee?.['first_name']?.message}
                        />
                      </Grid>
                    ) : null;
                  case 'last_name':
                    return defaultFieldsProps.last_name.display ? (
                      <Grid item xs={12} key={field.id}>
                        <InputBase
                          className={classes.simpleInput}
                          id="attendee[last_name]"
                          name="attendee[last_name]"
                          label={`${defaultFieldsProps.last_name.name}*`}
                          placeholder={defaultFieldsProps.last_name.name}
                          defaultValue={lastName}
                          inputRef={registerInput({
                            required: { value: true, message: t('formError.requiredField') },
                          })}
                          error={errors?.attendee?.['last_name']?.message}
                        />
                      </Grid>
                    ) : null;
                  case 'email':
                    return defaultFieldsProps.email.display ? (
                      <Grid item xs={12} key={field.id}>
                        <InputBase
                          className={classes.simpleInput}
                          id="attendee[email]"
                          name="attendee[email]"
                          label={`${defaultFieldsProps.email.name}*`}
                          placeholder={defaultFieldsProps.email.name}
                          defaultValue={email}
                          inputRef={registerInput({
                            required: { value: true, message: t('formError.requiredField') },
                            pattern: { value: emailRegexp, message: t('formError.enterValidEmail') },
                          })}
                          error={errors?.attendee?.['email']?.message}
                        />
                      </Grid>
                    ) : null;
                  case 'telephone':
                    return defaultFieldsProps.telephone.display ? (
                      <Grid item xs={12} key={field.id}>
                        <PhoneInput
                          className={classes.simpleInput}
                          id="attendee[telephone]"
                          name="attendee[telephone]"
                          label={defaultFieldsProps.telephone.name}
                          value={phone || ''}
                          handleChange={(val) => setFormValue('attendee[telephone]', val)}
                          error={errors?.attendee?.['telephone']?.message}
                        />
                      </Grid>
                    ) : null;
                  default:
                    return null;
                }
              }
              return null;
            })}
        </Grid>
        <Grid item container spacing={1} xs={12} md={4} className={classes.avatarGrid}>
          <div className={classes.avatarWrapper}>
            <IconButton
              className={classes.editPhotoButton}
              aria-label="edit-photo"
              onClick={() => setOpenEditPhoto(true)}
              edge="end"
              color="primary"
              size="small"
            >
              <CreateIcon />
            </IconButton>
            <div className={classes.progressWrapper}>
              <CircularProgress
                variant="determinate"
                className={classes.progressBakcground}
                size={107}
                thickness={1}
                value={100}
              />
              <CircularProgress
                variant="static"
                className={classes.progress}
                size={107}
                thickness={1}
                value={progress}
                style={{ transform: 'rotate(60deg)' }}
              />
              <Typography className={classes.progressValue}>
                {progress}%
              </Typography>
            </div>
            <Typography className={classes.progressComplete} variant="body1">
              {progress === 100 ? 'PROFILE COMPLETED' : 'COMPLETE YOUR PROFILE'}
            </Typography>
            <Avatar
              src={headshot?.filename && headshot.small}
              className={classes.avatar}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={1} className={classes.customFieldsWrapper}>
        {Object.values(attendeeTypeCustomFields).filter(({ id }) => (
          attendeeFields.includes(id))).sort((a, b) => (a.order - b.order))
          .map((field) => {
            if (field && field.display
              && !validateField(hiddenFields, field.internalId, field.name)) if (field.default) {
              const { name } = field;
              switch (name) {
                case 'birthdate':
                  return defaultFieldsProps.birthdate.display ? (
                    <Grid
                      item
                      xs={12}
                      md={validateField(fieldSizes, field.internalId, field.name) || 12}
                      key={field.id}
                    >
                      <DateInput
                        className={classes.simpleInput}
                        id="attendee[birthdate]"
                        name="attendee[birthdate]"
                        label={defaultFieldsProps.birthdate.name}
                        value={birthdate || ''}
                        handleChange={(val) => setFormValue('attendee[birthdate]', val)}
                        error={errors?.attendee?.['birthdate']?.message}
                      />
                    </Grid>
                  ) : null;
                case 'company':
                  return defaultFieldsProps.company.display ? (
                    <Grid
                      item
                      xs={12}
                      md={validateField(fieldSizes, field.internalId, field.name) || 12}
                      key={field.id}
                    >
                      <InputBase
                        className={classes.simpleInput}
                        id="attendee[company]"
                        name="attendee[company]"
                        label={defaultFieldsProps.company.name}
                        placeholder={defaultFieldsProps.company.name}
                        defaultValue={company}
                        inputRef={registerInput()}
                        error={errors?.attendee?.['company']?.message}
                      />
                    </Grid>
                  ) : null;
                case 'job_title':
                  return defaultFieldsProps.job_title.display ? (
                    <Grid
                      item
                      xs={12}
                      md={validateField(fieldSizes, field.internalId, field.name) || 12}
                      key={field.id}
                    >
                      <InputBase
                        className={classes.simpleInput}
                        id="attendee[job_title]"
                        name="attendee[job_title]"
                        label={defaultFieldsProps.job_title.name}
                        placeholder={defaultFieldsProps.job_title.name}
                        defaultValue={jobTitle}
                        inputRef={registerInput()}
                        error={errors?.attendee?.['job_title']?.message}
                      />
                    </Grid>
                  ) : null;
                case 'city_id':
                  return defaultFieldsProps.city_id.display ? (
                    <CountryField
                      handleChange={(val) => setFormValue('attendee[city_id]', val)}
                      identifiers={{
                        city: 'attendee[city_id]',
                        region: 'attendee[region_id]',
                        country: 'attendee[country_id]',
                      }}
                      error={errors?.attendee?.['city_id']?.message}
                      value={locationInfo}
                      key={field.id}
                    />
                  ) : null;
                case 'alternative_email':
                  return defaultFieldsProps.alternative_email.display ? (
                    <Grid
                      item
                      xs={12}
                      md={validateField(fieldSizes, field.internalId, field.name) || 12}
                      key={field.id}
                    >
                      <InputBase
                        className={classes.simpleInput}
                        id="attendee[alternative_email]"
                        name="attendee[alternative_email]"
                        label={`${defaultFieldsProps.alternative_email.name}`}
                        helperText={(
                          <>
                            This person will be in copy of World Meeting communications.
                            <br />
                            They will be able to connect to your profile, complete and modify it.
                          </>
                        )}
                        placeholder={defaultFieldsProps.alternative_email.name}
                        defaultValue={alternativeEmail}
                        inputRef={registerInput({
                          pattern: { value: emailRegexp, message: t('formError.enterValidEmail') },
                        })}
                        error={errors?.attendee?.['alternative_email']?.message}
                      />
                    </Grid>
                  ) : null;
                default:
                  return null;
              }
            } else {
              if (field.name === 'downloadVisaInvitationButton') return (
                <Grid
                  item
                  xs={12}
                  md={validateField(fieldSizes, field.internalId, field.name) || 12}
                  key={field.id}
                >
                  <CustomField
                    getIdentifier={(id) => `attendee[customFields]._${id}`}
                    field={{
                      ...field,
                      content: downloadVisaInvitationButton,
                    }}
                  />
                </Grid>
              );

              if ([100, 103].includes(field.internalId)) {
                let helperText;
                if (field.internalId === 100) helperText = 'If you cannot find your flight number, please share the details in the "Arrival Comment" field below.';
                if (field.internalId === 103) helperText = 'If you cannot find your flight number, please share the details in the "Departure Comment" field below.';

                const id = `attendee[customFields]._${field.id}`;
                const flightValue = watchFormValues(id);

                let flightDate;
                let timeId;
                let placeId;
                let flightType;
                if (field.internalId === 100) {
                  flightType = 'arrival';
                  flightDate = arrivalDateField;
                  timeId = `attendee[customFields]._${fieldsId[101]}`;
                  placeId = `attendee[customFields]._${fieldsId[102]}`;
                } else {
                  flightType = 'departure';
                  flightDate = departureDateField;
                  timeId = `attendee[customFields]._${fieldsId[104]}`;
                  placeId = `attendee[customFields]._${fieldsId[105]}`;
                }
                const onFlightSuccess = (timeValue, placeValue) => {
                  setFormValue(timeId, timeValue);
                  setFormValue(placeId, placeValue);
                };
                const setFlightError = (errorMessage) => {
                  setError(id, {
                    type: 'error',
                    message: errorMessage,
                  });
                };
                return (
                  <Grid
                    item
                    xs={12}
                    md={validateField(fieldSizes, field.internalId, field.name) || 12}
                    key={field.id}
                  >
                    <FlightInput
                      id={id}
                      label={field.name}
                      placeholder={field.name}
                      className={classes.simpleInput}
                      defaultValue={currentAttendee.fields[field.id]}
                      inputRef={registerInput}
                      error={errors?.attendee?.customFields?.[`_${field.id}`]?.types?.message}
                      disabled={field.editAdminOnly}
                      flightValue={flightValue}
                      flightDate={flightDate}
                      onFlightSuccess={onFlightSuccess}
                      setFlightError={setFlightError}
                      helperText={helperText}
                      flightType={flightType}
                    />
                  </Grid>
                );
              }

              return (
                <Grid
                  item
                  xs={12}
                  md={validateField(fieldSizes, field.internalId, field.name) || 12}
                  key={field.id}
                >
                  <CustomField
                    getIdentifier={(id) => `attendee[customFields]._${id}`}
                    field={field}
                    value={currentAttendee.fields[field.id]}
                    inputRef={registerInput}
                    watchFormValues={watchFormValues}
                    setFormValue={setFormValue}
                    error={
                      frontErrors[field.id]
                      || errors?.attendee?.customFields?.[`_${field.id}`]?.types?.message
                    }
                    disabled={field.editAdminOnly || shouldForceDisable[field.internalId]}
                    disabledButton={disabled}
                    clearError={clearError}
                  />
                </Grid>
              );
            }
            return null;
          })}
        <Grid className={classes.buttonContainer} item xs={12}>
          <Button
            type="submit"
            disabled={disabled}
            onClick={() => clearError()}
            variant="primary"
          >
            {disabled ? t('status.saving') : t('actions.save')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

MyProfileForm.propTypes = {
  disabled: PropTypes.bool,
  registerInput: PropTypes.func.isRequired,
  setFormValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  customErrors: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string,
    })
  ),
  watchFormValues: PropTypes.func.isRequired,
  errors: CustomPropTypes.formErrors.isRequired,
  entities: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.string,
  })).isRequired,
  attendeeTypeCustomFields: PropTypes.objectOf(
    CustomPropTypes.attendeeTypeCustomField
  ),
  attendeeTypes: PropTypes.objectOf(
    CustomPropTypes.attendeeType
  ).isRequired,
};

MyProfileForm.defaultProps = {
  disabled: false,
  customErrors: [],
  attendeeTypeCustomFields: {},
};

const mapStateToProps = ({
  entities,
  entities: {
    attendeeTypes,
    attendeeTypeCustomFields,
  },
}) => ({
  entities,
  attendeeTypes,
  attendeeTypeCustomFields,
});

export default compose(
  withForm({ required: false, autoComplete: 'off' }),
  connect(mapStateToProps)
)(MyProfileForm);
