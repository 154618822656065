import { LOGOUT } from '../../actions/app';
import { fetchConfig } from '../../actions/callApi';
import { setToken, removeToken, setLoginType } from '../../helpers/auth';
import { getEntity, getEventUri } from '../../helpers/getters';

import DemodeskAuth from '../../helpers/demodeskAuth';

const [, loginSuccess] = fetchConfig.login.types;

const demodeskAuthenticator = new DemodeskAuth({
  foreignHost: 'eventtia.com',
  meetingHost: 'demodesk.com',
});

// HACK
const VALEO_EVENT_URIS = [
  'valeo-integration',
  'valeo-integration-tests',
  'tech-day-renault',
  'valeo-tech-day-renault',
  'valeo-demo',
  'valeo-iaa-mobility',
];

export default (store) => (next) => (action) => {
  const shouldHandleDemodeskCookies = VALEO_EVENT_URIS.includes(getEventUri());

  switch (action.type) {
    case loginSuccess: {
      if (!action.response) break;

      const { eventUri } = getEntity(store.getState().entities, 'events');

      const { auth_token: authToken, loginType } = action.response;

      if (shouldHandleDemodeskCookies) demodeskAuthenticator.logout();

      setToken(eventUri, authToken);
      setLoginType(eventUri, loginType || 'assistant');

      break;
    }
    case LOGOUT: {
      const { eventUri } = getEntity(store.getState().entities, 'events');

      if (shouldHandleDemodeskCookies) demodeskAuthenticator.logout();

      removeToken(eventUri);
      setLoginType(eventUri, '');

      break;
    }
    default:
      break;
  }

  return next(action);
};
