import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import PersonIcon from '@material-ui/icons/Person';
import Input from 'eventtia-ui-components/lib/Input';
import Button from 'eventtia-ui-components/lib/Button';
import Loader from 'eventtia-ui-components/lib/Loader';
import Message from '../Message';
import Switch from '../Switch';
import callApi from '../../actions/callApi';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import { getCurrentAttendee } from '../../helpers/getters';
import useDidUpdate from '../../hooks/useDidUpdate';

const useStyles = makeStyles((theme) => ({
  question: {
    position: 'relative',
    margin: '0px auto 16px auto',
    padding: theme.spacing(2, 4),
    borderRadius: theme.spacing(1),
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    backgroundColor: theme.palette.common.white,
    overflow: 'hidden',
  },
  errorMessage: {
    marginBottom: theme.spacing(2),
  },
  avatar: {
    height: 52,
    width: 52,
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.lightGrey.main,
    color: theme.palette.primary.main,
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
  },
  inputsContainer: {
    flex: 1,
  },
  top: {
    borderBottom: `1px solid ${theme.palette.lightGrey.main}`,
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
  },
  bottom: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      '& $switchLabel': {
        flex: 'unset',
      },
    },
  },
  switchLabel: {
    color: theme.palette.darkGrey.main,
    marginLeft: theme.spacing(-2),
    flex: 1,
  },
  questionLength: {
    textAlign: 'right',
  },
}));

const MAX_LENGTH = 450;

const QAQuestionCreator = ({
  entities,
  qaSession,
  askingQuestion,
  askQuestionError,
  callApi: dispatchCallApi,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('qaSessions');

  const [question, setQuestion] = useState('');
  const [anonymousQuestion, setAnonymousQuestion] = useState(false);

  const {
    id: sessionId,
    anonymousQuestions: canAskAnonymously,
  } = qaSession;

  const [message, setMessage] = useState();
  useDidUpdate(() => {
    if (askQuestionError) setMessage({
      type: 'error',
      text: askQuestionError,
    });
  }, [askQuestionError]);
  const clearMessage = () => {
    setMessage({ type: message?.type });
  };

  const currentAttendee = getCurrentAttendee(entities, { parseCustomFields: true });
  const { headshot, firstName, lastName } = currentAttendee;
  const fullName = `${firstName} ${lastName}`;
  const fallbackAvatar = anonymousQuestion
    ? <PersonIcon fontSize="large" />
    : `${firstName?.[0]}${lastName?.[0]}`.toLocaleUpperCase();

  const submit = () => {
    clearMessage();
    dispatchCallApi(
      'askQaQuestion',
      { sessionId, question, anonymousQuestion }
    ).then((res) => {
      if (!res.error) {
        setQuestion('');
        if (!res.response) setMessage({
          type: 'success',
          text: t('pendingApproval'),
        });
      }
    });
  };

  const submitDisabled = askingQuestion || !question.trim();

  return (
    <>
      <Message
        type={message?.type}
        message={message?.text}
        onClose={() => {
          clearMessage();
        }}
        className={classes.errorMessage}
      />
      <div className={classes.question}>
        <Loader loading={askingQuestion} variant="absolute" />
        <div className={classes.top}>
          <Avatar
            src={!anonymousQuestion ? (headshot?.filename && headshot.small) : undefined}
            alt={fullName}
            className={classes.avatar}
          >
            {fallbackAvatar}
          </Avatar>
          <div className={classes.inputsContainer}>
            <Input
              id="qaQuestion"
              value={question}
              handleChange={setQuestion}
              placeholder={t('typeQuestion')}
              inputProps={{ maxLength: MAX_LENGTH, required: true }}
              multiline
              rows={3}
              rowsMax={3}
              fullWidth
            />
            <FormHelperText className={classes.questionLength}>
              {`${question.length}/${MAX_LENGTH}`}
            </FormHelperText>
          </div>
        </div>
        <div className={classes.bottom}>
          {canAskAnonymously && (
            <div className={classes.switchLabel}>
              <FormControlLabel
                value="start"
                control={(
                  <Switch
                    color="secondary"
                    onChange={() => setAnonymousQuestion(
                      !anonymousQuestion
                    )}
                    checked={anonymousQuestion}
                  />
                )}
                label={t('askAnonymously')}
                labelPlacement="start"
              />
            </div>
          )}
          <Button
            onClick={submit}
            className={classes.sendButton}
            disabled={submitDisabled}
          >
            {t('global:actions.send')}
          </Button>
        </div>
      </div>
    </>
  );
};

QAQuestionCreator.propTypes = {
  entities: CustomPropTypes.entities.isRequired,
  qaSession: CustomPropTypes.qaSession.isRequired,
  askingQuestion: PropTypes.bool.isRequired,
  askQuestionError: PropTypes.string,
  callApi: PropTypes.func.isRequired,
};

QAQuestionCreator.defaultProps = {
  askQuestionError: undefined,
};

const mapStateToProps = ({
  entities,
  fetchStatus: {
    askQaQuestion: {
      isFetching: askingQuestion,
      error: askQuestionError,
    },
  },
}) => ({
  entities,
  askingQuestion,
  askQuestionError,
});

export default connect(mapStateToProps, { callApi })(QAQuestionCreator);
