import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Content = (props) => (
  <SvgIcon {...props} viewBox="0 0 21 21">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <path d="M41.841 10H19.255l-4-4H.841v36h.033l-.033 2h41v-1.863L49.159 16h-7.318v-6zM14.427 8l4 4h21.414v4H8.083L2.841 34.72V8h11.586zm25.451 33.73l-.037.27H2.88L9.6 18h36.923l-6.645 23.73z" />
    </svg>
  </SvgIcon>
);
export default Content;
