import React from 'react';
import clsx from 'clsx';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from 'eventtia-ui-components/lib/Tooltip';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import FieldHelperText from 'eventtia-ui-components/lib/FieldHelperText';

const StyledLabel = withStyles(() => ({
  shrink: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    maxWidth: '133%',
    pointerEvents: 'all',
    lineHeight: 2,
    transform: 'translate(0, 0px) scale(0.75)',
  },
}))(InputLabel);

const StyledRadio = withStyles((theme) => ({
  root: {
    padding: 0,
    margin: theme.spacing(0, 1),
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  error: {
    color: theme.palette.red.main,
    '&$checked': {
      color: theme.palette.red.main,
    },
  },
  success: {
    color: theme.palette.green.main,
    '&$checked': {
      color: theme.palette.green.main,
    },
  },
  checked: {
  },
}))(({
  error, success, classes, disabled, ...other
}) => (
  <Radio
    classes={{
      root: clsx(classes.root,
        !disabled && success && classes.success,
        !disabled && error && classes.error),
      checked: classes.checked,
    }}
    disabled={disabled}
    {...other}
  />
));

const useStyles = makeStyles((theme) => ({
  radioGroup: {
    marginTop: theme.spacing(3),
  },
  radioContainer: {
    padding: 0,
    margin: theme.spacing(1, 0),
  },
  helperText: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(2),
    marginTop: 0,
  },
}));

const RadioButtons = (props) => {
  const {
    handleChange, error, success, options, defaultValue,
    value, row, name, disabled: disabledForm, inputRef,
    id, label,
  } = props;
  const classes = useStyles();
  return (
    <FormControl fullWidth>
      <Tooltip title={label}>
        <StyledLabel htmlFor={id} shrink>{label}</StyledLabel>
      </Tooltip>
      <RadioGroup
        id={id}
        ref={inputRef}
        value={value}
        defaultValue={defaultValue}
        name={name}
        row={row}
        className={classes.radioGroup}
        onChange={handleChange ? ((e) => handleChange(e.target.value)) : undefined}
      >
        {options && options.map(({
          value: optionValue, disabled, label: optionLabel, details,
        }) => (
          <div key={optionValue} className={classes.radioContainer}>
            <FormControlLabel
              value={optionValue}
              label={optionLabel}
              disabled={disabledForm || disabled}
              control={(
                <StyledRadio
                  error={error}
                  success={success}
                />
              )}
            />
            {!!details
              && (<FieldHelperText className={classes.helperText}>{details}</FieldHelperText>
              )}
          </div>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtons;

RadioButtons.propTypes = {
  id: PropTypes.string,
  label: PropTypes.node,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  name: PropTypes.string,
  row: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  defaultValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  handleChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    details: PropTypes.string,
    disabled: PropTypes.bool,
  })).isRequired,
};

RadioButtons.defaultProps = {
  id: undefined,
  label: '',
  inputRef: undefined,
  value: undefined,
  defaultValue: undefined,
  handleChange: undefined,
  row: undefined,
  name: undefined,
  error: false,
  success: false,
  disabled: false,
};
