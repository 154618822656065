import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const StaffSales = (props) => (
  <SvgIcon {...props} viewBox="0 0 25 21">
    <svg
      width="25"
      height="21"
      viewBox="0 0 25 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.9876 4.1845C23.9534 4.02952 23.8851 3.91329 23.7483 3.8358L18.0392 0.077489C17.9709 0.0387445 17.8683 0 17.7999 0H8.29627C8.22789 0 8.12534 0.0387445 8.05696 0.077489L2.34792 3.8358C2.21118 3.91329 2.14281 4.02952 2.10863 4.1845C2.07444 4.33949 2.10863 4.49447 2.177 4.6107L12.6378 20.7675C12.7405 20.9225 12.8771 21 13.0139 21C13.1506 21 13.3216 20.9225 13.39 20.7675L23.8509 4.6107C23.9876 4.49447 24.0218 4.33949 23.9876 4.1845ZM7.88603 1.43358L8.63813 4.30075H3.54443L7.88603 1.43358ZM9.62951 4.30075L8.77487 1.08487H17.2872L16.4667 4.30075H9.62951ZM16.1932 5.38562L13.0481 17.4741L9.90301 5.38562H16.1932ZM8.91161 5.38562L12.296 18.4041L3.88629 5.38562H8.91161ZM17.1846 5.38562H22.2441L13.8001 18.4041L17.1846 5.38562ZM22.5518 4.30075H17.4581L18.2102 1.43358L22.5518 4.30075Z"
        fill="currentColor"
      />
      <path
        d="M22.4794 4.51879H17.1311L17.9207 2.17293L22.4794 4.51879ZM23.987 4.42368C23.9511 4.29688 23.8794 4.20178 23.7358 4.13838L17.7412 1.0634C17.6695 1.0317 17.5617 1 17.49 1H7.51111C7.43932 1 7.33164 1.0317 7.25984 1.0634L1.26534 4.13838C1.12177 4.20178 1.04997 4.29688 1.01408 4.42368C0.978185 4.55049 1.01408 4.67729 1.08588 4.77239L12.0698 17.9916C12.1775 18.1184 12.321 18.1818 12.4646 18.1818C12.6082 18.1818 12.7877 18.1184 12.8595 17.9916L23.8435 4.77239C23.987 4.67729 24.023 4.55049 23.987 4.42368ZM7.08037 2.17293L7.87007 4.51879H2.52168L7.08037 2.17293ZM8.91102 4.51879L8.01365 1.88762H16.9516L16.0901 4.51879H8.91102ZM15.8029 5.40641L12.5005 15.297L9.19819 5.40641H15.8029ZM8.15723 5.40641L11.7109 16.0579L2.88063 5.40641H8.15723ZM16.8439 5.40641H22.1564L13.2902 16.0579L16.8439 5.40641Z"
        stroke="currentColor"
        strokeWidth="0.1"
      />
    </svg>
  </SvgIcon>
);

export default StaffSales;
