import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const WhosHere = (props) => (
  <SvgIcon {...props} viewBox="0 0 23 23">
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1098 14.1098L22 22M8.89017 16.7803C13.2601 16.7803 16.7803 13.2601 16.7803 8.89017C16.7803 4.52023 13.2601 1 8.89017 1C4.52023 1 1 4.52023 1 8.89017C1 13.2601 4.52023 16.7803 8.89017 16.7803Z"
        stroke="currentColor"
        strokeWidth="0.85"
      />
    </svg>
  </SvgIcon>
);

export default WhosHere;
