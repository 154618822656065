import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tabs from 'eventtia-ui-components/lib/Tabs';
import PeopleIcon from '@material-ui/icons/People';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Modal from 'eventtia-ui-components/lib/Modal';
import Button from 'eventtia-ui-components/lib/Button';
import Message from '../../components/Message';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import { getCurrentParticipant } from '../../helpers/getters';
import RequestedMeeting from '../../components/RequestedMeeting';
import MeetingRequestModal from '../../components/MeetingRequestModal';
import waitingImgSrc from '../../assets/waiting.png';
import callApi from '../../actions/callApi';
import { setActiveMeeting } from '../../actions/app';
import { MEETING_REQUEST_TYPES } from '../../helpers/constants';

const useStyles = makeStyles((theme) => ({
  message: {
    marginBottom: theme.spacing(2),
  },
  requestsCount: {
    margin: theme.spacing(7, 0, 2.2),
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  hideCount: {
    display: 'none',
  },
  requestsWrapper: {
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 350px)',
  },
  slotCard: {
    color: theme.palette.darkGrey.main,
    boxShadow: theme.customShadows.default,
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.spacing(1.25),
    padding: theme.spacing(2, 4),
    marginBottom: theme.spacing(2.5),
    display: 'flex',
    flexDirection: 'row',
    width: 482,
    height: 98,
    alignItems: 'center',
  },
  avatar: {
    height: 60,
    width: 60,
    borderRadius: '50%',
    backgroundColor: theme.palette.lightGrey.main,
    boxShadow: theme.customShadows.small,
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    fontSize: 18,
    marginRight: theme.spacing(0.5),
  },
  info: {
    marginLeft: theme.spacing(2),
  },
  date: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(0.8),
  },
  description: {
    fontWeight: 600,
  },
  modalPaper: {
    borderRadius: 10,
  },
  modalContent: {
    padding: theme.spacing(4, 3.5, 2.5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttons: {
    marginTop: theme.spacing(3.5),
    display: 'flex',
    justifyContent: 'center',
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  waitingContainer: {
    marginTop: theme.spacing(12),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  waitingImage: {
    width: 240,
  },
  waitingMessage: {
    marginTop: theme.spacing(1.5),
    textAlign: 'center',
    whiteSpace: 'pre-line',
  },
}));

const WaitingMessage = ({ requestsType }) => {
  const classes = useStyles();
  const { t } = useTranslation(['meeting']);

  return (
    <div className={classes.waitingContainer}>
      <img
        className={classes.waitingImage}
        src={waitingImgSrc}
        alt="waiting"
      />
      <Typography className={classes.waitingMessage}>
        {requestsType === 'received' && (
          t('requests.noReceivedRequestYet')
        )}
        {requestsType === 'sent' && (
          t('requests.noSentRequestsYet')
        )}
      </Typography>
    </div>
  );
};

WaitingMessage.propTypes = {
  requestsType: PropTypes.string.isRequired,
};

const { RECEIVED, SENT } = MEETING_REQUEST_TYPES;
const REQUEST_TYPES = [RECEIVED, SENT];

const MeetingRequest = ({
  entities, businessConferenceParticipants, attendees, attendeeTypeCustomFields, participantTypes,
  slotId, meetingRequests, callApi: dispatchCallApi, setActiveMeeting: dispatchSetActiveMeeting,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['meeting']);
  const [activeType, setActiveType] = useState(0);
  const [openedRequest, setOpenedRequest] = useState(undefined);
  const [hideOpenedRequest, setHideOpenedRequest] = useState(false);
  const [approveDialog, setApproveDialog] = useState(false);
  const [rejectDialog, setRejectDialog] = useState(false);
  const [message, setMessage] = useState();

  const closeAllDialogs = () => {
    setOpenedRequest(undefined);
    setHideOpenedRequest(false);
    setApproveDialog(false);
    setRejectDialog(false);
  };

  const toggleDialog = () => {
    setHideOpenedRequest((prev) => !prev);
    if (approveDialog) setApproveDialog((prev) => !prev);
    else setRejectDialog((prev) => !prev);
  };

  const closeModal = () => setOpenedRequest(undefined);

  const currentParticipant = getCurrentParticipant(entities);
  const currentParticipantType = participantTypes[currentParticipant?.participantTypeId];
  const agenda = currentParticipantType?.agenda || {};
  const businessConferenceId = currentParticipantType.businessConference.id;

  const slotsBySlotId = {};
  Object.values(agenda).forEach(([startDate, endDate, id]) => {
    slotsBySlotId[id] = { startDate, endDate };
  });

  const items = REQUEST_TYPES.map((type, index) => ({
    name: t(`requests.${type}`),
    onClick: () => setActiveType(index),
  }));

  const currentSlot = slotsBySlotId[slotId];
  const { startDate, endDate } = currentSlot;

  const meetingsRequestCounter = {
    received: {},
    requested: {},
  };

  Object.values(meetingRequests).forEach((request) => {
    const { sentBy: { id } } = request;
    if (id === currentParticipant.id) {
      if (!meetingsRequestCounter.requested[request.slotId]) meetingsRequestCounter
        .requested[request.slotId] = [];
      meetingsRequestCounter.requested[request.slotId].push(request.id);
    } else {
      if (!meetingsRequestCounter.received[request.slotId]) meetingsRequestCounter
        .received[request.slotId] = [];
      meetingsRequestCounter.received[request.slotId].push(request.id);
    }
  });

  const requestsReceived = meetingsRequestCounter.received[slotId] || [];
  const requestsSent = meetingsRequestCounter.requested[slotId] || [];

  useEffect(() => {
    if (requestsReceived.length === 0 && requestsSent.length >= 1) setActiveType(1);
  }, []);

  const activeRequests = REQUEST_TYPES[activeType] === RECEIVED ? requestsReceived : requestsSent;

  const handleRejection = () => {
    setRejectDialog((prev) => !prev);
    dispatchCallApi('rejectMeetingRequest', { businessConferenceId, meetingRequestId: openedRequest.id })
      .then(({ error }) => {
        if (error) setMessage({ type: 'error', text: error });
        else setMessage({ type: 'success', text: t('requests.successReject') });
      });
    closeAllDialogs();
  };
  const handleApprove = () => {
    setApproveDialog((prev) => !prev);
    dispatchCallApi('approveMeetingRequest', { businessConferenceId, meetingRequestId: openedRequest.id })
      .then((res) => {
        if (res.error) setMessage({ type: 'error', text: res.error[0] });
        else {
          setMessage({ type: 'success', text: t('requests.successApprove') });
          dispatchSetActiveMeeting(Object.values(
            res.response.entities.businessConferenceMeetings
          )[0].id);
        }
      });
    closeAllDialogs();
  };

  return (
    <>
      <Message
        className={classes.message}
        type={message?.type}
        onClose={() => setMessage()}
        message={message?.text}
      />
      <div className={(classes.slotCard)}>
        <div className={classes.avatar}>
          <PeopleIcon />
        </div>
        <div className={classes.info}>
          <div>
            <Typography variant="body2" className={classes.date}>
              <AccessTimeIcon className={classes.icon} />
              {moment(startDate).format('MMM DD, h:mm')} - {moment(endDate).format('h:mm')}
            </Typography>
            <Typography variant="body2" className={classes.description}>
              {t('meeting.networkingMeeting')}
            </Typography>
          </div>
        </div>
      </div>
      <Tabs
        variant="filter"
        activeItem={activeType}
        items={items}
      />
      <Typography variant="body2" className={clsx(classes.requestsCount, !activeRequests.length && classes.hideCount)}>
        {activeRequests.length} {activeRequests.length === 1 ? t('requests.request') : t('requests.requests')}
      </Typography>
      <Modal
        open={(approveDialog || rejectDialog)}
        maxWidth="sm"
        handleClose={closeModal}
        classes={{ paper: classes.modalPaper }}
      >
        <div className={classes.modalContent}>
          <Typography>
            {approveDialog && t('requests.confirmApprove')}
            {rejectDialog && (REQUEST_TYPES[activeType] === RECEIVED ? t('requests.confirmReject') : t('requests.confirmCancel'))}
          </Typography>
          <div className={classes.buttons}>
            {toggleDialog && (
              <Button
                small
                variant="tertiary"
                className={classes.cancelButton}
                onClick={toggleDialog}
              >
                {t('global:actions.cancel')}
              </Button>
            )}
            <Button
              small
              variant="primary"
              onClick={approveDialog ? handleApprove : handleRejection}
            >
              {t('global:confirmation.yes')}
            </Button>
          </div>
        </div>
      </Modal>
      <>
        {activeRequests.length > 0 ? (
          <div className={classes.requestsWrapper}>
            {activeRequests.map((requestId) => (
              <RequestedMeeting
                key={requestId}
                meetingRequest={meetingRequests[requestId]}
                attendees={attendees}
                attendeeTypeCustomFields={attendeeTypeCustomFields}
                businessConferenceParticipants={businessConferenceParticipants}
                setOpenedRequest={setOpenedRequest}
                sentByMe={REQUEST_TYPES[activeType] === SENT}
              />
            ))}
          </div>
        ) : (
          <WaitingMessage requestsType={REQUEST_TYPES[activeType]} />
        )}
      </>
      {(!!openedRequest && !hideOpenedRequest) && (
        <MeetingRequestModal
          attendees={attendees}
          closeModal={closeModal}
          openedRequest={openedRequest}
          attendeeTypeCustomFields={attendeeTypeCustomFields}
          businessConferenceParticipants={businessConferenceParticipants}
          requestsSent={activeType === 1}
          setApproveDialog={setApproveDialog}
          setRejectDialog={setRejectDialog}
          setHideOpenedRequest={setHideOpenedRequest}
        />
      )}
    </>
  );
};

MeetingRequest.propTypes = {
  callApi: PropTypes.func.isRequired,
  entities: CustomPropTypes.entities.isRequired,
  meetingRequests: PropTypes.objectOf(CustomPropTypes.meetingRequest),
  businessConferenceParticipants: PropTypes.objectOf(
    CustomPropTypes.participant
  ).isRequired,
  attendees: PropTypes.objectOf(
    CustomPropTypes.attendee
  ),
  attendeeTypeCustomFields: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  participantTypes: CustomPropTypes.participantType,
  slotId: PropTypes.number.isRequired,
  setActiveMeeting: PropTypes.func.isRequired,
};

MeetingRequest.defaultProps = {
  attendees: {},
  meetingRequests: {},
  attendeeTypeCustomFields: {},
  participantTypes: {},
};

const mapStateToProps = ({
  entities,
  entities: {
    attendees,
    meetingRequests,
    businessConferenceParticipants,
    businessConferenceParticipantTypes,
  },
}) => ({
  entities,
  attendees,
  businessConferenceParticipants,
  participantTypes: businessConferenceParticipantTypes,
  meetingRequests,
});

export default connect(mapStateToProps, { callApi, setActiveMeeting })(MeetingRequest);
